module.exports = {
    vertical: function(vueInstance){
        // 'optionHeight' BASED ON CONSTANT OPTION CONTAINER HEIGHT OF 24rem
        //  OTHERWISE, CAN USE '$(optionsMenu).css('height').replace('px','')' 
        //let optionsMenu = $(vueInstance.$el).find(`.profile__container--options`)[0];
        let optionHeight = 240;
        let optionY = $(vueInstance.$el)[0].getBoundingClientRect().y;
        let optionBottomEdge = Number(optionHeight) + Number(optionY);
        let windowHeight = Number(window.innerHeight - 50);
        let testAlignment = optionBottomEdge > windowHeight;
        return testAlignment;
    }
}