<template>
    <div class="profile__container--outer profile__container--languages"
        v-bind:class="addWrapperClasss">
        <div class="profile__container--header">
            <h3>Languages</h3>
            <div v-on:click="addRecord" class="button button-formAction add" v-show="!disabled"> 
                <i class="flaticon2-plus"></i> 
                Add new
            </div>
        </div>
        <div 
            v-bind:class="determineRecordClass(record, 'language-container profile__container--outer profile__container--main')"
            class="language-container profile__container--outer profile__container--main"
            v-for="(record, index) in records"
            v-bind:key="record.vue_key">
            <!-- <div v-for="(record, index) in records" v-bind:key="record.vue_key">
                <div class="language-container"> -->
                    <input v-if="record.id" type="hidden" v-model="record.id" v-bind:name="'job_request[job_request_languages_attributes][' + record.vue_key + '][id]'">
                    <input v-if="record._destroy" type="hidden" v-model="record._destroy" v-bind:name="'job_request[job_request_languages_attributes][' + record.vue_key + '][_destroy]'">

                    <form-field
                        v-if="languagesLoaded"
                        title="Language"
                        fieldtype="form-dropdown"
                        v-bind:inputname="'job_request[job_request_languages_attributes][' + record.vue_key + '][language_id]'"
                        v-bind:alloptions="initialLanguageOptions"
                        v-bind:startvalue="record.language"
                        searchurl="/autocomplete/languages"
                        v-bind:haserrors="record.errors.language"
                        v-on:event-bubble="languageSelected($event, record)"
                        v-bind:isdisabled="disabled"
                    ></form-field>

                    <!-- In original system: redio buttons; signle row -->
                    <form-field
                        title="Proficiency"
                        fieldtype="form-radio"
                        v-bind:inputname="'job_request[job_request_languages_attributes][' + record.vue_key + '][proficiency]'"
                        v-bind:startvalue="record.proficiency"
                        v-bind:alloptions="languageProficiencyOptions"
                        v-bind:haserrors="record.errors.proficiency"
                        v-bind:isdisabled="disabled"
                    ></form-field>

                    <div v-show="!disabled && !(record._destroy)" v-on:click="removeRecord(index)" class="button button-formAction danger" title="Delete"><i class="flaticon2-delete"></i></div>

                    <div v-show="!disabled && (record._destroy)" v-on:click="undoRemove(index)" class="button button-formAction" title="Undo delete"><i class="flaticon2-refresh"></i></div>

                <!-- </div>
            </div> -->
        </div>
    </div>
</template>

<script>
    import FormField from '../../../form-field/form-field.vue'

    export default {
        props: [
            'intialRecords',
            'languageProficiencyOptions',
            'addWrapperClasss',
            'isdisabled'
        ],
        data: function(){
            var records = this.intialRecords || [];
            records.forEach(function(item, i) { item.vue_key = i; });
            var disabled = this.isdisabled || false;
            
            return { 
                nextVueKey: records.length, 
                records: records,
                languagesLoaded: false,
                initialLanguageOptions: [],
                disabled: disabled
            };
        }, 
        mounted: function() {
            $.ajax({
                url: '/autocomplete/languages',
            }).done(this.setInitialLanguages);
        },
        created: function(){
            // console.log(`initializing candidate languages`);
            // console.log(this.records);
            // console.log(this.languageProficiencyOptions);
        },
        methods: {
            setInitialLanguages: function(response){
                // process the automcomplete ajax response
                // re-render the job titles with the new options
                this.initialLanguageOptions = response.results || [];
                this.languagesLoaded = true;   
            },
            addRecord: function() {
              this.records.splice(0,0,{
                id: null,
                language: {},
                proficiency: 1,
                vue_key: this.nextVueKey,
                _destroy: null,
                errors: []
              });

              this.nextVueKey += 1;

              //SCROLL THE NEWLY CREATED ITEM INTO VIEW
            //   this.$nextTick(() => {
            //       let educationItems = $(this.$el).find(`.profile__container--outer`);
            //       let createdItem = educationItems[educationItems.length - 1];
            //       createdItem.scrollIntoView(true);
            //       if(document.body.offsetHeight - window.scrollY > 858){
            //         window.scrollBy(0,-140);
            //       };
            //     //OFFSETTING THE Y VALUE WHEN SCROLLING BY '-140', TO SCROLL HEADER OUT OF VIEW
            //   });
            },

            removeRecord: function(index) {
              var record = this.records[index];
            //  this.records[index]._destroy = "1"

              if (record.id == null) {
                this.records.splice(index, 1)
              } else {
                this.records[index]._destroy = "1"
              }
            },

            undoRemove: function(index) {
              this.records[index]._destroy = null
            },

            determineRecordClass: function(record, otherClasses){
                var recordClass = otherClasses || '';
                if(record._destroy){
                    recordClass = recordClass + ' removed-record';
                }else if(record.id == null){
                    recordClass = recordClass + ' new-record'
                }

                return recordClass;
            },

            languageSelected: function(event, record){
                let selectedLanguage = event.data;
                let previousLanguage = { id: record.language.id, name: record.language.name };

                if(selectedLanguage.id != record.language.id){
                    let duplicateRecord = this.records.find(element => element.language.id == selectedLanguage.id);
                    if(duplicateRecord){
                        record.language = previousLanguage;
                        // record.language = {}  // deselect the language
                        record.vue_key = this.nextVueKey;
                        this.nextVueKey += 1;

                        // todo -  replace with custom alrets when ready?
                        alert(selectedLanguage.name + ' is alredy selected!');
                        return;
                    }
                }
                record.language = selectedLanguage;
            }
        },
        components: {
            FormField
        }
    }
</script>