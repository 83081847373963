module.exports = {
    sendRequest: function(method,data,url,callback,VueSender,options){
        // console.log(`sending request - ${url}`);
        // if(!window.lockBackground){ $(`#loading-background`).css('display','block') };
        if(VueSender != undefined){
            // console.log(VueSender);
            VueSender.$emit('event-ajax',{ VueComponent: VueSender, step: 'start' });
        };
        let reqStartTime = new Date();
        $.ajax({
            'method': method,
            // 'dataType': method == 'POST' ? 'json' : 'text',
            'url': url,
            'data': data,
            ...(options ? options : {})
        }).done(function(response){
            let reqEndTime = new Date();
            // console.log(`ajax success callback - ${url}, elapsed time - ${reqEndTime.getTime() - reqStartTime.getTime()}ms`);
            if(!window.lockBackground){ $(`#loading-background`).css('display','none') };
            if(VueSender != undefined){
                VueSender.$emit('event-ajax',{ VueComponent: VueSender, step: 'end' });
            };
            callback(response);
        }).fail(function(response,error,exception){
            let reqEndTime = new Date();
            // console.log(`ajax fail callback - ${url}, elapsed time - ${reqEndTime.getTime() - reqStartTime.getTime()}ms`);
            // console.log(response);
            // console.log(error);
            // console.log(exception);
            $(`#loading-background`).css('display','none');
            if(VueSender != undefined){
                VueSender.$emit('event-ajax',{ VueComponent: VueSender, step: 'end' });
            };
            // window.alert(`Ajax callback fail - ${response.responseText}`);
            callback(response,error)
        });    
    },
    request: function(method,data,url,success,VueSender,fail){
        if(VueSender != undefined){
            VueSender.$emit('event-ajax',{ VueComponent: VueSender, step: 'start' });
        };

        const Data = { authenticity_token: $(`meta[name=csrf-token]`)[0].content, ...data };

        const req = new XMLHttpRequest();
        req.onreadystatechange = function(){
            if(this.readyState == 4){
                if(this.status == 200){
                    if(!window.lockBackground){ $(`#loading-background`).css('display','none') };
                    if(success){ success(this.response) };
                }else{
                    if(fail){ fail(this.response) };
                };
            };
        };
        req.open(method,url,true);
        if(method == 'POST' || method == 'DELETE'){
            req.setRequestHeader('Content-Type','application/json');
        };
        req.send(JSON.stringify(Data));
    }
}