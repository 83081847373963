import Command from '@ckeditor/ckeditor5-core/src/command'
import { requirementInitialStructureDataArr, linkedIninitialStructureDataArr } from '../../ckeditor'

// const RequirementData = [
//     { name: 'Requirements', value: 'Answers' },
//     { name: 'Name of the Client', value: '', autofill: 'client_name' },
//     { name: 'Link to the job in Blue I', value: '', autofill: 'job_url' },
//     { name: 'Sourcing platforms (delete all but one)', value: 'Blue-i OR LinkedIn OR GitHub OR Other', autofill: 'candidate_types' },
//     { name: 'Link to the project in LinkedIn (if applicable)', value: '' },
//     { name: 'Location of the company ', value: '', autofill: 'company_location' },
//     { name: 'Max. radios OR cities for the search – how far', value: '' },
//     { name: 'Salary', value: '', autofill: 'salary' },
//     { name: 'Language(s)', value: '', autofill: 'languages' },
//     { name: 'Education', value: '' },
//     { name: 'Required software skills', value: '' },
//     { name: 'Min. 3 must have skills', value: '' },
//     { name: 'Min. 3 nice to have skills', value: '' },
//     { name: 'Any other info: personality/hard skills/soft skills', value: '' },
//     { name: 'Candidates’ availability', value: 'LI: Open to work / not open to work..\nfor Blue I 2: CVs not older than 6 months,\n etc.'},
//     { name: 'Additional client requirements (not necessarily can be found in the vacancy for example)', value: 'Years of relevant experience\nDress code\nMax travel time and or distance'}
// ];

// const LinkedInData = [
//     'Boolean search suggestions',
//     'Job title(s)',
//     'Job Title(s) we\n know will NOT\n work',
//     'Skills',
//     'Companies',
//     'Experience',
//     'Keywords'
// ];

export default class AutofillCommand extends Command {
    execute(input){
        console.log(`Should autofill table or w/e`,input,this.editor);

        if(!confirm('Do you want to autofill the table with available data?')){ return };

        function autofillTable(editor, dataStructureArr, tableType) {
            // clear the editor and insert new rows for the table, then autofil
            // fix issue when deleting the table from the editor causes the autofill option not work
            editor.setData('');
            editor.execute('insertTable',{ rows: dataStructureArr.length, columns: 2 });
            editor.execute('setTableColumnHeader');
            if (tableType === 'requirementsTable') {
                editor.execute('setTableRowHeader');
            }

            let autofillData = {};
            let autofillDataContainer = null;

            if (tableType === 'requirementsTable') {
                autofillDataContainer = document.querySelector('.js-requirements-autofill');
            } else if (tableType === 'linkedInTable') {
                // for now there is no element with class .js-linked-in-autofill and autofillDataContainer will be null,
                // this is done so, that in the future if there is need of autofilling, it will be provided
                autofillDataContainer = document.querySelector('.js-linked-in-autofill');
            }

            if (autofillDataContainer) {
                // Array.from(autofillDataContainer.querySelectorAll('.data'))
                // .forEach((dataEl) => {
                //     autofillData[dataEl.querySelector('.key').innerText] = dataEl.querySelector('.value').innerText;
                // });

                autofillData = JSON.parse(autofillDataContainer.dataset.autofillValuesObject);
            }

            const currentTableRows = Array.from(editor.ui.view.element.querySelectorAll('tr'));
            currentTableRows.forEach((currentTableRow, index) => {
                const cells = currentTableRow.querySelectorAll('span');
                cells[0].innerHTML = dataStructureArr[index].name;
                cells[1].innerHTML = dataStructureArr[index].autofill 
                    ? Array.isArray(autofillData[dataStructureArr[index].autofill]) 
                        ? autofillData[dataStructureArr[index].autofill].join(', ') 
                        : autofillData[dataStructureArr[index].autofill] 
                    : dataStructureArr[index].value;
            });
        }

        const editorLabel = this.editor.ui.view.element.parentElement.parentElement.querySelector('label').innerText;
        // let tableRows;
        // let AutofillData;
        console.log(`Editor label = ${editorLabel}`);
        switch(editorLabel.replace(':','').trim()){
            case 'Requirement':
                if (requirementInitialStructureDataArr.length > 0) {
                    autofillTable(this.editor, requirementInitialStructureDataArr, 'requirementsTable');

                    // // clear the editor and insert new rows for the table, then autofil
                    // // fix issue when deleting the table from the editor causes the autofill option not work
                    // this.editor.setData('');
                    // this.editor.execute('insertTable',{ rows: requirementInitialStructureDataArr.length, columns: 2 });
                    // this.editor.execute('setTableRowHeader');
                    // this.editor.execute('setTableColumnHeader');

                    // AutofillData = {};
                    // Array.from(document.querySelector('.js-requirements-autofill').querySelectorAll('.data'))
                    // .forEach((dataEl) => {
                    //     AutofillData[dataEl.querySelector('.key').innerText] = dataEl.querySelector('.value').innerText;
                    // });

                    // console.log(AutofillData);

                    // tableRows = Array.from(this.editor.ui.view.element.querySelectorAll('tr'));
                    // tableRows.forEach((tableRow,index) => {
                    //     const cells = tableRow.querySelectorAll('span');
                    //     cells[0].innerHTML = requirementInitialStructureDataArr[index].name;
                    //     cells[1].innerHTML = requirementInitialStructureDataArr[index].autofill ? 
                    //         AutofillData[requirementInitialStructureDataArr[index].autofill].replace(/(\[|\])/g,'') : 
                    //         requirementInitialStructureDataArr[index].value;
                    // });

                    // const intervalR = setInterval(() => {
                    //     clearInterval(intervalR);
                    //     tableRows = Array.from(this.editor.ui.view.element.querySelectorAll('tr'));
                    //     tableRows.forEach((tableRow,index) => {
                    //         const cells = tableRow.querySelectorAll('span');
                    //         cells[0].innerHTML = requirementInitialStructureDataArr[index].name;
                    //         cells[1].innerHTML = requirementInitialStructureDataArr[index].autofill ? 
                    //             AutofillData[requirementInitialStructureDataArr[index].autofill].replace(/(\[|\])/g,'') : 
                    //             requirementInitialStructureDataArr[index].value;
                    //     });
                    // },1000);
                } else {
                    alert('There is no initial data structure for the requirements section to be autofilled');
                }
                
                break;
            case 'LinkedIn Specific':
                if (linkedIninitialStructureDataArr.length > 0) {
                    autofillTable(this.editor, linkedIninitialStructureDataArr, 'linkedInTable');
                    // // clear the editor and insert new rows for the table, then autofil
                    // // fix issue when deleting the table from the editor causes the autofill option not work
                    // this.editor.setData('');
                    // this.editor.execute('insertTable',{ rows: linkedIninitialStructureDataArr.length, columns: 2 });
                    // this.editor.execute('setTableColumnHeader');

                    // tableRows = Array.from(this.editor.ui.view.element.querySelectorAll('tr'));
                    // tableRows.forEach((tableRow,index) => {
                    //     const cells = tableRow.querySelectorAll('span');
                    //     cells[0].innerHTML = linkedIninitialStructureDataArr[index].name;
                    // });

                    // // const intervalL = setInterval(() => {
                    // //     clearInterval(intervalL);
                    // //     tableRows = Array.from(this.editor.ui.view.element.querySelectorAll('tr'));
                    // //     tableRows.forEach((tableRow,index) => {
                    // //         const cells = tableRow.querySelectorAll('span');
                    // //         cells[0].innerHTML = linkedIninitialStructureDataArr[index];
                    // //     });
                    // // },1000);
                } else {
                    alert('There is no initial data structure for the linked in section to be autofilled');
                }
                
                break;
            default:
                break;
        };

    }
};