import ClassicEditor from './ckbuild.js'

export const requirementInitialStructureDataArr = [
    { name: 'Requirements', value: 'Answers' },
    { name: 'Name of the Client', value: '', autofill: 'client_name' },
    // { name: 'Link to the job in Blue I', value: '', autofill: 'job_url' },
    // { name: 'Sourcing platforms (delete all but one)', value: 'Blue-i OR LinkedIn OR GitHub OR Other', autofill: 'candidate_types' },
    { name: 'Source link(s)', value: 'Linkedin.com/project, Github.com/, bluei' },
    // { name: 'Link to the project in LinkedIn (if applicable)', value: '' },
    { name: 'Location of the company ', value: '', autofill: 'company_location' },
    { name: 'Max. radios OR cities for the search – how far', value: '' },
    { name: 'Salary', value: '', autofill: 'salary' },
    { name: 'Language(s)', value: '', autofill: 'languages' },
    { name: 'Education', value: '' },
    { name: 'Required software skills', value: '' },
    { name: 'Min. 3 must have skills', value: '' },
    { name: 'Min. 3 nice to have skills', value: '' },
    { name: 'Any other info: personality/hard skills/soft skills', value: '' },
    { name: 'Candidates’ availability', value: 'LI: Open to work / not open to work..\nfor Blue I 2: CVs not older than 6 months,\n etc.' },
    { name: 'Recruiters – Attach a minimum of 1 or a max of 3 good examples profiles', value: '' },
    { name: 'Recruiters - state here the pipelines and/or previous jobs the Sourcers can use', value: '' },
    { name: 'Additional client requirements (not necessarily can be found in the vacancy for example)', value: 'Years of relevant experience\nDress code\nMax travel time and or distance' },
    { name: 'Video briefing', value: '' }
];

export const linkedIninitialStructureDataArr = [
    { name: 'Boolean search suggestions', value: 'Consider using the Boolean generator' },
    { name: 'Job title(s)', value: '' },
    { name: 'Job Title(s) we\n know will NOT\n work', value: '' },
    { name: 'Skills', value: '' },
    { name: 'Companies', value: '' },
    { name: 'Experience', value: '' },
    { name: 'Keywords', value: '' },
    { name: 'Candidates’ availability', value: 'Start with Open to work; go to More likely to respond;' },
];

// export const linkedIninitialStructureDataArr = [
//     'Boolean search suggestions',
//     'Job title(s)',
//     'Job Title(s) we\n know will NOT\n work',
//     'Skills',
//     'Companies',
//     'Experience',
//     'Keywords'
// ];

document.addEventListener('turbolinks:load',() => {
    if(Array.from(document.querySelectorAll(`.ck-editor-target textarea`)).length){

        function createTableFromStructureArray(editor, dataStructureArr, tableType) {
            let currentTableRows = Array.from(editor.ui.view.element.querySelectorAll('tr'));
            if (currentTableRows.length === 0) {
                // create the table
                editor.execute('insertTable',{ rows: dataStructureArr.length, columns: 2 });
                editor.execute('setTableColumnHeader');
                if (tableType === 'requirementsTable') {
                    editor.execute('setTableRowHeader');
                }
                
                // fill the first column with the default data from the specified objects array
                currentTableRows = Array.from(editor.ui.view.element.querySelectorAll('tr'));
                currentTableRows.forEach((currentTableRow,index) => {
                    console.log(dataStructureArr[index].name)
                    const cells = currentTableRow.querySelectorAll('span');
                    cells[0].innerHTML = dataStructureArr[index].name;
                });
            }
        }

        Array.from(document.querySelectorAll(`.ck-editor-target textarea`)).forEach((el) => {
            console.log(`Creating ckeditor from element`,el);
            ClassicEditor.create(el,{
                toolbar: [
                    'heading',
                    '|','previewPage',
                    '|','autofill',
                    '|','insertTable',
                    '|','bold','italic','underline','removeFormat',
                    '|','fontColor','fontBackgroundColor',
                    '|','bulletedList','numberedList', /*'todoList', */
                    '|','link','specialCharacters',
                    '|','undo','redo',
                    '|','fullscreen'
                ],
                table: {
                    contentToolbar: ['tableColumn','tableRow','mergeTableCells'],
                    defaultHeadings: { rows: 1, columns: 1 }
                },
                link: {
                    defaultProtocol: 'http://',
                    decorators: {
                        isExternal: {
                            mode: "manual",
                            label: 'Open in a new tab',
                            defaultValue: false,
                            attributes: {
                                target: '_blank',
                                rel: 'noopener noreferrer'
                            }
                        }
                    }
                }
            })
            .then((Editor) => {
                Editor.ui.view._voiceLabelView.text = '';
                setAdditionalKeystrokes(Editor);
                console.log(`CK-Editor loaded`,Editor);
                
                const editorLabel = Editor.ui.view.element.parentElement.parentElement.querySelector('label').innerText;
                // let tableRows;
                // let AutofillData;
                console.log(`Editor label = ${editorLabel}`);
                switch(editorLabel.replace(':','').trim()){
                    case 'Requirement':
                        createTableFromStructureArray(Editor, requirementInitialStructureDataArr, 'requirementsTable');
                        // tableRows = Array.from(Editor.ui.view.element.querySelectorAll('tr'));
                        // if(!tableRows.length){ 
                        //     Editor.execute('insertTable',{ rows: requirementInitialStructureDataArr.length, columns: 2 });
                        //     Editor.execute('setTableRowHeader');
                        //     Editor.execute('setTableColumnHeader');

                        //     tableRows = Array.from(Editor.ui.view.element.querySelectorAll('tr'));
                        //     tableRows.forEach((tableRow,index) => {
                        //         const cells = tableRow.querySelectorAll('span');
                        //         cells[0].innerHTML = requirementInitialStructureDataArr[index].name;
                        //     });

                        //     AutofillData = {};
                        //     Array.from(document.querySelector('.js-requirements-autofill').querySelectorAll('.data'))
                        //     .forEach((dataEl) => {
                        //         AutofillData[dataEl.querySelector('.key').innerText] = dataEl.querySelector('.value').innerText;
                        //     });

                        //     const intervalR = setInterval(() => {
                        //         clearInterval(intervalR);
                        //         tableRows = Array.from(Editor.ui.view.element.querySelectorAll('tr'));
                        //         tableRows.forEach((tableRow,index) => {
                        //             const cells = tableRow.querySelectorAll('span');
                        //             cells[0].innerHTML = RequirementData[index].name;
                        //             // cells[1].innerHTML = RequirementData[index].autofill ? 
                        //             //     AutofillData[RequirementData[index].autofill].replace(/(\[|\])/g,'') : 
                        //             //     RequirementData[index].value;
                        //         });
                        //     },1000);
                        // };
                        break;
                    case 'LinkedIn Specific':
                        createTableFromStructureArray(Editor, linkedIninitialStructureDataArr, 'linkedInTable');
                        // tableRows = Array.from(Editor.ui.view.element.querySelectorAll('tr'));
                        // if(!tableRows.length){
                        //     Editor.execute('insertTable',{ rows: linkedIninitialStructureDataArr.length, columns: 2 });
                        //     Editor.execute('setTableColumnHeader');

                        //     tableRows = Array.from(Editor.ui.view.element.querySelectorAll('tr'));
                        //     tableRows.forEach((tableRow,index) => {
                        //         const cells = tableRow.querySelectorAll('span');
                        //         cells[0].innerHTML = linkedIninitialStructureDataArr[index].name;
                        //     });

                        //     const intervalL = setInterval(() => {
                        //         clearInterval(intervalL);
                        //         tableRows = Array.from(Editor.ui.view.element.querySelectorAll('tr'));
                        //         tableRows.forEach((tableRow,index) => {
                        //             const cells = tableRow.querySelectorAll('span');
                        //             cells[0].innerHTML = LinkedInData[index];
                        //         });
                        //     },1000);
                        // };
                        break;
                    default:
                        break;
                };
            })
            .catch((Error) => { console.log(`Editor error`,Error) });
        })
    }
    
    //CHECK IF THE 'WEB AD' EDITOR NEEDS TO BE LOADED AND DO IT
    if($(`#ck-editor`).length){
        ClassicEditor
            .create($(`#ck-editor`)[0],{
                toolbar: [
                    'heading',
                    '|','previewPage',
                    '|','bold','italic','underline','removeFormat',
                    '|','fontColor','fontBackgroundColor',
                    '|','bulletedList','numberedList',
                    '|','link','specialCharacters',
                    '|','undo','redo',
                    '|','fullscreen'
                ],
                link: {
                    defaultProtocol: 'http://',
                    decorators: {
                        isExternal: {
                            mode: "manual",
                            label: 'Open in a new tab',
                            defaultValue: false,
                            attributes: {
                                target: '_blank',
                                rel: 'noopener noreferrer'
                            }
                        }
                    }
                }
            })
            .then((Editor) => {
                Editor.ui.view._voiceLabelView.text = 'Web Advert';
                setAdditionalKeystrokes(Editor);
                console.log(`Web CK-Editor loaded`,Editor);
            })
            .catch((Error) => { console.log(`Editor error`,Error) });
    };

    //CHECK IF THE 'NOTE' EDITOR NEEDS TO BE LOADED AND DO IT
    if($(`#ck-note`).length){
        ClassicEditor
            .create($(`#ck-note`)[0],{
                toolbar: [
                    'bold','italic','underline','removeFormat',
                    '|','fontColor',
                    '|','bulletedList','numberedList',
                    '|','link',
                    '|','undo','redo',
                    '|','fullscreen'
                ],
                link: {
                    defaultProtocol: 'http://',
                    decorators: {
                        isExternal: {
                            mode: "manual",
                            label: 'Open in a new tab',
                            defaultValue: true,
                            attributes: {
                                target: '_blank',
                                rel: 'noopener noreferrer'
                            }
                        }
                    }
                }
            })
            .then((Editor) => {
                Editor.ui.view._voiceLabelView.text = '';
                setAdditionalKeystrokes(Editor);
                $(`#ck-note`)[0].ckInstance = Editor;
                console.log(`Note CK-Editor loaded`,Editor);
            })
            .catch((Error) => { console.log(`Editor error`,Error) });
    };

    //CHECK IF THE 'NOTE' EDITOR NEEDS TO BE LOADED AND DO IT
    // if($(`#ck-note`).length){
    //     ClassicEditor
    //         .create($(`#ck-note`)[0],{
    //             toolbar: [
    //                 'bold','italic','underline','removeFormat',
    //                 '|','fontColor',
    //                 '|','bulletedList','numberedList',
    //                 '|','link',
    //                 '|','undo','redo'
    //             ],
    //             link: {
    //                 defaultProtocol: 'http://',
    //                 decorators: {
    //                     isExternal: {
    //                         mode: "manual",
    //                         label: 'Open in a new tab',
    //                         defaultValue: true,
    //                         attributes: {
    //                             target: '_blank',
    //                             rel: 'noopener noreferrer'
    //                         }
    //                     }
    //                 }
    //             }
    //         })
    //         .then((Editor) => {
    //             Editor.ui.view._voiceLabelView.text = '';
    //             setAdditionalKeystrokes(Editor);
    //             $(`#ck-note`)[0].ckInstance = Editor;
    //             console.log(`Note CK-Editor loaded`,Editor);
    //         })
    //         .catch((Error) => { console.log(`Editor error`,Error) });
    // };

});

function setAdditionalKeystrokes(Editor){
    let toolbarItems = Editor.ui.view.toolbar.children._items;
    let visibleToolbarChildren = toolbarItems[0].children._items;
    visibleToolbarChildren.forEach((Item) => {
        let itemName;
        let continueFlag = false;
        switch(Item.constructor.name){
            case 'DropdownView':
                itemName = Item.buttonView._tooltipString
                break;
            case 'ToolbarSeparatorView':
                continueFlag = true;
                break;
            default:
                itemName = Item._tooltipString
                break;
        };
        if(!continueFlag){
            switch(itemName){
                case 'Font Size':
                    Editor.keystrokes.set(['ctrl',219],(eventData,stopEvent) => {
                        incrementFontSize(-1,Editor,Item);
                        stopEvent();
                    });
                    Editor.keystrokes.set(['ctrl',221],(eventData,stopEvent) => {
                        incrementFontSize(1,Editor,Item);
                        stopEvent();
                    });
                case 'Decrease indent':
                    Editor.keystrokes.set(['ctrl',188],(eventData,stopEvent) => {
                        Editor.execute('outdent');
                        stopEvent();
                    });
                    break;
                case 'Increase indent':
                    Editor.keystrokes.set(['ctrl', 190], (eventData, stopEvent) => {
                        Editor.execute('indent');
                        stopEvent();
                    });
                    break;
                default:
                    break;
            };
        };
    });
};

function incrementFontSize(direction,Editor,Item){
    let fontSizeOptions = Editor.config._config.fontSize.options
    let breakFlag = false;
    let listViewItems = Item.listView.items._items;
    listViewItems.forEach((listItem) => {
        if(!breakFlag){
            let itemButton = $(listItem.element).find(`button`)[0];
            let listItemName = itemButton.innerText.toLowerCase();
            if(itemButton.className.indexOf('ck-on') != -1){
                let selectIndex = fontSizeOptions.indexOf(listItemName);
                let nextIndex = (selectIndex + direction + fontSizeOptions.length) % fontSizeOptions.length;
                let nextSelect = fontSizeOptions[nextIndex];
                let newFontValue;
                if(nextSelect != 'default'){ newFontValue = { 'value': nextSelect } };
                Editor.execute('fontSize',newFontValue);
                breakFlag = true;
            };
        };
    });
};