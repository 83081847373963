<template>
    <div class="profile__container--section">
        <div class="profile__container--row">
            <form-field
                title="Address"
                fieldtype="form-input"
                v-bind:inputname="`candidate[address]${getOldIdAddition()}`"
                v-bind:startvalue="address.address"
                v-bind:haserrors="errors.address"
                v-bind:addclass="getClassForField('address')"
                v-bind:isdisabled="isold"
            ></form-field>

            <form-field
                title="No."
                fieldtype="form-input"
                v-bind:inputname="`candidate[housenumber]${getOldIdAddition()}`"
                v-bind:startvalue="address.housenumber"
                v-bind:haserrors="errors.housenumber"
                v-bind:addclass="getClassForField('housenumber')"
                v-bind:isdisabled="isold"
            ></form-field>
        </div>

        <div class="profile__container--row">
            <form-field
                title="Postcode"
                fieldtype="form-input"
                v-bind:inputname="`candidate[postcode]${getOldIdAddition()}`"
                v-bind:startvalue="address.postcode"
                v-bind:haserrors="errors.postcode"
                v-bind:addclass="getClassForField('postcode')"
                v-bind:isdisabled="isold"
            ></form-field>

            <!-- To do! -->
            <form-field
                v-if="lastCountryId != nl_country_id"
                title="City"
                fieldtype="form-input"
                v-bind:inputname="`candidate[town]${getOldIdAddition()}`"
                v-bind:startvalue="address.town"
                v-bind:haserrors="errors.town"
                v-bind:addclass="getClassForField('town')"
                v-bind:isdisabled="isold"
                v-bind:labelWarning="getLabelWarning()"
            ></form-field>
            <form-field
                v-if="lastCountryId == nl_country_id"
                title="City"
                fieldtype="form-dropdown"
                v-bind:inputname="`candidate[town]${getOldIdAddition()}`"
                v-bind:startvalue="{ name: address.town, id: address.town }"
                v-bind:haserrors="errors.town"
                v-bind:searchurl="`/autocomplete/nl_towns`"
                preload='1'
                v-bind:addclass="getClassForField('town')"
                v-bind:isdisabled="isold"
                v-bind:labelWarning="getLabelWarning()"
                addblank="true"
                blanktext=""
            ></form-field>
        </div>

        <form-field
            title="Country"
            fieldtype="form-dropdown"
            v-bind:inputname="`candidate[country_id]${getOldIdAddition()}`"
            v-bind:startvalue="address.country"
            searchurl="/autocomplete/countries"
            preload="1"
            v-on:event-bubble="countryEvent"
            v-bind:addclass="getClassForField('country_id')"
            v-bind:isdisabled="isold"
        ></form-field>

        <form-field
            v-if="showCountryRegion"
            title="Country Region"
            fieldtype="form-dropdown"
            v-bind:inputname="`candidate[country_region_id]${getOldIdAddition()}`"
            v-bind:startvalue="address.country_region"
            v-bind:searchurl="regionUrl"
            v-bind:preload="showCountryRegion"
            v-bind:preloadquery="initialCountryQuery"
            v-bind:addclass="getClassForField('country_region_id')"
            v-bind:isdisabled="isold"
            v-bind:labelWarning="getLabelWarning()"
        ></form-field>

        <input type="hidden" name="candidate[country_region_id]" v-if="!showCountryRegion"/>

        <form-field
            title="County / Province"
            fieldtype="form-input"
            v-bind:inputname="`candidate[province]${getOldIdAddition()}`"
            v-bind:startvalue="address.province"
            v-bind:error="errors.province"
            v-bind:addclass="getClassForField('province')"
            v-bind:isdisabled="isold"
        ></form-field>

        <i 
            v-if="isnew && dataNotOverwritten"
            class="flaticon-reply shift-data" 
            title="Overwrite address and country with old data" 
            v-on:click="copyOldData"
        ></i>
        <i
            v-if="isnew && !dataNotOverwritten"
            class="flaticon2-refresh shift-data"
            title="Restore original address and country data"
            v-on:click="restoreNewData"
        ></i>
    </div>
</template>


<script>
    import FormField from '../../../form-field/form-field.vue'
    import Ajax from '../../../form-field/sub-components/js/ajax.js'

    export default {
        props: [
            'addressData',
            'errorsData',
            'isold',
            'isnew'
        ],
        components: {
            FormField
        },
        data: function(){
            var address = this.addressData || { country: {}, country_region: {} };
            var errors = this.errorsData || {};
            var changedFields = address.changes || [];
           
            return {
                address: address,
                changedFields: changedFields,
                errors: errors,
                showCountryRegion: Boolean(address.country_region.id),
                initialCountryQuery: address.country.id,
                lastCountryId: address.country.id,
                regionUrl: `/autocomplete/countries/${address.country.id}/regions`,
                lastQuery: '',
                dataNotOverwritten: true,
                nl_country_id: 28,
                uk_country_id: 35
            }
        },
        methods: {
            countryEvent: function(event){
                this.lastCountryId = event.data.id;
                let query = event.data.id;
                let url = `/autocomplete/countries/${query}/regions`;
                this.regionUrl = url;
                this.lastQuery = query;
                this.initialCountryQuery = this.lastQuery;
                if(query){ Ajax.sendRequest('GET',{ 'q': query },url,this.handleRegionResponse) };
            },
            handleRegionResponse: function(data){
                // console.log('region response received');
                // console.log(data);
                if(data.results.length){
                    this.showCountryRegion = true;
                    this.initialCountryQuery = this.lastQuery;
                    this.regionUrl = `/autocomplete/countries/${this.lastQuery}/regions`
                    if(this.lastCountryId == this.uk_country_id){
                        this.$nextTick(() => {
                            let regionInstance = $(`#candidate_country_region_id`).parents(`.profile__input--wrapper`)[0].__vue__;
                            regionInstance.selectOption(data.results[0]);
                        });
                    };
                }else{
                    this.showCountryRegion = false;
                };
            },
            getClassForField: function(fieldKey){
                var fieldClass = '';

                if(this.changedFields.includes(fieldKey)){
                    fieldClass = 'changed';
                }

                return fieldClass;
            },
            getOldIdAddition: function(){ if(this.isold){ return '_old' }else{ return '' } },
            copyOldData: function(){
                let countryRegionFlag = Boolean($(`#candidate_country_region_id_old`).length);
                this.$children.forEach((vueInstance) => { 
                    switch(vueInstance.title){
                        case 'Country':
                            vueInstance.copyOldData();
                            this.$nextTick(() => {
                                if(this.lastCountryId == this.uk_country_id){
                                    let interval = setInterval(() => {
                                        if(this.showCountryRegion){
                                            this.$nextTick(() => {
                                                let regionField = $(`#candidate_country_region_id`).parents(`.profile__container--field`)[0].__vue__;
                                                regionField.copyOldData();
                                            });
                                            clearInterval(interval);
                                        };
                                    },100);
                                };
                            });
                            break;
                        case 'Country Region':
                            break;
                        case 'City':
                            this.$nextTick(() => {
                                this.$nextTick(() => { 
                                    let cityField = $(`#candidate_town`).parents(`.profile__container--field`)[0].__vue__;
                                    cityField.copyOldData();
                                }); 
                            });
                            break;
                        default: 
                            vueInstance.copyOldData();
                            break;
                    };
                });
                this.dataNotOverwritten = false;
            },
            restoreNewData: function(){
                this.$children.forEach((vueInstance) => { 
                    if(vueInstance.title == 'Country'){
                        vueInstance.restoreNewData();
                        this.$nextTick(() => {
                            this.lastCountryId = vueInstance.$children[0].selectedOption.id;
                            this.showCountryRegion = (this.lastCountryId == this.uk_country_id);
                        });
                    }else{
                        vueInstance.restoreNewData(); 
                    };
                });
                this.dataNotOverwritten = true;
            },
            getLabelWarning: function(){
                if(this.isnew){
                    return 'Field not guaranteed to copy/restore data, handle with care';
                }else{
                    return '';
                }
            }
        }
    }
</script>