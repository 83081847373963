<template>
    <div class="filters--wrapper">
        <div
            class="filters--copy"
            title="Copy filters' url"
            v-on:click="copyFilters()">
                <i class="flaticon2-copy"></i>
        </div>
        
        <!-- Addblank does not add a blank option? -->
        <!-- The blank option should be first -->
        <!-- Blank option name? 'All'/''/'Any'-->
        <div class="filter-row">
            <form-field
                title="Show mine"
                fieldtype="form-checkbox"
                inputname="q[show_mine]"
                addclass="filter-target"
                v-on:event-bubble="handleChange"
                v-bind:startvalue="appliedFilters.show_mine"
            ></form-field>

            <form-field
                title="Show hidden"
                fieldtype="form-checkbox"
                inputname="q[show_hidden]"
                addclass="filter-target filter-hidden"
                v-on:event-bubble="handleChange"
                v-bind:startvalue="appliedFilters.show_hidden"
            ></form-field>
        </div>

        <form-field
            title="Search term"
            fieldtype="form-input"
            inputname="q[term]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.term"
        ></form-field>

        <form-field
            title="Type"
            fieldtype="form-dropdown"
            inputname="q[request_type]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.request_type"
            v-bind:alloptions="formOptions.request_type_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="From date"
            fieldtype="form-date"
            inputname="q[from_date]"
            v-bind:startvalue="appliedFilters.from_date"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
        ></form-field>

        <form-field
            title="To date"
            fieldtype="form-date"
            inputname="q[to_date]"
            v-bind:startvalue="appliedFilters.to_date"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
        ></form-field>

        <form-field
            title="Location BL"
            fieldtype="form-dropdown"
            inputname="q[bl_location_id]"
            addclass="filter-target filter-location-bl"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.bl_location_id"
            v-bind:alloptions="formOptions.bluelynx_location_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Country"
            fieldtype="form-dropdown"
            inputname="q[country_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.country"
            searchurl="/autocomplete/countries"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Request status"
            fieldtype="form-dropdown"
            inputname="q[status_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.status_id"
            v-bind:alloptions="formOptions.job_request_status_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            v-if="view != 'briefing'"
            title="Web status"
            fieldtype="form-dropdown"
            inputname="q[web_status]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.web_status"
            v-bind:alloptions="formOptions.job_request_web_status_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            v-if="view != 'briefing'"
            title="Publish on"
            fieldtype="form-dropdown"
            inputname="q[publish_on_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.publish_on"
            v-bind:alloptions="formOptions.publish_on_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            v-if="view != 'briefing'"
            title="Publish to"
            fieldtype="form-dropdown"
            inputname="q[publish_to]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.publish_to"
            v-bind:alloptions="formOptions.publish_to_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Main Recruiter"
            fieldtype="form-dropdown"
            inputname="q[staff_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.staff"
            searchurl="/autocomplete/staff"
            preload='1'
            addblank="true"
            blanktext="Any"
            v-bind:isdisabled="appliedFilters.show_mine"
        ></form-field>

        <form-field
            title="Shared with"
            fieldtype="form-dropdown"
            inputname="q[shared_with_staff_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.shared_with_staff"
            searchurl="/autocomplete/staff"
            preload='1'
            addblank="true"
            blanktext="Any"
            v-bind:isdisabled="appliedFilters.show_mine"
        ></form-field>

        <form-field
            v-if="view != 'briefing'"
            title="In statistics"
            fieldtype="form-dropdown"
            inputname="q[in_ranking]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.in_ranking"
            v-bind:alloptions="formOptions.in_ranking_options"
        ></form-field>

        <form-field
            title="Company"
            fieldtype="form-dropdown"
            inputname="q[company_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.company"
            searchurl="/autocomplete/companies"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            v-if="view != 'briefing'"
            title="Company Contact"
            fieldtype="form-dropdown"
            inputname="q[company_contact_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.company_contact"
            v-bind:alloptions="formOptions.company_contact_options"
            addblank="true"
            blanktext="Any"
            v-bind:isdisabled="!appliedFilters.company.id"
        ></form-field>

        <form-field
            v-if="view != 'briefing'"
            title="Highlighted"
            fieldtype="form-dropdown"
            inputname="q[is_highlighted]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.is_highlighted"
            v-bind:alloptions="formOptions.is_highlighted_options"
        ></form-field>

        <form-field
            v-if="view != 'briefing'"
            title="Written in"
            fieldtype="form-dropdown"
            inputname="q[language_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.language"
            searchurl="/autocomplete/languages?is_job_request_written_in_option=1"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            v-if="(view == 'briefing' || view == 'sourcing')"
            title="Sourcer"
            fieldtype="form-dropdown"
            inputname="q[sourcer_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.sourcer"
            searchurl="/autocomplete/staff"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            v-if="(view == 'briefing' || view == 'sourcing')"
            title="Sourcing Sub-teams"
            fieldtype="form-dropdown"
            inputname="q[staff_list_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.staff_list"
            searchurl="/autocomplete/all-staff-lists"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            v-if="view == 'sourcing'"
            title="Assigner"
            fieldtype="form-dropdown"
            inputname="q[task_assigner_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.task_assigner"
            searchurl="/autocomplete/staff"
            preload='1'
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            v-if="view == 'sourcing'"
            title="Assignee"
            fieldtype="form-dropdown"
            inputname="q[task_assignee_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.task_assignee"
            searchurl="/autocomplete/staff"
            preload='1'
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            v-if="view == 'sourcing'"
            title="Process filter"
            fieldtype="form-dropdown"
            inputname="q[task_status]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.task_status"
            v-bind:alloptions="formOptions.task_status_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <!-- 
            Adding 'filter-start-with' and 'js-filter-relocate' classes to below form-field element
            which are used to style and reposition the filter respectively 
        -->
        <form-field
            title="Start with"
            fieldtype="form-dropdown"
            inputname="q[start_with_letter]"
            addclass="filter-target filter-start-with js-filter-relocate"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.start_with_letter"
            v-bind:alloptions="formOptions.start_with_letter_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <div class="js-filter-hidden">
            <i class="flaticon-eye eye-green"><p>/</p></i>
            <div class="filter-info">Toggle show/hide hidden job requests</div>
        </div>

        <input type="hidden" name="sort_order" v-bind:value="appliedFilters.sort_order"/>
        <input type="hidden" name="sort_by" v-bind:value="appliedFilters.sort_by"/>
    </div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'

    export default {
        props: [
            'formOptionsData',
            'appliedFiltersData',
            'viewName'
        ],
        data: function(){
            let appliedFilters = JSON.parse(this.appliedFiltersData || '{}');
            let formOptions = JSON.parse(this.formOptionsData || '{}');
            let view = this.viewName;

            // console.log(view)

            // console.log(appliedFilters);
            // console.log(formOptions);

            return { 
                formOptions: formOptions,
                appliedFilters: appliedFilters,
                view: view,
                requestCounter: 0
            };
        },
        components: {
            FormField
        },
        methods: {
            handleChange: function(event){ 
                this.requestCounter++;
                let localCounter = this.requestCounter;
                let waitTime = 1000;
                this.$nextTick(() => { 
                    setTimeout(() => { if(localCounter == this.requestCounter){ $(`#job-request-filter-form`).submit() } },waitTime);
                });
            },
            copyFilters: async function(){
                try {
                    await navigator.clipboard.writeText(window.location.href);
                    document.activeElement.blur();
                } catch (error) {
                    alert(`Error when copying to clipboard`, error)
                }
            }
        }
    }
</script>