<template>
    <div class="profile__container--wrapper profile__container--columns-3">

            <div class="profile__container--outer candidate-details sectioned profile__container--columnPos-1">
                <div class="profile__container--header">
                    <h3>Initial briefing</h3>
                </div>
                <div class="profile__container--main briefing-page__container--editors">            
                    <form-field
                        title="Requirement"
                        fieldtype="form-textarea"
                        inputname="job_request_briefing[requirements]"
                        addclass="ck-editor-target ck-js-title ck-requirements"
                        v-bind:startvalue="jobRequest.requirements"
                        v-bind:haserrors="jobRequest.errors.requirements"
                    ></form-field>

                    <form-field
                        title="LinkedIn Specific"
                        fieldtype="form-textarea"
                        inputname="job_request_briefing[linked_in_specific]"
                        addclass="ck-editor-target"
                        v-bind:startvalue="jobRequest.linked_in_specific"
                        v-bind:haserrors="jobRequest.errors.linked_in_specific"
                    ></form-field>
                </div>
            <!-- closing Details outer -->
            </div>
    </div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'

    export default {
        props: [
            'jobRequestData',
            'formOptionsData'
        ],
        data: function(){
            var jobRequest = JSON.parse(this.jobRequestData || '{}');
            var formOptions = JSON.parse(this.formOptionsData || '{}');
          
            return { 
                jobRequest: jobRequest,
                formOptions: formOptions
            };
        },
        components: {
            FormField
        }
    }
</script>