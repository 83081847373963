import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'
import PreviewPageCommand from './command'

let PreviewIcon = `
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                <path d="M431.212,378.94c30.82-41.098,27.558-99.829-9.799-137.186c-8.744-8.743-18.661-15.613-29.246-20.622v-92.345L263.382,0
                    H0.168v512h392V410.868c6.203-2.935,12.175-6.512,17.817-10.729l80.518,81.409l21.33-21.096L431.212,378.94z M272.168,51.213
                    L340.955,120h-68.787V51.213z M362.168,482h-332V30h212v120h120v62.118c-31.637-4.529-64.959,5.349-89.246,29.636
                    c-40.939,40.939-40.939,107.553,0,148.492c24.005,24.005,57.199,34.223,89.246,29.636V482z M400.202,369.033
                    c-29.243,29.241-76.824,29.241-106.066,0c-29.242-29.243-29.242-76.824,0-106.066s76.822-29.244,106.066,0
                    C429.444,292.209,429.444,339.791,400.202,369.033z"/>
    </svg>
`;

export default class PreviewPage extends Plugin {
    init(){
        this.editor.ui.componentFactory.add('previewPage', (locale) => {
            const view = new ButtonView(locale);
            view.set({
                label: 'Preview Page',
                icon: PreviewIcon,
                tooltip: true,
                class: 'ck-button--preview'
            });
            this.editor.commands.add('previewPage', new PreviewPageCommand(this.editor));
            this.listenTo(view, 'execute', () => { this.editor.execute('previewPage') });
            return view;
        });
    }
};