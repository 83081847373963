<template>
    <div class="add-application--wrapper" id="ap-modal">
        <div v-if="openState" class="note-cover"></div>

        <div v-if="openState" class="add-application-modal" v-bind:class="{
            'add-application-modal--open': openState 
        }">
            <h3 class="add-application--title">Add candidate to job request</h3>

            <form-field
                title="Candidate"
                fieldtype="form-input"
                v-bind:inputname="`application[candidate_${selectedCandidate.id}]`"
                v-bind:startvalue="getCandidateName()"
                isdisabled="true"
            ></form-field>

            <form-field
                v-if="jobRequestsLoaded"
                title="Job Request"
                fieldtype="form-multidrop"
                preload="0"
                v-bind:searchurl="searchUrl"
                v-bind:inputname="`application[job_request_${selectedCandidate.id}]`"
                v-bind:startvalue="jobRequestSelected"
                v-bind:alloptions="jobRequestOptions"
                v-on:event-bubble="updateJobRequest"
            ></form-field>

            <div class="application-button--wrapper">
                <button class="application-button" v-on:click="addCandidate" v-bind:disabled="!Boolean(jobRequestSelected.length)">Add</button>
                <button class="application-button application-button--cancel" v-on:click="closeModal">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import Ajax from '../form-field/sub-components/js/ajax.js'
    import FormField from '../form-field/form-field.vue'

    export default {
        props: [
            'jobrequests',
            'jobrequestid',
            'candidate',
            'formOptionsData'
        ],
        data: function(){
            let candidateInfo =  JSON.parse(this.candidate || '{}');
            let jobRequests =  [];

            if(this.formOptionsData){
                jobRequests = JSON.parse(this.formOptionsData).job_requests;
            };
            
            return {
                openState: false,
                jobRequestSelected: jobRequests,
                jobRequestOptions: {},
                jobRequestsLoaded: false,
                selectedCandidate: candidateInfo,
                searchUrl: '/autocomplete/job-requests/open',
                candidateName: ''
            }
        },
        mounted: function(){ 
            //this.getJobRequestOptions() 
        },
        components: { FormField },
        methods: {
            addCandidate: function(event){
                let jobRequestIds = [];
                this.jobRequestSelected.forEach((JobRequest) => { jobRequestIds.push(JobRequest.id) });

                window.lockBackground = true;
                $(`#loading-background`).css('display','block');
                Ajax.sendRequest('POST',{
                    'authenticity_token': $(`meta[name=csrf-token]`)[0].content,
                    'job_request_ids': jobRequestIds,
                    'candidate_id': this.selectedCandidate.id
                },`/job_requests/dossier/add_candidate`,(Response) => {
                    window.lockBackground = false;
                    window.location.reload();
                });
            },
            getJobRequestOptions: function(){
                Ajax.sendRequest('GET',{'q': ''},this.searchUrl,(Response) => {
                    if(this.jobrequestid){
                        let readyFlag = false;
                        Response.results.forEach((Result ,resultIndex) => {
                            if(!readyFlag && Result.id == this.jobrequestid){
                                if(this.jobRequestSelected.length){
                                    this.jobRequestSelected.push(Result);
                                }else{
                                    this.jobRequestSelected = [Result];
                                };
                                Response.results.splice(resultIndex,1);
                                readyFlag = true;
                            };
                        });
                    }else if(this.jobRequestSelected.length){
                    }else{
                        this.jobRequestSelected = [];
                    };
                    this.jobRequestOptions = Response.results;
                    this.jobRequestsLoaded = true;
                });
            },
            openModal: function(withCandidate,withJobRequests){
                if(withCandidate){ this.selectedCandidate = withCandidate };
                if(withJobRequests.length){ this.jobRequestSelected = withJobRequests || [] };
                if(!this.jobRequestsLoaded){ this.getJobRequestOptions() };
                this.openState = true; 
                return this; 
            },
            closeModal: function(){ this.openState = false; return this },
            updateJobRequest: function(event){ this.jobRequestSelected = event.instance.selectValue },
            setAddButtonState: function(){ if(this.jobRequestSelected){ return false }else{ return 'disabled' } },
            getCandidateName: function(){
                if(this.selectedCandidate.name != undefined){ 
                    return `${this.selectedCandidate.name}`; 
                }else{
                    return `${this.selectedCandidate.first_name} ${this.selectedCandidate.last_name}`;
                };
            }
        }
    }
</script>