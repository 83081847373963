<template>
    <div 
        class="notes__container--single" 
        v-on:click.stop="{}"
        v-bind:class="{ 
            'notes__container--single-new': (notenew),
            'notes__container--single-done': (taskStatus == 'done'),
            'notes__container--single-overdue': (isOverdue && !notenew)
        }">

        <!-- note/task/sourcing task normall view -->
        <div v-if="!notenew">
            <div v-if="isdashboard">
                <div class="single-note__container--top">
                    <div class="single-note-top__container--checkbox">
                        <form-field
                            fieldtype="form-checkbox"
                            v-bind:inputname="`dashboard_hidden_${notedata.id}`"
                            v-bind:startvalue="0"
                            v-on:event-bubble="updateNotesIdsArray"
                        ></form-field>
                    </div>
                    <div class="single-note-top__container--links">
                        <div class="note__container--link"
                            v-for="link in dashboardLinks"
                            v-bind:key="link"
                            v-html="link.replace(/\[/g,'').replace(/]/g,'')">
                        </div>
                    </div>
                </div>
                <sourcing-task-stages
                    v-if="noteType === 'sourcing_task'"
                    v-bind:sourcingtasks="notedata.sourcing_tasks"
                    v-bind:taskstatus="taskStatus"
                    v-bind:ismember="checkViewerMember()"
                    v-bind:isowner="isOwner"
                    v-bind:viewer="viewer"
                    v-bind:canupdateassignedstaff="notedata.permissions.change_assignee"
                    v-bind:members="members"
                ></sourcing-task-stages>
            </div>
            <div class="single-note__container--bottom"> 
                <div class="single_note__container--members-count"
                    v-bind:class="{ 'container--is-member': checkViewerMember() }">
                    <p>
                        {{ countMembers() }}
                    </p>
                    <span>
                        Number of members. If it's yellow, you're a member.
                    </span>
                </div>
                <div class="single-note__text--info" data-label="Updated on"><p>{{ reformatDate(notedata.updated_at) }}</p></div>
                <div v-if="!isdashboard" class="single-note__text--info" data-label="Owner"><p>{{ notedata.owner.name }}</p></div>
                <div v-if="isdashboard" class="single-note__text--info" data-label="Visibility">
                    <form-field
                        fieldtype="form-checkbox"
                        v-bind:inputname="`dashboard_hidden_${recordid}`"
                        v-bind:startvalue="notedata.dashboard_hidden"
                        v-on:event-bubble="updateDashboardState"
                    ></form-field>
                </div>
                <div v-if="!isdashboard" class="single-note__text--info single-note__container--description" data-label="Note" v-html="getNoteText()"></div>
                <div v-if="isdashboard" class="single-note__text--info single-note__container--description" data-label="Note"> <p>{{ getNoteText() }}</p></div>
                <div v-if="(notedata.note_type == 'task' || notedata.note_type == 'sourcing_task')" class="single-note__text--info" data-label="asap"><p v-if="isAsap">ASAP</p></div>
                <div v-if="(notedata.note_type == 'task' || notedata.note_type == 'sourcing_task')" class="single-note__text--info" data-label="Due to"><p v-if="dueDate">{{ reformatDate(dueDate) }}</p></div>
                
                <expanded-note
                    v-bind:notenew="notenew"
                    v-bind:notetype="noteType"
                    v-bind:taskstatus="taskStatus"
                    v-bind:isoverdue="isOverdue"
                    v-bind:daysoverdue="daysOverdue"
                    v-bind:dueerror="dueError"
                    v-bind:noteeditable="noteEditable"
                    v-bind:notedeletable="noteDeletable"
                    v-bind:isstatus="isstatus"
                    v-bind:statusdata="statusdata"
                    v-bind:recordid="recordid"
                    v-bind:isdashboard="isdashboard"
                    v-bind:sourcingtasks="notedata.sourcing_tasks"
                    v-bind:ismember="checkViewerMember()"
                    v-bind:isowner="isOwner"
                    v-bind:sftitle="sfTitle"
                    v-bind:notetext="noteText"
                    v-bind:duedate="dueDate"
                    v-bind:isasap="isAsap"
                    v-bind:priority="priority"
                    v-bind:stafflist="notedata.staff_list"
                    v-bind:stafflistid="notedata.staff_list_id"
                    v-bind:members="members"
                    v-bind:viewer="viewer"
                    v-bind:owner="notedata.owner"
                    v-bind:reloadafteraction="reloadAfterAction"
                    v-bind:taskstatusupdatedby="taskStatusUpdatedBy"
                    v-bind:taskstatusupdatedat="taskStatusUpdatedAt"
                    v-bind:noteid="notedata.id"
                    v-bind:taskcreatedat="notedata.created_at"
                    v-bind:taskupdatedat="notedata.updated_at"
                ></expanded-note>
            </div>
        </div>

        <expanded-note
            v-if="notenew"
            v-bind:notenew="notenew"
            v-bind:notetype="noteType"
            v-bind:taskstatus="taskStatus"
            v-bind:isoverdue="isOverdue"
            v-bind:daysoverdue="daysOverdue"
            v-bind:noteeditable="noteEditable"
            v-bind:isstatus="isstatus"
            v-bind:statusdata="statusdata"
            v-bind:recordid="recordid"
            v-bind:candidatetypeoptions="candidatetypeoptions"
            v-bind:ismember="checkViewerMember()"
            v-bind:isowner="isOwner"
            v-bind:sftitle="sfTitle"
            v-bind:notetext="noteText"
            v-bind:duedate="dueDate"
            v-bind:isasap="isAsap"
            v-bind:stafflist="notedata.staff_list"
            v-bind:members="members"
            v-bind:viewer="viewer"
            v-bind:owner="notedata.owner"
            v-bind:reloadafteraction="reloadAfterAction"
            v-bind:recordtype="recordType"
            v-bind:sourcepage="notedata.extra.source_page"
        ></expanded-note>
    </div>
</template>

<script>
    import Ajax from '../../form-field/sub-components/js/ajax.js'
    import Format from '../js/format.js'
    import FormField from '../../form-field/form-field.vue'
    import SourcingTaskList from './sourcing-task-list.vue';
    import SourcingTaskStages from './sourcing-task-stages.vue';
    import ExpandedNote from './expanded-note.vue';

    export default {
        props: [
            "viewer",
            "notedata",
            "noteeditable",
            "notenew",
            "recordid",
            "isstatus",
            "statusdata",
            "isdashboard",
            "candidatetypeoptions",
            "reloadafteraction"
        ],
        data: function(){
            return {
                recordType: this.notedata.extra.record_type,
                noteType: this.notedata.note_type || 'note',
                noteText: this.notedata.text || '',
                noteEditable: false,
                noteDelete: true,
                members: this.notedata.members || [],
                taskStatus: this.notedata.status,
                taskStatusUpdatedAt: Format.Date(this.notedata.status_updated_at) || null,
                taskStatusUpdatedBy: this.notedata.status_updated_by_member || {},
                isAsap: this.notedata.is_asap,
                dueDate: this.notedata.due_to || null,
                isOwner: (this.notedata.owner.id == this.viewer.id),
                isOverdue: false,
                daysOverdue: null,
                dueError: [],
                noteViewText: '',
                dashboardHidden: false,
                dashboardLinks: [],
                sfTitle: this.notedata.title || '',
                priority: this.notedata.priority || 2,
                staff_list_id: this.notedata.staff_list_id,
                reloadAfterAction: this.reloadafteraction, 
            };
        },
        created: function(){ 
            this.setTaskOverdue(); 
            this.noteDeletable = false;
            if(this.notenew){
                this.noteEditable = true;
            }else{
                this.noteEditable = this.notedata.permissions.edit;
                this.noteDeletable  = this.notedata.permissions.delete;
            }
            
            if(this.noteEditable){ this.noteViewText = 'Edit' }else{ this.noteViewText = 'View' };
            if(this.isdashboard){ this.dashboardHidden = this.notedata.dashboard_hidden }
            if(this.notenew && !this.isstatus){ this.members.push(this.viewer) }
        },
        mounted: function(){
            if(this.isdashboard){
                let linksData = this.notedata.record_details.replace('> for <','><').replace('> <','><');
                linksData = linksData.split('><');
                linksData.forEach((link, index) => {
                    if(index < (linksData.length - 1)){ link = `${link}>` };
                    if(index > 0){ link = `<${link}` };
                    this.dashboardLinks.push(link);
                });
                this.dashboardHidden = this.notedata.dashboard_hidden;
            }
        },
        beforeUpdate: function(){ this.setTaskOverdue() },
        updated: function () {
            // 13/02/2023 - clear note ids array for bulk show/hide option
            if (this.$parent.notesIdsArray && this.$parent.notesIdsArray.length > 0) {
                this.$parent.notesIdsArray = [];
            }
        },
        components: { Ajax, Format, FormField, SourcingTaskList, SourcingTaskStages, ExpandedNote },
        methods: {
            getNoteText: function(){ 
                if(this.isdashboard){ 
                    return this.noteText
                    .replace(/<\/?[^>]+(>|$)/g,' ')
                    .replace(/&nbsp;/g,' ') 
                    .replace(/&gt;/g,'>') 
                    .replace(/&lt;/g,'<');
                }else{
                    return this.noteText;
                };
            },
            checkViewerMember: function(){
                let viewerIsMember = false;
                this.members.forEach((Member) => {
                    if(!viewerIsMember && (Member.id == this.viewer.id)){ 
                        viewerIsMember = true 
                    };
                });
                return viewerIsMember;
            },
            setTaskOverdue: function(){
                if(this.dueDate && this.taskStatus != 'done'){
                    let dueDate = new Date(this.dueDate);
                    let currentDateObject = new Date();
                    let dueCalc = dueDate.getTime() - currentDateObject.getTime();
                    if(dueCalc < 0){
                        this.isOverdue = true;
                        this.dueError = ['OVERDUE'];
                        this.daysOverdue = Math.abs(Math.floor(dueCalc/(60*60*24*1000)));
                        if(this.daysOverdue > 99){ this.daysOverdue = '99+' };
                    }else{
                        this.isOverdue = false;
                        this.dueError = [];
                        this.daysOverdue = null;
                    };
                };
            },
            updateDashboardState: function(event){ 
                if(this.dashboardHidden != Boolean(Number(event.data))){
                    let confirmTextStart = `Hide`;
                    let confirmTextEnd = `${this.noteType}?`;
                    if(!this.dashboardHidden){ confirmTextStart = `Hide` }else{ confirmTextStart = `Show` };
                    if(confirm(`${confirmTextStart} ${confirmTextEnd}`)){
                        this.dashboardHidden = (event.data == 1);
                        let targetURL = '/dashboard/notes/visability';
                        Ajax.sendRequest('POST',{
                            'authenticity_token': $(`meta[name=csrf-token]`)[0].content,
                            'hidden': this.dashboardHidden,
                            'note_id': this.notedata.id
                        },targetURL,(Data) => { this.$parent.resetNotes() });
                    }else{
                        $(this.$el).find(`#dashboard_hidden_${this.recordid}`).click();
                    };
                };
            },
            reformatDate: function(date){
                return Format.Date(date);
            },
            countMembers: function(){
                if(this.members.length > 99){
                    return '99+';
                }else{
                    return this.members.length;
                };
            },
            updateNotesIdsArray: function(event) {
                const noteId = this.notedata.id;
                const idsArray = this.$parent.notesIdsArray;
                if (event.data === '1') {
                    idsArray.push(noteId);
                } else if (event.data === '0') {
                    const index = idsArray.indexOf(noteId);
                    idsArray.splice(index, 1);
                }
            }
        }
    }
</script>