import Command from '@ckeditor/ckeditor5-core/src/command'

export default class PreviewPageCommand extends Command {
    execute(){
        // let contentChildren = $(this.editor.sourceElement).find(` ~ .ck-editor .ck-content`)[0].children;    
        // let previewTab = window.open(location.href.replace('web/edit','preview'),'_blank');
        // $(contentChildren).each(function(index){ previewTab.document.writeln(this.outerHTML) });
        // previewTab.document.close();

        let contentChildren = $(this.editor.sourceElement).find(` ~ .ck-editor .ck-content`)[0].children;    
        let previewTab = window.open(location.href.replace('web/edit','preview'),'_blank');
        previewTab.transferChildren = Array.from(contentChildren);
    }
}