<template>
<div class="profile__container--wrapper profile__container--columns-3">

    <div class="profile__container--outer profile__container--details">
        <div class="profile__container--header">
            <h3>Company contact details</h3>
        </div>
        <div class="profile__container--main">
            <form-field
                title="Salesforce Id"
                fieldtype="form-input"
                inputname="company_contact[salesforce_id]"
                v-bind:startvalue="company_contact.salesforce_id"
                v-bind:haserrors="company_contact.errors.salesforce_id"
            ></form-field>

            <form-field
                title="Salesforce sync"
                inputname="company_contact[salesforce_sync]"
                fieldtype="form-radio"
                v-bind:startvalue="company_contact.salesforce_sync"
                v-bind:alloptions="[{ name: 'Yes', id: true }, { name: 'No', id: false }]"
                v-bind:haserrors="company_contact.errors.salesforce_sync"
            ></form-field>
            
            <form-field
                title="Salutation/ Prefix"
                fieldtype="form-dropdown"
                inputname="company_contact[salutation]"
                v-bind:startvalue="{ name: company_contact.salutation, id: company_contact.salutation }"
                v-bind:alloptions="formOptions.salutation_options"
                v-bind:haserrors="company_contact.errors.salutation"
                addblank="true"
            ></form-field>

            <form-field
                title="First name"
                fieldtype="form-input"
                inputname="company_contact[first_name]"
                v-bind:startvalue="company_contact.first_name"
                v-bind:haserrors="company_contact.errors.first_name"
            ></form-field>


            <form-field
                title="Last name preposition"
                fieldtype="form-input"
                inputname="company_contact[last_name_preposition]"
                v-bind:startvalue="company_contact.last_name_preposition"
                v-bind:haserrors="company_contact.errors.last_name_preposition"
            ></form-field>

            <form-field
                title="Last name"
                fieldtype="form-input"
                inputname="company_contact[last_name]"
                v-bind:startvalue="company_contact.last_name"
                v-bind:haserrors="company_contact.errors.last_name"
            ></form-field>

            <form-field
                title="Initials"
                fieldtype="form-input"
                inputname="company_contact[initials]"
                v-bind:startvalue="company_contact.initials"
                v-bind:haserrors="company_contact.errors.initials"
            ></form-field>

            <form-field
                title="Nickname"
                fieldtype="form-input"
                inputname="company_contact[nickname]"
                v-bind:startvalue="company_contact.nickname"
                v-bind:haserrors="company_contact.errors.nickname"
            ></form-field>


            <form-field
                title="Gender"
                fieldtype="form-dropdown"
                inputname="company_contact[gender]"
                v-bind:startvalue="company_contact.gender"
                v-bind:alloptions="formOptions.gender_options"
                v-bind:haserrors="company_contact.errors.gender"
            ></form-field>


            <form-field
                title="Date of birth"
                fieldtype="form-date"
                inputname="company_contact[date_of_birth]"
                v-bind:startvalue="company_contact.date_of_birth"
                v-bind:error="company_contact.date_of_birth"
                v-bind:haserrors="company_contact.errors.date_of_birth"
            ></form-field>

            <form-field
                title="Status (left/deceased)"
                fieldtype="form-dropdown"
                inputname="company_contact[status]"
                v-bind:startvalue="{ name: company_contact.status, id: company_contact.status }"
                v-bind:haserrors="company_contact.errors.status"
                v-bind:alloptions="formOptions.contact_status_options"
                addblank="true"
            ></form-field>

            <form-field
                title="E-Mail"
                fieldtype="form-input"
                inputname="company_contact[email_business]"
                v-bind:startvalue="company_contact.email_business"
                v-bind:haserrors="company_contact.errors.email_business"
            ></form-field>
            
            <!-- Title - to do? -->

        </div>
    </div>
    <div class="profile__container--outer profile__container--contacts">
        <div class="profile__container--header">
            <h3 style="visibility: hidden;">HIDDEN HEADER</h3> 
        </div>
        <div class="profile__container--main">

            <form-field
                title="Department"
                fieldtype="form-input"
                inputname="company_contact[department]"
                v-bind:startvalue="company_contact.department"
                v-bind:haserrors="company_contact.errors.department"
            ></form-field>

            <form-field
                title="Job title"
                fieldtype="form-dropdown"
                inputname="company_contact[position]"
                v-bind:startvalue="{ name: company_contact.position, id: company_contact.position }"
                v-bind:haserrors="company_contact.errors.position"
                v-bind:alloptions="formOptions.job_title_options"
                addblank="true"
            ></form-field>

            <form-field
                title="Phone"
                fieldtype="form-input"
                inputname="company_contact[direct_phone]"
                v-bind:startvalue="company_contact.direct_phone"
                v-bind:haserrors="company_contact.errors.direct_phone"
            ></form-field>

            <form-field
                title="Mobile"
                fieldtype="form-input"
                inputname="company_contact[mobile_number]"
                v-bind:startvalue="company_contact.mobile_number"
                v-bind:haserrors="company_contact.errors.mobile_number"
            ></form-field>

            <!-- Mailing address -->
            <form-field
                title="Mailing Street"
                fieldtype="form-input"
                inputname="company_contact[mailing_street]"
                v-bind:startvalue="company_contact.mailing_street"
                v-bind:haserrors="company_contact.errors.mailing_street"
            ></form-field>

            <form-field
                title="Mailing City"
                fieldtype="form-input"
                inputname="company_contact[mailing_city]"
                v-bind:startvalue="company_contact.mailing_city"
                v-bind:haserrors="company_contact.errors.mailing_city"
            ></form-field>
            <form-field
                title="Mailing Province"
                fieldtype="form-input"
                inputname="company_contact[mailing_province]"
                v-bind:startvalue="company_contact.mailing_province"
                v-bind:haserrors="company_contact.errors.mailing_province"
            ></form-field>
            <form-field
                title="Mailing Postcode"
                fieldtype="form-input"
                inputname="company_contact[mailing_postcode]"
                v-bind:startvalue="company_contact.mailing_postcode"
                v-bind:haserrors="company_contact.errors.mailing_postcode"
            ></form-field>
            <form-field
                title="Mailing Country"
                fieldtype="form-input"
                inputname="company_contact[mailing_country]"
                v-bind:startvalue="company_contact.mailing_country"
                v-bind:haserrors="company_contact.errors.mailing_country"
            ></form-field>

            <!-- End of Mailing address -->

        </div>
    </div>
    <div class="profile__container--outer profile__container--status profile__container--columnPos-3">
        <div class="profile__container--header">
            <h3></h3>
        </div>
        <div class="profile__container--main">
          
        </div>
    </div>

</div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'

    export default {
        props: [
            'recordData',
            'formOptionsData'
        ],
        data: function(){
            var company_contact = JSON.parse(this.recordData || '{}');
            var formOptions = JSON.parse(this.formOptionsData || '{}');
            // console.log(staff); console.log(formOptions);
            return { 
                company_contact: company_contact, 
                formOptions: formOptions
            };
        },
        components: {
            FormField
        }
    }
</script>