module.exports = {
    shortMonths: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
    Date: function(date){
        // console.log(`formatting date input - '${date}'`);
        if(date != undefined && date != null && date != ''){
            let objectify = new Date(date);

            if(objectify == 'Invalid Date'){
                //THIS ERROR HAPPENS IN CHROME WHEN THE INPUT DATE FORMAT EITHER DOESN'T FOLLOW THE
                //DATE STRING STANDARD, OR DOES NOT MATCH THE MM-DD-YYYY FORMATTING
                //HAPPENS IN FIREFOX WHEN THE INPUT DOES NOT MATCH THE STANDARD DATE STRING FORMAT
                //THE FALLBACK WORKS IF THE INPUT DATE FOLLOWS THE MM-DD-YYYY FORMAT
                // console.log(`DATE FORMATTING ERROR - doing fallback format`);
                let dateArray = date.split(`-`);

                fallbackDay = dateArray[1];
                fallbackMonth = this.shortMonths[Number(dateArray[0]) - 1];
                fallbackYear = dateArray[2];

                let formatted = `${fallbackDay}-${fallbackMonth}-${fallbackYear}`;
                // console.log(`fallback output - '${formatted}'`);
                return formatted;
            };
            let day;
            let dayFix;
            let month;
            let monthFix;
            let year;

            if(date.indexOf(`Z`) == -1){
                day = objectify.getDate();
                dayFix = ``;
                if(day < 10){ dayFix = `0` };
        
                month = objectify.getMonth() + 1;
                monthFix = ``;
                if(month < 10){ monthFix = `0` };
                
                year = objectify.getFullYear();
            }else{
                day = objectify.getUTCDate();
                dayFix = ``;
                if(day < 10){ dayFix = `0` };
        
                month = objectify.getUTCMonth() + 1;
                monthFix = ``;
                if(month < 10){ monthFix = `0` };
                
                year = objectify.getUTCFullYear();
            };

    
            let formatted = `${dayFix}${day}-${this.shortMonths[month - 1]}-${year}`;
            // console.log(`output - '${formatted}'`);
            return formatted;
        };
    }
}