<template>
	<div class="profile__input--wrapper">
		<div 
            class="profile__container--dropdown" 
            v-on:click="toggleDropdown"
            v-on:keydown="handleKeyboard">
                <div
                    v-on:focus="handleDropdownFocus"
                    v-on:blur="handleDropdownBlur"
                    class="profile__container--selected" 
                    tabindex="0">
                        <div 
                            v-if="icon == 'dropdown-colors-mode'"
                            v-bind:style="{
                                backgroundColor: selectedOption.color,
                                borderRadius: '50%',
                                width: selectedOption.color == null ? '100%' : '10px',
                                height: selectedOption.color == null ? 'unset' : '10px',
                                left: selectedOption.color == null ? '0' : 'calc(50% - 5px)',
                                top: selectedOption.color == null ? 'calc(50% - 12px)' : 'unset',
                                position: 'relative'
                            }"
                        ><p class="candidate-flag-name" v-if="selectedOption.color == null">{{ selectedOption.name }}</p></div>
                        <p v-if="icon != 'dropdown-colors-mode'">{{ selectedOption.name }}</p>
                        <div 
                            class="profile__arrow"
                            v-bind:class="{ 'profile__arrow--flip': toggleState }"
                        >&lt;</div>
                </div>
                <input 
                    type="text" 
                    class="dropdown-search" 
                    v-if="searchurl != undefined"
                    v-bind:class="{ 'profile__container--show': toggleState}"
                    v-bind:value="searchQuery"
                    v-on:click.stop=""
                    v-on:input="sendQuery">
                <div 
                    class="profile__container--options" 
                    v-bind:class="{ 'profile__container--show': toggleState, 'profile__container--showUP': toggleUp}">
                        <div 
                            class="profile__option" 
                            v-for="(item, index) in dropdownOptions" 
                            v-bind:key="item.vue_key" 
                            v-on:click="selectOption(item)"
                            v-bind:class="{ 'mark-selected': item.id == selectedOption.id, 'mark-keyboardFocus': highlightIndex == index }"
                            v-bind:value="item.id">
                                <div 
                                    v-if="icon == 'dropdown-colors-mode'"
                                    v-bind:style="{
                                        backgroundColor: item.color,
                                        borderRadius: '50%',
                                        width: '10px',
                                        height: '10px',
                                        marginRight: '10px'
                                    }"
                                ></div>
                                {{ item.name }}
                        </div>
                </div>
		</div>
		<select 
            class="hidden_select form-data-target"
            v-bind:name="inputname"
            v-bind:id="inputid">
                <option v-bind:value="selectedOption.id" selected>
                    {{ selectedOption.name }}
                </option>
		</select>
        <i v-if="copy" class="flaticon2-copy input-copy" title="Copy field contents"></i>
		<div v-if="haserrors" class="login-form__warning--icon" v-bind:title="haserrors[0]"><p>!</p></div>
		<!-- <div 
			v-if="haserrors"
			class="error-explanation">
				{{ haserrors[0] }}
		</div> -->
	</div>
</template>

<script>
    import Ajax from './js/ajax.js'
    import Align from './js/align.js'
    import CheckDuplicate from './js/checkDuplicate.js'
    import SearchHandler from './mixins/search_handler.js'

    export default {
        props: [
            'inputname',
            'inputid',
            'startvalue',
            'alloptions',
            'addblank',
            'blanktext',
            'searchurl',
            'preload',
            'preloadquery',
            'haserrors',
            'copy',
            'icon'
        ],
        mixins: [
            SearchHandler
        ],
        data: function(){
            let startOption = this.startvalue;
            if(startOption === 0){ startOption = '0' };
            return {
                selectedOption: startOption || {},
                dropdownOptions: this.alloptions || [],
                toggleState: false,
                toggleUp: false,
                highlightIndex: 0,
                searchQuery: null,
                eventCount: 0,
                initialQuery: {'target': {'value': '   '}, 'initial': 1},
                blankOption: { 'name': this.blanktext || '', 'id': '' },
                lastVueKey: -1
            }
        },
        created: function(){
            if(this.addblank){ this.dropdownOptions = [{'name': this.blanktext || '', 'id': ''}].concat(this.dropdownOptions) };

            let vueInstance = this;
            $(this.dropdownOptions).each(function(option_index){
                //HACKY FIX FOR A FEW FILTER FIELDS, SHOULD CONSIDER BETTER WAY TO IMPLEMENT
                if(vueInstance.$parent.title == 'Work status' 
                || vueInstance.$parent.title == 'Location BL'
                || (vueInstance.$parent.title == 'Request status' && vueInstance.selectedOption != 'active_or_on_hold')){
                    if(!isNaN(Number(vueInstance.selectedOption))){
                        vueInstance.selectedOption = Number(vueInstance.selectedOption);
                    };
                };

                if(this.id === vueInstance.selectedOption || this.name === vueInstance.selectedOption){
                    vueInstance.selectedOption = this;
                    return false;
                };
            });

            if(Number(this.preload)){
                //console.log('preload options');
                Ajax.sendRequest('GET',{ 'q': (this.preloadquery || '') },this.searchurl,this.processResponse,this);
            }else{
                //console.log('dont option preload');
            };
        },
        mounted: function(){
            if(this.icon == 'dropdown-colors-mode'){
                console.log(this);
                this.$parent.$el.className += ` form-dropdown-colors`;
            };
        },
        methods: {
            handleDropdownFocus: function(event){
                //console.log('dropdown focus');
                $(event.target).bind('keypress',(event) => {
                    //console.log(event);
                    if(event.key == "Enter"){
                        this.toggleDropdown();
                        this.$nextTick(() => {
                            event.target.focus()
                        });
                    };
                });
            },
            handleDropdownBlur: function(event){
                //console.log('dropdown blur');  
                $(event.target).unbind('keypress');
            },
            handleKeyboard: function(event){
                //console.log('handle keyboard');
                if(event.target.className.indexOf('dropdown-deselect') == -1){
                    if(event.key == "Escape"){
                        this.toggleState = true;
                        this.toggleDropdown();
                        return;
                    };
                    if(event.key == "ArrowUp"){
                        this.highlightIndex += -1;
                        event.preventDefault();
                    };
                    if(event.key == "ArrowDown"){
                        this.highlightIndex += 1;
                        event.preventDefault();
                    };
                    if(this.dropdownOptions && this.dropdownOptions.length){
                        let optionLength = this.dropdownOptions.length;
                        let targetIndex = (this.highlightIndex + optionLength) % optionLength;
                        this.highlightIndex = targetIndex || 0;
                        this.$nextTick(() => {
                            let markedElement = $(event.target.parentElement).find('.mark-keyboardFocus')[0];
                            let optionsContainer = $(event.target.parentElement).find('.profile__container--options')[0];
                            optionsContainer.scrollTop = markedElement.offsetTop;
                            if(event.key == "Enter" && this.toggleState){
                                //console.log('Enter pressed, select option:');
                                let newOptionFull = {
                                    name: markedElement.innerText,
                                    id: $(markedElement).attr('value')
                                };
                                //console.log(newOptionFull);
                                this.selectOption(newOptionFull);
                            };
                        });
                    };
                };
            },
            //TOGGLE DROPDOWN METHODS:
            toggleDropdown: function(){
                //console.log(`toggle dropdown`);
                if(this.$el.parentElement.className.indexOf('field--disabled') == -1){
                    let openDropdowns = $(this.$root.$el).find(`.profile__container--show`);
                    //console.log(openDropdowns);
                    this.toggleState = !this.toggleState;
                    this.toggleUp = this.toggleState && Align.vertical(this);
                    if(this.toggleState){
                        event.stopPropagation();
                        $(openDropdowns).each(function(dropdown_index){
                            this.click();
                        });
                        if(this.dropdownOptions.length <= Number(Boolean(this.addblank)) && Boolean(this.searchurl)){
                            //console.log(`empty options, re-initialize`);
                            Ajax.sendRequest('GET',{ 'q': (this.preloadquery || '') },this.searchurl,this.processResponse,this);
                        };
                        //console.log(`bind outside click listener`);
                        $(`body`).bind(`click`,this.toggleDropdown);
                        if(this.searchurl){ 
                            //console.log($(this.$el).find(`.dropdown-search`)[0]);
                            this.$nextTick(() => { $(this.$el).find(`.dropdown-search`)[0].focus() })
                        }
                    }else{
                        //console.log(`unbind outside click listener`);
                        $(`body`).unbind(`click`,this.toggleDropdown);
                    };
                };
            },
            initializeOptions: function(rawOptions){
                //console.log(`initializing options`);
                this.lastVueKey = -1;
                rawOptions.forEach((Option) => { this.lastVueKey++; Option.vue_key = this.lastVueKey });
                this.dropdownOptions = rawOptions;
            },
            updateOptions: function(newOptions){
                //console.log(`updating options`);
                if(this.addblank == 'true'){ newOptions = [{ 'name': this.blanktext || '', 'id': '' }].concat(newOptions) };
                this.initializeOptions(newOptions);
            },
            selectOption: function(newOption){
                console.log(`new selected option`);
                console.log(newOption);

                if(this.icon == 'dropdown-colors-mode'){
                    $(`.form-dropdown-colors`).each(function(){ this.__vue__.$children[0].selectedOption = newOption });
                    let eventObject = {
                        data: this.selectedOption
                    };
                    this.$emit('event-bubble',eventObject);
                    return;
                };

                this.selectedOption = newOption;
                let eventObject = {
                    data: this.selectedOption
                };
                this.$emit('event-bubble',eventObject);
            }   
        }
    }
</script>