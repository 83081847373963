<template>
    <div class="move-dossier" v-bind:class="{
            'open': isOpen
        }">
       <i class="flaticon2-arrow-up" v-on:click="toggleModal"></i>

        <div class="dossier-modal__container" v-bind:class="{
            'open': isOpen
        }">
            <div class="dossier-modal__header">
                <h5>Move candidate</h5>
                <i class="flaticon2-delete" v-on:click="toggleModal"></i>
            </div>

            <form-field
                title="E-Mail"
                fieldtype="form-input"
                inputname="candidate[email]"
                v-bind:startvalue="candidate.email_home"
                v-bind:haserrors="errors.length ? errors : ''"
            ></form-field>

            <div v-if="errors.length" style="color: red;">
                <div v-for="error in errors" v-bind:key="error">{{ error }}</div>
            </div>
            
            <button 
                type="submit" 
                name="commit" 
                value="Save Candidate" 
                data-disable-with="submitting..."
                class="update-button"
                v-on:click="moveCandidate">
                    Save
            </button>
            <div class="note-cover"></div>
        </div>
    </div>
</template>

<script>
    import Ajax from '../../form-field/sub-components/js/ajax.js';
    import FormField from '../../form-field/form-field.vue';

    export default {
        props: [
            'candidateData',
            'email',
            'method',
            'url'
        ],
        data: function(){
            let candidateInfo =  JSON.parse(this.candidateData || '{ "errors": {} }');
            console.log(candidateInfo);
            
            return {
                candidate: candidateInfo,
                isOpen: false,
                errors: []
            }
        },
        methods: {
            toggleModal: function(){
                this.isOpen = !this.isOpen;
            },
            moveCandidate: function(){
                const V = this;
                Ajax.sendRequest(this.method,{
                    authenticity_token: $(`meta[name=csrf-token]`)[0].content,
                    candidate: this.getDataFromFields()
                },this.url,(Response,error) => {
                    if(error){
                        V.errors = V.errors.concat(Response.responseJSON.errors.email_home);
                        console.log(`ERROR: `,Response,error,this);
                        this.$nextTick(() => {
                            console.log(Data.candidate);
                            this.setFieldsFromData(Data.candidate);
                            if(Response.status == 500){
                                window.alert(`Something went wrong when moving the account! ${Response.responseText}`);
                            };
                        });
                    }else{
                        // console.log(`SUCCESS: `,Response);
                        V.errors = [];
                        window.location.reload();
                    };
                },this,{ dataType: 'json' });
            },
            getDataFromFields: function(){
                const Data = {};
                Array.from(this.$el.querySelectorAll('.profile__container--field')).forEach((field) => {
                    const fv = field.__vue__;
                    switch(fv.fieldtype){
                        case 'form-dropdown':
                            Data[fv.inputid.replace('candidate_','')] = fv.$children[0].selectedOption.id
                            break;
                        default:
                            Data[fv.inputid.replace('candidate_','')] = field.querySelector('input').value;
                            break;
                    };
                });
                
                return Data;
            },
            setFieldsFromData: function(Data){
                Array.from(this.$el.querySelectorAll('.profile__container--field')).forEach((field) => {
                    const fv = field.__vue__;
                    switch(fv.fieldtype){
                        case 'form-dropdown':
                            const option = fv.$children[0].dropdownOptions.filter((opt) => {
                                return opt.id == Data[fv.inputid.replace('candidate_','')]
                            })[0];
                            fv.$children[0].selectOption(option);
                            break;
                        default:
                            field.querySelector('input').value = Data[fv.inputid.replace('candidate_','')];
                            break;
                    };
                });
            }
        },
        components: {
            FormField
        }
    }
</script>