<template>
    <div 
        class="ap-button" 
        title="Add candidate to job request" 
        v-bind:class="setWrapperClass()" 
        v-on:click="openModal">
            <i class="add-application-button flaticon-file-2"></i>
    </div>
</template>

<script>
    export default {
        props: [
            'candidateid',
            'candidatename',
            'candidatefirstname',
            'candidatelastname',
            'jobrequests',
            'addwrapperclass'
        ],
        data: function(){
            let candidate = {
                'id': this.candidateid,
                'name': this.candidatename,
                'first_name': this.candidatefirstname,
                'last_name': this.candidatelastname
            };
            
            return {
                Candidate: candidate,
                JobRequests: this.jobrequests || []
            };
        },
        methods: {
            setWrapperClass: function(){ return this.addwrapperclass },
            openModal: function(){ $(`#ap-modal`)[0].__vue__.openModal(this.Candidate,this.JobRequests) }
        }
    }
</script>