<template>
    <div class="profile__container--wrapper profile__document--wrapper">
        <div class="profile__container--outer candidate-details sectioned">
            <!--
            <div class="profile__container--header">
                <h3></h3>
            </div>
            -->
                <!-- <i class="flaticon-notes notes-toggle"></i>
                <div class="notes-container">
                    Note: The Text Kernel CV processing service searches for a match of "Job code" and "Web name"
                </div> -->
            <div class="profile__container--main">
        <!--         <div>This will create a new login account with the e-mail:</div> -->

                <form-field
                    title="Document type"
                    fieldtype="form-dropdown"
                    inputname="document[document_type]"
                    v-bind:alloptions="formOptions.document_type_options"
                ></form-field>

                <div class="document__container--actions">
                    <input type="file" name="document[document]" id="upload-docs" class="file-picker__input">
                    <label for="upload-docs" class="file-picker__label"><i class="flaticon2-file-1"></i><p>Choose File</p></label>
                    <input type="submit" name="commit" value="Upload" data-disable-with="Upload">
		            <i class="flaticon-upload-1"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'

    export default {
        props: [
            'formOptionsData'
        ],
        data: function(){
            var formOptions = JSON.parse(this.formOptionsData || '{}');

            return { formOptions: formOptions };
        },
        components: {
            FormField
        }
    }
</script>

