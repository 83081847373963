<template>
    <div class="dossier__statuses--wrapper" v-on:click="openStatuses">
        <div id="ck-note--wrapper">
            <textarea id="ck-note"></textarea>
        </div>
        <notes-table-full
            v-for="status in statusArray"
            v-bind:key="status.vue_key"
            v-bind:isstatus="true"
            v-bind:statusdata="status"
            v-bind:viewer="viewer"
            v-bind:notes="status.notes"
            v-bind:urlnotes="`/notes?job_request_candidate_status_change_id=${ status.id }`"
            v-bind:canaddnote="true"
            v-bind:addnoterecordid="status.id"
            v-bind:addnotedata="{
                'extra': { 'record_type': 'job_request_candidate_status_change' },
                'owner': viewer
            }"
            v-bind:noeditor="true"
        ></notes-table-full>

        <div v-if="showACStatusForm" class="ac-status-form comment-form">
            <h3>Change status to AC</h3>

            <form-field
                title="Applied from site"
                fieldtype="form-dropdown"
                v-bind:inputname="`afs_${Math.random()}`"
                addclass="applied-from-site-field"
                searchurl="/autocomplete/registered-on-options"
            ></form-field>

            <div class="comment-form__buttons">
                <button v-on:click="submitACStatusForm" class="single-note__button--sourcing-task">Update status</button>
                <button v-on:click="showACStatusForm = false" class="single-note__button--sourcing-task">Cancel</button>
            </div>
        </div>
    </div>    
</template>

<script>
    import Ajax from '../form-field/sub-components/js/ajax.js'
    import Format from './js/format.js'
    import NotesTableFull from './talbe.vue'
    import FormField from '../form-field/form-field.vue'

    export default {
        props: [
            'viewer',
            'candidateid',
            'urlstatuses'
        ],
        data: function(){
            return {
                statusArray: [],
                lastVueKey: -1,
                dataLoaded: false,
                showACStatusForm: false,
                lastStatusId: null,
                statusCallback: () => {}
            }
        },
        mounted: function(){
            let dossierHeaderDate = $(this.$el.parentElement).find(`.table__container--content[data-label='Last update:']`)[0];
            dossierHeaderDate.innerText = Format.Date(dossierHeaderDate.innerText);
        },
        components: { Ajax, Format, NotesTableFull, FormField },
        methods: {
            initializeArrayKeys: function(){ 
                this.lastVueKey = -1;
                this.statusArray.forEach((Status) => { 
                    this.lastVueKey++; 
                    Status.vue_key = this.lastVueKey; 
                });
            },
            resetStatuses: function(){
                Ajax.sendRequest('GET',{},this.urlstatuses,(Data) => {
                    this.dataLoaded = true;
                    this.statusArray = Data.results;
                    this.initializeArrayKeys();
                });
            },
            updateStatus: function(statusId,callback){
                const Data = {
                    'authenticity_token': $(`meta[name=csrf-token]`)[0].content, 
                    'status_id': statusId 
                };

                if(statusId == 2){ 
                    this.showACStatusForm = true;
                    // Data.applied_from_site = true 
                    this.lastStatusId = statusId;
                    this.statusCallback = callback;
                }else{
                    Ajax.sendRequest('POST',Data,`/job_requests/dossier/${this.candidateid}/status`,(NewStatusData) => { 
                        callback(NewStatusData);
                    });
                };
            },
            submitACStatusForm: function(){
                    const Data = {
                        'authenticity_token': $(`meta[name=csrf-token]`)[0].content, 
                        'status_id': this.lastStatusId,
                        'applied_from_site': this.$el.querySelector('.applied-from-site-field').__vue__.$children[0].selectedOption.id
                    };

                    Ajax.sendRequest('POST',Data,`/job_requests/dossier/${this.candidateid}/status`,(NewStatusData) => { 
                        this.showACStatusForm = false;
                        this.statusCallback(NewStatusData);
                    });
            },
            openStatuses: function(event){ if(!this.dataLoaded){ this.resetStatuses() } },
            loadStatuses: function(callback){ Ajax.sendRequest('GET',{},this.urlstatuses,callback) }
        }
    }
</script>
