import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import FormEducation from '../vue/form-education.vue'
import FormField from '../vue/form-field/form-field.vue'

import CandidateForm from '../vue/candidates/form/candidate-form.vue'
import CandidateFormInSteps from '../vue/candidates/form/candidate-form-in-steps.vue'
import NewCandidateForm from '../vue/candidates/form/new-candidate-form.vue'
import NewCandidateBluelynxAccount from '../vue/candidates/form/new-candidate-bluelynx-account.vue'
import CandidateMergeDossierForm from '../vue/candidates/form/candidate-merge-dossier-form.vue'
import CandidateFilters from  '../vue/candidates/index/candidate-filters.vue'
import CandidateJobRequestList from '../vue/candidates/index/candidate-job-request-list.vue'

import CandidatesAdvancedSearchFilters from '../vue/candidates/advanced-search/candidates-advanced-search-filters.vue'
import CandidateSearchTable from '../vue/candidates/advanced-search/search-table.vue'


import CandidateGdprRequestFilters from  '../vue/candidate-gdpr-requests/index/candidate-gdpr-request-filters.vue'

import JobRequestInfoForm from '../vue/job-requests/form/job-request-info-form.vue'
import JobRequestWebForm from '../vue/job-requests/form/job-request-web-form.vue'
import JobRequestBriefingForm from '../vue/job-requests/form/job-request-briefing-form.vue'
import JobRequestFilters from  '../vue/job-requests/index/job-request-filters.vue'
import DossierExternalCandidate from '../vue/job-requests/modal/dossier-external-candidate.vue'
import DossierMoveCandidate from '../vue/job-requests/modal/dossier-move-candidate.vue'

import CompanyForm from '../vue/companies/form/company-form.vue'
import CompanyContactForm from '../vue/companies/form/company-contact-form.vue'
import CompanyFilters from  '../vue/companies/index/company-filters.vue'
import CompanyDocumentForm from '../vue/company-documents/form/company-document-form.vue'

import ListValueForm from '../vue/list-values/form/list-value-form.vue'
import ListValueDeleteSection from '../vue/list-values/form/list-value-delete-section.vue'
import ListValueFilterForm from '../vue/list-values/index/list-value-filter-form.vue'

import CandidateDocumentForm from '../vue/candidate-documents/form/candidate-document-form.vue'
import CandidateResults from '../vue/candidates/advanced-search/candidate-results.vue'

import StaffForm from '../vue/staff/form/staff-form.vue'
import StaffAnnouncementForm from '../vue/staff-announcements/form/staff-announcement-form.vue'
import StaffRankingFilters from '../vue/staff-ranking/staff-ranking-filters.vue'
import StaffFilters from  '../vue/staff/index/staff-filters.vue'

import NotesTableFull from '../vue/notes/talbe.vue'
import NotesMenu from '../vue/notes/menu.vue'
import NoteNew from '../vue/notes/new-note.vue'
import ExpandedNote from '../vue/notes/sub-components/expanded-note.vue';
import NotesDossier from '../vue/notes/dossier.vue'

import SourcingTaskList from '../vue/notes/sub-components/sourcing-task-list.vue'
import SourcingTask from '../vue/notes/shared/sourcing-task.vue'
import Comments from '../vue/notes/shared/comments.vue';
import SourcingTaskStages from '../vue/notes/sub-components/sourcing-task-stages.vue';

import StaffLists from '../vue/staff-lists/staff-lists.vue'

import StaffListForm from '../vue/staff-lists/staff-list-form.vue'
import StaffListFilters from '../vue/staff-lists/staff-list-filters.vue'

import AttachApplicationModal from '../vue/job-application/attach-application-modal.vue'
import AttachApplicationButton from '../vue/job-application/attach-application-button.vue'

import DashboardSettings from '../vue/dashboard/settings.vue'
import JobRequestListFilter from '../vue/dashboard/job-request-list-filter.vue'
import CandidateApplicationsFilter from '../vue/dashboard/candidate-applications-filter.vue'

import CompletedJobsFilters from  '../vue/system-jobs/index/completed-jobs-filters.vue'

import ColorPicker from '../vue/color-picker/color-picker.vue'
// import CandidateAddress from '../vue/candidates/form/shared/candidate-address.vue'
// import CandidateLanguages from '../vue/candidates/form/shared/candidate-languages.vue'
// import CandidateEducationItems from '../vue/candidates/form/shared/candidate-education-items.vue'
// import CandidateJobExperienceItems from '../vue/candidates/form/shared/candidate-job-experience-items.vue'

// import '../vue/candidates/form/candidate-form.js'

Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {
  if($(`#vueApp`)[0]){
    const app = new Vue({
      el: '#vueApp',
      components: { 
        FormEducation,
        FormField,
        CandidateForm,
        NewCandidateForm,
        NewCandidateBluelynxAccount,
        CompanyForm,
        CompanyContactForm,
        CompanyFilters,
        CompanyDocumentForm,
        ListValueForm,
        ListValueDeleteSection,
        ListValueFilterForm,
        CandidateFormInSteps,
        CandidateMergeDossierForm,
        CandidateDocumentForm,
        CandidateResults,
        CandidatesAdvancedSearchFilters,
        CandidateSearchTable,
        CandidateGdprRequestFilters,
        CandidateFilters,
        CandidateJobRequestList,
        StaffForm,
        StaffAnnouncementForm,
        StaffRankingFilters,
        StaffFilters,
        JobRequestInfoForm,
        JobRequestWebForm,
        JobRequestBriefingForm,
        JobRequestFilters,
        DossierExternalCandidate,
        DossierMoveCandidate,
        NotesTableFull,
        NotesMenu,
        NoteNew,
        ExpandedNote,
        NotesDossier,
        SourcingTaskList,
        SourcingTask,
        Comments,
        SourcingTaskStages,
        StaffLists,
        AttachApplicationModal,
        AttachApplicationButton,
        DashboardSettings,
        JobRequestListFilter,
        CandidateApplicationsFilter,
        CompletedJobsFilters,
        ColorPicker,
        StaffListForm,
        StaffListFilters
      }
    });
  };
});
