<!--
    @created by Zheko Hristov on 12/03/2024
    @last-updated by Zheko Hristov on 05/04/2024
    This component represents the sourcing task stages section

    - this component can be used inside another vue component or standalone in page
    - if the component is used standalone in page the v-if="noteType === 'sourcing_task'" check 
    will not work and has to be made with rails code
-->
<template>
    <div class="single-note__container--task-stages">
        <div class="task-stages__container--outer">
            <div 
                class="single-task__container--stages"
                v-for="task in tasklist" 
                v-bind:key="task.id">
                <div class="task__container--details">
                    <p>
                        <i class="flaticon2-right-arrow"></i> {{ task.candidate_type.short_name }}
                    </p>
                    <p>
                        <i class="flaticon2-right-arrow"></i> ({{ task.requested }})
                    </p>
                    <p
                        v-if="task.assigned_staff.id">
                        <i class="flaticon2-right-arrow"></i> {{ task.assigned_staff.name }}
                        <button
                            v-if="canUpdateAssignedStaff && task.status === 'assigned' && taskStatus !== 'done'"
                            v-on:click="toggleEditAssignedStaffForm(task)"
                            title="Click to edit assigned staff"
                            class="button--edit-staff"> 
                            <i class="flaticon-edit"></i> 
                        </button>
                    </p>
                </div>
                <div 
                    class="stages__container--outer"
                    v-bind:class="task.status">
                    <div 
                        class="stages__container--single"
                        v-bind:class="task.status === 'unassigned' ?  getTaskClass(task) : ''"
                        v-bind:title="task.status === 'unassigned' ? getTaskTitle(task) : ''"
                        v-on:click="task.status === 'unassigned' ? handleStatusClick(task) : ''">
                        <i class="flaticon2-add"></i>
                        <span> Unassigned task</span>
                    </div>
                    <div 
                        class="stages__container--single"
                        v-bind:class="task.status === 'assigned' ?  getTaskClass(task) : ''"
                        v-bind:title="task.status === 'assigned' ? getTaskTitle(task) : ''"
                        v-on:click="task.status === 'assigned' ? handleStatusClick(task) : ''">
                        <i class="flaticon2-user-1"></i>
                        <span> Aassigned task</span>
                    </div>
                    <div 
                        class="stages__container--single"
                        v-bind:class="task.status === 'waiting_for_review' ?  getTaskClass(task): ''"
                        v-bind:title="task.status === 'waiting_for_review' ? getTaskTitle(task): ''"
                        v-on:click="task.status === 'waiting_for_review' ? handleStatusClick(task) : ''">
                        <i class="flaticon2-hourglass-1"></i>
                        <span> Waiting for review </span>
                    </div>
                    <div 
                        class="stages__container--single"
                        v-bind:class="task.status === 'done' ?  getTaskClass(task): ''"
                        v-bind:title="task.status === 'done' ? getTaskTitle(task): ''"
                        v-on:click="task.status === 'done' ? handleStatusClick(task) : ''">
                        <i class="flaticon2-checkmark"></i>
                        <span> Done task </span>
                    </div>
                </div>
                <!-- 
                <button
                    v-bind:title="getTaskTitle(task)" 
                    class="single-note__button--status single-note__button--sourcing-task" 
                    v-bind:class="getTaskClass(task)" 
                    v-on:click="handleStatusClick(task)">
                    {{ getTaskButtonText(task) }}
                </button> 
                -->
            </div>
        </div>

        <!-- Comment form modal -->
        <div
            v-if="showCommentForm" 
            class="comments__container--modal"
            v-bind:class="{ 'show' : showModalContainerAfterResponseRecieved }"> 
            <div class="comments__container--inner js-modal-container-inner">
                <div class="comments__container--top js-modal-container-inner-top">
                    <h3>
                        {{ lastTask.status === 'waiting_for_review' ? 'Recruiter Feedback Form: Sourcing Task' : 'Sourcer Close Form: Sourcing Task' }}
                    </h3>
                    <button 
                        class="single-note__button--sourcing-task" 
                        v-on:click="toggleCommentForm">
                        <i class="flaticon-close"></i>
                    </button>
                </div>
                <div class="comments__container--middle js-modal-container-inner-middle"> 
                    <div
                        v-if="closeSourcingTaskSourcerComment"
                        class="submit-comment__container--single submit-comment__container--sourcer-comment">
                        <p> Sourcer <strong>{{ this.closeSourcingTaskSourcerName }}</strong>: </p>
                        <pre>{{ this.closeSourcingTaskSourcerComment }}</pre>
                    </div>

                    <div class="submit-comment__container--single">
                        <p> Requested: <strong> {{ lastTask.requested }} </strong> </p>
                        <p v-if="lastTask.status === 'waiting_for_review'"> Delivered: <strong> {{ lastTask.delivered }} </strong> </p>
                    </div>

                    <div class="submit-comment__container--single">
                        <form-field
                            v-if="lastTask.status !== 'waiting_for_review'"
                            title="Delivered"
                            fieldtype="form-number"
                            inputname="delivered"
                            addclass="comment-form-field"
                            v-bind:startvalue="comment_delivered"
                            v-bind:alloptions="{min: 0, max: Infinity, step: 1}"
                        ></form-field>
                        <form-field
                            v-if="lastTask.status === 'waiting_for_review'"
                            title="Approved"
                            fieldtype="form-number"
                            inputname="approved"
                            addclass="comment-form-field"
                            v-bind:startvalue="comment_approved"
                            v-bind:alloptions="{min: 0, max: Infinity, step: 1}"
                        ></form-field>

                        <form-field
                            v-if="lastTask.status === 'waiting_for_review'"
                            title="Rejected"
                            fieldtype="form-number"
                            inputname="rejected"
                            addclass="comment-form-field"
                            v-bind:startvalue="comment_rejected"
                            v-bind:alloptions="{min: 0, max: Infinity, step: 1}"
                        ></form-field>
                    </div>
                
                    <div class="submit-comment__container--single">
                        <form-field
                            title="Feedback"
                            fieldtype="form-textarea"
                            inputname="comment"
                            addclass="comment-form-field"
                            v-bind:startvalue="comment"
                        ></form-field>
                    </div>
                    
                </div>
                <div class="comments__container--bottom js-modal-container-inner-bottom"> 
                    <div class="comments__container--buttons">
                        <button class="single-note__button--save" v-on:click="submitCommentForm">Submit</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Edit assigned staff modal -->
        <div
            v-if="showEditAssignedStaffForm" 
            class="edit-assigned-staff__container--modal show"> 
            <div class="edit-assigned-staff__container--inner js-modal-container-inner">
                <div class="edit-assigned-staff__container--top js-modal-container-inner-top">
                    <h3>
                        Edit assigned staff
                    </h3>
                    <button 
                        class="single-note__button--sourcing-task" 
                        v-on:click="toggleEditAssignedStaffForm">
                        <i class="flaticon-close"></i>
                    </button>
                </div>
                <div class="edit-assigned-staff__container--middle js-modal-container-inner-middle"> 
                    <form-field
                        title="Assigned staff"
                        fieldtype="form-dropdown"
                        inputname="assigned_staff_id"
                        v-bind:startvalue="lastTask.assigned_staff"
                        v-bind:alloptions="members"
                        v-bind:isdisabled="false"
                        addblank="true"
                        blanktext="Unassign task"
                        v-on:event-bubble="setAssignedStaff"
                    ></form-field>
                    <p 
                        v-for="error in editAssignedStaffErrors" 
                        v-bind:key="error" 
                        class="text--error-message">
                        {{ error }}
                    </p>
                </div>
                <div class="edit-assigned-staff__container--bottom js-modal-container-inner-bottom"> 
                    <div class="edit-assigned-staff__container--buttons">
                        <button class="single-note__button--save" v-on:click="submitEditAssignedStaffForm">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
    import FormField from '../../form-field/form-field.vue';
    import Ajax from '../../form-field/sub-components/js/ajax.js';

    export default {
        props: [
            'sourcingtasks',
            'taskstatus',
            'ismember',
            'isowner',
            'viewer',
            'canupdateassignedstaff',
            'members'
        ],
        data: function () {
            return {
                tasklist: this.sourcingtasks || [],
                taskStatus: this.taskstatus,
                isMember: this.ismember,
                isOwner: this.isowner,
                canUpdateAssignedStaff: this.canupdateassignedstaff,
                showCommentForm: false,
                comment: '',
                comment_delivered: 0,
                comment_approved: 0,
                comment_rejected: 0,
                lastTask: null,
                comment_errors: [],
                note_errors: [],
                closeSourcingTaskSourcerComment: null,
                closeSourcingTaskSourcerName: '',
                showModalContainerAfterResponseRecieved: false,
                showEditAssignedStaffForm: false,
                assignedStaffId: '',
                editAssignedStaffErrors: []
            }
        },
        created: function () {
        },
        mounted: function () {
            window.addEventListener('resize', () => {
                this.calculateCommentsMiddleSectionMaxHeight;
                this.calculateEditAssignedStaffMiddleSectionMaxHeight;
            });
        },
        updated: function () {
            this.calculateCommentsMiddleSectionMaxHeight();
            this.calculateEditAssignedStaffMiddleSectionMaxHeight();
        },
        methods: {
            getTaskButtonText: function (task) {
                return task.assigned_staff.id
                ? `${task.candidate_type.short_name} / (${task.requested}) / ${task.assigned_staff.name}`
                : `${task.candidate_type.short_name} / (${task.requested})`;
            },
            getTaskClass: function (task){
                if (!this.isMember) {
                    return {
                        'status-white': true
                    };
                }

                if (task.status === 'done' || this.taskStatus === 'done') {
                    return {
                        'status-green': true
                    };
                };

                if (task.status === 'unassigned') {
                    return {
                        'status-orange': true
                    };
                };

                // review task status red
                if (task.status === 'assigned' && task.assigned_staff.id === this.viewer.id) {
                    return {
                        'status-red': true
                    };
                };

                // close task status red
                if (task.status === 'waiting_for_review' && this.isOwner) {
                    return {
                        'status-red': true
                    };
                };

                return {
                    'status-white': true
                };
            },
            getTaskTitle: function (task) {
                let title = ``;
                const statusClass = Object.keys(this.getTaskClass(task))[0];
                if (statusClass === 'status-orange') {
                    title = 'Click to accept task - ';
                }

                if (statusClass === 'status-red') {
                    if (task.status === 'waiting_for_review') {
                        title = 'Click to close task - ';
                    } else if (task.status === 'assigned') {
                        title = 'Click to review task - ';
                    }
                }

                return `${title} ${this.getTaskButtonText(task)}`;
            },
            toggleCommentForm: function (task) {
                console.log('TOGGLE CF')
                this.showCommentForm = !this.showCommentForm;

                if (!this.showCommentForm) {
                    // fixes for job request sourcing page
                    $('.sub-header__container--wrapper, #job-request-filter-form, .filter-start-with.js-filter-relocate, .table__pagination--wrapper')
                        .css({ 'pointer-events': 'auto', 'opacity': '1' });
                    this.comment = '';
                    this.comment_delivered = 0;
                    this.comment_approved = 0;
                    this.comment_rejected = 0;
                    this.lastTask = null;
                    this.showModalContainerAfterResponseRecieved = false;
                    return;
                } else {
                    // fixes for job request sourcing page
                    $('.sub-header__container--wrapper, #job-request-filter-form, .filter-start-with.js-filter-relocate, .table__pagination--wrapper')
                        .css({ 'pointer-events': 'none', 'opacity': '0' });
                }

                if (task) {
                    this.comment_delivered = task.requested;
                };

                if (task.status === 'waiting_for_review') {
                    Ajax.request('GET',{},`/sourcing-tasks/${task.id}`,(response) => {
                        this.showModalContainerAfterResponseRecieved = true;
                        this.closeSourcingTaskSourcerComment = JSON.parse(response).comments[0].comment;
                        this.closeSourcingTaskSourcerName = JSON.parse(response).assigned_staff.name;
                    },this,(response) => {
                        console.log(`FAIL`,response);
                    });
                } else {
                    this.showModalContainerAfterResponseRecieved = true;
                }
            },
            handleStatusClick: function (task) {
                const statusClass = Object.keys(this.getTaskClass(task))[0];

                this.lastTask = task;
                switch(statusClass){
                    case 'status-orange':
                        console.log(`ASSIGNED MEMBER ACCEPTING...`);

                        if (confirm(`Accept sourcing ${task.requested} ${task.candidate_type.name} candidate${task.requested > 1 ? 's' : ''}`)) {
                            Ajax.request('POST',{},`/sourcing-tasks/${task.id}/accept`,(response) => {
                                window.location.reload();
                            },this,(response) => {
                                console.log(`ERROR`,response);
                                const errorText = `Errors:\n${JSON.parse(response).errors.map((error) => { return error }).join('\n')}`;
                                alert(errorText);
                                // window.location.reload();
                            });
                        };
                        break;
                    case 'status-red':
                        console.log(`ASSIGNED STAFF UPDATING...`);

                        this.toggleCommentForm(task);
                        break;
                    // case 'status-red':
                    //     console.log(`OWNER REVIEWING...`);

                    //     this.toggleCommentForm(task);
                    //     break;
                    default:
                        break;
                };
            },
            submitCommentForm: function () {
                const Data = {};
                const commentFields = Array.from(this.$el.querySelectorAll('.comment-form-field')).map((el) => {
                    return el.__vue__;
                });

                commentFields.forEach((VueEl) => {
                    console.log(VueEl);

                    switch(VueEl.fieldtype){
                        case 'form-input':
                            Data[VueEl.inputname] = VueEl.$children[0].$el.querySelector('input').value;
                            break;
                        case 'form-number':
                            Data[VueEl.inputname] = VueEl.$children[0].numberValue;
                            break;
                            case 'form-textarea':
                            Data[VueEl.inputname] = VueEl.$children[0].$el.querySelector('textarea').value;
                            break;
                        default:
                            break;
                    };
                });

                // console.log(commentFields,Data);

                Ajax.request('POST',Data,`/sourcing-tasks/${this.lastTask.id}/comment`,(response) => {
                    window.location.reload();
                },this,(response) => {
                    this.comment_errors = response.errors;
                });
            },
            toggleEditAssignedStaffForm: function (task) {
                this.showEditAssignedStaffForm = !this.showEditAssignedStaffForm;

                if (!this.showEditAssignedStaffForm) {
                    // fixes for job request sourcing page
                    $('.sub-header__container--wrapper, #job-request-filter-form, .filter-start-with.js-filter-relocate, .table__pagination--wrapper')
                        .css({ 'pointer-events': 'auto', 'opacity': '1' });
                    this.lastTask = null;
                    this.assignedStaffId = '';
                    return;
                } else {
                    // fixes for job request sourcing page
                    $('.sub-header__container--wrapper, #job-request-filter-form, .filter-start-with.js-filter-relocate, .table__pagination--wrapper')
                        .css({ 'pointer-events': 'none', 'opacity': '0' });
                }
                this.lastTask = task;
                this.assignedStaffId = task.assigned_staff.id;
            },
            setAssignedStaff: function (event) {
                this.assignedStaffId = event.data.id;
            },
            submitEditAssignedStaffForm: function () {
                const Data = {};
                Data.assigned_staff_id = this.assignedStaffId;

                Ajax.request(
                    'POST',
                    Data,
                    `/sourcing-tasks/${this.lastTask.id}/assignee`,
                    (response) => {
                        window.location.reload();
                    },
                    this,
                    (response) => {
                        const responseObject = JSON.parse(response);
                        this.editAssignedStaffErrors = responseObject.errors;
                    }
                );
            },
            calculateMaxHeightFunction: function (modalContainerOuter) {
                const modalContainerInner = modalContainerOuter.querySelector('.js-modal-container-inner');
                const modalContainerTop = modalContainerOuter.querySelector('.js-modal-container-inner-top');
                const modalContainerBottom = modalContainerOuter.querySelector('.js-modal-container-inner-bottom');
                const modalContainerMiddle = modalContainerOuter.querySelector('.js-modal-container-inner-middle');

                const modalContainerOuterStyles = window.getComputedStyle(modalContainerOuter);
                const modalContainerInnerStyles = window.getComputedStyle(modalContainerInner);
                const modalContainerTopStyles = window.getComputedStyle(modalContainerTop);
                const modalContainerBottomStyles = window.getComputedStyle(modalContainerBottom);
        
                // buffer zone between the inner and the outer container
                const topBufferZone = 20;
                let calculatedMaxHeight = 0;

                calculatedMaxHeight = 
                    parseFloat(modalContainerOuterStyles['height']) - 
                    (
                        (topBufferZone * 2) + 
                        (parseFloat(modalContainerInnerStyles['padding-top']) * 2) +
                        parseFloat(modalContainerTopStyles['height']) +
                        parseFloat(modalContainerTopStyles['padding-bottom']) +
                        parseFloat(modalContainerTopStyles['border-bottom-width']) +
                        parseFloat(modalContainerBottomStyles['height']) +
                        parseFloat(modalContainerBottomStyles['padding-top']) +
                        parseFloat(modalContainerBottomStyles['border-top-width'])
                    );

                modalContainerMiddle.style.maxHeight = (calculatedMaxHeight / 10) + 'rem';
            },
            calculateCommentsMiddleSectionMaxHeight: function () {
                if (!this.showCommentForm) return;
                
                this.calculateMaxHeightFunction(this.$el.querySelector('.comments__container--modal'));
            },
            calculateEditAssignedStaffMiddleSectionMaxHeight: function () {
                if (!this.showEditAssignedStaffForm) return;
                
                this.calculateMaxHeightFunction(this.$el.querySelector('.edit-assigned-staff__container--modal'));
            },
        },
        components: { FormField, Ajax },
    }
</script>
