<!--
    @created by Zheko Hristov on 19/03/2024
    @last-updated by Zheko Hristov on 04/04/2024
    This component represents the expanded note modal
-->

<template>
    <div 
        class="expanded-note__container--outer"
        v-bind:class="{
            'notes__container--single-task': (noteType == 'task' || noteType == 'sourcing_task') }">
        <button
            v-if="!noteNew"
            type="button"
            class="single-note__button--save single-note__button--expand"
            v-on:click="toggleExpand">
            <i v-if="taskStatus == 'done'" class="flaticon2-check-mark"></i>
            <i v-if="isOverdue && taskStatus != 'done'" class="flaticon2-exclamation" title="Number of days the task is overdue by.">{{ daysOverdue }}</i>
            <i v-if="!isOverdue && taskStatus != 'done' && !noteEditable" class="flaticon-eye single-note__button--expand-arrow"></i>
            <i v-if="!isOverdue && taskStatus != 'done' && noteEditable" class="flaticon-edit"></i>
            <p> {{ openExpandText }} </p>
        </button>

        <button
            v-if="noteNew"
            type="button"
            class="single-note__button--save single-note__button--expand"
            v-bind:class="{
                'note-sourcing_task': noteType == 'sourcing_task'
            }"
            v-on:click="toggleExpand">
                <i class="flaticon-add-circular-button single-note__button--expand-arrow"></i>
                <p> {{ openExpandText }} </p>
        </button>

        <div 
            class="expanded-note__container--modal notes__container--single-expanded"
            v-if="showExpandedNoteModal">
            <div 
                class="expanded-note__container--inner js-modal-container-inner">
                <div class="expanded-note__container--top js-modal-container-inner-top"> 
                    <h3 v-if="!noteNew">
                        {{ `${noteViewText} ${noteType.replace('sourcing_','')} ${dossierText}` }}
                    </h3>
                    <h3 v-if="noteNew"> 
                        Add {{ noteType === 'sourcing_task' ? 'sourcing task' : noteType }}  {{ dossierText }}
                    </h3>
                    <button
                        type="button"
                        class="single-note__button--save single-note__button--expand"
                        v-on:click="toggleExpand">
                            <i class="flaticon-close"></i>
                            <p>{{ closeExpandText }}</p>
                    </button>
                </div>
                <div class="expanded-note__container--middle js-modal-container-inner-middle"> 
                    <div
                        v-if="noteNew" 
                        class="expanded-note__container--middle-single container--hide-label">
                        <div class="container--header">
                            <h4> Type </h4>
                        </div>
                        <form-field
                            title="Type"
                            fieldtype="form-radio"
                            addclass="note-type"
                            v-bind:inputname="`note_type[${recordId}]`"
                            v-bind:startvalue="noteType"
                            v-bind:alloptions="noteTypeOptions"
                            v-on:event-bubble="setNoteType"
                        ></form-field>
                    </div>
                    <div
                        v-if="noteType === 'sourcing_task'" 
                        class="expanded-note__container--middle-single">
                        <div class="container--header">
                            <h4> Sourcing tasks </h4>
                        </div>
                        <sourcing-task-list
                            v-bind:ismember="isMember"
                            v-bind:isowner="isOwner"
                            v-bind:sourcingtasks="taskList"
                            v-bind:candidatetypeoptions="candidateTypeOptions"
                        ></sourcing-task-list>
                    </div>
                    <div class="expanded-note__container--middle-single container--hide-label">
                        <div class="container--header">
                            <h4> Description </h4>
                        </div>
                        <form-field
                            v-if="noteType != 'task' && noteType != 'sourcing_task'"
                            title="Title"
                            fieldtype="form-input"
                            addclass="note-title"
                            v-bind:inputname="`title[${recordId}]`"
                            v-bind:startvalue="sfTitle"
                            v-bind:isdisabled="!isOwner"
                            v-on:event-bubble="setTitle"
                        ></form-field>
                        <div 
                            class="text__container"
                            v-on:keydown="handleCKInput"
                        ></div>                    
                    </div>
                    <div class="expanded-note__container--middle-single">
                        <div class="container--header">
                            <h4> Details </h4>
                        </div>
                        <div
                            v-if="noteType === 'task'"
                            class="expanded-note__container--task-status">
                            <form-field
                                title="Due to"
                                fieldtype="form-date"
                                v-bind:inputname="`task_due_to[${recordId}]`"
                                v-bind:startvalue="dueDate"
                                v-bind:isdisabled="!isOwner"
                                v-on:event-bubble="setDueDate"
                                v-bind:haserrors="dueError"
                                v-bind:hastime="true"
                            ></form-field>
                            <form-field
                                title="ASAP"
                                fieldtype="form-checkbox"
                                addclass="note-asap"
                                v-bind:inputname="`task_asap[${recordId}]`"
                                v-bind:startvalue="isAsap"
                                v-bind:isdisabled="!noteNew && !isOwner"
                                v-on:event-bubble="setIsAsap"
                            ></form-field>
                        </div> 

                        <form-field
                            v-if="noteType === 'sourcing_task'"
                            title="Priority"
                            fieldtype="form-dropdown"
                            v-bind:inputname="`prority[${recordId}]`"
                            v-bind:startvalue="sourceTaskPriority"
                            v-bind:alloptions="[
                                {name: 'High', id: 1},
                                {name: 'Normal', id: 2},
                                {name: 'Low', id: 3}
                            ]"
                            v-bind:isdisabled="!noteEditable"
                            v-on:event-bubble="setPriority"
                        ></form-field>

                        <form-field
                            title="Staff lists"
                            fieldtype="form-dropdown"
                            v-bind:inputname="`[${recordId}]`"
                            v-bind:startvalue="staffList"
                            v-bind:searchurl="'/autocomplete/all-staff-lists'"
                            v-bind:alloptions="staffLists"
                            v-bind:isdisabled="!noteEditable"
                            v-on:event-bubble="setStaffList"
                        ></form-field>

                        <form-field
                            v-bind:isdisabled="!isOwner"
                            title="Members"
                            addclass="note-members"
                            fieldtype="form-multidrop"
                            inputname="member_ids[]"
                            selectall="true"
                            preload="0"
                            searchurl="/autocomplete/staff"
                            v-bind:startvalue="taskMembers"
                            v-bind:alloptions="[]"
                            v-on:event-bubble="setMembers"
                        ></form-field>
                        <div
                            v-if="!noteNew && 
                                (noteType === 'task' || noteType ==='sourcing_task')" 
                            class="expanded-note__container--status">
                            <form-field
                                title="Status"
                                fieldtype="form-dropdown"
                                v-bind:inputname="`task_status[${recordId}]`"
                                v-bind:startvalue="taskStatus"
                                v-bind:alloptions="[
                                    {name: 'open', id: 'open'},
                                    {name: 'done', id: 'done'}    
                                ]"
                                v-bind:isdisabled="!noteEditable"
                                v-on:event-bubble="setStatus"
                            ></form-field>
                            <p
                                v-if="taskStatusUpdatedAt"
                                class="note__container--status">
                                {{ reformatDate(taskStatusUpdatedAt) }} - {{ taskStatusUpdatedBy.name }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="expanded-note__container--bottom js-modal-container-inner-bottom">
                    <div
                        v-if="noteErrors.length > 0"
                        class="note-errors">
                        <p 
                            v-for="error in noteErrors"
                            v-bind:key="error" 
                            class="note-error">
                            {{ error }}
                        </p>
                    </div>
                    <div class="expanded-note__container--buttons">
                        <input 
                            v-if="!noteNew"
                            type="button" 
                            value="Update" 
                            class="single-note__button--save"
                            v-bind:class="{ 'single-note__button--save-disabled': (!noteEditable) }"
                            v-bind:disabled="!noteEditable"
                            v-on:click="updateNote">
                        <input
                            v-if="(!noteNew && noteDeletable)"
                            type="button"
                            value="Delete"
                            class="single-note__button--delete"
                            v-bind:class="{ 'single-note__button--delete--disabled': (!noteEditable) }"
                            v-bind:disabled="!isOwner"
                            v-on:click="deleteNote">

                        <input 
                            v-if="noteNew"
                            type="button" 
                            value="Create" 
                            class="single-note__button--save"
                            v-bind:class="{ 'single-note__button--save-disabled': (!noteEditable) }"
                            v-bind:disabled="!noteEditable"
                            v-on:click="createNote">
                    </div>
                    <div
                        v-if="!noteNew" 
                        class="note__container--owner">
                        <p>{{ owner.name }}</p>
                        <p>{{ reformatDate(taskCreatedAt) }}</p>
                        <p>{{ reformatDate(taskUpdatedAt) }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FormField from '../../form-field/form-field.vue';
    import SourcingTaskList from './sourcing-task-list.vue';
    import Ajax from '../../form-field/sub-components/js/ajax.js';
    import Format from '../js/format.js';

    export default {
        props: [
            'notenew',
            'notetype',
            'taskstatus',
            'isoverdue',
            'daysoverdue',
            'dueerror',
            'noteeditable',
            'notedeletable',
            'isstatus',
            'statusdata',
            'recordid',
            'isdashboard',
            'candidatetypeoptions',
            'sourcingtasks',
            'ismember',
            'isowner',
            'sftitle',
            'notetext',
            'duedate',
            'isasap',
            'priority',
            'stafflist',
            'stafflistid',
            'members',
            'viewer',
            'owner',
            'reloadafteraction',
            'taskstatusupdatedby',
            'taskstatusupdatedat',
            'noteid',
            'recordtype',
            'sourcepage',
            'taskcreatedat',
            'taskupdatedat',
        ],
        data: function () {
            return {
                noteNew: this.notenew,
                noteType: this.notetype,
                taskStatus: this.taskstatus,
                taskStatusInitial: this.taskstatus,
                isOverdue: this.isoverdue,
                daysOverdue: this.daysoverdue,
                dueError: this.dueerror || [],
                noteEditable: this.noteeditable,
                noteDeletable: this.notedeletable,
                isStatus: this.isstatus,
                statusData: this.statusdata,
                recordId: this.recordid,
                isDashboard: this.isdashboard,
                candidateTypeOptions: this.candidatetypeoptions,
                taskList: this.sourcingtasks || [],
                isMember: this.ismember,
                isOwner: this.isowner,
                sfTitle: this.sftitle,
                noteText: this.notetext,
                dueDate: this.duedate,
                isAsap: this.isasap,
                sourceTaskPriority: this.priority || 2,
                staffList: this.stafflist,
                staffListId: this.stafflistid,
                taskMembers: this.members || [],
                reloadAfterAction: this.reloadafteraction,
                taskStatusUpdatedBy: this.taskstatusupdatedby || {},
                taskStatusUpdatedByInitial: this.taskstatusupdatedby || {},
                taskStatusUpdatedAt:  this.taskstatusupdatedat ?  Format.Date(this.taskstatusupdatedat) : null,
                noteId: this.noteid,
                recordType: this.recordtype,
                sourcePage: this.sourcepage,
                taskCreatedAt: this.taskcreatedat,
                taskUpdatedAt: this.taskupdatedat,
                openExpandText: '',
                closeExpandText: '',
                showExpandedNoteModal: false,
                noteViewText: '',
                dossierText: '',
                ckInstance: null,
                noteTypeOptions: [],
                staffLists: [],
                noteErrors: [],
            }
        },
        created: function () {
            if (this.noteEditable) {
                this.noteViewText = 'Edit' 
            } else {
                this.noteViewText = 'View' 
            }

            const urlFirst = window.location.href.split('/')[3];
            this.noteTypeOptions = ((urlFirst == 'job_requests' || this.isDashboard) && !this.isStatus) ? [
                {'name': 'Note', 'id': 'note'}, 
                {'name': 'Task', 'id': 'task'},
                {'name': 'Source', 'id': 'sourcing_task'}
            ] : [
                {'name': 'Note', 'id': 'note'}, 
                {'name': 'Task', 'id': 'task'}
            ];

            this.dossierText = this.getDossierText();
            this.openExpandText = this.getOpenExpandedText();
            this.closeExpandText = this.getCloseExpandedText();
        },
        mounted: function () {
            window.addEventListener('resize', this.calculateExpandedNoteMiddleSectionMaxHeight);
        },
        updated: function () {
            this.calculateExpandedNoteMiddleSectionMaxHeight();
        },
        methods: {
            getDossierText: function () {
                if (this.isStatus) {
                    return `[${this.statusData.status.code}]`;
                }
                return ``;
            },
            getOpenExpandedText: function() {
                let openText = '';
                if (this.notenew) { 
                    openText = 'Add';
                } else {
                    if (this.noteEditable) {
                        openText = 'Edit';
                    } else {
                        openText = 'View';
                    }
                }
                openText += ` ${this.noteType || 'note'}`.replace('sourcing_','');
                return openText;
            },
            getCloseExpandedText: function () {
                let closeText = '';
                if (this.noteNew) {
                    closeText = 'Cancel';
                } else {
                    closeText = 'Close';
                }
                closeText += ` ${this.noteType }`.replace('sourcing_','');

                return closeText;
            },
            toggleExpand: function () {
                this.showExpandedNoteModal = !this.showExpandedNoteModal;

                if (this.showExpandedNoteModal) {
                    // fixes for job request sourcing page
                    $('.sub-header__container--wrapper, #job-request-filter-form, .filter-start-with.js-filter-relocate, .table__pagination--wrapper')
                        .css({ 'pointer-events': 'none', 'opacity': '0' });
                    this.$nextTick(() => {
                        let container = $(this.$el).find(`.text__container`);
                        // console.log(`container`,container)
                        let editor = $(`#ck-note ~ .ck`)[0];
                        $(container).append(editor);

                        let editorInstance = $(`#ck-note`)[0].ckInstance;
                        editorInstance.setData(this.noteText);
                        this.ckInstance = editorInstance;
                        if (!this.noteEditable) {
                            editorInstance.isReadOnly = true 
                        } else { 
                            editorInstance.isReadOnly = false 
                        }
                    })
                } else {
                    // fixes for job request sourcing page
                    $('.sub-header__container--wrapper, #job-request-filter-form, .filter-start-with.js-filter-relocate, .table__pagination--wrapper')
                        .css({ 'pointer-events': 'auto', 'opacity': '1' });
                    let wrapper = $(`#ck-note--wrapper`);
                    let editor = $(this.$el).find(`.text__container > .ck`);

                    $(wrapper).append(editor);
                    this.ckInstance = null;
                    if (this.isDashboard) {
                        this.$nextTick(() => { this.$parent.$parent.$parent.fitLists() });
                    }

                    this.resetDataOnClose();
                }
            },
            resetDataOnClose: function () {
                // TO DO - Reset fields on close if there is need, for now not
            },
            setNoteType: function (event) {
                this.noteType = event.data
                if (this.isStatus) {
                    if (this.noteType == 'note') {
                        this.taskMembers = this.taskMembers.filter((Member) => { 
                            return Member.id != this.viewer.id 
                        });
                        $(this.$el).find(`.note-members`)[0].__vue__.$children[0].selectValue = this.taskMembers;
                      } else {
                        if (this.taskMembers.indexOf(this.viewer) == -1) {
                            this.taskMembers.push(this.viewer);
                        }
                    }
                }
                this.showExpandedNoteModal = false;
                let wrapper = $(`#ck-note--wrapper`);
                let editor = $(this.$el).find(`.text__container > .ck`);

                $(wrapper).append(editor);
                this.ckInstance = null;
                this.$nextTick(() => {
                    this.toggleExpand();
                });

                this.openExpandText = this.getOpenExpandedText();
                this.closeExpandText = this.getCloseExpandedText();
            },
            setTitle: function (event) { 
                this.sfTitle = event.data;
            },
            handleCKInput: function (event) {
                if (this.noteEditable) {
                    this.$nextTick(() => {
                        this.noteText = this.ckInstance.getData();
                    });
                } else{
                    event.stopPropagation();
                    event.preventDefault();
                }
            },
            setDueDate: function (event) { 
                this.dueDate = event.data || null;
            },
            setIsAsap: function (event) { 
                this.isAsap = event.data 
            },
            setPriority: function (event) {  
                this.sourceTaskPriority = event.data.id 
            },
            staffIsAssignedSourcingTask: function(Staff) {
                if (this.noteType !== 'sourcing_task') return false;
                console.log(`CHECK ASSIGNED STAFF`,Staff,this.$el.querySelector('.note__container--sourcing-tasks').__vue__.tasklist);
                return Boolean(this.$el.querySelector('.note__container--sourcing-tasks').__vue__.tasklist.filter((Task) => {
                    return Task.assigned_staff && Task.assigned_staff.name && (Task.assigned_staff.id == Staff.id);
                }).length);
            },
            setMembers: function(event){
                console.log(`SELECT NOTE MEMBERS EVENT`,event);
                if (event.type == 'deselect') {
                    if (this.staffIsAssignedSourcingTask(event.data)) {
                        if(confirm('The selected staff member has assigned sourcing tasks, removing them would delete their progress! Continue?')){
                            this.taskMembers = event.instance.selectValue || [];
                        } else {
                            this.$children.forEach((VueChild) => {
                                if (VueChild.title == 'Members') {
                                    VueChild.$children[0].selectOption(event.data);
                                }
                            });
                        }
                    } else {
                        this.taskMembers = event.instance.selectValue || [];
                    }
                } else {
                    this.taskMembers = event.instance.selectValue || [];
                }
            },
            setStaffList: function (event) { 
                console.log(event); 
                this.staffListId = event.data.id;
                Ajax.request('GET',{},`/autocomplete/staff-lists/${this.staffListId}/members`,(response) => {
                    const departmentMembers = JSON.parse(response).results;
                    this.$children.forEach((VueChild) => {
                        if(VueChild.title == 'Members'){
                            const field = VueChild.$children[0];
                            field.selectValue.forEach((Member) => {
                                this.$nextTick(function() {
                                    if(!this.staffIsAssignedSourcingTask(Member) && Member.id != this.owner.id){
                                        field.deselectOption(Member);
                                    }
                                });
                            });

                            departmentMembers.forEach((result) => { 
                                this.$nextTick(function() {
                                    if(true || result.id != this.owner.id){ 
                                        if(!field.selectValue.filter((Member) => { return Member.id == result.id}).length){
                                            field.selectOption(result);
                                        }
                                    }
                                });
                                
                            });
                        };
                    });
                });
            },
            setStatus: function (event) { 
                if (event.data.id != this.taskStatusInitial){
                    this.taskStatusUpdatedBy = this.viewer;
                }else{
                    this.taskStatusUpdatedBy = this.taskStatusUpdatedByInitial;
                }

                this.taskStatus = event.data.id;
            },
            reformatDate: function (date) {
                return Format.Date(date);
            },
            getMemberIds: function (membersArray) {
                let memberIds = [];
                membersArray.forEach((Member) => { memberIds.push(Member.id) });
                return memberIds;
            },
            updateNote: function (event) {
                if (event.target.className.indexOf('disabled') == -1) {
                    let memberIds = this.getMemberIds(this.taskMembers);
                    if (!memberIds.length) { 
                        memberIds = [null] 
                    }
                    this.noteText = this.ckInstance.getData();

                    const Data = {
                        'authenticity_token': $(`meta[name=csrf-token]`)[0].content,
                        'text': this.noteText,
                        'member_ids': memberIds,
                        'status': this.taskStatus,
                        'status_updated_by_member': this.taskStatusUpdatedBy,
                        'is_asap': this.isAsap,
                        'due_to': this.dueDate,
                        'title': this.sfTitle,
                        'staff_list_id': this.staffListId
                    };

                    if (this.noteType == 'sourcing_task') {
                        Data.priority  = this.sourceTaskPriority;
                        // Data.staff_list_id = this.staff_list_id;
                        Data.sourcing_tasks = this.$el.querySelector('.note__container--sourcing-tasks').__vue__.tasklist.map((Task) => {
                            return {
                                id: Task.id,
                                requested: Task.requested
                            };
                        });
                    }

                    console.log(`SUBMITTING NOTE UPDATE DATA`,Data);

                    Ajax.request(
                        'POST',
                        Data,
                        `/notes/${this.noteId}`,
                        () => {
                            if (this.reloadAfterAction) {
                                location.reload();
                            } else {
                                this.$parent.$parent.resetNotes();
                                this.toggleExpand();
                            }
                        },this,(Data) => {
                            console.log(`Update note error!`,Data);
                            this.noteErrors = JSON.parse(Data).errors;
                        }
                    );
                };
            },
            deleteNote: function (event) {

                if (confirm("Delete the note?")) {
                    Ajax.sendRequest(
                        'DELETE',
                        {
                            'authenticity_token': $(`meta[name=csrf-token]`)[0].content,
                            'id': this.noteId
                        },
                        `/notes/${this.noteId}`,
                        (Data) => { 
                            if (this.reloadAfterAction) {
                                location.reload();
                            } else {
                                let currentInstance = this;
                                let readyFlag = false;
                                while (!readyFlag) {
                                    if (currentInstance.$el.className.indexOf('notes__container--inner') != -1) {
                                        readyFlag = true;
                                    } else { 
                                        currentInstance = currentInstance.$parent 
                                    }
                                }

                                currentInstance.resetNotes();
                                this.$el.remove(); 
                                this.toggleExpand();
                            }
                        }
                    );
                };
            },
            createNote: function (event) { 
                this.noteText = this.ckInstance.getData();

                const Data = {
                    'authenticity_token': $(`meta[name=csrf-token]`)[0].content,
                    'note_type': this.noteType,
                    'record_id': this.recordId,
                    'record_type': this.recordType,
                    'source_page': this.sourcePage,
                    'text': this.noteText,
                    'member_ids': this.getMemberIds(this.taskMembers),
                    'is_asap': this.isAsap,
                    'due_to': this.dueDate,
                    'title': this.sfTitle,
                    'staff_list_id': this.staffListId
                };

                if (this.noteType == 'sourcing_task') {
                    Data.priority  = this.sourceTaskPriority;
                    // Data.staff_list_id = this.staff_list_id;
                    Data.sourcing_tasks = this.$el.querySelector('.note__container--sourcing-tasks').__vue__.tasklist.map((Task) => {
                        return {
                            id: null,
                            requested: Task.candidate_type.requested,
                            candidate_type_id: Task.candidate_type.id
                        };
                    });
                }

                Ajax.request(
                    'POST',
                    Data,
                    `/notes/new`,
                    () => { 
                        if (this.reloadAfterAction) {
                            location.reload();
                        } else {
                            this.noteType = 'note';
                            this.noteText = '';
                            this.sfTitle = null;
                            this.isAsap = false;
                            this.taskMembers = [this.owner];
                            this.$parent.$parent.$parent.resetNotes();
                            this.toggleExpand();
                        }
                    },
                    this,
                    (Data) => {
                        console.log(`Create note error!`,Data);
                        this.noteErrors = JSON.parse(Data).errors;
                    }
                );
            },
            calculateMaxHeightFunction: function (modalContainerOuter) {
                const modalContainerInner = modalContainerOuter.querySelector('.js-modal-container-inner');
                const modalContainerTop = modalContainerOuter.querySelector('.js-modal-container-inner-top');
                const modalContainerBottom = modalContainerOuter.querySelector('.js-modal-container-inner-bottom');
                const modalContainerMiddle = modalContainerOuter.querySelector('.js-modal-container-inner-middle');
                
                const modalContainerOuterStyles = window.getComputedStyle(modalContainerOuter);
                const modalContainerInnerStyles = window.getComputedStyle(modalContainerInner);
                const modalContainerTopStyles = window.getComputedStyle(modalContainerTop);
                const modalContainerBottomStyles = window.getComputedStyle(modalContainerBottom);
        
                // buffer zone between the inner and the outer container
                const topBufferZone = 20;
                let calculatedMaxHeight = 0;

                calculatedMaxHeight = 
                    parseFloat(modalContainerOuterStyles['height']) - 
                    (
                        (topBufferZone * 2) + 
                        (parseFloat(modalContainerInnerStyles['padding-top']) * 2) +
                        parseFloat(modalContainerTopStyles['height']) +
                        parseFloat(modalContainerTopStyles['padding-bottom']) +
                        parseFloat(modalContainerTopStyles['border-bottom-width']) +
                        parseFloat(modalContainerBottomStyles['height']) +
                        parseFloat(modalContainerBottomStyles['padding-top']) +
                        parseFloat(modalContainerBottomStyles['border-top-width'])
                    );

                modalContainerMiddle.style.maxHeight = (calculatedMaxHeight / 10) + 'rem';
            },
            calculateExpandedNoteMiddleSectionMaxHeight: function () {
                if (!this.showExpandedNoteModal) return;

                this.calculateMaxHeightFunction(this.$el.querySelector('.expanded-note__container--modal'));
            },
        },
        components: { FormField, SourcingTaskList, Ajax }
    }
</script>
