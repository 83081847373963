<template>
    <div class="color-picker__wrapper">
        <input v-bind:id="inputid" v-bind:value="initialColor" class="minicolors">
    </div>
</template>

<script>
    export default {
        props: [
            'inputid',
            'initialColor'
        ],
        data: function(){
            return {
                color: this.initialColor
            }
        },
        mounted: function(){
            $('input.minicolors').minicolors($.minicolors.defaults);
        },
        methods: {
            getInputEl: function(){ return document.getElementById(this.inputid) },
            getColor: function(){ return this.getInputEl().value }
        }
    }
</script>