<template>
        <div class="profile__container--valueForms">
            <!--
            <div v-if="deleteAllowed">I want to delete this record (active)</div>
            <div v-if="!deleteAllowed">I want to delete this record (inactive)</div>
            -->
            <list-value-delete-and-replace-form 
                v-if="deleteAndReplaceForm.active"
                v-bind:options="deleteAndReplaceForm"
            >
            </list-value-delete-and-replace-form>
            
            <list-value-delete-form 
                v-if="deleteForm.active"
                v-bind:options="deleteForm"
            >
            </list-value-delete-form>
        </div>
</template>

<script>
    import ListValueDeleteForm from './list-value-delete-form.vue'
    import ListValueDeleteAndReplaceForm from './list-value-delete-and-replace-form.vue'

    export default {
        props: [
            'optionsData'
        ],
        data: function(){
            let options = JSON.parse(this.optionsData || '{}');
            let deleteForm = options.delete_form || { active: false };
            let deleteAndReplaceForm = options.delete_and_replace_form || { active: false };
            let deleteAllowed = options.delete_allowed || true;

            return { 
                options: options, 
                deleteForm: deleteForm, 
                deleteAndReplaceForm: deleteAndReplaceForm, 
                deleteAllowed: deleteAllowed
            };
        },
        components: {
            ListValueDeleteForm,
            ListValueDeleteAndReplaceForm
        }
    }
</script>

