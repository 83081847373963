<template>
    <div class="notes__container--inner">
        <div v-if="isstatus" class="notes__container--header notes__container--header-status">
            <p class="header-status">Created: {{ reformatDate(statusdata.created_at) }}</p>
            <p class="header-status header-status--divider">|</p>
            <p class="header-status">{{ statusdata.created_by_staff.name }}</p>
            <p class="header-status header-status--divider">|</p>
            <p class="header-status header-status--code">{{ statusdata.status.code }}</p>
        </div>
        <note-new
            v-if="canaddnote && !isdashboard && newNoteReset"
            v-bind:reloadafteraction='false'
            v-bind:viewer="viewer"
            v-bind:notedata="addnotedata"
            v-bind:recordid="addnoterecordid"
            v-bind:noteeditable="true"
            v-bind:isstatus="isstatus"
            v-bind:statusdata="statusdata"
            v-bind:candidatetypeoptions="candidatetypeoptions"
        ></note-new>
        <note
            v-bind:reloadafteraction='false'
            v-for="Note in notesArray"
            v-bind:key="Note.vue_key"
            v-bind:viewer="viewer"
            v-bind:notedata="Note"
            v-bind:noteeditable="viewer.id == Note.owner.id"
            v-bind:recordid="addnoterecordid"
            v-bind:isstatus="isstatus"
            v-bind:statusdata="statusdata"
            v-bind:isdashboard="isdashboard"
            v-bind:candidatetypeoptions="candidatetypeoptions"
        ></note>
    </div>
</template>

<script>
    import Ajax from '../../form-field/sub-components/js/ajax.js'
    import Format from '../js/format.js'
    import FormField from '../../form-field/form-field.vue'
    import Note from '../sub-components/note.vue'
    import NoteNew from '../new-note.vue'

    export default {
        props: [
            'isstatus',
            'statusdata',
            'viewer',
            'notes',
            'urlnotes',
            'canaddnote',
            'addnotedata',
            'addnoterecordid',
            'isdashboard',
            'candidatetypeoptions'
        ],
        data: function(){
            return {
                notesArray: this.notes || [],
                storeNotesArray: this.notes || [],
                lastVueKey: -1,
                showDashboardHidden: false,
                newNoteReset: true,
                notesIdsArray: []
            }
        },
        created: function(){ if(!this.notesArray.length && !this.isdashboard){ this.resetNotes() } },
        components: { Ajax, Format, FormField, Note, NoteNew },
        methods: {
            reformatDate: function(date){ return Format.Date(date) },
            initializeArrayKeys: function(){ 
                // console.log(`File: 'notes-table.vue', Calling: 'initializeArrayKeys()`);
                this.lastVueKey = -1;
                this.notesArray.forEach((Note) => { this.lastVueKey++; Note.vue_key = this.lastVueKey });
                this.storeNotesArray = this.notesArray;
                this.checkCounterUpdate();
            },
            resetNotes: function(){
                // console.log(`File: 'notes-table.vue', Calling: 'resetNotes()`);
                this.notesArray = [];
                this.newNoteReset = false;

                if(this.isdashboard){
                    let pageURL = ``;
                    if(this.urlnotes.indexOf(`?`) != -1){
                        if(this.urlnotes.indexOf(`page`) == -1){
                            pageURL = `&page=${this.$parent.currentPage}`;
                        };
                    }else{
                        pageURL = `?page=${this.$parent.currentPage}`;
                    }
                    Ajax.sendRequest('GET',{},`${this.urlnotes}${pageURL}`,(Data) => {
                        this.$parent.updateTableData(Data);
                    });
                }else{
                    Ajax.sendRequest('GET',{},this.urlnotes,(Data) => {
                        this.notesArray = Data.results;
                        this.initializeArrayKeys();
                    });
                };
                this.$nextTick(() => { this.newNoteReset = true });
            },
            updateNotes: function(callback){ Ajax.sendRequest('GET',{},this.urlnotes,callback) },
            checkCounterUpdate: function(){
                if(this.$parent.$parent.$el.className.indexOf('notes-menu__wrapper') != -1){
                    this.$parent.$parent.notesCount = this.notesArray.length;
                };
            }
        }
    }
</script>
