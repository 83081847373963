<template>
    <div class="filters--wrapper">
        <div
            class="filters--copy"
            title="Copy filters' url"
            v-on:click="copyFilters()">
                <i class="flaticon2-copy"></i>
        </div>

        <form-field
            title="Approved"
            fieldtype="form-dropdown"
            inputname="q[is_approved]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.is_approved"
            v-bind:alloptions="formOptions.boolean_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

<!--         <form-field
            title="Blacklist"
            fieldtype="form-checkbox"
            inputname="q[blacklist]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.blacklist"
        ></form-field>
 -->

        <form-field
            title="Candidate No"
            fieldtype="form-input"
            inputname="q[registration_nr]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.registration_nr"
        ></form-field>

     
<!--         <div class="js-filter-hidden">
            <i class="flaticon-eye eye-green"><p>/</p></i>
            <div class="filter-info">Toggle show/hide hidden candidates</div>
        </div>
 -->
        <input type="hidden" name="sort_order" v-bind:value="appliedFilters.sort_order"/>
        <input type="hidden" name="sort_by" v-bind:value="appliedFilters.sort_by"/>
    </div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'

    export default {
        props: [
            'formOptionsData',
            'appliedFiltersData'
        ],
        data: function(){
            var appliedFilters = JSON.parse(this.appliedFiltersData || '{}');
            var formOptions = JSON.parse(this.formOptionsData || '{}');

            // console.log(appliedFilters);
            // console.log(formOptions);

            return { 
                formOptions: formOptions,
                appliedFilters: appliedFilters,
                requestCounter: 0
            };
        },
        components: {
            FormField
        },
        methods: {
            handleChange: function(event){ 
                this.requestCounter++;
                let localCounter = this.requestCounter;
                let waitTime = 1000;
                console.log(`before next tick ${this.requestCounter}, ${localCounter}`)
                this.$nextTick(() => { 
                    console.log(`after next tick ${this.requestCounter}, ${localCounter}`)
                    setTimeout(() => { 
                        console.log(`after timeout ${this.requestCounter}, ${localCounter}`)
                        if(localCounter == this.requestCounter){ $(`#candidate-gdpr-request-filter-form`).submit() };
                    },waitTime);
                }) 
            },
            copyFilters: async function(){
                try {
                    await navigator.clipboard.writeText(window.location.href);
                    document.activeElement.blur();
                } catch (error) {
                    alert(`Error when copying to clipboard`, error)
                }
            }
        }
    }
</script>