<template>
    <div class="profile__container--wrapper profile__container--columns-3">

            <div class="profile__container--outer candidate-details sectioned profile__container--columnPos-1">
                <div class="profile__container--header">
                    <h3>Personalia</h3>
                    <form-field
                        fieldtype="form-dropdown"
                        inputname="candidate[candidate_flag_id]"
                        icon="dropdown-colors-mode"
                        v-bind:startvalue="candidate.candidate_flag"
                        v-bind:alloptions="formOptions.candidate_flag_options"
                        v-bind:haserrors="candidate.errors.candidate_flag"
                    ></form-field>
                    <attach-application-button
                        v-bind:candidateid="candidate.id"
                        v-bind:candidatefirstname="candidate.first_name"
                        v-bind:candidatelastname="candidate.last_name"
                        addwrapperclass="notes-menu__button single-note__button--save"
                    ></attach-application-button>
                </div>
                <div class="profile__container--main">
                    <!-- To do - avatar upload -->
                    <!-- Registration number - readonly - in the info section?  -->
                        <form-field
                            v-if="false"
                            title="Color"
                            fieldtype="form-colorpicker"
                            inputname="candidate[color]"
                            v-bind:startvalue="'#fff'"
                        ></form-field>

                    <form-field
                        title="First name"
                        fieldtype="form-input"
                        inputname="candidate[first_name]"
                        v-bind:startvalue="candidate.first_name"
                        v-bind:haserrors="candidate.errors.first_name"
                    ></form-field>

                    <form-field
                        title="Middle name"
                        fieldtype="form-input"
                        inputname="candidate[middle_name]"
                        v-bind:startvalue="candidate.middle_name"
                        v-bind:haserrors="candidate.errors.middle_name"
                    ></form-field>

                    <form-field
                        title="Last name preposition"
                        fieldtype="form-input"
                        inputname="candidate[last_name_preposition]"
                        v-bind:startvalue="candidate.last_name_preposition"
                        v-bind:haserrors="candidate.errors.last_name_preposition"
                    ></form-field>

                    <form-field
                        title="Last name"
                        fieldtype="form-input"
                        inputname="candidate[last_name]"
                        v-bind:startvalue="candidate.last_name"
                        v-bind:haserrors="candidate.errors.last_name"
                    ></form-field>

                    <form-field
                        title="Initials"
                        fieldtype="form-input"
                        inputname="candidate[initials]"
                        v-bind:startvalue="candidate.initials"
                        v-bind:haserrors="candidate.errors.initials"
                    ></form-field>

                    <form-field
                        title="Gender"
                        fieldtype="form-dropdown"
                        inputname="candidate[gender]"
                        v-bind:startvalue="candidate.gender"
                        v-bind:alloptions="formOptions.gender_options"
                        v-bind:haserrors="candidate.errors.gender"
                    ></form-field>

                    <form-field
                        title="Date of birth"
                        fieldtype="form-date"
                        inputname="candidate[date_of_birth]"
                        v-bind:startvalue="candidate.date_of_birth"
                        v-bind:error="candidate.date_of_birth"
                        v-bind:haserrors="candidate.errors.date_of_birth"
                    ></form-field>

                    <form-field
                        title="Nationalities"
                        fieldtype="form-multidrop"
                        inputname="candidate[nationality_ids][]"
                        v-bind:startvalue="candidate.nationalities"
                        searchurl="/autocomplete/nationalities"
                        v-bind:haserrors="candidate.errors.nationality_ids"
                    ></form-field>

                    <candidate-address
                        v-bind:address-data="{ 
                            address: candidate.address, 
                            housenumber: candidate.housenumber, 
                            postcode: candidate.postcode, 
                            town: candidate.town, 
                            country: candidate.country, 
                            country_region: candidate.country_region,
                            province: candidate.province }"

                        v-bind:errors-data="{ 
                            address: candidate.errors.address, 
                            housenumber: candidate.errors.housenumber, 
                            postcode: candidate.errors.postcode, 
                            town: candidate.errors.town,
                            country: candidate.errors.country,
                            country_region: candidate.errors.country_region,
                            province: candidate.errors.province }"
                    ></candidate-address>

                    <form-field
                        title="Address / Street 2"
                        fieldtype="form-input"
                        inputname="candidate[address2]"
                        v-bind:startvalue="candidate.address2"
                        v-bind:haserrors="candidate.errors.address2"
                    ></form-field>
                
                    <form-field
                        title="Tel. Home"
                        fieldtype="form-input"
                        inputname="candidate[phone_home]"
                        v-bind:startvalue="candidate.phone_home"
                        v-bind:haserrors="candidate.errors.phone_home"
                    ></form-field>

                    <form-field
                        title="Tel. Mobile"
                        fieldtype="form-input"
                        inputname="candidate[phone_mobile]"
                        v-bind:startvalue="candidate.phone_mobile"
                        v-bind:haserrors="candidate.errors.phone_mobile"
                    ></form-field>
                
                    <form-field
                        title="Online profile URL"
                        fieldtype="form-input"
                        inputname="candidate[online_profile_url]"
                        v-bind:startvalue="candidate.online_profile_url"
                        v-bind:haserrors="candidate.errors.online_profile_url"
                    ></form-field>
                
                    <form-field
                        title="E-Mail"
                        fieldtype="form-input"
                        inputname="candidate[email_home]"
                        v-bind:startvalue="candidate.email_home"
                        v-bind:haserrors="candidate.errors.email_home || candidate.errors['authentication.email']"
                    ></form-field>

                    <form-field
                        title="Personal website"
                        inputname="candidate[personal_website]"
                        fieldtype="form-input"
                        v-bind:startvalue="candidate.personal_website"
                        v-bind:haserrors="candidate.errors.personal_website"
                    ></form-field>

                    <form-field
                        title="Drivers license"
                        inputname="candidate[drivers_license]"
                        fieldtype="form-input"
                        v-bind:startvalue="candidate.drivers_license"
                        v-bind:haserrors="candidate.errors.drivers_license"
                    ></form-field>

<!--                     <form-field
                        title="Preferred countries"
                        fieldtype="form-multidrop"
                        inputname="candidate[preferred_country_ids][]"
                        v-bind:startvalue="candidate.preferred_countries"
                        searchurl="/autocomplete/countries"
                        v-bind:haserrors="candidate.errors.preferred_country_ids"
                    ></form-field> -->
                <!-- closing Details main -->
                </div>
            <!-- closing Details outer -->
            </div>

            <div class="profile__container--outer candidate-work sectioned profile__container--columnPos-2">
                <div class="profile__container--header">
                    <h3>Blue Lynx Data</h3>
                </div>
                <div class="profile__container--main">
                    <form-field
                        title="Work status"
                        inputname="candidate[identification_work_status_approved]"
                        fieldtype="form-radio"
                        v-bind:startvalue="candidate.identification_work_status_approved"
                        v-bind:alloptions="[{ name: 'Approved', id: 1 }, { name: 'No Approval', id: -1 }, { name: 'Unknown', id: 0 }]"
                        v-bind:haserrors="candidate.errors.identification_work_status_approved"
                    ></form-field>

                    <form-field
                        title="Valid ID"
                        inputname="candidate[identification]"
                        fieldtype="form-radio"
                        v-bind:startvalue="candidate.identification"
                        v-bind:alloptions="[{ name: 'Yes', id: true }, { name: 'No', id: false }]"
                        v-bind:haserrors="candidate.errors.identification"
                    ></form-field>

                    <form-field
                        title="Preferred countries"
                        fieldtype="form-multidrop"
                        inputname="candidate[preferred_country_ids][]"
                        v-bind:startvalue="candidate.preferred_countries"
                        searchurl="/autocomplete/countries"
                        preload="1"
                        v-bind:haserrors="candidate.errors.preferred_country_ids"
                    ></form-field>

                    <form-field
                        title="Registered on"
                        fieldtype="form-input"
                        inputname="candidate[registered_on]"
                        v-bind:startvalue="candidate.registered_on"
                        isdisabled="true"
                        v-bind:haserrors="candidate.errors.registered_on"
                    ></form-field>


                   <!--  <div class="profile__container--field"></div> -->

                    <form-field
                        title="Internal admission notes"
                        fieldtype="form-textarea"
                        inputname="candidate[int_admission_notes]"
                        v-bind:startvalue="candidate.int_admission_notes"
                        v-bind:haserrors="candidate.errors.int_admission_notes"
                    ></form-field>

                    <div class="profile__container--col">
                        <form-field
                            title="Blue Lynx location"
                            fieldtype="form-dropdown"
                            inputname="candidate[bluelynx_location_id]"
                            v-bind:startvalue="candidate.bluelynx_location"
                            v-bind:alloptions="formOptions.bluelynx_locations"
                            v-bind:haserrors="candidate.errors.bluelynx_location"
                        ></form-field>

                        <form-field
                            title="Intake"
                            fieldtype="form-dropdown"
                            inputname="candidate[intake]"
                            v-bind:startvalue="candidate.intake"
                            v-bind:alloptions="formOptions.intake_options"
                            v-bind:haserrors="candidate.errors.intake"
                        ></form-field>
                    </div>

                    <div class="profile__container--row salary-row--flip">
                        <form-field
                            title="Salary min"
                            fieldtype="form-number"
                            inputname="candidate[salary_range_min]"
                            v-bind:startvalue="candidate.salary_range_min"
                            v-bind:alloptions="{
                                min: 0,
                                max: 1000000,
                                step: 1
                            }"
                            v-bind:haserrors="candidate.errors.salary_range_min"
                        ></form-field>

                        <form-field
                            title="Salary max"
                            fieldtype="form-number"
                            inputname="candidate[salary_range_max]"
                            v-bind:startvalue="candidate.salary_range_max"
                            v-bind:alloptions="{
                                min: 0,
                                max: 1000000,
                                step: 1
                            }"
                            v-bind:haserrors="candidate.errors.salary_range_max"
                        ></form-field>
                    </div>
                    <div class="profile__container--row">
                        <form-field
                            title="Salary currency"
                            fieldtype="form-dropdown"
                            inputname="candidate[salary_currency]"
                            v-bind:startvalue="candidate.salary_currency"
                            v-bind:alloptions="formOptions.salary_currencies"
                            v-bind:haserrors="candidate.errors.salary_currency"
                        ></form-field>

                        <form-field
                            title="Salary range"
                            fieldtype="form-radio"
                            inputname="candidate[salary_range_type]"
                            v-bind:startvalue="candidate.salary_range_type"
                            v-bind:alloptions="formOptions.salary_range_types"
                            v-bind:haserrors="candidate.errors.salary_range_type"
                        ></form-field>
                    </div>

                    <div class="profile__container--row">
                        <form-field
                            title="Includes vacation"
                            fieldtype="form-checkbox"
                            inputname="candidate[salary_includes_vacation]"
                            v-bind:startvalue="candidate.salary_includes_vacation"
                            v-bind:haserrors="candidate.errors.salary_includes_vacation"
                        ></form-field>

                        <form-field
                            title="Is negotiable"
                            fieldtype="form-checkbox"
                            inputname="candidate[salary_is_negotiable]"
                            v-bind:startvalue="candidate.salary_is_negotiable"
                            v-bind:haserrors="candidate.errors.salary_is_negotiable"
                        ></form-field>
                    </div>

                    <div class="profile__container--row">
                        <form-field
                            title="Freelancer interest"
                            fieldtype="form-checkbox"
                            inputname="candidate[freelancer_interest]"
                            v-bind:startvalue="candidate.freelancer_interest"
                            v-bind:haserrors="candidate.errors.freelancer_interest"
                        ></form-field>

                        <form-field
                            title="Freelancer day rate"
                            fieldtype="form-input"
                            inputname="candidate[freelancer_day_rate]"
                            v-bind:startvalue="candidate.freelancer_day_rate"
                            v-bind:haserrors="candidate.errors.freelancer_day_rate"
                        ></form-field>
                    </div>

                    <div class="profile__container--row">
                        <form-field
                            title="Employment type"
                            fieldtype="form-dropdown"
                            inputname="candidate[type_of_employment]"
                            v-bind:startvalue="candidate.type_of_employment"
                            v-bind:alloptions="formOptions.type_of_employment_options"
                            v-bind:haserrors="candidate.errors.type_of_employment"
                        ></form-field>

                        <form-field
                            title="Job Type"
                            fieldtype="form-dropdown"
                            inputname="candidate[full_parttime]"
                            v-bind:startvalue="candidate.full_parttime"
                            v-bind:alloptions="formOptions.full_parttime_options"
                            v-bind:haserrors="candidate.errors.full_parttime"
                        ></form-field>
                    </div>

                    <form-field
                        title="Max. Commute Time"
                        fieldtype="form-dropdown"
                        inputname="candidate[travelling_time_id]"
                        v-bind:startvalue="candidate.travelling_time_id"
                        v-bind:alloptions="formOptions.travelling_time_options"
                        v-bind:haserrors="candidate.errors.travelling_time"
                    ></form-field>

                    <form-field
                        title="Active / Non Active"
                        inputname="candidate[active_nonactive]"
                        fieldtype="form-radio"
                        v-bind:startvalue="candidate.active_nonactive"
                        v-bind:alloptions="[{ name: 'Active', id: true }, { name: 'Non Active', id: false }]"
                        v-bind:haserrors="candidate.errors.active_nonactive"
                    ></form-field>

                    <form-field
                        title="BLACKLISTED"
                        inputname="candidate[blacklist]"
                        fieldtype="form-radio"
                        v-bind:startvalue="candidate.blacklist"
                        v-bind:alloptions="[{ name: 'Yes', id: true }, { name: 'No', id: false }]"
                        v-bind:haserrors="candidate.errors.blacklist"
                    ></form-field>

                    <form-field
                        title="Under Offer"
                        inputname="candidate[under_offer]"
                        fieldtype="form-radio"
                        v-bind:startvalue="candidate.under_offer"
                        v-bind:alloptions="[{ name: 'Yes', id: true }, { name: 'No', id: false }]"
                        v-bind:haserrors="candidate.errors.under_offer"
                    ></form-field>

 <!--                    <div class="form-group profile__container--field">
                        <label for="candidate_gdpr">GDPR (no contact)</label>
                        <input id="candidate_gdpr" type="checkbox" name="candidate[gdpr]" v-model="candidate.gdpr">
                    </div>
 -->
                    <form-field
                        title="GDPR (no contact)"
                        inputname="candidate[gdpr]"
                        fieldtype="form-radio"
                        v-bind:startvalue="candidate.gdpr"
                        v-bind:alloptions="[{ name: 'Yes - do not contact', id: true }, { name: 'No', id: false }]"
                        v-bind:haserrors="candidate.errors.gdpr"
                    ></form-field>

                    <form-field
                        title="Contractor BLE"
                        fieldtype="form-dropdown"
                        inputname="candidate[works_for_ble]"
                        v-bind:startvalue="candidate.works_for_ble"
                        v-bind:alloptions="formOptions.works_for_ble_options"
                        v-bind:haserrors="candidate.errors.works_for_ble"
                    ></form-field>

                    <form-field
                        title="Contract End Date"
                        fieldtype="form-date"
                        inputname="candidate[end_date_contract]"
                        v-bind:startvalue="candidate.end_date_contract"
                        v-bind:haserrors="candidate.errors.end_date_contract"
                    ></form-field>

                    <form-field
                        title="Heard of BL"
                        fieldtype="form-dropdown"
                        inputname="candidate[heard_of_blue_lynx_id]"
                        v-bind:startvalue="candidate.heard_of_blue_lynx"
                        searchurl="/autocomplete/heard-of-blue-lynxes"
                    ></form-field>
                <!-- closing work main div -->
                </div>
            <!-- closing work outer div -->
            </div>

            <div class="profile__container--outer candidate-interview sectioned profile__container--status profile__container--columnPos-3 profile__container--rowPos-4">
                <div class="profile__container--header">
                    <h3>Other</h3>
                </div>
                <div class="profile__container--main">
                    <form-field
                        v-if="false"
                        title="Old Notes (read-only)"
                        fieldtype="form-textarea"
                        inputname="candidate[general_note]"
                        addclass="textarea-tall"
                        v-bind:startvalue="candidate.general_note"
                        v-bind:alloptions="{ readonly: true }"
                        v-bind:haserrors="candidate.errors.general_note"
                    ></form-field>
                <!-- closing interview main div -->
                </div>
            <!-- closing interview outer div -->
            </div>

            <!-- HEREE --><!-- languages in skills section? -->
            <candidate-languages
                addWrapperClasss="profile__container--columnPos-1"
                v-bind:intial-records="candidate.languages"
                v-bind:language-proficiency-options="formOptions.language_proficiency_options"
            ></candidate-languages>

            <div class="profile__container--outer candidate-skills sectioned profile__container--columnPos-2">
                <div class="profile__container--header">
                    <h3>Skills</h3>
                </div>
                <div class="profile__container--main">

                    <!-- Skills section ? -->
                    <!-- [Search input for adding software] -->
                    <form-field
                        title="Software"
                        inputname="candidate[software][]"
                        fieldtype="form-tags"
                        v-bind:startvalue="candidate.software"
                        v-bind:haserrors="candidate.errors.software"
                        searchurl="/autocomplete/software_skills"
                    ></form-field>

                    <form-field
                        title="Soft skills"
                        inputname="candidate[soft_skills][]"
                        fieldtype="form-tags"
                        v-bind:startvalue="candidate.soft_skills"
                        v-bind:haserrors="candidate.errors.soft_skills"
                    ></form-field>

                    <form-field
                        title="Professional skills"
                        inputname="candidate[professional_skills][]"
                        fieldtype="form-tags"
                        v-bind:startvalue="candidate.professional_skills"
                        v-bind:haserrors="candidate.errors.professional_skills"
                        searchurl="/autocomplete/professional_skills"
                        v-bind:isdisabled="false"
                    ></form-field>

                    <form-field
                        title="Hobbies"
                        inputname="candidate[hobbies][]"
                        fieldtype="form-tags"
                        v-bind:startvalue="candidate.hobbies"
                        v-bind:haserrors="candidate.errors.hobbies"
                    ></form-field>

                    <form-field
                        title="Ambitions"
                        fieldtype="form-textarea"
                        inputname="candidate[ambitions]"
                        addclass="textarea-tall"
                        v-bind:startvalue="candidate.ambitions"
                        v-bind:haserrors="candidate.errors.ambitions"
                    ></form-field>
                <!-- closing skills main div -->
                </div>
            <!-- closing skills outer div -->
            </div>

            <div class="profile__container--outer profile__container--experience  profile__container--columnPos-1">
                <div class="profile__container--main">
                    <div class="profile__container--header">
                        <h3>Job experience</h3>
                    </div>

                    <form-field
                        title="Companies"
                        fieldtype="form-multidrop"
                        inputname="candidate[company_ids][]"
                        v-bind:startvalue="candidate.companies"
                        searchurl="/autocomplete/companies"
                        preload="1"
                        v-bind:haserrors="candidate.errors.company_ids"
                    ></form-field>

                    <form-field
                        title="Industries"
                        fieldtype="form-multidrop"
                        inputname="candidate[industry_ids][]"
                        v-bind:startvalue="candidate.industries"
                        searchurl="/autocomplete/industries"
                        preload="1"
                        v-bind:haserrors="candidate.errors.industry_ids"
                    ></form-field>

                    <candidate-job-experience-items
                        addWrapperClass="profile__container--columnPos-1 no-border"
                        v-bind:initial-records="candidate.job_experiences"
                        v-bind:maxRecordsShown="maxJobExperienceRecordsShown"
                    ></candidate-job-experience-items>
                </div>
            </div>
            
            <!-- Note - heighest education can be determined from this collection? -->
            <div class="profile__container--outer profile__container--experience  profile__container--columnPos-2">
                <div class="profile__container--main">
                    <div class="profile__container--header">
                        <h3>Education</h3>
                    </div>

                    <form-field
                        title="Highest education"
                        fieldtype="form-dropdown"
                        inputname="candidate[highest_education_id]"
                        v-bind:startvalue="candidate.highest_education_id"
                        v-bind:alloptions="formOptions.education_levels"
                        v-bind:haserrors="candidate.errors.highest_education"
                    ></form-field>

                    <candidate-education-items
                        addWrapperClass="profile__container--columnPos-2 no-border"
                        v-bind:initial-records="candidate.education_history_items"
                        v-bind:diploma-code-options="formOptions.diploma_codes"
                        v-bind:education-level-options="formOptions.education_levels"
                        v-bind:maxRecordsShown="maxEducationRecordsShown"
                    ></candidate-education-items>
                </div>
            </div>
    </div>
</template>

<script>
    import AttachApplicationButton from '../../job-application/attach-application-button.vue'
    import FormField from './../../form-field/form-field.vue'
    import CandidateAddress from './shared/candidate-address.vue'
    import CandidateLanguages from './shared/candidate-languages.vue'
    import CandidateEducationItems from './shared/candidate-education-items.vue'
    import CandidateJobExperienceItems from './shared/candidate-job-experience-items.vue'
    import ColorPicker from '../../color-picker/color-picker.vue'

    export default {
        props: [
            'candidateData',
            'formOptionsData'
        ],
        data: function(){
            var candidate = JSON.parse(this.candidateData || '{}');
            var formOptions = JSON.parse(this.formOptionsData || '{}');

            var maxJobExperienceRecordsShown = 3;
            var maxEducationRecordsShown = 3;
            // the panel should be opened if: 1. a record has errors; 2. a record is changed; 3. a record is new; 4. a record will be deleted
            // for simplicity - show all records if there are any validation errors

            if(Object.keys(candidate.errors).length > 0){
                maxJobExperienceRecordsShown = Infinity;
                maxEducationRecordsShown = Infinity;
            };

            // console.log(candidate);

            return { 
                candidate: candidate, 
                formOptions: formOptions,
                maxJobExperienceRecordsShown: maxJobExperienceRecordsShown,
                maxEducationRecordsShown: maxEducationRecordsShown,
                showCountryRegions: false, 
                regionsForCountry: ''
            };
        },
        components: {
            AttachApplicationButton,
            FormField,
            CandidateLanguages,
            CandidateEducationItems,
            CandidateJobExperienceItems,
            CandidateAddress,
            ColorPicker
        }
    }
</script>