module.exports = {
    data: function(){
        return {
            recordShowLimit: this.maxRecordsShown,
            showPanel: 1,
            showPanelState: 'open',
            showMore: true,
            showMoreState: 'Hide'
        };
    },
    methods: {
        toggleExpandPanel: function(){
            this.showPanel = !this.showPanel;
            if(this.showPanel){ this.showPanelState = 'open' }else{ this.showPanelState = 'closed'};
        },
        toggleShowMore: function(){
            this.showMore = !this.showMore;
            if(this.showMore){
                this.recordShowLimit = Infinity;
                this.showMoreState = 'Hide';         
            }else{
                this.recordShowLimit = this.maxRecordsShown;
                this.showMoreState = 'Show more';
            };
            this.$nextTick(() => {
                let showMoreEl = $(this.$el).find(`.panel-showMore`)[0];
                showMoreEl.scrollIntoView(true);
                //SCROLLING THE HEADER OUT OF THE WAY
                window.scrollBy(0, -window.innerHeight/2);
            });
        },
        setExpansion: function(trueORfalse){
            this.showMore = trueORfalse;
            if(trueORfalse){
                this.recordShowLimit = Infinity;
                this.showPanel = 1;
                this.showPanelState = 'open';
                this.showMoreState = 'Hide';   
            }else{
                this.recordShowLimit = this.maxRecordsShown;
                this.showPanel = 0;
                this.showPanelState = 'close';
                this.showMoreState = 'Show more'; 
            };
        }
    }
};