<template>
    <div class="profile__container--outer profile__container--educations form-panels--expandable"
        v-bind:class="addWrapperClass">
        <div class="profile__container--header panel-expand">
            <h3 v-on:click="toggleExpandPanel" class="panel-expand--title">
                <i class="flaticon2-arrow panel-arrow" v-bind:class="{ 'panel-arrow--flipped': Boolean(showPanel) }"></i>
                    Education Panel
            </h3>
            <div v-if="!isold" v-on:click.stop="addRecord" class="button button-formAction add"> <i class="flaticon2-plus"></i> Add new</div>
            <div v-if="globalDestroy && isnew" v-on:click="restoreAllRecords" title="Restore all old records" class="button button-formAction"><i class="flaticon2-refresh"></i></div>
            <div v-if="!globalDestroy && isnew" v-on:click="removeAllRecords" title="Remove all old records" class="button button-formAction danger"><i class="flaticon2-delete"></i></div>
        </div>
        <!-- <div v-for="(record, index) in records" v-bind:key="record.vue_key"> -->
            <div 
                v-for="(record, index) in records"
                v-bind:key="record.vue_key"
                v-bind:id="`education-item-${record.vue_key}`"
                v-bind:class="determineRecordClass(record, index, 'profile__container--outer profile__container--education')">
                    <div class="profile__container--header">
                        <h3>Education details</h3>

                        <div v-if="!isold">
                            <div v-show="!(record._destroy)" v-on:click="removeRecord(index)" class="button button-formAction danger" title="Delete"><i class="flaticon2-delete"></i></div>

                            <div v-show="(record._destroy)" v-on:click="undoRemove(index)" class="button button-formAction" title="Undo delete"><i class="flaticon2-refresh"></i></div>
                        </div>
                    </div>
                    <div class="profile__container--main">

                        <input v-if="record.id" type="hidden" v-model="record.id" v-bind:name="'candidate[education_history_items_attributes][' + record.vue_key + '][id]'">
                        <input v-if="record._destroy" type="hidden" v-model="record._destroy" v-bind:name="'candidate[education_history_items_attributes][' + record.vue_key + '][_destroy]'">

                        <div class="profile__container--row date-row">
                            <form-field
                                title="Start date"
                                fieldtype="form-date"
                                v-bind:inputname="'candidate[education_history_items_attributes][' + record.vue_key + '][start_date]' + getOldIdAddition()"
                                v-bind:startvalue="record.start_date"
                                v-bind:haserrors="record.errors.start_date"
                                v-bind:isdisabled="isold"
                            ></form-field>

                            <form-field
                                title="End date"
                                fieldtype="form-date"
                                v-bind:inputname="'candidate[education_history_items_attributes][' + record.vue_key + '][end_date]' + getOldIdAddition()"
                                v-bind:startvalue="record.end_date"
                                v-bind:haserrors="record.errors.end_date"
                                v-bind:isdisabled="isold"
                            ></form-field>
                        </div>

                        <form-field
                            title="Education level"
                            fieldtype="form-dropdown"
                            v-bind:inputname="'candidate[education_history_items_attributes][' + record.vue_key + '][education_level_id]' + getOldIdAddition()"
                            v-bind:startvalue="record.education_level.id"
                            v-bind:alloptions="educationLevelOptions"
                            v-bind:haserrors="record.errors.education_level"
                            v-bind:isdisabled="isold"
                        ></form-field>

                        <form-field
                            title="Degree direction"
                            fieldtype="form-input"
                            v-bind:inputname="'candidate[education_history_items_attributes][' + record.vue_key + '][degree_direction]' + getOldIdAddition()"
                            v-bind:startvalue="record.degree_direction"
                            v-bind:haserrors="record.errors.degree_direction"
                            v-bind:isdisabled="isold"
                        ></form-field>

                        <form-field
                            title="Institute name"
                            fieldtype="form-input"
                            v-bind:inputname="'candidate[education_history_items_attributes][' + record.vue_key + '][institute_name]' + getOldIdAddition()"
                            v-bind:startvalue="record.institute_name"
                            v-bind:haserrors="record.errors.institute_name"
                            v-bind:isdisabled="isold"
                        ></form-field>

                        <form-field
                            title="Institute location"
                            fieldtype="form-input"
                            v-bind:inputname="'candidate[education_history_items_attributes][' + record.vue_key + '][institute_location]' + getOldIdAddition()"
                            v-bind:startvalue="record.institute_location"
                            v-bind:haserrors="record.errors.institute_location"
                            v-bind:isdisabled="isold"
                        ></form-field>

                        <form-field
                            title="GPA"
                            fieldtype="form-input"
                            v-bind:inputname="'candidate[education_history_items_attributes][' + record.vue_key + '][gpa]' + getOldIdAddition()"
                            v-bind:startvalue="record.gpa"
                            v-bind:haserrors="record.errors.gpa"
                            v-bind:isdisabled="isold"
                        ></form-field>

                        <form-field
                            title="Diploma"
                            fieldtype="form-dropdown"
                            v-bind:inputname="'candidate[education_history_items_attributes][' + record.vue_key + '][diploma_code_id]' + getOldIdAddition()"
                            v-bind:startvalue="record.diploma_code"
                            v-bind:alloptions="diplomaCodeOptions"
                            v-bind:haserrors="record.errors.diploma_code"
                            v-bind:isdisabled="isold"
                        ></form-field>
                    </div>
            </div>
        <!-- </div> -->
        <div v-if="showPanel && (records.length > maxRecordsShown)" v-on:click="toggleShowMore" class="profile__container--header panel-showMore">
            <h3 class="panel-showMore--title">{{ showMoreState }}</h3>
        </div>
    </div>
</template>

<script>
    import FormField from '../../../form-field/form-field.vue'
    import PanelExpand from '../shared/mixins/panel_expand.js'

    export default {
        props: [
            'initialRecords',
            'diplomaCodeOptions',
            'educationLevelOptions',
            'addWrapperClass',
            'maxRecordsShown',
            'isold',
            'isnew'
        ],
        data: function(){
            let orderDestroyed = [];
            let orderNew = [];

            var records = this.initialRecords || [];
            records.forEach(function(item, i) { 
                item.vue_key = i; 
                if(item._destroy){ orderDestroyed.push(item) }else{ orderNew.push(item) }; 
            });
            records = orderDestroyed.concat(orderNew);

            return { 
                records: records, 
                nextVueKey: records.length,
                globalDestroy: true
            };
        },
        mounted: function(){
            this.setExpansion(false);
            this.toggleExpandPanel();
        },
        methods: {
            addRecord: function() {
            //   this.records.push({
            //     id: null,
            //     education_level: {},
            //     vue_key: this.nextVueKey,
            //     _destroy: null,
            //     errors: []
            //   });

              if(this.isnew){
                this.records.push({
                    id: null,
                    education_level: {},
                    vue_key: this.nextVueKey,
                    _destroy: null,
                    errors: []
                });

                this.$nextTick(() => {
                    let educationItems = $(this.$el).find(`.profile__container--outer`);
                    let createdItem = educationItems[educationItems.length - 1];
                    createdItem.scrollIntoView(true);
                    if(document.body.offsetHeight - window.scrollY > 858){
                        window.scrollBy(0,-150);
                    };
                    //OFFSETTING THE Y VALUE WHEN SCROLLING BY '-140', TO SCROLL HEADER OUT OF VIEW
                });
              }else{
                this.records.splice(0,0,{
                    id: null,
                    education_level: {},
                    vue_key: this.nextVueKey,
                    _destroy: null,
                    errors: []
                });
              };

              this.nextVueKey += 1;
              
            //   this.setExpansion(true);
            },

            removeRecord: function(index) {
              var record = this.records[index];
            //  this.records[index]._destroy = "1"

              if (record.id == null) {
                this.records.splice(index, 1)
              } else {
                this.records[index]._destroy = "1"
              }
            },

            undoRemove: function(index) {
              this.records[index]._destroy = null
            },

            determineRecordClass: function(record, index, otherClasses){
                var recordClass = otherClasses || '';
                if(record._destroy){
                    recordClass = recordClass + ' removed-record';
                }else if(record.id == null){
                    recordClass = recordClass + ' new-record';
                };

                if(this.showPanel && (index < this.showPanel*this.recordShowLimit)){
                    recordClass = recordClass + ' show-panel';
                }else{
                    recordClass = recordClass + ' hide-panel';
                };

                // console.log(recordClass);
                return recordClass;
            },
            getOldIdAddition: function(){ if(this.isold){ return '_old' }else{ return '' } },
            restoreAllRecords: function(){
                this.records.forEach((Record, index) => { if(!Record.new_record){ this.undoRemove(index) } });
                this.globalDestroy = false;
            },
            removeAllRecords: function(){
                this.records.forEach((Record, index) => { if(!Record.new_record){ this.removeRecord(index) } });
                this.globalDestroy = true;
            }
        }, 
        mixins: [
            PanelExpand
        ],
        components: {
            FormField
        }
    }
</script>