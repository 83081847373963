<template>
    <div class="filters--wrapper">
        <div
            class="filters--copy"
            title="Copy filters' url"
            v-on:click="copyFilters()">
                <i class="flaticon2-copy"></i>
        </div>

<!-- Dropdowns: Location, Department (We would like this one back), Role/Job Title, Login disabled -->
        <form-field
            title="Show hidden"
            fieldtype="form-checkbox"
            inputname="q[show_hidden]"
            addclass="filter-target filter-hidden"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.show_hidden"
        ></form-field>

        <form-field
            title="Location BL"
            fieldtype="form-dropdown"
            inputname="q[bl_location_id]"
            addclass="filter-target filter-location-bl"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.bl_location_id"
            v-bind:alloptions="formOptions.bluelynx_location_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Department"
            fieldtype="form-dropdown"
            inputname="q[staff_department_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.staff_department_id"
            v-bind:alloptions="formOptions.staff_department_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Role"
            fieldtype="form-dropdown"
            inputname="q[staff_role_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.staff_role_id"
            v-bind:alloptions="formOptions.staff_roles_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Is sourcer"
            fieldtype="form-dropdown"
            inputname="q[is_sourcer]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.is_sourcer"
            v-bind:alloptions="formOptions.boolean_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Login disabled"
            fieldtype="form-dropdown"
            inputname="q[login_disabled]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.login_disabled"
            v-bind:alloptions="formOptions.boolean_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

<!--         <form-field
            title="From date"
            fieldtype="form-date"
            inputname="q[from_date]"
            v-bind:startvalue="appliedFilters.from_date"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
        ></form-field>

        <form-field
            title="To date"
            fieldtype="form-date"
            inputname="q[to_date]"
            v-bind:startvalue="appliedFilters.to_date"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
        ></form-field>
 -->

        <!-- 
            Adding 'filter-start-with' and 'js-filter-relocate' classes to below form-field element
            which are used to style and reposition the filter respectively 
        -->

        <div class="js-filter-hidden">
            <i class="flaticon-eye eye-green"><p>/</p></i>
            <div class="filter-info">Toggle show/hide hidden candidates</div>
        </div>

        <input type="hidden" name="sort_order" v-bind:value="appliedFilters.sort_order"/>
        <input type="hidden" name="sort_by" v-bind:value="appliedFilters.sort_by"/>
    </div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'

    export default {
        props: [
            'formOptionsData',
            'appliedFiltersData'
        ],
        data: function(){
            var appliedFilters = JSON.parse(this.appliedFiltersData || '{}');
            var formOptions = JSON.parse(this.formOptionsData || '{}');

            // console.log(appliedFilters);
            // console.log(formOptions);

            return { 
                formOptions: formOptions,
                appliedFilters: appliedFilters,
                requestCounter: 0
            };
        },
        components: {
            FormField
        },
        methods: {
            handleChange: function(event){ 
                this.requestCounter++;
                let localCounter = this.requestCounter;
                let waitTime = 1000;
                this.$nextTick(() => { 
                    setTimeout(() => { if(localCounter == this.requestCounter){ $(`#staff-filter-form`).submit() } },waitTime);
                });
            },
            copyFilters: async function(){
                try {
                    await navigator.clipboard.writeText(window.location.href);
                    document.activeElement.blur();
                } catch (error) {
                    alert(`Error when copying to clipboard`, error)
                }
            }
        }
    }
</script>