<template>
    <div class="table__container--parent">
        <div class="table__container--outer">
            <div class="table__container--block">
                <div class="table__container--wrapper stats-table">
					<div class="js-table-expand-all" title="Expand/Contract all table entries"><i class="flaticon2-arrow"></i></div>
					<div class="table__container--labels">
						<div></div>
						<div class="table__container--label"><p>Position</p></div>
						<div class="table__container--label"><p>List</p></div>
						<div class="table__container--label"><p>Show on dashboard</p></div>
					</div>
                    <div 
                        v-for="(list, index) in orderedLists"
                        v-bind:key="list.id"
                        v-on:mousedown="grab($event,list)"
                        v-on:mouseup="drop($event,list)"
                        v-on:mousemove="move($event,list)"
                        v-on:mouseover="checkOrder($event,list)"
                        v-on:mouseout="drop($event,list)"
                        class="table__container--single">
                            <input
                                v-if="list.id"
                                type="hidden"
                                v-bind:name="`staff[dashboard_lists_attributes][${index}][id]`"
                                v-bind:value="list.id">
                            <input
                                type="hidden"
                                v-bind:name="`staff[dashboard_lists_attributes][${index}][code]`"
                                v-bind:value="list.code">

							<a href="#" class="flaticon2-arrow table-dropdown"></a>
							<div class="table__container--content table__cell--statstamp">
								<form-field
						            title=""
						            fieldtype="form-number"
                                    v-bind:inputid="`staff-dashboard-list-${list.code}-weight`"
                                    v-bind:inputname="`staff[dashboard_lists_attributes][${index}][weight]`"
                                    v-bind:startvalue="list.weight"
						            v-bind:alloptions="{
						                min: 0,
						                max: 1000000,
						                step: 1
						            }"
							    ></form-field>
							</div>
							<div class="table__container--content table__cell--statstamp">
								{{ list.name }}
							</div>
							<div class="table__container--content table__cell--set-18">
								<form-field
						            title=""
						            fieldtype="form-checkbox"
						            v-bind:inputid="`staff-dashboard-list-${list.code}-show`"
						            v-bind:inputname='`staff[dashboard_lists_attributes][${index}][show]`'
						            v-bind:startvalue="list.show"
						        ></form-field>
							</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FormField from '../form-field/form-field.vue'

    export default {
        props: [
            'lists'
        ],
        components: { FormField },
        data: function(){
            return {
                orderedLists: this.lists
            }
        },
        methods: {
            grab: function(event,list){
                console.log('grab list',event,list)
                list.grabbed = true;
                let listContainer = $(event.target).parents('.table__container--single').first();
                $(listContainer).addClass('moving-list');
                $(listContainer).css(`left`,event.x - $(listContainer).css('width').replace('px','')/2);
                $(listContainer).css(`top`,event.y - $(listContainer).css('height').replace('px','')/2);
            },
            drop: function(event,list){
                if(list.grabbed){
                    console.log('drop list',event,list)
                    let dropFlag = true;

                    if(event.type == 'mouseout' && event.target.className.indexOf(`table__container--single`) == -1){
                        dropFlag = false;
                    };

                    if(dropFlag){
                        list.grabbed = false;                    
                        let listContainer = $(event.target).parents('.table__container--single').first();
                        $(listContainer).removeClass('moving-list');
                        $(listContainer).css(`left`,`unset`);
                        $(listContainer).css(`top`,`unset`);
                    };
                };
            },
            move: function(event,list){
                if(list.grabbed){
                    console.log('move list',event,list);
                    let listContainer = $(event.target).parents('.table__container--single').first();
                    $(listContainer).css(`left`,event.x - $(listContainer).css('width').replace('px','')/2);
                    $(listContainer).css(`top`,event.y - $(listContainer).css('height').replace('px','')/2);
                }
            },
            checkOrder: function(event,list){
                // if(!list.grabbed){
                //     console.log(event,list)

                //     console.log(event.target,event.relatedTarget);
                // }
            }
        }
    }
</script>