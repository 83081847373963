var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile__input--wrapper"},[_c('div',{staticClass:"profile__container--dropdown",on:{"click":_vm.toggleDropdown,"keydown":_vm.handleKeyboard}},[_c('div',{staticClass:"profile__container--selected",attrs:{"tabindex":"0"},on:{"focus":_vm.handleDropdownFocus,"blur":_vm.handleDropdownBlur}},[(_vm.icon == 'dropdown-colors-mode')?_c('div',{style:({
                                backgroundColor: _vm.selectedOption.color,
                                borderRadius: '50%',
                                width: _vm.selectedOption.color == null ? '100%' : '10px',
                                height: _vm.selectedOption.color == null ? 'unset' : '10px',
                                left: _vm.selectedOption.color == null ? '0' : 'calc(50% - 5px)',
                                top: _vm.selectedOption.color == null ? 'calc(50% - 12px)' : 'unset',
                                position: 'relative'
                            })},[(_vm.selectedOption.color == null)?_c('p',{staticClass:"candidate-flag-name"},[_vm._v(_vm._s(_vm.selectedOption.name))]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.icon != 'dropdown-colors-mode')?_c('p',[_vm._v(_vm._s(_vm.selectedOption.name))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"profile__arrow",class:{ 'profile__arrow--flip': _vm.toggleState }},[_vm._v("<")])]),_vm._v(" "),(_vm.searchurl != undefined)?_c('input',{staticClass:"dropdown-search",class:{ 'profile__container--show': _vm.toggleState},attrs:{"type":"text"},domProps:{"value":_vm.searchQuery},on:{"click":function($event){$event.stopPropagation();},"input":_vm.sendQuery}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"profile__container--options",class:{ 'profile__container--show': _vm.toggleState, 'profile__container--showUP': _vm.toggleUp}},_vm._l((_vm.dropdownOptions),function(item,index){return _c('div',{key:item.vue_key,staticClass:"profile__option",class:{ 'mark-selected': item.id == _vm.selectedOption.id, 'mark-keyboardFocus': _vm.highlightIndex == index },attrs:{"value":item.id},on:{"click":function($event){return _vm.selectOption(item)}}},[(_vm.icon == 'dropdown-colors-mode')?_c('div',{style:({
                                        backgroundColor: item.color,
                                        borderRadius: '50%',
                                        width: '10px',
                                        height: '10px',
                                        marginRight: '10px'
                                    })}):_vm._e(),_vm._v("\n                                "+_vm._s(item.name)+"\n                        ")])}),0)]),_vm._v(" "),_c('select',{staticClass:"hidden_select form-data-target",attrs:{"name":_vm.inputname,"id":_vm.inputid}},[_c('option',{attrs:{"selected":""},domProps:{"value":_vm.selectedOption.id}},[_vm._v("\n                    "+_vm._s(_vm.selectedOption.name)+"\n                ")])]),_vm._v(" "),(_vm.copy)?_c('i',{staticClass:"flaticon2-copy input-copy",attrs:{"title":"Copy field contents"}}):_vm._e(),_vm._v(" "),(_vm.haserrors)?_c('div',{staticClass:"login-form__warning--icon",attrs:{"title":_vm.haserrors[0]}},[_c('p',[_vm._v("!")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }