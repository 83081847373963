<template>
    <div class="profile__container--wrapper"
        v-bind:id="`step-${step}${getIsOldText().replace(/\(/g,'').replace(/\)/g,'')}`"
        v-bind:class="{
            'profile__container--columns-1': ((step > 1)  && (step < 4)),
            'profile__container--columns-2': ((step == 1 || step == 4))
        }">

            <div v-if="step == 1" class="profile__container--outer candidate-details sectioned profile__container--columnPos-1">
                <div class="profile__container--header">
                    <h3>Personalia{{ getIsOldText() }}</h3>
                    <i 
                        v-if="isnew && dataNotOverwritten"
                        class="flaticon-reply shift-data" 
                        title="Overwrite all fields with old data" 
                        v-on:click="copyOldData"
                    ></i>
                    <i
                        v-if="isnew && !dataNotOverwritten"
                        class="flaticon2-refresh shift-data"
                        title="Restore all original data"
                        v-on:click="restoreNewData"
                    ></i>
                </div>
                <div class="profile__container--main">
                    <i 
                        v-if="isnew && nameDataNotOverwritten"
                        class="flaticon-reply shift-data" 
                        title="Overwrite name and gender fields with old data" 
                        v-on:click="copyOldNames"
                    ></i>
                    <i
                        v-if="isnew && !nameDataNotOverwritten"
                        class="flaticon2-refresh shift-data"
                        title="Restore original name and gender data"
                        v-on:click="restoreNewNames"
                    ></i>

                    <form-field
                        title="First name"
                        fieldtype="form-input"
                        v-bind:inputname="`candidate[first_name]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.first_name"
                        v-bind:haserrors="candidate.errors.first_name"
                        v-bind:addclass="getClassForField('first_name') + ' group-target group-name'"
                        v-bind:isdisabled="isold"
                    ></form-field>

                    <form-field
                        title="Middle name"
                        fieldtype="form-input"
                        v-bind:inputname="`candidate[middle_name]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.middle_name"
                        v-bind:haserrors="candidate.errors.middle_name"
                        v-bind:addclass="getClassForField('middle_name') + ' group-target group-name'"
                        v-bind:isdisabled="isold"
                    ></form-field>

                    <form-field
                        title="Last name preposition"
                        fieldtype="form-input"
                        v-bind:inputname="`candidate[last_name_preposition]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.last_name_preposition"
                        v-bind:haserrors="candidate.errors.last_name_preposition"
                        v-bind:addclass="getClassForField('last_name_preposition') + ' group-target group-name'"
                        v-bind:isdisabled="isold"
                    ></form-field>

                    <form-field
                        title="Last name"
                        fieldtype="form-input"
                        v-bind:inputname="`candidate[last_name]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.last_name"
                        v-bind:haserrors="candidate.errors.last_name"
                        v-bind:addclass="getClassForField('last_name') + ' group-target group-name'"
                        v-bind:isdisabled="isold"
                    ></form-field>

                    <form-field
                        title="Initials"
                        fieldtype="form-input"
                        v-bind:inputname="`candidate[initials]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.initials"
                        v-bind:haserrors="candidate.errors.initials"
                        v-bind:addclass="getClassForField('initials') + ' group-target group-name'"
                        v-bind:isdisabled="isold"
                    ></form-field>

                    <form-field
                        title="Gender"
                        fieldtype="form-dropdown"
                        v-bind:inputname="`candidate[gender]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.gender"
                        v-bind:alloptions="formOptions.gender_options"
                        v-bind:haserrors="candidate.errors.gender"
                        v-bind:addclass="getClassForField('gender') + ' group-target group-name'"
                        v-bind:isdisabled="isold"
                    ></form-field>

                    <form-field
                        title="Date of birth"
                        fieldtype="form-date"
                        v-bind:inputname="`candidate[date_of_birth]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.date_of_birth"
                        v-bind:error="candidate.date_of_birth"
                        v-bind:haserrors="candidate.errors.date_of_birth"
                        v-bind:addclass="getClassForField('date_of_birth')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <form-field
                        title="Nationalities"
                        fieldtype="form-multidrop"
                        v-bind:inputname="`candidate[nationality_ids][]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.nationalities"
                        searchurl="/autocomplete/nationalities"
                        preload="1"
                        v-bind:haserrors="candidate.errors.nationality_ids"
                        v-bind:addclass="getClassForField('nationality_ids')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>


                    <candidate-address
                        v-bind:address-data="{ 
                            address: candidate.address, 
                            housenumber: candidate.housenumber, 
                            postcode: candidate.postcode, 
                            town: candidate.town, 
                            country: candidate.country, 
                            country_region: candidate.country_region,
                            province: candidate.province,
                            changes: candidate.changes }"

                        v-bind:errors-data="{ 
                            address: candidate.errors.address, 
                            housenumber: candidate.errors.housenumber, 
                            postcode: candidate.errors.postcode, 
                            town: candidate.errors.town,
                            country: candidate.errors.country,
                            country_region: candidate.errors.country_region,
                            province: candidate.errors.province }"

                        v-bind:isold="isold"
                        v-bind:isnew="isnew"
                    ></candidate-address>

                    <form-field
                        title="Address / Street 2"
                        fieldtype="form-input"
                        v-bind:inputname="`candidate[address2]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.address2"
                        v-bind:haserrors="candidate.errors.address2"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>
                
                    <form-field
                        title="Tel. Home"
                        fieldtype="form-input"
                        v-bind:inputname="`candidate[phone_home]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.phone_home"
                        v-bind:haserrors="candidate.errors.phone_home"
                        v-bind:addclass="getClassForField('phone_home')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <form-field
                        title="Tel. Mobile"
                        fieldtype="form-input"
                        v-bind:inputname="`candidate[phone_mobile]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.phone_mobile"
                        v-bind:haserrors="candidate.errors.phone_mobile"
                        v-bind:addclass="getClassForField('phone_mobile')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>
                
                    <form-field
                        title="Online profile URL"
                        fieldtype="form-input"
                        v-bind:inputname="`candidate[online_profile_url]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.online_profile_url"
                        v-bind:haserrors="candidate.errors.online_profile_url"
                        v-bind:addclass="getClassForField('online_profile_url')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>
                
                    <form-field
                        title="E-Mail"
                        fieldtype="form-input"
                        v-bind:inputname="`candidate[email_home]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.email_home"
                        v-bind:haserrors="candidate.errors.email_home || candidate.errors['authentication.email']"
                        v-bind:addclass="getClassForField('email_home')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <form-field
                        title="Personal website"
                        v-bind:inputname="`candidate[personal_website]${getOldIdAddition()}`"
                        fieldtype="form-input"
                        v-bind:startvalue="candidate.personal_website"
                        v-bind:haserrors="candidate.errors.personal_website"
                        v-bind:addclass="getClassForField('personal_website')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <form-field
                        title="Drivers license"
                        v-bind:inputname="`candidate[drivers_license]${getOldIdAddition()}`"
                        fieldtype="form-input"
                        v-bind:startvalue="candidate.drivers_license"
                        v-bind:haserrors="candidate.errors.drivers_license"
                        v-bind:addclass="getClassForField('drivers_license')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>


                    <!-- Note:  most probably will be canaged to boolean - true/ false/ null -->

                <!-- closing Details main -->
                </div>
            <!-- closing Details outer -->
            </div>

            <div v-if="step == 1" class="profile__container--outer candidate-work sectioned profile__container--columnPos-2">
                <div class="profile__container--header">
                    <h3>Blue Lynx Data{{ getIsOldText() }}</h3>
                </div>
                <div class="profile__container--main">
                    <form-field
                        title="Work status"
                        v-bind:inputname="`candidate[identification_work_status_approved]${getOldIdAddition()}`"
                        fieldtype="form-radio"
                        v-bind:startvalue="candidate.identification_work_status_approved"
                        v-bind:alloptions="[{ name: 'Approved', id: 1 }, { name: 'No Approval', id: -1 }, { name: 'Unknown', id: 0 }]"
                        v-bind:haserrors="candidate.errors.identification_work_status_approved"
                        v-bind:addclass="getClassForField('identification_work_status_approved')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <form-field
                        title="Valid ID"
                        v-bind:inputname="`candidate[identification]${getOldIdAddition()}`"
                        fieldtype="form-radio"
                        v-bind:startvalue="candidate.identification"
                        v-bind:alloptions="[{ name: 'Yes', id: true }, { name: 'No', id: false }]"
                        v-bind:haserrors="candidate.errors.identification"
                        v-bind:addclass="getClassForField('identification')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <form-field
                        title="Preferred countries"
                        fieldtype="form-multidrop"
                        v-bind:inputname="`candidate[preferred_country_ids][]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.preferred_countries"
                        searchurl="/autocomplete/countries"
                        preload="1"
                        v-bind:haserrors="candidate.errors.preferred_country_ids"
                        v-bind:addclass="getClassForField('preferred_country_ids')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <div class="profile__container--field"></div>

                    <form-field
                        title="Internal admission notes"
                        fieldtype="form-textarea"
                        v-bind:inputname="`candidate[int_admission_notes]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.int_admission_notes"
                        v-bind:haserrors="candidate.errors.int_admission_notes"
                        v-bind:addclass="getClassForField('int_admission_notes')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <div class="profile__container--col">
                        <form-field
                            title="Blue Lynx location"
                            fieldtype="form-dropdown"
                            v-bind:inputname="`candidate[bluelynx_location_id]${getOldIdAddition()}`"
                            v-bind:startvalue="candidate.bluelynx_location"
                            v-bind:alloptions="formOptions.bluelynx_locations"
                            v-bind:haserrors="candidate.errors.bluelynx_location"
                            v-bind:addclass="getClassForField('bluelynx_location_id')"
                            v-bind:isdisabled="isold"
                            v-bind:isnew="isnew"
                        ></form-field>

                        <form-field
                            title="Intake"
                            fieldtype="form-dropdown"
                            v-bind:inputname="`candidate[intake]${getOldIdAddition()}`"
                            v-bind:startvalue="candidate.intake"
                            v-bind:alloptions="formOptions.intake_options"
                            v-bind:haserrors="candidate.errors.intake"
                            v-bind:addclass="getClassForField('intake')"
                            v-bind:isdisabled="isold"
                            v-bind:isnew="isnew"
                        ></form-field>
                    </div>

                    <div class="profile__container--row salary-row--flip">
                        <form-field
                            title="Salary min"
                            fieldtype="form-number"
                            v-bind:inputname="`candidate[salary_range_min]${getOldIdAddition()}`"
                            v-bind:startvalue="candidate.salary_range_min"
                            v-bind:alloptions="{
                                min: 0,
                                max: 1000000,
                                step: 1
                            }"
                            v-bind:haserrors="candidate.errors.salary_range_min"
                            v-bind:addclass="getClassForField('salary_range_min')"
                            v-bind:isdisabled="isold"
                            v-bind:isnew="isnew"
                        ></form-field>

                        <form-field
                            title="Salary max"
                            fieldtype="form-number"
                            v-bind:inputname="`candidate[salary_range_max]${getOldIdAddition()}`"
                            v-bind:startvalue="candidate.salary_range_max"
                            v-bind:alloptions="{
                                min: 0,
                                max: 1000000,
                                step: 1
                            }"
                            v-bind:haserrors="candidate.errors.salary_range_max"
                            v-bind:addclass="getClassForField('salary_range_max')"
                            v-bind:isdisabled="isold"
                            v-bind:isnew="isnew"
                        ></form-field>
                    </div>
                    <div class="profile__container--row">
                        <form-field
                            title="Salary currency"
                            fieldtype="form-dropdown"
                            v-bind:inputname="`candidate[salary_currency]${getOldIdAddition()}`"
                            v-bind:startvalue="candidate.salary_currency"
                            v-bind:alloptions="formOptions.salary_currencies"
                            v-bind:haserrors="candidate.errors.salary_currency"
                            v-bind:addclass="getClassForField('salary_currency')"
                            v-bind:isdisabled="isold"
                            v-bind:isnew="isnew"
                        ></form-field>

                        <form-field
                            title="Salary range"
                            fieldtype="form-radio"
                            v-bind:inputname="`candidate[salary_range_type]${getOldIdAddition()}`"
                            v-bind:startvalue="candidate.salary_range_type"
                            v-bind:alloptions="formOptions.salary_range_types"
                            v-bind:haserrors="candidate.errors.salary_range_type"
                            v-bind:addclass="getClassForField('salary_range_type')"
                            v-bind:isdisabled="isold"
                            v-bind:isnew="isnew"
                        ></form-field>
                    </div>

                    <div class="profile__container--row">
                        <form-field
                            title="Includes vacation"
                            fieldtype="form-checkbox"
                            v-bind:inputname="`candidate[salary_includes_vacation]${getOldIdAddition()}`"
                            v-bind:startvalue="candidate.salary_includes_vacation"
                            v-bind:haserrors="candidate.errors.salary_includes_vacation"
                            v-bind:addclass="getClassForField('salary_includes_vacation')"
                            v-bind:isdisabled="isold"
                            v-bind:isnew="isnew"
                        ></form-field>

                        <form-field
                            title="Is negotiable"
                            fieldtype="form-checkbox"
                            v-bind:inputname="`candidate[salary_is_negotiable]${getOldIdAddition()}`"
                            v-bind:startvalue="candidate.salary_is_negotiable"
                            v-bind:haserrors="candidate.errors.salary_is_negotiable"
                            v-bind:addclass="getClassForField('salary_is_negotiable')"
                            v-bind:isdisabled="isold"
                            v-bind:isnew="isnew"
                        ></form-field>
                    </div>

                    <div class="profile__container--row">
                        <form-field
                            title="Freelancer interest"
                            fieldtype="form-checkbox"
                            v-bind:inputname="`candidate[freelancer_interest]${getOldIdAddition()}`"
                            v-bind:startvalue="candidate.freelancer_interest"
                            v-bind:haserrors="candidate.errors.freelancer_interest"
                            v-bind:addclass="getClassForField('freelancer_interest')"
                            v-bind:isdisabled="isold"
                            v-bind:isnew="isnew"
                        ></form-field>

                        <form-field
                            title="Freelancer day rate"
                            fieldtype="form-number"
                            v-bind:inputname="`candidate[freelancer_day_rate]${getOldIdAddition()}`"
                            v-bind:startvalue="candidate.freelancer_day_rate"
                            v-bind:alloptions="{
                                min: 0,
                                max: 1000000,
                                step: 1
                            }"
                            v-bind:haserrors="candidate.errors.freelancer_day_rate"
                            v-bind:addclass="getClassForField('freelancer_day_rate')"
                            v-bind:isdisabled="isold"
                            v-bind:isnew="isnew"
                        ></form-field>
                    </div>


                    <div class="profile__container--row">
                        <form-field
                            title="Employment type"
                            fieldtype="form-dropdown"
                            v-bind:inputname="`candidate[type_of_employment]${getOldIdAddition()}`"
                            v-bind:startvalue="candidate.type_of_employment"
                            v-bind:alloptions="formOptions.type_of_employment_options"
                            v-bind:haserrors="candidate.errors.type_of_employment"
                            v-bind:addclass="getClassForField('type_of_employment')"
                            v-bind:isdisabled="isold"
                            v-bind:isnew="isnew"
                        ></form-field>

                        <form-field
                            title="Job Type"
                            fieldtype="form-dropdown"
                            v-bind:inputname="`candidate[full_parttime]${getOldIdAddition()}`"
                            v-bind:startvalue="candidate.full_parttime"
                            v-bind:alloptions="formOptions.full_parttime_options"
                            v-bind:haserrors="candidate.errors.full_parttime"
                            v-bind:addclass="getClassForField('full_parttime')"
                            v-bind:isdisabled="isold"
                            v-bind:isnew="isnew"
                        ></form-field>
                    </div>

                    <form-field
                        title="Max. Commute Time"
                        fieldtype="form-dropdown"
                        v-bind:inputname="`candidate[travelling_time_id]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.travelling_time_id"
                        v-bind:alloptions="formOptions.travelling_time_options"
                        v-bind:haserrors="candidate.errors.travelling_time"
                        v-bind:addclass="getClassForField('travelling_time_id')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <form-field
                        title="Active / Non Active"
                        v-bind:inputname="`candidate[active_nonactive]${getOldIdAddition()}`"
                        fieldtype="form-radio"
                        v-bind:startvalue="candidate.active_nonactive"
                        v-bind:alloptions="[{ name: 'Active', id: true }, { name: 'Non Active', id: false }]"
                        v-bind:haserrors="candidate.errors.active_nonactive"
                        v-bind:addclass="getClassForField('active_nonactive')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <form-field
                        title="BLACKLISTED"
                        v-bind:inputname="`candidate[blacklist]${getOldIdAddition()}`"
                        fieldtype="form-radio"
                        v-bind:startvalue="candidate.blacklist"
                        v-bind:alloptions="[{ name: 'Yes', id: true }, { name: 'No', id: false }]"
                        v-bind:haserrors="candidate.errors.blacklist"
                        v-bind:addclass="getClassForField('blacklist')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

            
                    <form-field
                        title="Under Offer"
                        v-bind:inputname="`candidate[under_offer]${getOldIdAddition()}`"
                        fieldtype="form-radio"
                        v-bind:startvalue="candidate.under_offer"
                        v-bind:alloptions="[{ name: 'Yes', id: true }, { name: 'No', id: false }]"
                        v-bind:haserrors="candidate.errors.under_offer"
                        v-bind:addclass="getClassForField('under_offer')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <form-field
                        title="GDPR (no contact)"
                        v-bind:inputname="`candidate[gdpr]${getOldIdAddition()}`"
                        fieldtype="form-radio"
                        v-bind:startvalue="candidate.gdpr"
                        v-bind:alloptions="[{ name: 'Yes - do not contact', id: true }, { name: 'No', id: false }]"
                        v-bind:haserrors="candidate.errors.gdpr"
                        v-bind:addclass="getClassForField('gdpr')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <form-field
                        title="Contractor BLE"
                        fieldtype="form-dropdown"
                        v-bind:inputname="`candidate[works_for_ble]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.works_for_ble"
                        v-bind:alloptions="formOptions.works_for_ble_options"
                        v-bind:haserrors="candidate.errors.works_for_ble"
                        v-bind:addclass="getClassForField('works_for_ble')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <form-field
                        title="Contract End Date"
                        fieldtype="form-date"
                        v-bind:inputname="`candidate[end_date_contract]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.end_date_contract"
                        v-bind:haserrors="candidate.errors.end_date_contract"
                        v-bind:addclass="getClassForField('end_date_contract')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>
                    
                    <form-field
                        title="Heard of BL"
                        fieldtype="form-dropdown"
                        v-bind:inputname="`candidate[heard_of_blue_lynx_id]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.heard_of_blue_lynx"
                        searchurl="/autocomplete/heard-of-blue-lynxes"
                        v-bind:haserrors="candidate.errors.heard_of_blue_lynx_id"
                        v-bind:addclass="getClassForField('heard_of_blue_lynx_id')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>
                <!-- closing work main div -->
                </div>
            <!-- closing work outer div -->
            </div>

            <div v-if="false" class="profile__container--outer candidate-interview sectioned profile__container--status profile__container--columnPos-3 profile__container--rowPos-4">
                <div class="profile__container--header">
                    <h3>Other{{ getIsOldText() }}</h3>
                </div>
                <div class="profile__container--main">
                    <form-field
                        v-if="false"
                        title="Old Notes (read-only)"
                        fieldtype="form-textarea"
                        v-bind:inputname="`candidate[general_note]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.general_note"
                        v-bind:alloptions="{ readonly: true }"
                        v-bind:haserrors="candidate.errors.general_note"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>


                <!-- closing interview main div -->
                </div>
            <!-- closing interview outer div -->
            </div>

            <div v-if="step == 2" class="profile__container--outer profile__container--experience  profile__container--columnPos-1">
                <div class="profile__container--main">
                    <div class="profile__container--header">
                        <h3>Job experience{{ getIsOldText() }}</h3>
                        <i 
                            v-if="isnew && dataNotOverwritten"
                            class="flaticon-reply shift-data" 
                            title="Overwrite all fields with old data" 
                            v-on:click="copyOldData"
                        ></i>
                        <i
                            v-if="isnew && !dataNotOverwritten"
                            class="flaticon2-refresh shift-data"
                            title="Restore all original data"
                            v-on:click="restoreNewData"
                        ></i>
                    </div>

                    <form-field
                        title="Companies"
                        fieldtype="form-multidrop"
                        v-bind:inputname="`candidate[company_ids][]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.companies"
                        searchurl="/autocomplete/companies"
                        preload="1"
                        v-bind:haserrors="candidate.errors.company_ids"
                        v-bind:addclass="getClassForField('company_ids')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <form-field
                        title="Industries"
                        fieldtype="form-multidrop"
                        v-bind:inputname="`candidate[industry_ids][]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.industries"
                        searchurl="/autocomplete/industries"
                        preload="1"
                        v-bind:haserrors="candidate.errors.industry_ids"
                        v-bind:addclass="getClassForField('industry_ids')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <candidate-job-experience-items
                        addWrapperClass="profile__container--columnPos-1 steps-form"
                        v-bind:initial-records="candidate.job_experiences"
                        v-bind:maxRecordsShown="maxJobExperienceRecordsShown"
                        v-bind:isold="isold"
                        v-bind:isnew="isnew"
                    ></candidate-job-experience-items>
                </div>
            </div>
            
            <!-- Note - heighest education can be determined from this collection? -->
            <div v-if="step == 3" class="profile__container--outer profile__container--experience  profile__container--columnPos-1">
                <div class="profile__container--main">
                    <div class="profile__container--header">
                        <h3>Education{{ getIsOldText() }}</h3>
                        <i 
                            v-if="isnew && dataNotOverwritten"
                            class="flaticon-reply shift-data" 
                            title="Overwrite all fields with old data" 
                            v-on:click="copyOldData"
                        ></i>
                        <i
                            v-if="isnew && !dataNotOverwritten"
                            class="flaticon2-refresh shift-data"
                            title="Restore all original data"
                            v-on:click="restoreNewData"
                        ></i>
                    </div>

                    <form-field
                        title="Highest education"
                        fieldtype="form-dropdown"
                        v-bind:inputname="`candidate[highest_education_id]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.highest_education_id"
                        v-bind:alloptions="formOptions.education_levels"
                        v-bind:haserrors="candidate.errors.highest_education"
                        v-bind:addclass="getClassForField('highest_education_id')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <candidate-education-items
                        addWrapperClass="profile__container--columnPos-2 steps-form"
                        v-bind:initial-records="candidate.education_history_items"
                        v-bind:diploma-code-options="formOptions.diploma_codes"
                        v-bind:education-level-options="formOptions.education_levels"
                        v-bind:maxRecordsShown="maxEducationRecordsShown"
                        v-bind:isold="isold"
                        v-bind:isnew="isnew"
                    ></candidate-education-items>
                </div>
            </div>

            <!-- languages in skills section? -->
            <candidate-languages
                v-if="step == 4"
                addWrapperClasss="profile__container--columnPos-1 steps-form"
                v-bind:intial-records="candidate.languages"
                v-bind:language-proficiency-options="formOptions.language_proficiency_options"
                v-bind:isold="isold"
                v-bind:isnew="isnew"
            ></candidate-languages>

            <div v-if="step == 4" class="profile__container--outer candidate-skills sectioned profile__container--columnPos-2">
                <div class="profile__container--header">
                    <h3>Skills{{ getIsOldText() }}</h3>
                    <i 
                        v-if="isnew && dataNotOverwritten"
                        class="flaticon-reply shift-data" 
                        title="Overwrite all fields with old data" 
                        v-on:click="copyOldData"
                    ></i>
                    <i
                        v-if="isnew && !dataNotOverwritten"
                        class="flaticon2-refresh shift-data"
                        title="Restore all original data"
                        v-on:click="restoreNewData"
                    ></i>
                </div>
                <div class="profile__container--main">

                    <!-- Skills section ? -->
                    <!-- [Search input for adding software] -->
                    <!-- [Added search capability in test-form-tags, not styled] -->
                    <form-field
                        title="Software"
                        v-bind:inputname="`candidate[software][]${getOldIdAddition()}`"
                        fieldtype="form-tags"
                        v-bind:startvalue="candidate.software"
                        v-bind:haserrors="candidate.errors.software"
                        searchurl="/autocomplete/software_skills"
                        v-bind:addclass="getClassForField('software')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <form-field
                        title="Soft skills"
                        v-bind:inputname="`candidate[soft_skills][]${getOldIdAddition()}`"
                        fieldtype="form-tags"
                        v-bind:startvalue="candidate.soft_skills"
                        v-bind:haserrors="candidate.errors.soft_skills"
                        v-bind:addclass="getClassForField('soft_skills')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <form-field
                        title="Professional skills"
                        v-bind:inputname="`candidate[professional_skills][]${getOldIdAddition()}`"
                        fieldtype="form-tags"
                        v-bind:startvalue="candidate.professional_skills"
                        v-bind:haserrors="candidate.errors.professional_skills"
                        searchurl="/autocomplete/professional_skills"
                        v-bind:addclass="getClassForField('professional_skills')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <form-field
                        title="Hobbies"
                        v-bind:inputname="`candidate[hobbies][]${getOldIdAddition()}`"
                        fieldtype="form-tags"
                        v-bind:startvalue="candidate.hobbies"
                        v-bind:haserrors="candidate.errors.hobbies"
                        v-bind:addclass="getClassForField('hobbies')"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>

                    <form-field
                        title="Ambitions"
                        fieldtype="form-textarea"
                        v-bind:inputname="`candidate[ambitions]${getOldIdAddition()}`"
                        v-bind:startvalue="candidate.ambitions"
                        v-bind:haserrors="candidate.errors.ambitions"
                        v-bind:addclass="`${getClassForField('ambitions')} textarea-tall`"
                        v-bind:isdisabled="isold"
                        v-bind:isnew="isnew"
                    ></form-field>
                <!-- closing skills main div -->
                </div>
            <!-- closing skills outer div -->
            </div>

    </div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'
    import CandidateAddress from './shared/candidate-address.vue'
    import CandidateLanguages from './shared/candidate-languages.vue'
    import CandidateEducationItems from './shared/candidate-education-items.vue'
    import CandidateJobExperienceItems from './shared/candidate-job-experience-items.vue'

    export default {
        props: [
            'candidateData',
            'formOptionsData',
            'step',
            'isold',
            'isnew'
        ],
        data: function(){
            var candidate = JSON.parse(this.candidateData || '{}');
            var formOptions = JSON.parse(this.formOptionsData || '{}');
            var changedFields = candidate.changes || [];

            if(candidate.nationalities.some(record => record.new_record)){
                changedFields.push('nationality_ids');
            };

            if(candidate.preferred_countries.some(record => record.new_record)){
                changedFields.push('preferred_country_ids');
            };

            if(candidate.industries.some(record => record.new_record)){
                changedFields.push('industry_ids');
            };

            if(candidate.companies.some(record => record.new_record)){
                changedFields.push('company_ids');
            };

            // console.log(candidate.changes);

            var maxJobExperienceRecordsShown = Infinity;
            var maxEducationRecordsShown = Infinity;

            return { 
                candidate: candidate, 
                changedFields: changedFields,
                formOptions: formOptions,
                maxJobExperienceRecordsShown: maxJobExperienceRecordsShown,
                maxEducationRecordsShown: maxEducationRecordsShown,
                showCountryRegions: false, 
                regionsForCountry: '',
                nameDataNotOverwritten: true,
                dataNotOverwritten: true
            };
        },
        methods: {
            getClassForField: function(fieldKey){
                var fieldClass = '';

                if(this.changedFields.includes(fieldKey)){
                    fieldClass = 'changed';
                }

                return fieldClass;
            },
            getIsOldText: function(){
                let isOldText = '';
                if(this.isold){ isOldText = '(Old)'};
                return isOldText;
            },
            getOldIdAddition: function(){ if(this.isold){ return '_old' }else{ return '' } },
            copyOldNames: function(){
                $(this.$el).find(`.group-name`).each(function(index){ this.__vue__.copyOldData() });
                this.nameDataNotOverwritten = false;
            },
            restoreNewNames: function(){
                $(this.$el).find(`.group-name`).each(function(index){ this.__vue__.restoreNewData() });
                this.nameDataNotOverwritten = true;
            },
            copyOldData: function(){
                this.copyOldNames();
                this.$children.forEach(function(vueInstance){ 
                    let instanceClass = vueInstance.$el.className;
                    if(instanceClass.indexOf('profile__container--languages') == -1
                    && instanceClass.indexOf('profile__container--experience') == -1
                    && instanceClass.indexOf('profile__container--education') == -1){
                        vueInstance.copyOldData();
                    };
                });
                this.dataNotOverwritten = false;
            },
            restoreNewData: function(){
                this.restoreNewNames();
                this.$children.forEach(function(vueInstance){ 
                    let instanceClass = vueInstance.$el.className;
                    if(instanceClass.indexOf('profile__container--languages') == -1
                    && instanceClass.indexOf('profile__container--experience') == -1
                    && instanceClass.indexOf('profile__container--educations') == -1){
                        vueInstance.restoreNewData();
                    };
                });
                this.dataNotOverwritten = true;
            }
        },
        components: {
            FormField,
            CandidateLanguages,
            CandidateEducationItems,
            CandidateJobExperienceItems,
            CandidateAddress
        }
    }
</script>