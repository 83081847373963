<template>
<div class="profile__container--wrapper profile__container--columns-3">

    <div class="profile__container--outer profile__container--details profile__container--news">
        <div class="profile__container--header">
            <h3>Details</h3>
        </div>
        <div class="profile__container--main">
            <form-field
                title="Title"
                fieldtype="form-input"
                inputname="staff_announcement[title]"
                v-bind:startvalue="staff_announcement.title"
                v-bind:haserrors="staff_announcement.errors.title"
            ></form-field>

            <form-field
                title="Text"
                fieldtype="form-textarea"
                inputname="staff_announcement[text]"
                v-bind:startvalue="staff_announcement.text"
                v-bind:haserrors="staff_announcement.errors.text"
                v-bind:addclass="'textarea-tall'"
            ></form-field>

            <form-field
                title="Start date"
                fieldtype="form-date"
                inputname="staff_announcement[start_date]"
                v-bind:startvalue="staff_announcement.start_date"
                v-bind:error="staff_announcement.start_date"
                v-bind:haserrors="staff_announcement.errors.start_date"
            ></form-field>

            <form-field
                title="End date"
                fieldtype="form-date"
                inputname="staff_announcement[end_date]"
                v-bind:startvalue="staff_announcement.end_date"
                v-bind:error="staff_announcement.end_date"
                v-bind:haserrors="staff_announcement.errors.end_date"
            ></form-field>

        </div>
    </div>
</div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'

    export default {
        props: [
            'recordData',
            'formOptionsData'
        ],
        data: function(){
            var staff_announcement = JSON.parse(this.recordData || '{}');
            return { 
                staff_announcement: staff_announcement
            };
        },
        components: {
            FormField
        }
    }
</script>