<template>
    <div class="profile__input--wrapper">
        <div 
            class="profile__container--dropdown dropdown-multiple" 
            v-on:click.stop="toggleDropdown"
            v-on:keydown="handleKeyboard">
                <div 
                    v-on:focus="handleDropdownFocus"
                    v-on:blur="handleDropdownBlur"
                    class="dropdown__container--values dropdown__display--flex" 
                    style="display: inline-block;" 
                    tabindex="0">
                        <div 
                            class="dropdown-value dropdown-selected dropdown-placeholder"
                            v-bind:class="{ 'dropdown-placeholder--none':  selectValue.length }"
                        ></div>
                        <div 
                            class="dropdown-value dropdown-selected"
                            v-for="item in selectValue"
                            v-bind:key="item.id"
                            v-bind:value="item.id">
                                <p 
                                    v-if="!isdisabled"
                                    v-on:focus="handleDeselectFocus"
                                    v-on:blur="handleDeselectBlur"
                                    v-on:click.stop="deselectOption(item)" 
                                    tabindex="0" 
                                    class="dropdown-deselect">
                                        X
                                </p>
                                <p class="dropdown-selected-title">
                                    {{ item.name }}
                                </p>
                        </div>
                </div>
                <input 
                    v-if="searchurl"
                    type="text" 
                    class="dropdown-search" 
                    v-bind:class="{ 'profile__container--show': toggleState}"
                    v-bind:value="searchQuery"
                    v-on:click.stop=""
                    v-on:input="sendQuery">
                <div class="profile__container--options" v-bind:class="{ 'profile__container--show': toggleState, 'profile__container--showUP': toggleUp}">
                    <div 
                        class="profile__option"
                        v-on:click.stop="selectOption(item)"
                        v-for="(item, index) in dropdownOptions"
                        v-bind:class="{ 'mark-keyboardFocus': highlightIndex == index}"
                        v-bind:key="item.id"
                        v-bind:value="item.id">
                            {{ item.name }}
                            <p style="display: none;">
                                {{ item }}, {{ index }}, {{ highlightIndex }}
                            </p>
                    </div>
                </div>
        </div>
        <select class="hidden_select form-data-target" v-bind:name="inputname" v-bind:id="inputid" multiple="multiple">
            <option
                selected
                v-for="item in selectValue"
                v-bind:key="item.id"
                v-bind:value="item.id">
                    {{ item.name }}
            </option>
            <option
                selected
                v-if="selectValue.length == 0"
                value=""
                style="display: none;">
                    {{ selectValue.length }}
            </option>
        </select>
		<div v-if="haserrors" class="login-form__warning--icon" v-bind:title="haserrors[0]"><p>!</p></div>
		<!-- <div 
			v-if="haserrors"
			class="error-explanation">
				{{ haserrors[0] }}
		</div> -->
    </div>
</template>

<script>
    import Ajax from './js/ajax.js'
    import Align from './js/align.js'
    import CheckDuplicate from './js/checkDuplicate.js'
    import OrderArray from './js/order-array.js'
    import SearchHandler from './mixins/search_handler.js'

    export default {
        props: [
            'inputname',
            'inputid',
            'startvalue',
            'alloptions',
            'selectall',
            'searchurl',
            'preload',
            'preloadquery',
            'isdisabled',
            'haserrors'
        ],
        mixins: [
            SearchHandler
        ],
        data: function(){
            return {
                storedOptions: '',
                selectValue: this.startvalue || [],
                dropdownOptions: this.alloptions || [],
                toggleState: false,
                toggleUp: false,
                eventCount: 0,
                highlightIndex: 0,
                searchQuery: null,
                initialQuery: {'target': {'value': '   '}, 'initial': 1},
                bulkState: false
            }
        },
        created: function(){
            if(Number(this.preload)){
                // console.log('preload options');
                Ajax.sendRequest('GET',{ 'q': (this.preloadquery || '') },this.searchurl,this.processResponse,this);
            }else{
                // console.log('dont option preload');
            };
        },
        mounted: function(){ if(this.$parent.isnew){ this.$parent.copyOldData().restoreNewData() } },
        updated: function(){ if(this.toggleState && this.searchurl){ $(this.$el).find('.dropdown-search')[0].focus() } },
        methods: {
            //KEYBOARD HANDLERS:
            handleDeselectFocus: function(event){
                // console.log('deselect focus');
                let targetOptionValue = $(event.target.parentElement).attr('value');
                let targetOptionText = $(event.target.parentElement).find('.dropdown-selected-title')[0].innerText;
                let targetOptionFull = {
                    name: targetOptionText,
                    id: targetOptionValue
                };
                $(event.target).bind('keydown',(event) => {
                    if(event.key == "Enter"){
                        // console.log('enter pressed, call deselectOption');
                        this.deselectOption(targetOptionFull);
                        $(event.target).unbind('keydown');
                    };
                });
            },
            handleDeselectBlur: function(event){ $(event.target).unbind('keydown') },
            handleDropdownFocus: function(event){
                //console.log('dropdown focus');
                $(event.target).bind('keypress',(event) => {
                    if(event.key == "Enter"){
                        this.toggleDropdown();
                    };
                });
            },
            handleDropdownBlur: function(event){ $(event.target).unbind('keypress') },
            handleKeyboard: function(event){
                //console.log('handle keyboard');
                if(event.target.className.indexOf('dropdown-deselect') == -1){
                    if(event.key == "Escape"){
                        this.toggleState = true;
                        this.toggleDropdown();
                        return;
                    };
                    if(event.key == "ArrowUp"){
                        this.highlightIndex += -1;
                    };
                    if(event.key == "ArrowDown"){
                        this.highlightIndex += 1;
                    };
                    if(this.dropdownOptions.length){
                        let optionLength = this.dropdownOptions.length;
                        let targetIndex = (this.highlightIndex + optionLength) % optionLength;
                        this.highlightIndex = targetIndex || 0;
                        this.$nextTick(() => {
                            let markedElement = $(event.target.parentElement).find('.mark-keyboardFocus')[0];
                            let optionsContainer = $(event.target.parentElement).find('.profile__container--options')[0];
                            optionsContainer.scrollTop = markedElement.offsetTop;
                            if(event.key == "Enter" && this.toggleState){
                                //console.log('Enter pressed, selectOption');
                                let newOptionFull = {
                                    name: markedElement.innerText,
                                    id: $(markedElement).attr('value')
                                };
                                this.selectOption(newOptionFull);
                            };
                        });
                    };
                };
            },
            //TOGGLE DROPDOWN METHODS:
            toggleDropdown: function(){
                //console.log('toggle multi-dropdown');
                if(this.$el.parentElement.className.indexOf('field--disabled') != -1){ return };

                let openDropdowns = $(this.$root.$el).find(`.profile__container--show`);
                this.toggleState = !this.toggleState;
                this.toggleUp = this.toggleState && Align.vertical(this);
                if(this.toggleState){
                    if(this.dropdownOptions.length <= 0){
                        //console.log(`empty options, re-initialize`);
                        Ajax.sendRequest('GET',{ 'q': (this.preloadquery || '') },this.searchurl,this.processResponse,this);
                    };
                    $(openDropdowns).each(function(dropdown_index){ this.click() });
                    //console.log(`bind outside click listener`);
                    $(`body`).bind(`click`,this.toggleDropdown);
                }else{
                    //console.log(`unbind outside click listener`);
                    $(`body`).unbind(`click`,this.toggleDropdown);
                };
                if(this.selectall == 'true'){ this.removeBulkOptions(); this.setBulkOption() };
            },
            initializeOptions: function(rawSelected,rawOptions){
                //console.log(`initializing options`);
                this.selectValue = rawSelected;
                this.dropdownOptions = rawOptions;
                if(this.selectall == 'true'){ this.setBulkOption() };
            },
            updateOptions: function(newOptions){
                //console.log(`updating options`);
                newOptions = newOptions || [];
                if(newOptions.length){
                    //console.log('new options exist');
                    $(this.selectValue).each(function(value_index){
                        let thisDupes = CheckDuplicate.isDuped(newOptions,this);
                        if(thisDupes.found){
                            newOptions.splice(thisDupes.index,1);
                        };
                    });
                    this.initializeOptions(this.selectValue,newOptions);
                }else{
                    //console.log(`new options are empty`);
                    this.initializeOptions(this.selectValue,[]);
                };
            },
            selectOption: function(thisSelected){
                //console.log('selecting option');
                //console.log(thisSelected);
                //console.log(this.selectValue);
                //console.log(this.startvalue);
                let vueInstance = this;
                switch(thisSelected.id){
                    case 'VUE-ALL':
                        this.removeBulkOptions();
                        $(this.dropdownOptions).each(function(index){ vueInstance.selectOption(this) });
                        this.removeBulkOptions();
                        this.setBulkOption();
                        break;
                    case 'VUE-NONE':
                        this.removeBulkOptions();
                        $(this.selectValue).each(function(index){ vueInstance.deselectOption(this) });
                        this.removeBulkOptions();
                        this.setBulkOption();
                        break;
                    default:
                        let dupes = CheckDuplicate.isDuped(this.dropdownOptions,thisSelected);
                        this.dropdownOptions.splice(dupes.index,1);
                        this.selectValue.push(thisSelected);
                        this.$emit('event-bubble',{data: thisSelected, instance: this});
                        this.$nextTick(() => {
                            $(this.$el)
                            .find(`.dropdown__container--values`)[0]
                            .scrollBy(0,9999);
                            // this.selectValue = OrderArray.vueObjects(this.selectValue);
                        });
                        if(this.selectall == 'true'){
                            this.removeBulkOptions();
                            this.setBulkOption();
                        };
                        break;
                };
            },
            deselectOption: function(thisDeselected){
                if(this.$el.parentElement.className.indexOf('field--disabled') != -1){ return };
                //console.log('deselect option');
                let selectDupes = CheckDuplicate.isDuped(this.selectValue,thisDeselected);
                if(selectDupes.found){
                    //console.log(`found deselected option in current selected`);
                    this.selectValue.splice(selectDupes.index,1);
                }else{
                    //console.log('deselected option not found in current selected');
                };
                let optionDupes = CheckDuplicate.isDuped(this.dropdownOptions,thisDeselected);
                if(optionDupes.found){
                    //console.log(`found deselected option in current options`);
                }else{
                    //console.log('deselected option not found in current options');
                    this.dropdownOptions.push(thisDeselected);
                };
                this.$emit('event-bubble',{data: thisDeselected, instance: this, type: 'deselect'});
                if(this.selectall == 'true'){ this.setBulkOption() };
            },
            updateStoredOptions: function(newOption){
                //console.log('updating storage');
                let dupes = CheckDuplicate.isDuped(this.storedOptions,newOption);
                if(!dupes.found){
                    //console.log(`adding new option to storage`);
                    this.storedOptions.push(newOption);
                }else{
                    //console.log('option exists in storage');
                };
            },
            removeBulkOptions: function(){
                let cleanOptions = [];
                $(this.dropdownOptions).each(function(index){ if(String(this.id).indexOf(`VUE-`) == -1){ cleanOptions.push(this) } });
                this.dropdownOptions = cleanOptions;
            },
            setBulkOption: function(){ 
                //console.log(`calling setBulkOption()`);
                let optionToAdd;
                let optionDuped;
                if(this.dropdownOptions.length){
                    optionToAdd = { 'name': 'Select all', 'id': 'VUE-ALL' };
                    optionDuped = CheckDuplicate.isDuped(this.dropdownOptions,optionToAdd);
                    if(!optionDuped.found){ this.dropdownOptions = [optionToAdd].concat(this.dropdownOptions) };
                };
                if(this.selectValue.length){
                    optionToAdd = { 'name': 'Deselect all', 'id': 'VUE-NONE' };
                    optionDuped = CheckDuplicate.isDuped(this.dropdownOptions,optionToAdd);
                    if(!optionDuped.found){ this.dropdownOptions = [optionToAdd].concat(this.dropdownOptions) };
                };
            }
        }
    }
</script>