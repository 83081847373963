<template>
    <div class="search-list__jr" v-on:mouseenter="handleDossierList" v-on:mouseleave="handleDossierList">
        <div class="candidate-dossier-counter">{{ countactive }}</div>
        <i class="flaticon2-list-1 status-in-selection"></i>
        <div v-if="true" class="search-list__jr-list">
            <div
                v-for="job_request in countedRequests"
                v-bind:key="job_request.job_request_id">
                    <a v-bind:href="`/job_requests/${job_request.job_request_id}/edit`">
                        {{ job_request.job_title }}
                    </a>
            </div>
        </div>
    </div>
</template>

<script>
    import Ajax from '../../form-field/sub-components/js/ajax.js'

    export default {
        props: [
            'jobrequests',
            'countactive',
            'candidateid'
        ],
        data: function(){
            let activeCount = this.countactive;
            if(this.countactive > 99){ activeCount = '99+' };
            return {
                activeCount: activeCount,
                JobRequests: JSON.parse(this.jobrequests || {}),
                countedRequests: [],
                loadedRequests: false
            };
        },
        mounted: function(){
            // this.loadRequests();
        },
        methods: {
            loadRequests: function(){
                Ajax.sendRequest('GET',{},`candidates/${this.candidateid}/dossier/active`,(Response) => {
                    this.countedRequests = Response.results;
                });
                this.loadedRequests = true;
            },
            handleDossierList: function(event){
                let dossierList = $(event.target).find(`.search-list__jr-list`)[0];
                if(event.type == 'mouseenter'){
                    if(!this.loadedRequests){
                        this.loadRequests();
                    };

                    // adding padding and border
                    let listHeight = $(dossierList).height() + 10 + 10 + 2 + 2;

                    let listTopY = event.target.offsetParent.offsetTop;
                    let listBottomY = listTopY + listHeight;
                    let tableHeight = event.target.offsetParent.offsetParent.offsetHeight;

                    if(listHeight >= tableHeight){
                        $(dossierList).removeClass('show-wide');
                        $(dossierList).addClass('show-wide');
                    }else if(listBottomY >= tableHeight){
                        if(listTopY - listHeight <= 0){
                            $(dossierList).removeClass('show-wide');
                            $(dossierList).addClass('show-wide');
                        }else{
                            $(dossierList).removeClass('show-up');
                            $(dossierList).addClass('show-up');
                        };
                    };
                }else{
                    $(dossierList).removeClass('show-up');
                    $(dossierList).removeClass('show-wide');
                };
            }
        }
    }
</script>