<template>
    <div class="job-request-list-filter">
        <div v-bind:class="{ 'selected': appliedFilter == 'all' }" v-on:click="applyFilter">all</div>
        <div v-bind:class="{ 'selected': appliedFilter == '1w' }" v-on:click="applyFilter">1w</div>
        <div v-bind:class="{ 'selected': appliedFilter == '24h' }" v-on:click="applyFilter">24h</div>
    </div>
</template>

<script>
    import Ajax from '../form-field/sub-components/js/ajax.js'

    export default {
        props: [
            'url'
        ],
        data: function(){
            return {
                jobRequests: [],
                appliedFilter: 'all'
            }
        },
        mounted: function(){
            this.applyFilter({
                target: this.$el.querySelectorAll('div')[0]
            });
        },
        methods: {
            applyFilter: function(event){
                console.log(`applyFilter event`,event);

                this.appliedFilter = event.target.innerText;

                Ajax.sendRequest('GET',{},`${this.url}stats_period=${event.target.innerText}`,(response) => {
                    console.log(`RESPONSE`,response);

                    const listParent = event.target.parentElement.parentElement.parentElement;

                    listParent.querySelectorAll('.table__container--single').forEach((row) => { row.remove() });

                    const html = response.results.map((Result) => {
                        return /*html*/`
                            <div class="table__container--single">
                                <a href="#" class="flaticon2-arrow table-dropdown"></a>
                                <div class="table__container--content table__cell--8">${Result.request_nr}</div>
                                <div class="table__container--content table__cell--20">
                                    ${Result.highlighted ? /*html*/`
                                        <div class="job-request-highlighted" title="Highlighted"></div>
                                    ` : ``}
                                    <a class="jrl-link" href="${Result.edit_link}">${Result.job_title}</a>
                                    <div class="jr-status-count">
                                        <p><span class="text-primary">SR:</span><span>${Result.stats.total_sr}</span></p>
                                        <p><span class="text-primary">AC:</span><span>${Result.stats.total_ac}</span></p>
                                    </div>
                                </div>
                                <div class="table__container--content table__cell--4">
                                    <span class="jr-status ${Result.status.code}" title="${Result.status.name}"></span> 
                                </div>
                            </div>
                        `;
                    }).join(``);

                    listParent.querySelectorAll('.table__container--wrapper')[0].innerHTML += html;
                });
            }
        }
    }
</script>
