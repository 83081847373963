<template>
    <div class="filters--wrapper">
        <div
            class="filters--copy"
            title="Copy filters' url"
            v-on:click="copyFilters()">
                <i class="flaticon2-copy"></i>
        </div>

        <form-field
            title="Show hidden"
            fieldtype="form-checkbox"
            inputname="q[show_hidden]"
            addclass="filter-target filter-hidden"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.show_hidden"
        ></form-field>

        <form-field
            title="Show hidden contacts"
            fieldtype="form-checkbox"
            inputname="q[show_hidden_contacts]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.show_hidden_contacts"
        ></form-field>

        <form-field
            title="Search term"
            fieldtype="form-input"
            inputname="q[term]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.term"
        ></form-field>
    
        <form-field
            title="Account manager"
            fieldtype="form-dropdown"
            inputname="q[account_manager_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.account_manager"
            searchurl="/autocomplete/staff"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <!-- Account owner SF -->
        <form-field
            title="Account owner (SF)"
            fieldtype="form-dropdown"
            inputname="q[salesforce_owner_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.salesforce_owner"
            searchurl="/autocomplete/salesforce-users"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Client"
            fieldtype="form-dropdown"
            inputname="q[is_client]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.is_client"
            v-bind:alloptions="formOptions.boolean_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Supplier"
            fieldtype="form-dropdown"
            inputname="q[is_supplier]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.is_supplier"
            v-bind:alloptions="formOptions.boolean_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Status"
            fieldtype="form-dropdown"
            inputname="q[status]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.status"
            v-bind:alloptions="formOptions.company_status_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Industry"
            fieldtype="form-dropdown"
            inputname="q[industry_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.industry_id"
            v-bind:alloptions="formOptions.industry_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="T&C's sent"
            fieldtype="form-dropdown"
            inputname="q[terms_conditions_sent]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.terms_conditions_sent"
            v-bind:alloptions="formOptions.terms_conditions_sent_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="T&C's signed"
            fieldtype="form-dropdown"
            inputname="q[terms_conditions_signed]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.terms_conditions_signed"
            v-bind:alloptions="formOptions.terms_conditions_signed_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            v-if="formOptions.show_feed_filter"
            title="Has a feed"
            fieldtype="form-dropdown"
            inputname="q[has_feed]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.has_feed"
            v-bind:alloptions="formOptions.boolean_options"
            addblank="true"
            blanktext="Any"
        ></form-field>
        

        <!-- 
            Adding 'filter-start-with' and 'js-filter-relocate' classes to below form-field element
            which are used to style and reposition the filter respectively 
        -->
        <form-field
            title="Start with"
            fieldtype="form-dropdown"
            inputname="q[start_with_letter]"
            addclass="filter-target filter-start-with js-filter-relocate"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.start_with_letter"
            v-bind:alloptions="formOptions.start_with_letter_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <div class="js-filter-hidden">
            <i class="flaticon-eye eye-green"><p>/</p></i>
            <div class="filter-info">Toggle show/hide hidden candidates</div>
        </div>

        <input type="hidden" name="sort_order" v-bind:value="appliedFilters.sort_order"/>
        <input type="hidden" name="sort_by" v-bind:value="appliedFilters.sort_by"/>
    </div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'

    export default {
        props: [
            'formOptionsData',
            'appliedFiltersData'
        ],
        data: function(){
            var appliedFilters = JSON.parse(this.appliedFiltersData || '{}');
            var formOptions = JSON.parse(this.formOptionsData || '{}');

            // console.log(appliedFilters);
            // console.log(formOptions);

            return { 
                formOptions: formOptions,
                appliedFilters: appliedFilters,
                requestCounter: 0
            };
        },
        components: {
            FormField
        },
        methods: {
            handleChange: function(event){ 
                this.requestCounter++;
                let localCounter = this.requestCounter;
                let waitTime = 1000;
                console.log(`before next tick ${this.requestCounter}, ${localCounter}`)
                this.$nextTick(() => { 
                    console.log(`after next tick ${this.requestCounter}, ${localCounter}`)
                    setTimeout(() => { 
                        console.log(`after timeout ${this.requestCounter}, ${localCounter}`)
                        if(localCounter == this.requestCounter){ $(`#company-filter-form`).submit() };
                    },waitTime);
                }) 
            },
            copyFilters: async function(){
                try {
                    await navigator.clipboard.writeText(window.location.href);
                    document.activeElement.blur();
                } catch (error) {
                    alert(`Error when copying to clipboard`, error)
                }
            }
        }
    }
</script>