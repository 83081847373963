<!--
    @created by Zheko Hristov on 01/11/2022
    @last-updated by Zheko Hristov on 13/03/2024
    This component represents the comments section
    It is used for showing comments in 
    sourcing tasks section in the notes component and
    in job request briefing page sourcing tasks overview section
-->
<template>
    <div class="comments__container--outer">
        <button 
            class="single-note__button--sourcing-task" 
            v-on:click="toggleComments">
            <i class="flaticon-comment"></i>
        </button>
        <div
            v-if="showComments"
            class="comments__container--modal"
            v-bind:class="{ 'show' : showModalContainerAfterResponseRecieved }">
            <div class="comments__container--inner">
                <div class="comments__container--top">
                    <h3>
                        Sourcing Task Comments<span v-if="jobrequestid">:</span>
                        <p v-if="jobRequestNumber.length > 0">
                            Request {{ jobRequestNumber }}
                        </p>
                        <p v-if="jobRequestTitle.length > 0">
                            {{ jobRequestTitle }}
                        </p> 
                    </h3>
                    <button 
                        class="single-note__button--sourcing-task" 
                        v-on:click="toggleComments">
                        <i class="flaticon-close"></i>
                    </button>
                    <div
                        v-if="jobrequestid && !showCommentNotFound" 
                        class="comments-top__container--filter">
                        <form-field
                            v-if="candidateTypeOptionsLoaded"
                            title="Filter by source"
                            fieldtype="form-dropdown"
                            inputname="candidate_type"
                            addblank="true"
                            blanktext="All"
                            startvalue="All"
                            v-bind:alloptions="candidateTypeOptions"
                            v-on:event-bubble="filterTasks"
                        ></form-field>
                    </div>
                </div>
                <div class="comments__container--middle">
                    <!-- Comments for the sourcing task section in the notes component -->
                    <p 
                        v-if="showCommentNotFound"
                        class="text--align-center">
                        No comments found!
                    </p>
                    <div
                        v-if="commentsList.length > 0"
                        class="single-task__container--top">
                        <p>
                            <strong> Source: </strong> <span> {{ sourcingTaskCandidateType }} </span>
                        </p>
                        <p>
                            <strong> Req. </strong> <span> {{ sourcingTaskRequestedCount }} </span>
                        </p>
                    </div>
                    <div> 
                        <div 
                            v-for="comment in commentsList" 
                            v-bind:key="comment.id" 
                            class="comment__container--single">
                            <div class="single-comment__container--top">
                                <p>
                                    <strong> Date: </strong> <span> {{ comment.created_at }} </span>
                                </p>
                                <p class="comment__text--first-message">
                                    <strong> Del. </strong> <span> {{ comment.delivered }} </span>
                                </p>
                                <p class="comment__text--second-message">
                                    <strong> App. </strong> <span> {{ comment.approved }} </span>
                                </p>
                                <p class="comment__text--second-message">
                                    <strong> Rej. </strong> <span> {{ comment.rejected }} </span>
                                </p>
                                <p class="comment__text--first-message">
                                    <strong> Sourcer: </strong> <span> {{ comment.staff.name }} </span>
                                </p>
                                <p class="comment__text--second-message">
                                    <strong> Recruiter: </strong> <span> {{ comment.staff.name }} </span>
                                </p>
                            </div>
                            <div class="single-comment_container--bottom">
                                <pre>{{ comment.comment }}</pre>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Comments for the sourcing tasks overview section in briefng page -->
                    <div
                        v-for="task in filteredSourcingTasksList"
                        v-bind:key="task.id"
                        class="comments__container--task-single">
                        <div class="single-task__container--top">
                            <p>
                                <strong> Date: </strong> <span> {{ task.created_at }} </span>
                            </p>
                            <p>
                                <strong> Source: </strong> <span> {{ task.candidate_type.name }} </span>
                            </p>
                            <p>
                                <strong> Req. </strong> <span> {{ task.requested }} </span>
                            </p>
                        </div>
                        <div>
                            <div 
                                v-for="comment in task.comments" 
                                v-bind:key="comment.id"
                                class="comment__container--single">
                                <div class="single-comment__container--top">
                                    <p>
                                        <strong> Date: </strong> <span> {{ comment.created_at }} </span>
                                    </p>
                                    <p class="comment__text--first-message">
                                        <strong> Del. </strong> <span> {{ comment.delivered }} </span>
                                    </p>
                                    <p class="comment__text--second-message">
                                        <strong> App. </strong> <span> {{ comment.approved }} </span>
                                    </p>
                                    <p class="comment__text--second-message">
                                        <strong> Rej. </strong> <span> {{ comment.rejected }} </span>
                                    </p>
                                    <p class="comment__text--first-message">
                                        <strong> Sourcer: </strong> <span> {{ comment.staff.name }} </span>
                                    </p>
                                    <p class="comment__text--second-message">
                                        <strong> Recruiter: </strong> <span> {{ comment.staff.name }} </span>
                                    </p>
                                </div>
                                <div class="single-comment_container--bottom">
                                    <pre>{{ comment.comment }}</pre>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="comments__container--bottom">
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Ajax from '../../form-field/sub-components/js/ajax.js';
    import FormField from '../../form-field/form-field.vue';

    export default {
        props: [
            'sourcingtaskid',
            'jobrequestid'
        ],
        data: function() {
            return {
                candidateTypeOptionsLoaded: false,
                candidateTypeOptions: [],
                commentsList: [],
                sourcingTasksList: [],
                showComments: false,
                sourcingTaskCandidateType: '',
                sourcingTaskRequestedCount: 0,
                jobRequestNumber: '',
                jobRequestTitle: '',
                jobRequestCandidateTypeName: '',
                showCommentNotFound: false,
                showModalContainerAfterResponseRecieved: false,
                responseRecieved: false,
                filteredSourcingTasksList: []
            }
        },
        mounted: function() {
            window.addEventListener('resize', this.calculateCommentsMiddleSectionMaxHeight);
        },
        updated: function() {
            this.calculateCommentsMiddleSectionMaxHeight();
        },
        methods: {
            toggleComments: function(){
                this.showComments = !this.showComments;
                if (this.showComments) {
                    this.renderComments();
                } else {
                    this.filteredSourcingTasksList = this.sourcingTasksList;
                }
            },
            renderComments: function() {
                if (!this.responseRecieved) {
                    if (this.sourcingtaskid) {
                        Ajax.request('GET',{},`/sourcing-tasks/${this.sourcingtaskid}`,(response) => {
                            this.showModalContainerAfterResponseRecieved = true;
                            this.responseRecieved = true;
                            if (JSON.parse(response).comments.length === 0) {
                                this.showCommentNotFound = true;
                            }
                            this.commentsList = JSON.parse(response).comments;
                            this.sourcingTaskCandidateType = JSON.parse(response).candidate_type.name;
                            this.sourcingTaskRequestedCount = JSON.parse(response).requested;
                        },this,(response) => {
                            console.log(`FAIL`,response);
                        });
                    }

                    if (this.jobrequestid) {
                        Ajax.request('GET',{},`/job_requests/${this.jobrequestid}/sourcing-tasks-comments`,(response) => {
                            this.showModalContainerAfterResponseRecieved = true;
                            this.responseRecieved = true;
                            if (JSON.parse(response).tasks.length === 0) {
                                this.showCommentNotFound = true;
                            }
                            this.candidateTypeOptions = JSON.parse(response).candidate_types;
                            this.sourcingTasksList = JSON.parse(response).tasks;
                            this.filteredSourcingTasksList = this.sourcingTasksList;
                            this.jobRequestNumber = JSON.parse(response).request_nr;
                            this.jobRequestTitle = JSON.parse(response).job_title;

                            this.candidateTypeOptionsLoaded = true;
                        },this,(response) => {
                            console.log(`FAIL`,response);
                        });
                    }
                }
            },
            filterTasks: function(event) {
                const selectedCandidateTypeName = event.data.name;

                this.filteredSourcingTasksList = 
                    selectedCandidateTypeName === 'All' 
                        ? this.sourcingTasksList 
                        : this.sourcingTasksList.filter(task => task.candidate_type.name === selectedCandidateTypeName);
            
                this.$el.querySelector('.comments__container--middle').scrollTop = 0;
            },
            calculateCommentsMiddleSectionMaxHeight: function() {
                if (!this.showComments) return;
                
                const commentsComponentContainer = this.$el;
                const commentsContainerOuter = commentsComponentContainer.querySelector('.comments__container--modal');
                const commentsContainerInner = commentsComponentContainer.querySelector('.comments__container--inner');
                const commentsContainerTop = commentsComponentContainer.querySelector('.comments__container--top');
                const commentsContainerBottom = commentsComponentContainer.querySelector('.comments__container--bottom');
                const commentsContainerMiddle = commentsComponentContainer.querySelector('.comments__container--middle');

                const commentsContainerOuterStyles = window.getComputedStyle(commentsContainerOuter);
                const commentsContainerInnerStyles = window.getComputedStyle(commentsContainerInner);
                const commentsContainerTopStyles = window.getComputedStyle(commentsContainerTop);
                const commentsContainerBottomStyles = window.getComputedStyle(commentsContainerBottom);

                // buffer zone between the inner and the outer container
                const topBufferZone = 20;
                let calculatedMaxHeight = 0;

                calculatedMaxHeight = 
                    parseFloat(commentsContainerOuterStyles['height']) - 
                    (
                        (topBufferZone * 2) + 
                        (parseFloat(commentsContainerInnerStyles['padding-top']) * 2) +
                        parseFloat(commentsContainerTopStyles['height']) +
                        parseFloat(commentsContainerTopStyles['padding-bottom']) +
                        parseFloat(commentsContainerTopStyles['border-bottom-width']) +
                        parseFloat(commentsContainerBottomStyles['height']) +
                        parseFloat(commentsContainerBottomStyles['padding-top']) +
                        parseFloat(commentsContainerBottomStyles['border-top-width'])
                    );

                commentsContainerMiddle.style.maxHeight = (calculatedMaxHeight / 10) + 'rem';
            }
        },
        components: { 
            Ajax,
            FormField
        }
    };

</script>
