<template>
    <div class="profile__input--wrapper">
        <input 
            type="file" 
            v-bind:name="inputname" 
            v-bind:id="fieldId"
            v-on:change="updateCustomInputFileText"
            accept=".doc,.docx,.rtf,.txt,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
        <span 
            class="file-input__container--custom"
            v-on:click="triggerInputFile">
            <i class="flaticon2-file-1"></i>
            <span class="file-input__text--file-name"> Choose File </span>
        </span>
        <div v-if="haserrors" class="login-form__warning--icon" v-bind:title="haserrors[0]"><p>!</p></div>
    </div>
</template>

<script>

    export default {
        props: [
            'inputname',
			'inputid',
            'haserrors'
        ],
        data: function(){
			return {
				fieldId: this.inputid
			}
		},
        methods: {
            triggerInputFile: function() {
                this.$el.querySelector('input[type="file"').click();
            },
            updateCustomInputFileText: function() {
                const thisComponentElement = this.$el;
                const customInputFileContainer = thisComponentElement.querySelector('.file-input__container--custom');
                const inputFileElement = thisComponentElement.querySelector('input[type="file"');
                const customInputFileText = customInputFileContainer.querySelector('.file-input__text--file-name');
                const fileName = inputFileElement.files[0].name;

                // for now show the full name
                // customInputFileText.innerText = this.modifyFileName(fileName);
                customInputFileText.innerText = fileName;
            },
            modifyFileName: function(name) {
                const maxNameLength = 10;
                let modifiedFileName = '';
                let modifiedFileFullName = '';

                const fileNameWithoutExtension = name.split('.')[0];
                const fileNameExtension = name.split('.')[1];

                if (fileNameWithoutExtension.length >= maxNameLength) {
                   modifiedFileName = fileNameWithoutExtension.slice(0, maxNameLength) + '...';
                } else {
                    modifiedFileName = fileNameWithoutExtension
                }

                modifiedFileFullName = modifiedFileName + '.' + fileNameExtension;

                return modifiedFileFullName;
            }
        }
    };

</script>