<template>
    <div class="filters--wrapper">
        <div
            class="filters--copy"
            title="Copy filters' url"
            v-on:click="copyFilters()">
                <i class="flaticon2-copy"></i>
        </div>

        <form-field
            title="Status"
            fieldtype="form-dropdown"
            inputname="q[status]"
            addclass="filter-target filter-location-bl"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.status"
            v-bind:alloptions="formOptions.status_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Queue"
            fieldtype="form-dropdown"
            inputname="q[queue]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.queue"
            v-bind:alloptions="formOptions.queue_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <input type="hidden" name="sort_order" v-bind:value="appliedFilters.sort_order"/>
        <input type="hidden" name="sort_by" v-bind:value="appliedFilters.sort_by"/>
    </div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'

    export default {
        props: [
            'formOptionsData',
            'appliedFiltersData'
        ],
        data: function(){
            var appliedFilters = JSON.parse(this.appliedFiltersData || '{}');
            var formOptions = JSON.parse(this.formOptionsData || '{}');

            // console.log(appliedFilters);
            // console.log(formOptions);

            return { 
                formOptions: formOptions,
                appliedFilters: appliedFilters,
                requestCounter: 0
            };
        },
        components: {
            FormField
        },
        methods: {
            handleChange: function(event){ 
                this.requestCounter++;
                let localCounter = this.requestCounter;
                let waitTime = 1000;
                this.$nextTick(() => { 
                    setTimeout(() => { if(localCounter == this.requestCounter){ $(`#system-jobs-filter-form`).submit() } },waitTime);
                });
            },
            copyFilters: async function(){
                try {
                    await navigator.clipboard.writeText(window.location.href);
                    document.activeElement.blur();
                } catch (error) {
                    alert(`Error when copying to clipboard`, error)
                }
            }
        }
    }
</script>