<template>

    <div class="dossier-modal__wrapper" v-bind:class="{
            'open': isOpen
        }">
        <i class="flaticon2-plus open-icon" v-on:click="toggleModal" v-bind:class="{'hide': isOpen}"></i>
        <div class="dossier-modal__container" v-bind:class="{
            'open': isOpen }">
            <div class="dossier-modal__header">
                <h5>New candidate</h5>
                <i class="flaticon2-delete" v-on:click="toggleModal"></i>
            </div>

            <div class="dossier-modal__container--inputs">
                <form-field
                    title="Account Type"
                    fieldtype="form-dropdown"
                    inputname="candidate[candidate_type_id]"
                    v-bind:startvalue="candidate.candidate_type"
                    v-bind:alloptions="formOptions.candidate_type_options"
                    v-bind:haserrors="candidate.errors.candidate_type"
                ></form-field>

                <form-field
                    title="Online Profile URL"
                    fieldtype="form-input"
                    inputname="candidate[online_profile_url]"
                    v-bind:startvalue="candidate.online_profile_url"
                    v-on:event-bubble="validateUniquness"
                    v-bind:haserrors="candidate.errors.online_profile_url"
                ></form-field>

                <form-field
                    title="E-Mail"
                    fieldtype="form-input"
                    inputname="candidate[email_home]"
                    v-bind:startvalue="candidate.email_home"
                    v-on:event-bubble="validateUniquness"
                    v-bind:haserrors="candidate.errors.email_home"
                ></form-field>

                <form-field
                    title="Tel. Mobile"
                    fieldtype="form-input"
                    inputname="candidate[phone_mobile]"
                    v-bind:startvalue="candidate.phone_mobile"
                    v-on:event-bubble="validateUniquness"
                    v-bind:haserrors="candidate.errors.phone_mobile"
                ></form-field>

                <form-field
                    title="First name"
                    fieldtype="form-input"
                    inputname="candidate[first_name]"
                    v-bind:startvalue="candidate.first_name"
                    v-bind:haserrors="candidate.errors.first_name"
                ></form-field>

                <form-field
                    title="Last name preposition"
                    fieldtype="form-input"
                    inputname="candidate[last_name_preposition]"
                    v-bind:startvalue="candidate.last_name_preposition"
                    v-bind:haserrors="candidate.errors.last_name_preposition"
                ></form-field>

                <form-field
                    title="Last name"
                    fieldtype="form-input"
                    inputname="candidate[last_name]"
                    v-bind:startvalue="candidate.last_name"
                    v-bind:haserrors="candidate.errors.last_name"
                ></form-field>

                <!-- CV file upload - the file is not sent -->
                <!-- <label for="candidate_cv">CV</label>
                <input type="file" name="b_cv" id="candidate_cv" accept=".doc,.docx,.rtf,.txt,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"> -->
                <!-- Errors for CV ?-->
                <!-- <div class="profile__container--field form-input">
                    <label for="candidate_cv">
                        CV:
                    </label>
                    <input type="file" name="b_cv" id="candidate_cv" accept=".doc,.docx,.rtf,.txt,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                </div> -->

                <form-field
                    title="CV"
                    fieldtype="form-file-upload"
                    inputname="candidate[b_cv]"
                    v-bind:haserrors="candidate.errors.b_cv"
                ></form-field> 

                <!-- Trows exception -->
                <form-field
                    title="Internal admission notes"
                    fieldtype="form-textarea"
                    inputname="candidate[int_admission_notes]"
                    v-bind:startvalue="candidate.int_admission_notes"
                    v-bind:haserrors="candidate.errors.int_admission_notes"
                ></form-field> 
                

                <!-- Sends param as: candidate[job_request_ids_]:  -->
                <form-field
                    title="Job Requests"
                    fieldtype="form-multidrop"
                    preload="0"
                    searchurl="/autocomplete/job-requests/open"
                    inputname="candidate[job_request_ids][]"
                    v-bind:startvalue="jobRequests"
                    v-bind:haserrors="candidate.errors.job_request_ids"
                ></form-field>

    <!--             <form-field
                    v-if="jobRequestsLoaded"
                    title="Job Request"
                    fieldtype="form-multidrop"
                    preload="0"
                    v-bind:searchurl="searchUrl"
                    v-bind:inputname="`application[job_request_${selectedCandidate.id}]`"
                    v-bind:startvalue="jobRequestSelected"
                    v-bind:alloptions="jobRequestOptions"
                    v-on:event-bubble="updateJobRequest"
                ></form-field>
    -->

                <form-field
                    title="Blue Lynx location"
                    fieldtype="form-dropdown"
                    inputname="candidate[bluelynx_location_id]"
                    v-bind:startvalue="candidate.bluelynx_location"
                    v-bind:alloptions="formOptions.bluelynx_locations"
                    v-bind:haserrors="candidate.errors.bluelynx_location"
                ></form-field>
            </div>

            <button 
                type="submit" 
                name="commit" 
                value="Save Candidate" 
                data-disable-with="submitting..."
                class="update-button"
                v-on:click="createCandidate">
                    Save
            </button>
            <div class="note-cover"></div>
        </div>
    </div>

</template>

<script>
    import Ajax from '../../form-field/sub-components/js/ajax.js';
    import FormField from '../../form-field/form-field.vue';

    export default {
        props: [
            'jobRequest',
            'candidateData',
            'formOptionsData'
        ],
        data: function(){
            var candidate = JSON.parse(this.candidateData || '{"errors": "{}"}');
            var formOptions = JSON.parse(this.formOptionsData || '{}');
            let jobRequests = [];
            console.log('Job Request id:')
            console.log(this.jobRequest);
            if(this.jobRequest != undefined){
                jobRequests.push(JSON.parse(this.jobRequest));
            }
            console.log(jobRequests)
            return {
                candidate: candidate || {}, 
                formOptions: formOptions || {},
                jobRequests: jobRequests,
                isOpen: false,
                debounceTimeOut: null,
            }
        },
        methods: {
            toggleModal: function(){
                this.isOpen = !this.isOpen;
            },
            createCandidate: function(event){
                event.preventDefault();
                event.stopPropagation();

                const candidateObjectFromFields = this.getDataFromFields();
                const formData = new FormData();

                // append properties to the formData object
                formData.append('authenticity_token', $(`meta[name=csrf-token]`)[0].content);
                let jobRequestsIdsArray = [];
                for (let key in candidateObjectFromFields ) {
                    if (Array.isArray(candidateObjectFromFields[key])) {
                        jobRequestsIdsArray = candidateObjectFromFields[key];
                        for (let i = 0; i < jobRequestsIdsArray.length; i++) {
                            formData.append(key, jobRequestsIdsArray[i]);
                        }
                    } else {
                        formData.append(key, candidateObjectFromFields[key]);
                    }
                }
                
                Ajax.sendRequest('POST',formData,'/candidates/quick-create',(Response,error) => {
                    if(error){
                        console.log(`ERRORS!`,Response);
                        this.candidate.errors = Response.responseJSON.candidate_errors;
                        this.$nextTick(() => {
                            console.log(candidateObjectFromFields);
                            this.setFieldsFromData(candidateObjectFromFields);
                            if(Response.status == 500){
                                window.alert(`Something went wrong when creating the account! ${Response.responseText}`);
                            };
                        });
                    }else{
                        window.location.reload();
                    };
                },this,{ dataType: 'json', contentType: false, processData: false });
            },
            getDataFromFields: function(){
                const Data = {};
                Array.from(this.$el.querySelectorAll('.profile__container--field')).forEach((field) => {
                    const fv = field.__vue__;
                    switch(fv.fieldtype){
                        case 'form-dropdown':
                            Data[fv.inputname] = fv.$children[0].selectedOption.id
                            break;
                        case 'form-textarea': 
                            Data[fv.inputname] = field.querySelector('textarea').value;
                            break;
                        case 'form-file-upload':
                            Data[fv.inputname] = field.querySelector('input').files[0] || '';
                            break;
                        case 'form-multidrop':
                            Data[fv.inputname] = fv.$children[0].selectValue.map(selectedValueObject => selectedValueObject.id)
                            break;
                        default:
                            Data[fv.inputname] = field.querySelector('input').value;
                    };
                });
                
                return Data;
            },
            setFieldsFromData: function(Data){
                Array.from(this.$el.querySelectorAll('.profile__container--field')).forEach((field) => {
                    const fv = field.__vue__;
                    switch(fv.fieldtype){
                        case 'form-dropdown':
                            const option = fv.$children[0].dropdownOptions.filter((opt) => {
                                return opt.id == Data[fv.inputname]
                            })[0];
                            fv.$children[0].selectOption(option);
                            break;
                        case 'form-textarea': 
                            field.querySelector('textarea').value = Data[fv.inputname];
                            break;
                        case 'form-input':
                            field.querySelector('input').value = Data[fv.inputname];
                            break;
                        default:
                            console.log('Not provided field');
                    };
                });
            },
            validateUniquness: function(event) {
                const checkingValue = event.data;
                const inputElement = event.element;
                let fieldType;
                switch (event.title.toLowerCase()) {
                    case 'online profile url':
                        fieldType = 'online_profile_url';
                        break;
                    case 'e-mail':
                        fieldType = 'email';
                        break;
                    case 'tel. mobile':
                        fieldType = 'phone_mobile';
                        break;
                    default:
                        fieldType = '';
                }

                if (checkingValue.length > 0) {
                    window.clearTimeout(this.debounceTimeOut);
                    this.debounceTimeOut = window.setTimeout(function() {
        
                        $.ajax({
                            type: 'POST',
                            url: '/candidates/validate-field-uniquness',
                            data: {
                                field: fieldType,
                                value: checkingValue
                            }
                        })
                        .done(function (data) {
                            if (data.unique === false) {
                                if (inputElement.find('.warning__container--not-unique').length < 1) {
                                    inputElement.append('<span class="warning__container--not-unique"> <i class="flaticon2-warning"></i>The field value is not unique! </span>');
                                }
                            } else {
                                inputElement.find('.warning__container--not-unique').remove();
                            }
                        })
                        .fail(function (xhr, status, error) {
                            console.log(xhr);
                            console.log(error);
                        });
                    }, 1000);
                }
            }
        },
        components: {
            FormField
        }
    }
</script>