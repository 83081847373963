<template>
    <div class="profile__input--wrapper field-checkbox" v-on:mouseenter="handleMouseEnter" v-on:mouseleave="handleMouseLeave">
    	<input type="hidden" v-bind:name="inputname" value="0">
    	<input 
			v-bind:disabled="isdisabled"
			type="checkbox" 
			v-bind:id="fieldId"
			v-bind:name="inputname"  
			v-model="value"  
			true-value="1" 
			false-value="0" 
			class="form__input--checkbox form-data-target">
		<div v-if="haserrors" class="login-form__warning--icon" v-bind:title="haserrors[0]"><p>!</p></div>
		<!-- <div 
			v-if="haserrors"
			class="error-explanation">
				{{ haserrors[0] }}
		</div> -->
    </div>
</template>

<script>
    export default {
        props: [
			'inputname',
			'inputid',
            'startvalue',
			'haserrors',
			'isdisabled'
        ],
		data: function(){
			var value = '0';
			if(this.startvalue == true || this.startvalue == '1' || this.startvalue == 'true' || this.startvalue == 'on'){
				value = '1';
			}

			return {
				fieldId: this.inputid,
				value: value,
				hoverState: false
			}
		},
		watch: {
			value: function(newValue,oldValue){ this.$emit('event-bubble',{ data: this.value }) }
		},
		methods: {
			handleMouseEnter: function(event){ this.hoverState = true; this.updateLabelClass() },
			handleMouseLeave: function(event){ this.hoverState = false; this.updateLabelClass() },
			updateLabelClass: function(){
				let fieldLabel = $(this.$parent.$el).find(`label`);
				$(fieldLabel).removeClass(`mark-tabFocus`);
				if(this.hoverState){ $(fieldLabel).addClass(`mark-tabFocus`) };
			}
		}
    }
</script>