<template>
	<div class="profile__input--wrapper">
		<div class="form-number__buttons">
			<div class="numberUp" v-on:mousedown="setIncrementFlag" v-on:mouseup="setIncrementFlag" v-on:mouseout="setIncrementFlag">+</div>
			<div class="numberDown" v-on:mousedown="setIncrementFlag" v-on:mouseup="setIncrementFlag" v-on:mouseout="setIncrementFlag">-</div>
		</div>
		<input 
            v-bind:disabled="isdisabled"
			v-bind:name="inputname" 
			type="number" 
			class="form__input--number form-data-target" 
            v-bind:min="alloptions.min"
            v-bind:max="alloptions.max"
            v-bind:step="alloptions.step"
			v-model="numberValue"
            v-bind:id="fieldId">
		<div v-if="haserrors" class="login-form__warning--icon" v-bind:title="haserrors[0]"><p>!</p></div>
		<!-- <div 
			v-if="haserrors"
			class="error-explanation">
				{{ haserrors[0] }}
		</div> -->
	</div>
</template>

<script>
    export default {
        props: [
			'inputname',
			'inputid',
            'startvalue',
            'alloptions',
			'haserrors',
			'isdisabled'
        ],
		data: function(){
			return {
				numberValue: this.startvalue,
				incrementFlag: false,
				fieldId: this.inputid
			};
		},
		watch: {
			numberValue: function(newVal,oldVal){ 
				this.limit(); 
				if(!this.incrementFlag){ this.$emit('event-bubble',{ data: this.numberValue }) };
			}
		},
		methods: {
			increment: function(buttonClass){
				if(this.incrementFlag){
					switch(buttonClass){
						case 'numberUp':
							this.numberValue++;
							break;
						case 'numberDown':
							this.numberValue--;
							break;
						default: 
							console.log(`invalid increment button class`);
							break;
					};
					setTimeout(() => { this.increment(buttonClass) }, 100);
				}else{
					this.$emit('event-bubble',{ data: this.numberValue });
				};
			},
			setIncrementFlag: function(event){
				switch(event.type){
					case 'mousedown':
						this.incrementFlag = true;
						break;
					case 'mouseup':
					case 'mouseout':
						this.incrementFlag = false;
						break;
					default:
						console.log(`invalid set increment event type`);
						break;
				};
				if(this.incrementFlag){ this.increment(event.target.className) };
			},
			limit: function(){
				if(this.numberValue > this.alloptions.max){ this.numberValue = this.alloptions.max };
				if(this.numberValue < this.alloptions.min){ this.numberValue = this.alloptions.min };
			}
		}
    }
</script>