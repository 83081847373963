<template>
    <form v-bind:action="submitUrl" accept-charset="UTF-8" method="post">

        <input type="hidden" name="_method" value="delete">
        <input type="hidden" name="authenticity_token" v-bind:value="formToken">
   
        <div class="profile__container--wrapper profile__container--columns-2">
            <div class="profile__container--outer candidate-details sectioned">
                <div class="profile__container--header">
                    <h3>Delete &amp; Replace</h3>
                </div>
                <div class="profile__container--main">
                    <div class="form-hint">{{ hintText }}</div>

                    <form-field
                        title="Replace with"
                        fieldtype="form-dropdown"
                        inputname="replacement_id"
                        v-bind:startvalue="replacementId"
                        v-bind:alloptions="replacementOptions.list"
                        v-bind:searchurl="replacementOptions.autocomplete_url"
                        v-bind:preload="replacementOptions.autocomplete_url != null ? '1' : '0'"
                        v-on:event-bubble="replacementSelected"

                    ></form-field>

                    <button 
                        type="submit" 
                        class="submit-button danger" 
                        v-bind:disabled="submitIsDisabled"
                        data-disable-with="submitting"
                        v-bind:data-confirm="submitConfirmText"
                    > 
                        Delete & Replace
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'

    export default {
        props: [
            'options'
        ],
        data: function(){
            let defaultHintText = "This will update all related records with the replacement, delete all related records that already have the replacement and delete the record";

            let defaultSubmitConfirmText = "Are you sure?";

            return {  
                recordId: this.options.record_id,
                replacementId: null, 
                replacementOptions: this.options.replacement_options, 
                submitUrl: this.options.submit_url, 
                formToken: this.options.form_token,
                hintText: this.options.hint_text || defaultHintText,
                submitConfirmText: this.options.submit_confirm_text || defaultSubmitConfirmText
            };
        },
        components: {
            FormField
        },
        methods: {
            replacementSelected: function(event){
                let selected = event.data.id;
                if(selected != this.recordId){
                    this.replacementId = selected;
                }else{
                    alert("You need to choose another record!");
                    this.replacementId = null;
                }
            }
        },
        computed: {
          submitIsDisabled() {
            // evaluate whatever you need to determine disabled here...
            return this.replacementId == null;
          }
        }
    }
</script>

