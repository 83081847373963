<template>
    <div class="notes__container--outer" v-bind:class="{ 'notes__dashboard': isDashboard  }">
        <div v-if="isDashboard" class="notes__container--header notes__container--header-dashboard">
            <button
                v-if="showHiddenNotes"
                v-on:click="bulkShowHideNotes('show')"
                class="notes__button--bulk-hide-show">
                Bulk show
            </button>
            <button
                v-if="!showHiddenNotes"
                v-on:click="bulkShowHideNotes('hide')"
                class="notes__button--bulk-hide-show">
                Bulk hide
            </button>
            <h3>DASHBOARD NOTES ({{ Pagination.total_count }} found)</h3>
        </div>

        <form id="js-dashboard-filters" class="notes__container--filters" v-if="typefilter">
            <div
                class="filters--copy"
                title="Copy filters' url"
                v-on:click="copyFilters()">
                    <i class="flaticon2-copy"></i>
            </div>
            
            <form-field
                title="Show hidden"
                fieldtype="form-checkbox"
                inputname="show_hidden"
                addclass="dashboard--show-hidden filter-target filter-hidden"
                v-bind:startvalue="0"
                v-on:event-bubble="applyFilters"
            ></form-field>

            <div class="js-filter-hidden dashboard--show-hidden profile__container--field">
                <i class="flaticon-eye eye-green eye-crossed"><p>/</p></i>
                <div class="filter-info">Toggle show/hide hidden notes</div>
            </div>

            <form-field
                fieldtype="form-dropdown"
                title="Priority"
                startvalue="any"
                inputname="priority"
                addclass="notes__filter filter-target"
                v-bind:alloptions="[
                    {name: 'any', id: null},
                    {name: 'High', id: 1},
                    {name: 'Normal', id: 2},
                    {name: 'Low', id: 3}  
                ]"
                v-on:event-bubble="applyFilters"
            ></form-field>

            <form-field
                title="Staff list"
                fieldtype="form-dropdown"
                inputname="staff_list_id"
                addclass="notes__filter filter-target"
                addblank="true"
                blanktext="any"
                startvalue="any"
                searchurl="/autocomplete/all-staff-lists"
                v-on:event-bubble="applyFilters"
            ></form-field>

            <form-field
                title="Task assignee"
                fieldtype="form-dropdown"
                inputname="assigned_staff_id"
                addclass="notes__filter filter-target"
                addblank="true"
                blanktext="any"
                startvalue="any"
                searchurl="/autocomplete/staff"
                v-on:event-bubble="applyFilters"
            ></form-field>

            <form-field
                fieldtype="form-dropdown"
                title="Record"
                startvalue="all"
                inputname="record_type"
                addclass="notes__filter filter-target"
                v-bind:alloptions="[
                    { 'name': 'all', 'id': null },
                    { 'name': 'Candidate', 'id': 'candidate' },
                    { 'name': 'Company', 'id': 'company' },
                    { 'name': 'Company contact', 'id': 'company_contact' },
                    { 'name': 'Job request', 'id': 'job_request' },
                    { 'name': 'Job dossier', 'id': 'job_request_candidate_status_change' }
                ]"
                v-on:event-bubble="applyFilters"
            ></form-field>

            <form-field
                fieldtype="form-dropdown"
                title="Type"
                startvalue="all"
                inputname="note_type"
                addclass="notes__filter filter-target"
                v-bind:alloptions="[
                    { 'name': 'all', 'id': null },
                    { 'name': 'Note', 'id': 'note' },
                    { 'name': 'Task', 'id': 'task' },
                    { 'name': 'Source', 'id': 'sourcing_task' }
                ]"
                v-on:event-bubble="applyFilters"
            ></form-field>

            <form-field
                fieldtype="form-dropdown"
                title="Overdue"
                inputname="overdue"
                addclass="dashboard--overdue notes__filter filter-target"
                startvalue="all"
                v-bind:alloptions="[
                    { 'name': 'all', 'id': null },
                    { 'name': 'Yes', 'id': 'yes' },
                    { 'name': 'No', 'id': 'no' }
                ]"
                v-on:event-bubble="applyFilters"
            ></form-field>

            <form-field
                fieldtype="form-dropdown"
                title="Status"
                startvalue="all"
                inputname="status"
                addclass="notes__filter filter-target"
                v-bind:alloptions="[
                    { 'name': 'all', 'id': null },
                    { 'name': 'Open', 'id': 'open' },
                    { 'name': 'Done', 'id': 'done'}
                ]"
                v-on:event-bubble="applyFilters"
            ></form-field>

            <form-field
                fieldtype="form-dropdown"
                title="ASAP"
                startvalue="all"
                inputname="is_asap"
                addclass="notes__filter filter-target"
                v-bind:alloptions="[
                    { 'name': 'all', 'id': null },
                    { 'name': 'Yes', 'id': 'true' },
                    { 'name': 'No', 'id': 'false' }
                ]"
                v-on:event-bubble="applyFilters"
            ></form-field>

            <form-field
                fieldtype="form-dropdown"
                startvalue="20"
                inputname="per_page"
                addclass="dropdown__container--display-none"
                v-bind:alloptions="[
                    { 'name': '10', 'id': '10' },
                    { 'name': '20', 'id': '20' },
                    { 'name': '50', 'id': '50' },
                    { 'name': '100', 'id': '100' }
                ]"
                v-on:event-bubble="applyFilters"
            ></form-field>
        </form>

        <div v-if="!noeditor" id="ck-note--wrapper">
            <textarea id="ck-note"></textarea>
        </div>

        <div class="notes__table--scroll-wrap">
            <notes-table
                v-bind:isstatus="isstatus"
                v-bind:statusdata="statusdata"
                v-bind:viewer="viewer"
                v-bind:notes="notes"
                v-bind:urlnotes="notesURL"
                v-bind:canaddnote="canaddnote"
                v-bind:addnotedata="addnotedata"
                v-bind:addnoterecordid="addnoterecordid"
                v-bind:isdashboard="isDashboard"
                v-bind:candidatetypeoptions="candidatetypeoptions"
            ></notes-table>

            <div v-if="pageIsLoading" class="table-loader">
                <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </div>

        <div v-if="isDashboard && !pageIsLoading && totalPages > 1" class="table__pagination--wrapper">
            <nav role="navigation" aria-label="pager" class="pagination">
                <span v-if="currentPage > 2" class="first" v-on:click="setPage(1)">«</span>
                <span v-if="currentPage > 1" class="prev" v-on:click="setPage(currentPage - 1)">‹</span>

                <span v-if="currentPage > 3" class="page gap">...</span>

                <span v-if="(currentPage - 2) > 0" class="page" v-on:click="setPage(currentPage - 2)">{{ currentPage - 2 }}</span>
                <span v-if="(currentPage - 1) > 0" class="page" v-on:click="setPage(currentPage - 1)">{{ currentPage - 1 }}</span>

                <span class="page current">{{ currentPage }}</span>

                <span v-if="currentPage < totalPages" class="page" v-on:click="setPage(currentPage + 1)">{{ currentPage + 1 }}</span>
                <span v-if="(currentPage + 1) < totalPages" class="page" v-on:click="setPage(currentPage + 2)">{{ currentPage + 2 }}</span>

                <span v-if="(currentPage + 2) < totalPages" class="page gap">...</span>

                <span v-if="currentPage < totalPages" class="next" v-on:click="setPage(currentPage + 1)">›</span>
                <span v-if="currentPage < (totalPages - 1)" class="last" v-on:click="setPage(totalPages)">»</span>
            </nav>
            <div class="pagination-options">
                <div class="pagination-options__page">
                    <form-field
                        fieldtype="form-dropdown"
                        v-bind:startvalue="perPage"
                        inputname="per_page"
                        addclass="notes__filter filter-target"
                        v-bind:alloptions="[
                            { 'name': '10', 'id': '10' },
                            { 'name': '20', 'id': '20' },
                            { 'name': '50', 'id': '50' },
                            { 'name': '100', 'id': '100' }
                        ]"
                        v-on:event-bubble="applyPerPage"
                    ></form-field>
                </div>
                <div class="pagination-options__showing">
                    Showing {{ Pagination.offset + 1 }} - {{ Pagination.offset + Pagination.count }} of {{ Pagination.total_count }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Ajax from '../form-field/sub-components/js/ajax.js'
    import FormField from '../form-field/form-field.vue'
    import NotesTable from './shared/notes-table.vue'

    export default {
        props: [
            'isstatus',
            'statusdata',
            'viewer',
            'typefilter',
            'dashfilters',
            'notes',
            'urlnotes',
            'filternotes',
            'canaddnote',
            'addnotedata',
            'addnoterecordid',
            'noeditor',
            'candidatetypeoptions'
        ],
        data: function(){ 
            return {
                tableVueInstance: '',
                filters: this.dashfilters || {},
                notesURL: this.urlnotes,
                isDashboard: this.urlnotes.indexOf('dashboard') != -1,
                showDashboardHidden: false,
                eventThrottle: 0,
                pageIsLoading: this.isDashboard,
                Pagination: {},
                currentPage: 1,
                lastPage: 1,
                totalPages: 0,
                perPage: '20',
                showHiddenNotes: false
            } 
        },
        mounted: function(){
            this.$nextTick(() => { this.setTableInstance() });
            if(Object.keys(this.filters).length > 2){
                Object.keys(this.filters).forEach((filterKey) => {
                    let filterField = $(`#${filterKey}`).parents(`.profile__container--field`)[0];
                    if(filterField){
                        let filterVue = filterField.__vue__.$children[0];
                        switch(filterField.__vue__.fieldtype){
                            case 'form-dropdown':
                                filterVue.dropdownOptions.forEach((Option) => {
                                    if(Option.id === this.filters[filterKey]){ 
                                        filterVue.selectOption(Option);
                                        if(filterKey == 'per_page'){ this.perPage = Option.id };
                                    };
                                }); 
                                break;
                            case 'form-checkbox':
                                let val = 0;
                                if(this.filters[filterKey] == 'on'){ val = 1 };
                                filterVue.value = val;
                                break;
                            default:
                                break;
                        };
                    };

                    if(filterKey == 'page'){ 
                        if(Number(this.filters[filterKey]) > 0){
                            this.lastPage = this.currentPage;
                            this.currentPage = Number(this.filters[filterKey]);
                        }else{
                            this.currentPage = 1;
                        };
                    };
                });
                if(Object.keys(this.filters).length <= 3){
                    this.$nextTick(() => { this.getFilteredNotes() });
                };
            }else{
                this.$nextTick(() => { this.getNotes() });
            };
            if(this.isDashboard){ this.pageIsLoading = true; window.addEventListener('resize',(event) => { this.fitLists() }) };
        },
        components: { NotesTable, FormField },
        methods:{ 
            applyFilters: function(event){
                this.eventThrottle++;
                let currentThrottle = this.eventThrottle;
                setTimeout(() => { if(this.eventThrottle == currentThrottle){ this.getFilteredNotes() } }, 1000);
            },
            getNotes: function(){
                this.tableVueInstance.notesArray = [];
                this.tableVueInstance.updateNotes((Response) => {
                    this.updateTableData(Response);
                });
            },
            getFilteredNotes: function(){
                this.currentPage = 1;
                let serialData = $(`#js-dashboard-filters`).serialize();
                this.notesURL = `/dashboard/notes?${serialData}`;

                if(this.notesURL.indexOf('&page=') == -1){ this.notesURL = `${this.notesURL}&page=${this.currentPage}` };

                history.replaceState(history.state,'',this.notesURL.replace('dashboard/notes',''));

                this.pageIsLoading = true;
                this.tableVueInstance.notesArray = [];
                this.tableVueInstance.$nextTick(() => {
                    this.tableVueInstance.updateNotes((Response) => {
                        this.updateTableData(Response);

                        // 13/02/2023 - toggle bulk show/bulk hide button
                        this.showHiddenNotes = Boolean(parseInt(this.$children[0].$children[0].value));
                        // // 13/02/2023 - clear note ids array for bulk show/hide option
                        // if (this.tableVueInstance.notesIdsArray.length > 0) {
                        //     this.tableVueInstance.notesIdsArray = [];
                        // }
                    });
                });
            },
            setTableInstance: function(){
                let readyFlag = false;
                this.$children.forEach((VueChild) => {
                    if(!readyFlag && VueChild.$el.className.indexOf('notes__container--inner') != -1){
                        this.tableVueInstance = VueChild;
                        readyFlag = true;
                    };
                });
            },
            updateTableData: function(Data){
                this.pageIsLoading = false;

                this.tableVueInstance.notesArray = Data.results;
                this.tableVueInstance.initializeArrayKeys();

                if(Data.pagination){
                    this.Pagination = Data.pagination;
                    this.lastPage = this.currentPage;
                    this.currentPage = Data.pagination.current_page;
                    this.totalPages = Math.ceil(Data.pagination.total_count/Data.pagination.per_page);
                };

                if(this.isDashboard){ 
                    this.$nextTick(() => { 
                        this.fitTable(); 
                        this.fitLists(); 
                        console.log(`DASHBOARD DATA SET - IS FIXED - ${$(this.$el).find(`.dashboard-list--fixed`).length}`)
                        if($(this.$el).find(`.dashboard-list--fixed`).length == 0){ window.scrollTo(0,0) };
                    }); 
                };

                if(this.$parent.$el.className.indexOf('notes-menu__wrapper') != -1){
                    this.$parent.notesCount = Data.results.length;
                };
            },
            setPage: function(toPage){ 
                if(!this.pageIsLoading){ 
                    this.lastPage = this.currentPage;
                    this.currentPage = toPage; 
                    this.getNewPage(false); 
                } 
            },
            getNewPage: function(fromCreation){
                let getURLOptions = '';
                let currentURL = this.notesURL;

                let searchURLIndex = currentURL.indexOf('?');
                if(searchURLIndex != -1){
                    if(currentURL.indexOf(`?page=`) != -1){
                        currentURL = `${currentURL.replace(`?page=${this.lastPage}`,`?page=${this.currentPage}`)}`;
                    }else if(currentURL.indexOf(`&page=`) != -1){
                        currentURL = `${currentURL.replace(`&page=${this.lastPage}`,`&page=${this.currentPage}`)}`;
                    }else{
                        currentURL = `${currentURL}&page=${this.currentPage}`;
                    };
                }else{
                    currentURL = `${currentURL}?page=${this.currentPage}`;
                };

                this.pageIsLoading = true;
                this.tableVueInstance.notesArray = [];
                $(this.$el).find(`.notes__container--header`)[0].scrollIntoView(false);


                history.replaceState(history.state,'',currentURL.replace('dashboard/notes',''));

                this.notesURL = currentURL;

                Ajax.sendRequest('GET',{},currentURL,(Response) => { 
                    this.updateTableData(Response);
                    // if(!fromCreation){ this.$nextTick(() => { $(this.$el).find(`.table__pagination--wrapper`)[0].scrollIntoView(false) }) };
                });
            },
            fitTable: function(){
                //below is Pagination + Table header + Sub header + Page header
                let headerHeights = 82 + 54 + 66 + 70;
                let itemHeight = 82;
                let itemCount = this.tableVueInstance.notesArray.length;
                let screenHeight = window.innerHeight;
                
                let totalItemHeight = 0;

                $(`.notes__container--single`).each(function(index){
                    totalItemHeight += Number($(this).css(`height`).replace(`px`,``));
                    totalItemHeight += Number($(this).css(`padding-top`).replace(`px`,``));
                    totalItemHeight += Number($(this).css(`padding-bottom`).replace(`px`,``));
                    totalItemHeight += Number($(this).css(`border-top-width`).replace(`px`,``));
                    totalItemHeight += Number($(this).css(`border-bottom-width`).replace(`px`,``));
                    totalItemHeight += Number($(this).css(`margin-top`).replace(`px`,``));
                    totalItemHeight += Number($(this).css(`margin-bottom`).replace(`px`,``));
                });

                if(this.Pagination.totalPages > 1){
                    totalItemHeight += Number($(this.$el).find(`.table__pagination--wrapper`).css(`height`).replace('px',''));
                };

                // console.log(totalItemHeight);
                // console.log((screenHeight - headerHeights));

                if(totalItemHeight < (screenHeight - headerHeights)){
                    $(this.$el).addClass(`dashboard-list--fixed`);
                }else{
                    $(this.$el).removeClass(`dashboard-list--fixed`);
                };
            },
            fitLists: function(){
                let dashboardLists = $.merge($(`.dashboard-lists .table__container--outer`),$(`.my-stats__wrapper`));
                let maxWidth = 0;
            
                dashboardLists.each(function(index){
                    let thisWidth = $(this).css(`width`).replace('px','');
                    if(thisWidth > maxWidth){ maxWidth = thisWidth };
                });

                let newTotal = Number(maxWidth) + 10;

                let percentageListMax = 0.35*window.innerWidth;

                if(newTotal > percentageListMax){ newTotal = percentageListMax };
                if(newTotal < 200){ newTotal = 200 };

                let newDash = window.innerWidth - 40 - newTotal;
                $(`.dashboard-lists`).css(`width`,newTotal);
                $(`.notes__dashboard`).css(`width`,newDash);
                $(`.table__cell--note p`).css(`max-width`,window.innerWidth - newTotal - 460);
            },
            applyPerPage: function(event){
                $(`#per_page`).parents(`.profile__container--field`)[0].__vue__.$children[0].selectOption(event.data);
                this.perPage = event.data.id;
            },
            copyFilters: async function(){
                try {
                    await navigator.clipboard.writeText(window.location.href);
                    document.activeElement.blur();
                } catch (error) {
                    alert(`Error when copying to clipboard`, error)
                }
            },
            bulkShowHideNotes: function (action) {
                let hiddenValue;
                if (action === 'hide') {
                    hiddenValue = true;
                } else {
                     hiddenValue = false;
                }

                let targetURL = '/dashboard/notes/visability/batch';

                Ajax.sendRequest('POST',{
                    'authenticity_token': $(`meta[name=csrf-token]`)[0].content,
                    'hidden': hiddenValue,
                    'note_ids': this.tableVueInstance.notesIdsArray
                },targetURL,() => { this.tableVueInstance.resetNotes() });
            }
        }
    }
</script>