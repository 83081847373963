<template>
    <div class="profile__input--wrapper">
        <div class="profile__container--tagEditor">
            <div 
                v-bind:id="inputid"
                class="profile__container--values"
                v-on:click="toggleTags"
                v-on:keydown="handleKeysParent"
                tabindex="0">
                    <input v-if="selectValue.length == 0" type="hidden" v-bind:name="inputname" value=""/>
                    <div
                        class="profile__container--tagEdit dropdown-value"
                        v-for="(item, index) in selectValue"
                        v-bind:key="item">
                            <p 
                                v-if="!isdisabled"
                                class="dropdown-deselect" 
                                v-on:keydown="handleKeysDeselect"
                                v-bind:class="{ 'tag-edit-flag--text': (index == tagToggleIndex) && tagToggleFlag }"
                                tabindex="0">
                                    X
                            </p>
                            <p 
                                class="dropdown-selected-title"
                                v-bind:class="{ 'tag-edit-flag--text': (index == tagToggleIndex) && tagToggleFlag }">
                                    {{ item }}
                            </p>
                            <input 
                                type="text" 
                                class="input-tag"
                                v-bind:class="{ 'tag-edit-flag--input': (index == tagToggleIndex) && tagToggleFlag }"
                                v-on:blur="saveTagEdit"
                                v-on:keydown="handleKeysTag">
                            <input
                                style="display: none;"
                                type="text"
                                class="form-data-target"
                                v-model="selectValue[index]"
                                v-bind:name="inputname">
                    </div>
                    <div
                        class="profile__container--tagEdit dropdown-value container-newTag"
                        v-bind:class="{ 'tag-edit-flag--new': !tagNewFlag}">
                            <input
                                type="text"
                                class="input-newTag"
                                v-on:blur="saveTagNew"
                                v-on:keydown="handleKeysTag">
                    </div>
            </div>
            <div
                class="profile__container--options"
                v-bind:class="{ 'profile__container--show': searchResults.length, 'profile__container--showUP': toggleUp}">
                    <div
                        class="profile__option"
                        v-for="result in searchResults"
                        v-bind:key="result.id"
                        v-bind:value="result.id"
                        v-on:mousedown="clickOption">
                            {{ result.name }}
                    </div>
            </div>
        </div>
        <i v-if="!copy" class="flaticon2-copy input-copy" title="Copy field contents"></i>
		<div v-if="haserrors" class="login-form__warning--icon" v-bind:title="haserrors[0]"><p>!</p></div>
		<!-- <div 
			v-if="haserrors"
			class="error-explanation">
				{{ haserrors[0] }}
		</div> -->
    </div>
</template>

<script>
    import Ajax from './js/ajax.js'
    import Align from './js/align.js'
    import OrderArray from './js/order-array.js'

    export default {
        props: [
            'startvalue',
            'inputname',
            'inputid',
            'haserrors',
            'searchurl',
            'isdisabled',
            'copy'
        ],
        data: function(){
            //console.log(this.startvalue);
            return {
                selectValue: OrderArray.alphabetical(this.startvalue) || [],
                tagToggleFlag: false,
                tagToggleIndex: -1,
                tagNewFlag: false,
                eventCount: 0,
                searchQuery: '',
                searchResults: [],
                toggleUp: false
            }
        },
        watch: {
            //Scroll to the bottom of the displayed options when adding a new tag
            tagNewFlag: function(newState,oldState){
                if(newState){
                    this.$nextTick(() => {
                        $(this.$el)
                        .find(`.profile__container--values`)[0]
                        .scrollBy(0,9999);
                    });
                };
            }
        },
        methods: {
            toggleTags: function(event){
                //console.log('toggle tag');
                // console.log(this.$el.parentElement);
                if(this.$el.parentElement.className.indexOf('field--disabled') == -1){
                    let targetClass = event.target.className;
                    this.tagNewFlag = false;
                    if(targetClass.indexOf('title') != -1){
                        //console.log('edit target tag');
                        let targetText = event.target.innerText;
                        let targetIndex = this.selectValue.indexOf(targetText);
                        let tagParent = event.target.parentElement;
                        this.tagToggleIndex = targetIndex;
                        this.tagToggleFlag = true;
                        this.$nextTick(() => {
                            $(tagParent).find(`.input-tag`)[0].value = targetText;
                            $(tagParent).find(`.input-tag`)[0].focus();
                        });
                    }else if(targetClass.indexOf('deselect') != -1){
                        //console.log('deselect target tag');
                        let itemText = $(event.target.parentElement).find(`.dropdown-selected-title`)[0].innerText;
                        this.selectValue.splice(this.selectValue.indexOf(itemText),1);
                        $(event.target.parentElement).remove();
                        this.$emit('event-bubble',{ data: itemText });
                    }else if(targetClass.indexOf('profile__container--values') != -1){
                        //console.log('add new tag');
                        if(this.searchurl){
                            this.searchQuery = '';
                            this.sendAutoCompleteRequest(0);
                        };
                        this.tagNewFlag = true;
                        this.$nextTick(() => {
                            $(event.target).find(`.input-newTag`)[0].focus();
                        });
                    }else{
                        if(targetClass.indexOf('input-tag') != -1){
                            //console.log('continue edit');
                        }else if(targetClass.indexOf('input-newTag') != -1){
                            //console.log('continue new');
                            this.tagNewFlag = true;
                        }else{
                            //console.log('close all tags');
                        };
                    };
                };
            },
            saveTagEdit: function(event){
                //console.log('save edit tag');
                this.searchQuery = 'blockQuery';
                this.searchResults = [];
                let targetText = $(event.target.parentElement).find(`.dropdown-selected-title`)[0].innerText.trim();
                let targetIndex = this.selectValue.indexOf(targetText);
                this.tagToggleIndex = -1;
                this.tagToggleFlag = false;
                let newText = event.target.value;
                if(!newText){
                    return;
                };
                if(newText != targetText){
                    if(newText.indexOf(',') != -1){
                        this.selectValue.splice(targetIndex,1);
                        this.updateTags(newText);
                    }else{
                        if(!this.hasDuplicate(newText)){
                            //console.log('editing tag');
                            this.selectValue[targetIndex] = newText.trim();
                            this.selectValue = OrderArray.alphabetical(this.selectValue);
                            this.$emit('event-bubble',{ data: newText.trim() });
                        }else{
                            //console.log('duplicate value');
                        };
                    };
                };
            },
            saveTagNew: function(event){
                //console.log('save tag new');
                this.searchQuery = 'blockQuery';
                this.searchResults = [];
                this.tagNewFlag = false;
                let newValue = event.target.value;
                event.target.value = '';
                this.updateTags(newValue);
            },
            updateTags: function(newText){
                //console.log(`updating tags with new text - ${newText}`);
                if(newText.indexOf(',') != -1){
                    //console.log('comma list');
                    let vueInstance = this;
                    let itemList = newText.split(',');
                    $(itemList).each(function(itemIndex){
                        if(!vueInstance.hasDuplicate(this)){
                            //console.log(`adding tag from comma list - '${this}'`);
                            if(this.replace(/ /g,'')){
                                vueInstance.selectValue.push(this.trim());
                            }else{
                                //console.log(`skipping empty value - '${this}'`);
                            };
                        }else{
                            //console.log(`skipping duplicate - '${this}'`);
                        };
                    });
                }else{
                    //console.log('single item');
                    if(!this.hasDuplicate(newText)){
                        if(newText.replace(/ /g,'')){
                            //console.log('adding single tag');
                            this.selectValue.push(newText.trim());
                        }else{
                            //console.log('skipping empty value');
                        };
                    }else{
                        //console.log('skipping duplicate');
                    };
                };
                this.selectValue = OrderArray.alphabetical(this.selectValue);
                this.$nextTick(() => {
                    $(this.$el)
                    .find(`.profile__container--values`)[0]
                    .scrollBy(0,9999);
                });
                this.$emit('event-bubble',{ data: newText });
            },
            hasDuplicate: function(ofThisText){
                //console.log(`checking duplicate for - '${ofThisText}'`);
                if(this.selectValue.indexOf(ofThisText.trim()) != -1){
                    return true;
                }else{
                    return false;
                };
            },
            handleKeysDeselect: function(event){
                let tagParent = event.target.parentElement;
                let tagTitle = $(tagParent).find(`.dropdown-selected-title`)[0];
                let tagText = tagTitle.innerText;
                switch(event.code){
                    case 'Enter':
                        $(tagParent).remove();
                        this.$emit('event-bubble',{ data: tagText });
                        break;
                    case 'Space':
                        tagTitle.click();
                        break;
                    default:
                        break;
                };
            },
            handleKeysTag: function(event){
                switch(event.code){
                    case 'Enter':
                    case 'Escape':
                        event.target.blur();
                        break;
                    case 'Backspace':
                        this.searchQuery = event.target.value.slice(0,event.target.value.length - 1);
                        break;
                    default:
                        if(event.code.indexOf('Key') != -1){
                            this.searchQuery = event.target.value + event.key;
                        };
                        break;
                };
                if(this.searchurl){ this.sendAutoCompleteRequest(500) };
            },
            handleKeysParent: function(event){
                if(event.target.className != 'profile__container--values'){
                    event.stopPropagation();
                    return;
                };
                if(event.code == "Enter" || event.code == "Space"){
                    this.tagNewFlag = true;
                    this.$nextTick(() => {
                        $(event.target).find(`.input-newTag`)[0].focus();
                    });
                };
            },
            clickOption: function(event){
                this.updateTags(event.target.innerText);
                document.activeElement.value = ''
                document.activeElement.blur();
            },
            sendAutoCompleteRequest: function(waitTime){
                let url = this.searchurl;
                let query = this.searchQuery;
                this.eventCount += 1;
                let localEventCount = this.eventCount;
                setTimeout(() => {
                    if(localEventCount == this.eventCount){
                        if(query.length > 2){
                            Ajax.sendRequest('GET',{ 'q': query },url,this.handleAutoCompleteResponse,this);
                        }else{
                            //console.log('query less than 3 symbols long, sending initial request');
                            Ajax.sendRequest('GET',{ 'q': '' },url,this.handleAutoCompleteResponse,this);
                        };   
                    }else{
                        //console.log('waiting for additional input');
                    };
                },waitTime);
            },
            handleAutoCompleteResponse: function(responseData){
                let resultsArray = responseData.results;
                let duplicateIndexArray = [];
                resultsArray.forEach((result, index) => {
                    if(this.hasDuplicate(result.name)){
                        responseData.results.splice(index,1,'VUE_DELETE');
                    };
                });

                let readyFlag = 0;
                while(!readyFlag){
                    let deleteIndex = responseData.results.indexOf('VUE_DELETE');
                    if(deleteIndex != -1){
                        responseData.results.splice(deleteIndex,1);
                    }else{
                        readyFlag = 1;
                    };
                };

                this.searchResults = responseData.results;
                this.toggleUp = this.searchResults.length && Align.vertical(this);
            }
        }
    }
</script>