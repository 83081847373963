<template>
    <div class="job-request-list-filter">
        <div v-bind:class="{ 'selected': appliedFilter == 'active' }" v-on:click="applyFilter">active</div>
        <div v-bind:class="{ 'selected': appliedFilter == 'inactive' }" v-on:click="applyFilter">inactive</div>
    </div>
</template>

<script>
import Ajax from '../form-field/sub-components/js/ajax.js'

export default {
    props: ['url'],
    data: function(){
        return {
            appliedFilter: 'active'
        }
    },
    mounted: function(){
        this.applyFilter({
            target: this.$el.querySelectorAll('div')[0]
        });
    },
    methods: {
        applyFilter: function(event){

            this.appliedFilter = event.target.innerText;

            Ajax.sendRequest('GET',{},`${this.url}${this.appliedFilter}`,(response) => {
                console.log(response);

                const listParent = event.target.parentElement.parentElement.parentElement;
                listParent.querySelectorAll('.table__container--single').forEach((row) => { row.remove() });
                
                const html = response.results.map((Result) => {
                    return/*html*/`
                        <div class="table__container--single">
                            <a href="#" class="flaticon2-arrow table-dropdown"></a>
                            <div class="table__container--content table__cell--8">${Result.job_request.request_nr}</div>
                            <div class="table__container--content table__cell--10">${Result.date}</div>
                            <div class="table__container--content" title="${Result.candidate.registered_on}">
                                ${Result.candidate.flag ? /*html*/`
                                    <div title="${Result.candidate.flag.name}" style="background-color: ${Result.candidate.flag.color};" class="candidate-flag"></div>
                                ` : ``}
                                <a href="${Result.candidate.edit_link}">${Result.candidate.name}</a>
                            </div>
                            <div class="table__container--content">
                                ${Result.job_request.highlighted ? /*html*/`
                                    <div class="job-request-highlighted" title="Highlighted"></div>
                                ` : /*html*/``}
                                <a href="${Result.job_request.edit_link}">${Result.job_request.job_title}</a>
                            </div>
                            <div class="table__container--content">${Result.staff}<br>${Result.shared_with_staff || ''}</div>
                        </div>
                    `;
                }).join(``);

                listParent.querySelectorAll('.table__container--wrapper')[0].innerHTML += html;
            });
        }
    }
}
</script>