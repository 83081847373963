import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'
import FullscreenCommand from './command'

let FullscreenIcon = `
    <svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48h-48z" fill="none"/><path d="M14 28h-4v10h10v-4h-6v-6zm-4-8h4v-6h6v-4h-10v10zm24 14h-6v4h10v-10h-4v6zm-6-24v4h6v6h4v-10h-10z"/></svg>
`;


export default class Fullscreen extends Plugin {
    init(){
        this.editor.ui.componentFactory.add('fullscreen', (locale) => {
            const view = new ButtonView(locale);
            view.set({
                label: 'Fullscreen',
                icon: FullscreenIcon,
                tooltip: true,
                class: 'ck-button--fullscreen'
            });
            this.editor.commands.add('fullscreen', new FullscreenCommand(this.editor));
            this.listenTo(view, 'execute', () => { this.editor.execute('fullscreen') });
            return view;
        });
    }

}