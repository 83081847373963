<template>
	<div class="profile__input--wrapper">
		<i
			v-if="icon != undefined"
			class="input-icon"
			v-bind:class="{ 
				'flaticon2-phone': icon == 'phone',
				'flaticon2-mail': icon == 'mail'
			}">
		</i>
		<input 
			v-bind:disabled="isdisabled"
			v-bind:name="inputname" 
			type="text" 
			class="form__input--text form-data-target" 
			v-bind:required="setRequired()"
			v-bind:value="val"
			v-bind:id="fieldId"
			v-on:input="handleChange">
		<div v-if="haserrors" class="login-form__warning--icon" v-bind:title="haserrors[0]"><p>!</p></div>
		<!-- <div 
			v-if="haserrors"
			class="error-explanation">
				{{ haserrors[0] }}
		</div> -->
	</div>
</template>

<script>
	export default {
		props: [
			'inputname',
			'inputid',
			'startvalue',
			'icon',
			'haserrors',
			'isdisabled',
			'isrequired'
		],
		data: function(){
			return {
				fieldId: this.inputid,
				val: this.startvalue || ''
			}
		},
		methods: {
			setRequired: function(){ if(this.isrequired){ return 'required' }else{ return false } },
			handleChange: function(event){ this.$emit('event-bubble',{ data: $(this.$el).find(`input`)[0].value, element: $(this.$el)}) }
		}
	}
</script>