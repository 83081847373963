<template>
    <div class="profile__container--wrapper profile__container--columns-2">
        <candidate-dossier-records
            addWrapperClasss="profile__container--columnPos-1"
            v-bind:intial-records="conflictingRecords"
        ></candidate-dossier-records>
    </div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'
    import CandidateDossierRecords from './shared/candidate-dossier-records.vue'

    export default {
        props: [
            'candidateData'
        ],
        data: function(){
            var candidate = JSON.parse(this.candidateData || '{}');
            var conflictingRecords = candidate.conflicting_dossier_records || [];

            return { 
                conflictingRecords: conflictingRecords, 
            };
        },
        components: {
            FormField,
            CandidateDossierRecords
        }
    }
</script>