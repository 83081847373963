import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'

import EssentialsPlugins from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicsPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline'
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ListPlugin from '@ckeditor/ckeditor5-list/src/list'
import TodoList from '@ckeditor/ckeditor5-list/src/todolist';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'

import RemoveFormatPlugin from '@ckeditor/ckeditor5-remove-format/src/removeformat'
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat'
import PasteFromOfficePlugin from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'

import IndentPlugin from '@ckeditor/ckeditor5-indent/src/indent'
import IndentBlockPlugin from '@ckeditor/ckeditor5-indent/src/indentblock'
import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment'

import FontPlugin from '@ckeditor/ckeditor5-font/src/font'
import FontBackgroundColorPlugin from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor'
import FontColorPlugin from '@ckeditor/ckeditor5-font/src/fontcolor'
import FontSizePlugin from '@ckeditor/ckeditor5-font/src/fontsize'
import FontFamilyPlugin from '@ckeditor/ckeditor5-font/src/fontfamily'

import SpecialCharactersPlugin from '@ckeditor/ckeditor5-special-characters/src/specialcharacters'
import SpecialEssentialsPlugin from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials'
import SpecialTextPlugin from '@ckeditor/ckeditor5-special-characters/src/specialcharacterstext'
import SpecialCurrencyPlugin from '@ckeditor/ckeditor5-special-characters/src/specialcharacterscurrency'
import SpecialArrowsPlugin from '@ckeditor/ckeditor5-special-characters/src/specialcharactersarrows'
import SpecialLatinPlugin from '@ckeditor/ckeditor5-special-characters/src/specialcharacterslatin'
import SpecialMathPlugin from '@ckeditor/ckeditor5-special-characters/src/specialcharactersmathematical'

// import Table from '@ckeditor/ckeditor5-table'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'

import PreviewPagePlugin from './custom-plugins/page-preview/page-preview'
import FullscreenPlugin from './custom-plugins/fullscreen/fullscreen'
import AutofillPlugin from './custom-plugins/autofill/autofill'

export default class ClassicEditor extends ClassicEditorBase {}

ClassicEditor.builtinPlugins = [
    EssentialsPlugins,
    BoldPlugin,
    ItalicsPlugin,
    UnderlinePlugin,
    HeadingPlugin,
    ImagePlugin,
    ListPlugin,
    TodoList,
    LinkPlugin,
    AutoformatPlugin,
    PasteFromOfficePlugin,
    RemoveFormatPlugin,
    IndentPlugin,
    IndentBlockPlugin,
    AlignmentPlugin,
    FontPlugin,
    FontBackgroundColorPlugin,
    FontColorPlugin,
    FontFamilyPlugin,
    FontSizePlugin,
    SpecialCharactersPlugin,
    SpecialEssentialsPlugin,
    SpecialTextPlugin,
    SpecialCurrencyPlugin,
    SpecialArrowsPlugin,
    SpecialLatinPlugin,
    SpecialMathPlugin,
    Table,
    TableToolbar,
    PreviewPagePlugin,
    FullscreenPlugin,
    AutofillPlugin
];

ClassicEditor.defaultConfig = {
    toolbar: {
        items: [
            'heading',
            '|','previewPage',
            '|','autofill',
            '|','insertTable',
            '|','bold','italic','underline','removeFormat',
            '|','fontColor','fontBackgroundColor',
            // 'fontFamily','fontSize',
            '|','bulletedList','numberedList',
            // '|','outdent','indent','alignment',
            '|','link','specialCharacters',
            '|','undo','redo',
            '|','fullscreen'
        ],
        shouldNotGroupWhenFull: false
    },
    indentBlock: {
        'offset': 1,
        'unit': 'em'
    },
    language: 'en'
};