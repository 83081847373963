<template>
<div class="profile__container--wrapper profile__container--columns-3">

    <div class="profile__container--outer profile__container--details profile__container--news">
        <div class="profile__container--header">
            <h3>Details</h3>
        </div>
        <div class="profile__container--main">
            <form-field
                title="Name"
                fieldtype="form-input"
                inputname="staff_list[name]"
                v-bind:startvalue="staff_list.name"
                v-bind:haserrors="staff_list.errors.name"
            ></form-field>

            <form-field
                title="Position"
                fieldtype="form-number"
                inputname="staff_list[priority]"
                v-bind:startvalue="staff_list.priority"
                v-bind:alloptions="{
                    min: 0,
                    max: 1000000,
                    step: 1
                }"
                v-bind:haserrors="staff_list.errors.priority"
            ></form-field>
         	
            <form-field
                title="Members"
                fieldtype="form-multidrop"
                addclass="staff-list__info"
                v-bind:inputname="`staff_list[staff_ids][]`"
                selectall="true"
                preload="0"
                searchurl="/autocomplete/staff"
                v-bind:startvalue="staff_list.members"
                v-bind:alloptions="[]"
                v-bind:haserrors="staff_list.errors.members"
            ></form-field>


            <div v-if="staff_list.id" class="value-form--wrapper">
                <form-field
                    title="Hidden"
                    fieldtype="form-checkbox"
                    inputname="staff_list[hidden]"
                    v-bind:startvalue="staff_list.hidden"
                    v-bind:haserrors="staff_list.errors.hidden"
                ></form-field>
            </div>

        </div>
    </div>
</div>
</template>

<script>
	import FormField from '../form-field/form-field.vue'

    export default {
        props: [
            'recordData',
            'formOptionsData'
        ],
        data: function(){
            var staff_list = JSON.parse(this.recordData || '{}');
            console.log(staff_list.errors)
            return { 
                staff_list: staff_list
            };
        },
        components: {
            FormField
        }
    }
</script>