let Ajax = require('../js/ajax.js');

module.exports = {
    methods: {
        sendQuery: function(event){
            let url = this.searchurl;
            let query = event.target.value;
            this.searchQuery = query;
            this.eventCount += 1;
            let localEventCount = this.eventCount;
            let waitTime = 500;
            setTimeout(() => {
                if(localEventCount == this.eventCount){
                    if(query.length > 2){
                        Ajax.sendRequest('GET',{ 'q': query },url,this.processResponse,this);
                    }else{
                        //console.log('query less than 3 symbols long, sending initial request');
                        Ajax.sendRequest('GET',{ 'q': '' },url,this.processResponse,this);
                    };   
                }else{
                    //console.log('waiting for additional input');
                };
            },waitTime);
        },
        processResponse: function(data){
            //console.log(`received response for: title = ${this.$parent.title}`);
            console.log(data);
            this.updateOptions(data.results);
        }
    }
}