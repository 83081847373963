<template>
    <div class="profile__container--wrapper profile__container--columns-2">
        <div class="profile__container--outer candidate-details sectioned">
            <div class="profile__container--header">
                <h3>{{title}}</h3>
                <!-- <i class="flaticon-notes notes-toggle"></i>
                <div class="notes-container">
                    Note: The Text Kernel CV processing service searches for a match of "Job code" and "Web name"
                </div> -->
            </div>
            <div class="profile__container--main">
        <!--         <div>This will create a new login account with the e-mail:</div> -->

                <div 
                    v-for="(formInput, index) in formInputs"
                    v-bind:key="index"
                    class="value-form--wrapper">
                    <form-field
                        v-if="formInput.component_type == 'form-field'"
                        v-bind:title="formInput.title"
                        v-bind:fieldtype="formInput.type"
                        v-bind:inputname="formInput.name"
                        v-bind:startvalue="formInput.value"
                        v-bind:haserrors="formInput.errors"
                        v-bind:searchurl="formInput.searchurl"
                        v-bind:preload="formInput.preload"
                        v-bind:alloptions="formInput.alloptions"
                        v-bind:isdisabled="formInput.isdisabled"
                    ></form-field>

                    <job-code-select
                        v-if="formInput.component_type == 'job-code-select'"
                        v-bind:record-data="formInput.data"
                    ></job-code-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'
    import JobCodeSelect from './shared/job-code-select.vue'

    export default {
        props: [
            'formInputsData',
            'formSettingsData'
        ],
        data: function(){
            var formSettings = JSON.parse(this.formSettingsData || '{ }');
            var formInputs = formSettings.inputs || [];
            console.log(formInputs);

            return { title: formSettings.title, formInputs: formInputs };
        },
        components: {
            FormField,
            JobCodeSelect
        }
    }
</script>

