<template>
    <div class="filters--wrapper-DEL filters--wrapper">
        <div
            class="filters--copy"
            title="Copy filters' url"
            v-on:click="copyFilters()">
                <i class="flaticon2-copy"></i>
        </div>
        <div 
            class="filters--clear"
            title="Clear filters" 
            v-on:click="clearFilters()">
                <i class="flaticon2-delete"></i>
        </div>
        <!-- If this field is not submitted the default filters will be loaded -->
        <input type="hidden" name="fiters_submitted" value="1">
        
        <!-- Not auto-submitting? -->
        <div class="toggle-search-explain" v-on:click="toggleSearchExplain">TOGGLE EXPLANATION</div>

        <form-field
            title="Job titles"
            fieldtype="form-multigroup"
            inputname="job_title_ids[]"
            v-bind:startvalue="appliedFilters.job_titles"
            searchurl="/autocomplete/job_titles/extended"
            v-on:event-bubble="handleChange"
            addclass="filter-target lin-target"
        ></form-field>

        <!-- NEW MULTIGROUP FIELD -->
        <form-field
            title=""
            inputname="job_title_query_depth"
            fieldtype="form-radio"
            v-bind:startvalue="appliedFilters.job_title_query_depth"
            v-bind:alloptions="formOptions.job_title_query_depth_options"
            v-on:event-bubble="handleChange"
            addclass="filter-target query-depth"
        ></form-field>
        
        <form-field
            title=""
            inputname="job_title_query_type"
            fieldtype="form-radio"
            v-bind:startvalue="appliedFilters.job_title_query_type"
            v-bind:alloptions="formOptions.multi_values_query_type_options"
            v-on:event-bubble="handleChange"
            addclass="filter-target lin-radio"
        ></form-field>

        <form-field
            title="Most recent job titles"
            fieldtype="form-checkbox"
            inputname="job_title_only_latest"
            v-bind:startvalue="appliedFilters.job_title_only_latest"
            v-on:event-bubble="handleChange"
            ddclass="filter-target"
        ></form-field>

        <hr/>

        <form-field
            title="Country"
            fieldtype="form-dropdown"
            inputname="country_id"
            v-bind:startvalue="appliedFilters.country"
            searchurl="/autocomplete/countries"
            v-on:event-bubble="handleChange"
            addclass="filter-target"
            addblank="true"
            blanktext="Any"
            copy="true"
        ></form-field>

        <form-field
            title="Near (NL town)"
            fieldtype="form-dropdown"
            inputname="distance_geolocation_id"
            v-bind:startvalue="appliedFilters.distance_geolocation"
            searchurl="/autocomplete/geolocations"
            v-on:event-bubble="handleChange"
            addclass="filter-target"
            addblank="true"
            blanktext="Any"
            copy="true"
        ></form-field>
        
        <form-field
            title="Distance"
            fieldtype="form-dropdown"
            inputname="distance_in_meters"
            v-bind:startvalue="appliedFilters.distance_in_meters"
            v-bind:alloptions="formOptions.distance_options"
            v-on:event-bubble="handleChange"
            v-bind:isdisabled="!distanceActive"
            addclass="filter-target"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title=""
            inputname="distance_query_type"
            fieldtype="form-radio"
            v-bind:startvalue="appliedFilters.distance_query_type"
            v-bind:alloptions="formOptions.single_value_query_type_options"
            v-on:event-bubble="handleChange"
            addclass="filter-target"
        ></form-field>

        <hr/>

        <candidate-languages
            addWrapperClasss="profile__container--columnPos-1 no-highlight"
            v-bind:intial-records="appliedFilters.languages"
            v-bind:language-proficiency-options="formOptions.language_proficiency_options"
            v-on:event-bubble="handleChange"
        ></candidate-languages>

        <form-field
            title=""
            inputname="languages_query_type"
            fieldtype="form-radio"
            v-bind:startvalue="appliedFilters.languages_query_type"
            v-bind:alloptions="formOptions.multi_values_query_type_options"
            v-on:event-bubble="handleChange"
            addclass="filter-target language-opt"
        ></form-field>

        <hr/>

        <div class="profile__container--row">
            <form-field
                title="Profile updated from"
                fieldtype="form-date"
                inputname="updated_from"
                v-bind:startvalue="appliedFilters.updated_from"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>

            <form-field
                title="to"
                fieldtype="form-date"
                inputname="updated_to"
                v-bind:startvalue="appliedFilters.updated_to"
                v-on:event-bubble="handleChange"
                addclass="filter-target advanced-date"
            ></form-field>
        </div>

        <form-field
            title=""
            inputname="updated_query_type"
            fieldtype="form-radio"
            v-bind:startvalue="appliedFilters.updated_query_type"
            v-bind:alloptions="formOptions.single_value_query_type_options"
            v-on:event-bubble="handleChange"
            addclass="filter-target"
        ></form-field>

        <hr/>

        <form-field
            title="Professional skills"
            inputname="professional_skills[]"
            fieldtype="form-tags"
            v-bind:startvalue="appliedFilters.professional_skills"
            searchurl="/autocomplete/professional_skills"
            v-on:event-bubble="handleChange"
            addclass="filter-target lin-target"
        ></form-field>

     
        <form-field
            title=""
            inputname="professional_skills_query_type"
            fieldtype="form-radio"
            v-bind:startvalue="appliedFilters.professional_skills_query_type"
            v-bind:alloptions="formOptions.multi_values_query_type_options"
            v-on:event-bubble="handleChange"
            addclass="filter-target lin-radio"
        ></form-field>

        <hr/>

        <form-field
            title="Software"
            inputname="software_terms[]"
            v-bind:startvalue="appliedFilters.software_terms"
            fieldtype="form-tags"
            searchurl="/autocomplete/software_skills"
            addclass="filter-target lin-target"
            v-on:event-bubble="handleChange"
        ></form-field>

        <form-field
            title=""
            inputname="software_terms_query_type"
            fieldtype="form-radio"
            v-bind:startvalue="appliedFilters.software_terms_query_type"
            v-bind:alloptions="formOptions.multi_values_query_type_options"
            v-on:event-bubble="handleChange"
            addclass="filter-target lin-radio"
        ></form-field>

        <hr/>

        <form-field
            title="Keywords (B CV)"
            inputname="keywords[]"
            v-bind:startvalue="appliedFilters.keywords || []"
            fieldtype="form-tags"
            addclass="filter-target lin-target"
            v-on:event-bubble="handleChange"
        ></form-field>

        <form-field
            title=""
            inputname="keywords_query_type"
            fieldtype="form-radio"
            v-bind:startvalue="appliedFilters.keywords_query_type"
            v-bind:alloptions="formOptions.multi_values_query_type_options"
            v-on:event-bubble="handleChange"
            addclass="filter-target lin-radio"
        ></form-field>
        
        <!-- {{Fixed}} Handle changes causes submit on every click -->
        <div class="filters--group filters--hidden" title="Filters: Salary min/max, Currency, Frequency">
            <div class="filters--toggler" v-on:click="toggleFilterGroup">
                <i class="flaticon2-back-1" title="Expand/contract salary filters group"></i> 
                <p>Salary group</p>
            </div>      

            <div class="profile__container--row row-column">
                <form-field
                    title="Salary min"
                    fieldtype="form-number"
                    inputname="salary_min"
                    v-bind:startvalue="appliedFilters.salary_min"
                    v-bind:alloptions="{
                        min: 0,
                        max: 10000000000000,
                        step: 1
                    }"
                    addclass="filter-target"
                    v-on:event-bubble="handleChange"
                ></form-field>

                <form-field
                    title="Salary max"
                    fieldtype="form-number"
                    inputname="salary_max"
                    v-bind:startvalue="appliedFilters.salary_max"
                    v-bind:alloptions="{
                        min: 0,
                        max: 10000000000000,
                        step: 1
                    }"
                    addclass="filter-target"
                    v-on:event-bubble="handleChange"
                ></form-field>
            </div>

            <div class="profile__container--row row-column">
                <form-field
                    title="Currency"
                    fieldtype="form-dropdown"
                    inputname="salary_currency"
                    v-bind:startvalue="appliedFilters.salary_currency"
                    v-bind:alloptions="formOptions.salary_currencies"
                    v-on:event-bubble="handleChange"
                    addclass="filter-target"
                ></form-field>

                <form-field
                    title=""
                    fieldtype="form-radio"
                    inputname="salary_range"
                    v-bind:startvalue="appliedFilters.salary_range"
                    v-bind:alloptions="formOptions.salary_range_types"
                    v-on:event-bubble="handleChange"
                    addclass="filter-target"
                ></form-field>
            </div>

            <form-field
                title=""
                inputname="salary_query_type"
                fieldtype="form-radio"
                v-bind:startvalue="appliedFilters.salary_query_type"
                v-bind:alloptions="formOptions.single_value_query_type_options"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>

        </div>

        <div class="filters--group filters--hidden" title="Filters: Jobs count, Industries, Companies">
            <div class="filters--toggler" v-on:click="toggleFilterGroup">
                <i class="flaticon2-back-1" title="Expand/contract job experience filters group"></i>
                <p>Job experience group</p>
            </div>

            <div class="profile__container--row row-column">
                <i class="input-copy linkedin-copy lin-years flaticon-linkedin" title="Copy years of experience as linkedin query" v-on:click="copyLinkedInYears"></i>
                <form-field
                    title="Numbers of job experince from"
                    fieldtype="form-number"
                    inputname="total_experience_from"
                    v-bind:startvalue="appliedFilters.total_experience_from"
                    v-bind:alloptions="{
                        min: 0,
                        max: 100,
                        step: 1
                    }"
                    v-on:event-bubble="handleChange"
                ></form-field>

                <form-field
                    title="to"
                    fieldtype="form-number"
                    inputname="total_experience_to"
                    v-bind:startvalue="appliedFilters.total_experience_to"
                    v-bind:alloptions="{
                        min: 0,
                        max: 100,
                        step: 1
                    }"
                    v-on:event-bubble="handleChange"
                ></form-field>
            </div>

            <form-field
                title=""
                inputname="total_experience_query_type"
                fieldtype="form-radio"
                v-bind:startvalue="appliedFilters.total_experience_query_type"
                v-bind:alloptions="formOptions.single_value_query_type_options"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>


            <hr/>

            <form-field
                title="Industries"
                fieldtype="form-multidrop"
                inputname="industry_ids[]"
                v-bind:startvalue="appliedFilters.industries"
                searchurl="/autocomplete/industries"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>

            <form-field
                title=""
                inputname="industries_query_type"
                fieldtype="form-radio"
                v-bind:startvalue="appliedFilters.industries_query_type"
                v-bind:alloptions="formOptions.multi_values_query_type_options"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>

            <hr/>

            <form-field
                title="Companies"
                fieldtype="form-multidrop"
                inputname="company_ids[]"
                v-bind:startvalue="appliedFilters.companies"
                searchurl="/autocomplete/companies"
                v-on:event-bubble="handleChange"
                addclass="filter-target lin-target"
            ></form-field>

            <form-field
                title=""
                inputname="companies_query_type"
                fieldtype="form-radio"
                v-bind:startvalue="appliedFilters.companies_query_type"
                v-bind:alloptions="formOptions.multi_values_query_type_options"
                v-on:event-bubble="handleChange"
                addclass="filter-target lin-radio"
            ></form-field>

        </div>

        <div class="filters--group filters--hidden" title="Filters: Highest education, Education institute, Education direction">
            <div class="filters--toggler" v-on:click="toggleFilterGroup">
                <i class="flaticon2-back-1" title="Expand/contract education filters group"></i>
                <p>Education group</p>
            </div>

            <form-field
                title="Highest education"
                fieldtype="form-dropdown"
                inputname="education_id"
                v-bind:startvalue="appliedFilters.education"
                v-bind:alloptions="formOptions.education_options"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
                copy="true"
            ></form-field>

            <form-field
                title=""
                inputname="education_query_type"
                fieldtype="form-radio"
                v-bind:startvalue="appliedFilters.education_query_type"
                v-bind:alloptions="formOptions.single_value_query_type_options"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>

            <hr/>
            <form-field
                title="Education institute"
                fieldtype="form-multidrop"
                inputname="education_institutes[]"
                searchurl="/autocomplete/education-institutes"
                v-bind:startvalue="appliedFilters.education_institutes"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>

            <form-field
                title=""
                inputname="education_institutes_query_type"
                fieldtype="form-radio"
                v-bind:startvalue="appliedFilters.education_institutes_query_type"
                v-bind:alloptions="formOptions.multi_values_query_type_options"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>

            <hr/>

            <form-field
                title="Education direction"
                fieldtype="form-tags"
                inputname="education_directions[]"
                v-bind:startvalue="appliedFilters.education_directions"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
                copy="true"
            ></form-field>

            <form-field
                title=""
                inputname="education_directions_query_type"
                fieldtype="form-radio"
                v-bind:startvalue="appliedFilters.education_directions_query_type"
                v-bind:alloptions="formOptions.multi_values_query_type_options"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>

        </div>
        
        <!-- Add checkbox - default scope? When not clicked, open the other filters? -->
        <!-- Default scope start -->
        <div class="filters--group filters--hidden" title="Filters: Blacklisted, Hidden, GDPR, Under offer, Active">
            <div class="filters--toggler" v-on:click="toggleFilterGroup">
                <i class="flaticon2-back-1" title="Expand/contract meta filters group"></i>
                <p>Meta group</p>
            </div>

            <form-field
                title="Active"
                inputname="is_active"
                fieldtype="form-radio"
                v-bind:startvalue="appliedFilters.is_active"
                v-bind:alloptions="[{ name: 'Yes', id: true }, { name: 'No', id: false }]"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>


            <form-field
                title=""
                inputname="is_active_query_type"
                fieldtype="form-radio"
                v-bind:startvalue="appliedFilters.is_active_query_type"
                v-bind:alloptions="formOptions.single_value_query_type_options"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>

            <hr/>

            <form-field
                title="Under offer"
                inputname="under_offer"
                fieldtype="form-radio"
                v-bind:startvalue="appliedFilters.under_offer"
                v-bind:alloptions="[{ name: 'Yes', id: true }, { name: 'No', id: false }]"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>

            <form-field
                title=""
                inputname="under_offer_query_type"
                fieldtype="form-radio"
                v-bind:startvalue="appliedFilters.under_offer_query_type"
                v-bind:alloptions="formOptions.single_value_query_type_options"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>

            <hr/>

            <form-field
                title="GDPR"
                inputname="gdpr"
                fieldtype="form-radio"
                v-bind:startvalue="appliedFilters.gdpr"
                v-bind:alloptions="[{ name: 'Yes', id: true }, { name: 'No', id: false }]"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>

            <form-field
                title=""
                inputname="gdpr_query_type"
                fieldtype="form-radio"
                v-bind:startvalue="appliedFilters.gdpr_query_type"
                v-bind:alloptions="formOptions.single_value_query_type_options"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>

            <hr/>

            <form-field
                title="Hidden"
                inputname="hidden"
                fieldtype="form-radio"
                v-bind:startvalue="appliedFilters.hidden"
                v-bind:alloptions="[{ name: 'Yes', id: true }, { name: 'No', id: false }]"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>

            <form-field
                title=""
                inputname="hidden_query_type"
                fieldtype="form-radio"
                v-bind:startvalue="appliedFilters.hidden_query_type"
                v-bind:alloptions="formOptions.single_value_query_type_options"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>

            <hr/>

            <form-field
                title="BLACKLISTED"
                inputname="blacklist"
                fieldtype="form-radio"
                v-bind:startvalue="appliedFilters.blacklist"
                v-bind:alloptions="[{ name: 'Yes', id: true }, { name: 'No', id: false }]"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>

            <form-field
                title=""
                inputname="blacklist_query_type"
                fieldtype="form-radio"
                v-bind:startvalue="appliedFilters.blacklist_query_type"
                v-bind:alloptions="formOptions.single_value_query_type_options"
                v-on:event-bubble="handleChange"
                addclass="filter-target"
            ></form-field>
        </div>
        <!-- Default scope end -->


      <!--   <form-field
            title="From date"
            fieldtype="form-date"
            inputname="q[from_date]"
            v-bind:startvalue="appliedFilters.from_date"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
        ></form-field>

        <form-field
            title="To date"
            fieldtype="form-date"
            inputname="q[to_date]"
            v-bind:startvalue="appliedFilters.to_date"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
        ></form-field> -->

       
        <input type="hidden" name="sort_order" v-bind:value="appliedFilters.sort_order"/>
        <input type="hidden" name="sort_by" v-bind:value="appliedFilters.sort_by"/>
    </div>
</template>

<script>
    import Ajax from '../../form-field/sub-components/js/ajax.js'
    import FormField from './../../form-field/form-field.vue'
    import CandidateLanguages from './candidate-languages.vue'

    export default {
        props: [
            'formOptionsData',
            'appliedFiltersData',
            'jobrequestid'
        ],
        data: function(){
            var appliedFilters = JSON.parse(this.appliedFiltersData || '{}');
            var formOptions = JSON.parse(this.formOptionsData || '{}');
            
            console.log(appliedFilters);
            //console.log(formOptions);

            return { 
                formOptions: formOptions,
                appliedFilters: appliedFilters,
                eventThrottle: 0,
                filtersShown: true,
                jobRequestId: this.jobrequestid,
                distanceActive: Boolean(appliedFilters.distance_geolocation.id),

            };
        },
        components: {
            Ajax,
            FormField,
            CandidateLanguages
        },
        mounted: function(){
            if(!this.distanceActive){
                this.clearDistanceFilter();
            };
            this.setupLinkedInCopy();

            const jtField = this.$children[0];
            $(jtField.$el).find(`label`)[0].click();      
            this.$nextTick(() => { $(`.filters--header`)[0].click() });                
            
            const jtInner = jtField.$children[0];
            const thisVue = this;    
            
            Ajax.sendRequest('GET',{},'/autocomplete/job_titles/extended',(Response) => {
                //console.log(Response);
                //console.log(`OPTIONS LOADED, ADD INFO PASS`,jtInner,jtInner.selectValue,jtInner.dropdownOptions);

                jtInner.selectValue.forEach((Val) => {
                    let foundVal = false;

                    Response.results.forEach((Option) => {
                        Option.items.forEach((Item) => {
                            if(!foundVal && Item.id == Val.id){
                                foundVal = true;
                                // console.log(`FOUND VAL`,Item,Val);

                                Val.group = Option.group;
                            };
                        });
                    });
                });

                document.getElementsByClassName('search-table--wrapper')[0].__vue__.update();
            });
        },
        methods: {
            toggleSearchExplain: function(){
                const searchTableInstance = document.getElementsByClassName('search-table--wrapper')[0].__vue__;

                if(searchTableInstance.showClass){
                    searchTableInstance.hideClassTable();
                }else{
                    searchTableInstance.showClassTable();
                };
            },
            getCodeMatchInfo: function(){
                const infoArray = this.getJobTitlesInfoArray();

                const uniqueCodes = [];
                const codes = [];

                infoArray.forEach((Entry) => {
                    Entry.code = (Entry.code || '').trim();

                    if(uniqueCodes.indexOf(Entry.code) == -1){
                        uniqueCodes.push(Entry.code);

                        codes.push({
                            name: Entry.code,
                            matchCode: Entry.matchCode,
                            titles: [{ matchTitle: Entry.matchTitle, name: Entry.name }]
                        });
                    }else{
                        const codeIndex = uniqueCodes.indexOf(Entry.code);

                        codes[codeIndex].titles.push({ matchTitle: Entry.matchTitle, name: Entry.name });
                    };
                });

                return codes;
            },
            getSearchTableInfo: function(){
                const infoArray = this.getJobTitlesInfoArray();

                const uniqueClasses = [];
                const classes = [];

                infoArray.forEach((Entry) => {
                    Entry.class = (Entry.class || '').trim();

                    if(uniqueClasses.indexOf(Entry.class) == -1){
                        uniqueClasses.push(Entry.class);

                        classes.push({
                            name: Entry.class,
                            matchClass: Entry.matchClass,
                            groups: [Entry]
                        });
                    }else{
                        const classIndex = uniqueClasses.indexOf(Entry.class);

                        classes[classIndex].groups.push(Entry);
                    };
                });

                classes.forEach((Class) => {
                    const uniqueGroups = [];
                    const groups = [];

                    Class.groups.forEach((Group) => {
                        Group.group = (Group.group || '').trim();

                        if(uniqueGroups.indexOf(Group.group) == -1){
                            uniqueGroups.push(Group.group);

                            groups.push({
                                name: Group.group,
                                matchGroup: Group.matchGroup,
                                codes: [Group]
                            });
                        }else{
                            const groupIndex = uniqueGroups.indexOf(Group.group);

                            groups[groupIndex].codes.push(Group);
                        };
                    });

                    Class.groups = groups;

                    groups.forEach((Group) => {
                        const uniqueCodes = [];
                        const codes = [];

                        Group.codes.forEach((Code) => {
                            Code.code = (Code.code || '').trim();

                            if(uniqueCodes.indexOf(Code.code) == -1){
                                uniqueCodes.push(Code.code);

                                codes.push({
                                    name: Code.code,
                                    matchCode: Code.matchCode,
                                    titles: [{ name: Code.name, matchTitle: Code.matchTitle }]
                                });
                            }else{
                                const codeIndex = uniqueCodes.indexOf(Code.code);

                                codes[codeIndex].titles.push({ name: Code.name, matchTitle: Code.matchTitle });
                            };
                        });

                        Group.codes = codes;
                    });


                });

                return classes;
            },
            getJobTitlesInfoArray: function(){
                return document.getElementsByClassName('search-list--parent')[0].__vue__.matchedTitles;

                return document.getElementsByClassName('lin-target')[0].__vue__.$children[0].selectValue.map((val) => {
                    let c = val.name;
                    
                    // console.log(`WHILE BUILDING INFO ARRAY`,val)

                    return {
                        title: c.slice(c.indexOf('-') + 1).trim(),
                        code: c.slice(c.indexOf(')') + 1,c.indexOf('-')).trim(),
                        group: (val.group || '').split('|')[1],
                        class: (val.group || '').split('|')[0]
                    };
                });
            },
            handleChange: function(event){ 
                console.log(`called handleChange()`,event);
                let metaFix = ['BLACKLISTED','Hidden','GDPR','Under offer','Active'].indexOf(event.title) == -1;

                if(!event.data && metaFix){ return };
                let submitFlag = true;
                if(event.data.action == 'language-action'){
                    event.data.records.forEach((Record) => {
                        if(submitFlag && (Record.language.id == null || Record.language.id == undefined)){ 
                            submitFlag = false 
                        };
                    });
                };
                if(event.title == 'Near (NL town)'){
                    this.distanceActive = Boolean(event.data.id);
                    if(!this.distanceActive){
                        this.clearDistanceFilter();
                    };
                };
                let waitTime = 1000;
                this.eventThrottle++;
                let currentThrottle = this.eventThrottle;

                setTimeout(() => {
                    if(this.eventThrottle == currentThrottle){ 
                        if(submitFlag){ 
                            this.$nextTick(() => { 
                                let serialData = $(`#candidates-advanced-search-filter-form`).serialize();
                                
                                if(this.jobRequestId){ serialData = `${serialData}&job_request_id=${this.jobRequestId}` };
                                
                                history.replaceState(history.state,'',`/search?${serialData}`);


                                let resultsInstance = this.getResultsInstance();
                                this.$nextTick(() => {
                                    resultsInstance.pageIsLoading = true;
                                    Ajax.sendRequest('GET',{},`/search.json?${serialData}`,(Response) => {
                                        resultsInstance.lastPage = 0;
                                        resultsInstance.currentPage = 1;
                                        resultsInstance.setDataFromResponse(Response,true);

                                        document.getElementsByClassName('search-table--wrapper')[0].__vue__.update();
                                    });
                                });

                                // $(`#candidates-advanced-search-filter-form`).submit(); 
                            }); 
                        }; 
                    };
                },waitTime);
            },
            clearFilters: function(){
                let jR = ''; 
                if(this.jobRequestId){ jR = `?job_request_id=${this.jobRequestId}` };
                window.location.href = `/search${jR}`; 
            },
            getResultsInstance: function(){ return $(this.$el).parents(`.search--wrapper`).find(`.search-list--parent`)[0].__vue__ },
            toggleFilters: function(newState){
                if(newState != null){ this.filtersShown = newState }else{ this.filtersShown = !this.filtersShown };

                let searchWrapper = this.$el.parentElement.parentElement.parentElement;
                let resultsWrapper = $(searchWrapper).find(`.search-list--parent`)[0];
                let expandButton = $(searchWrapper).find(`.filters--expander`)[0];

                let merged = $.merge($.merge($(searchWrapper),$(resultsWrapper)),$(expandButton));
                if(newState == null){ 
                    $(merged).toggleClass('filters-hide') 
                }else{
                    if(!this.filtersShown){ $(merged).addClass('filters-hide') }else{ $(merged).removeClass('filters-hide') };
                };
            },
            toggleFilterGroup: function(event){ $(event.target).parents('.filters--group').toggleClass('filters--hidden') },
            copyFilters: async function(){ this.copyToClipboard(window.location.href) },
            copyToClipboard: async function(text){
                try {
                    await navigator.clipboard.writeText(text);
                    document.activeElement.blur();
                } catch (error) {
                    alert(`Error when copying to clipboard`, error)
                };
            },
            clearDistanceFilter: function(){
                let formFieldDistance = $(`#distance_in_meters`).parents(`.profile__input--wrapper`)[0];
                formFieldDistance.__vue__.selectedOption = {'id': 25000, 'name': '25km'};
            },
            setupLinkedInCopy: function(){
                let tagFields = $(`.lin-target`);
                let booleanSettings = $(`.lin-radio`);
                // console.log(tagFields,booleanSettings);

                let filtersInstance = this;

                $(tagFields).each(function(index){
                    let thisFilter = tagFields[index].__vue__.$children[0];
                    let booleanVue = booleanSettings[index].__vue__.$children[0];

                    let linkedInHTMLNode = document.createElement(`i`);
                    linkedInHTMLNode.className = `input-copy linkedin-copy flaticon-linkedin`;
                    linkedInHTMLNode.title = `Copy ${thisFilter.$parent.title} filter as linkedin query`;

                    $(thisFilter.$el).append(linkedInHTMLNode);
                    $(thisFilter.$el).find(`.linkedin-copy`).bind('click',function(event){
                        console.log(thisFilter,booleanVue)
                        let resultString = ``;
                        if(thisFilter.selectValue.length){
                            let condition;
                            if(booleanVue.checkedOption == 'nice'){
                                condition = ' OR ';
                            }else{
                                condition = ` ${booleanVue.checkedOption.toUpperCase()} `;
                            };

                            switch(thisFilter.$parent._props.fieldtype){
                                case 'form-multigroup':
                                    let titleSetting = $(tagFields[index]).find(` + .form-radio`)[0].__vue__.$children[0].checkedOption;
                                    console.log(titleSetting);
                                    let filterData = filtersInstance.getResultsInstance().appliedFilters;
                                    console.log(`filterData`,filterData);

                                    let targetArray = [];
                                    let conditionString = `" ${condition} "`;

                                    switch(titleSetting){
                                        case 'job_class':
                                            targetArray = filterData.job_classes;
                                            targetArray = filterData.job_classes.map((job) => { 
                                                return {
                                                    'name': job.name.replace(/ *\([^)]*\) */g,'')
                                                    .replace(/ *, *and */g,conditionString)
                                                    .replace(/ *, */g,conditionString)
                                                    .replace(/ *and */g,conditionString)
                                                    .replace(/ *& */g,conditionString)
                                                    .replace(/ *\/ */g,conditionString)
                                                    .replace(/ *\\ */g,conditionString)
                                                    .replace(/  */g,' ')
                                                }
                                            });
                                            break;
                                        case 'job_group':
                                            targetArray = filterData.job_groups;
                                            targetArray = filterData.job_groups.map((job) => {
                                                return {
                                                    'name': job.name.replace(/ *\([^)]*\) */g,'')
                                                    .replace(/ *, *and */g,conditionString)
                                                    .replace(/ *, */g,conditionString)
                                                    .replace(/ *and */g,conditionString)
                                                    .replace(/ *& */g,conditionString)
                                                    .replace(/ *\/ */g,conditionString)
                                                    .replace(/ *\\ */g,conditionString)
                                                    .replace(/  */g,' ')
                                                }
                                            });
                                            break;
                                        case 'job_code':
                                            targetArray = filterData.job_codes.map((job) => {
                                                return {
                                                    'name': job.name.replace(/ *\([^)]*\) */g,'')
                                                    .replace(/ *, *and */g,conditionString)
                                                    .replace(/ *, */g,conditionString)
                                                    .replace(/ *and */g,conditionString)
                                                    .replace(/ *& */g,conditionString)
                                                    .replace(/ *\\\/ */g,conditionString)
                                                    .replace(/ *\/ */g,conditionString)
                                                    .replace(/ *\\ */g,conditionString)
                                                    .replace(/  */g,' ')
                                                }
                                            });
                                            break;
                                        case 'id':
                                            targetArray = filterData.job_titles_clean.map((job) => {
                                                return {
                                                    'name': job.name.replace(/ \D* *-*\D* *- /gi,'')
                                                    .replace(/ *\(\d+\) */g,'')
                                                    .replace(/\(/g,'')
                                                    .replace(/\)/g,'')
                                                    .replace(/ *\D*\. */g,'')
                                                    .replace(/ *, *and */g,conditionString)
                                                    .replace(/ *, */g,conditionString)
                                                    .replace(/ *and */g,conditionString)
                                                    .replace(/ *& */g,conditionString)
                                                    .replace(/ *\/ */g,conditionString)
                                                    .replace(/ *\\ */g,conditionString)
                                                    .replace(/  */g,' ')
                                                }
                                            });
                                            break;
                                        default:
                                            alert(`Something went wrong when determining job title query depth`);
                                            break;
                                    };
                                    resultString = targetArray.map(function(val){
                                        return `"${val.name}"`;
                                    }).join(condition);
                                    // resultString = thisFilter.selectValue.map(function(val){
                                    //     return `"${val.name}"`;
                                    // }).join(condition);
                                    break;
                                case 'form-tags':
                                    resultString = thisFilter.selectValue.map(function(val){
                                        return `"${val}"`;
                                    }).join(condition);
                                    break;
                                case 'form-multidrop':
                                    resultString = thisFilter.selectValue.map(function(val){
                                        return `"${val.name}"`;
                                    }).join(condition);
                                    break;
                                default:
                                    break;
                            };
                        };

                        // console.log(resultString);
                        filtersInstance.copyToClipboard(`(${resultString})`);
                    });
                });

            },
            copyLinkedInYears: function(event){
                let jobExpGroup = $(`.filters--group`)[1];
                let fromYears = $(jobExpGroup).find(`.form-number`)[0].__vue__.$children[0].numberValue;
                let toYears = $(jobExpGroup).find(`.form-number`)[1].__vue__.$children[0].numberValue;
                let resultString = `${fromYears} years`;
                if(fromYears >= 0 && toYears > fromYears){
                    resultString = `${fromYears} - ${toYears} years`;
                };
                // console.log(resultString)
                this.copyToClipboard(resultString);
            }
        }
    }
</script>
