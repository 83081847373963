<template>
	<div class="profile__input--wrapper profile__input--date" v-bind:class="{'profile__input--time': hastime}">
		<div 
            class="profile__container--datepicker date-temp"
            v-on:click="showDatepicker">
			    <i class="flaticon2-calendar-9 calendar-icon input-datepicker"></i>
		</div>
		<input 
			type="date" 
			class="form__input--date datepicker form-data-target"
            v-bind:disabled="isdisabled"
            v-bind:name="inputname"
            v-model="value"
            v-bind:id="fieldId">
		<div v-if="errors.length > 0" class="login-form__warning--icon" v-bind:title="errors[0]"><p>!</p></div>
		<!-- <div 
			v-if="errors.length > 0"
			class="error-explanation">
				{{ errors[0] }}
		</div> -->
	</div>
</template>


<script>
    import flatpickr from "flatpickr";
    
    export default {
        props: [
            'inputname',
            'inputid',
            'startvalue',
            'haserrors',
            'isdisabled',
            'hastime'
        ],
        data: function(){
            return {
                value: this.startvalue || '',
                fieldId: this.inputid,
                errors: this.haserrors || [],
                flatpickerInstance: null
            }
        },
        mounted: function(){
            // console.log(this.startvalue);

            let dateInputSelector = "#" + this.fieldId;
            let utcDate;
            let firstDate;
            let zoneOffset = 0;

            let startDate = new Date(this.startvalue || null);
            if(startDate == 'Invalid Date'){ 
                startDate = new Date(this.startvalue.slice(0,18));
                utcDate = startDate;
            }else{
                if(this.hastime){ zoneOffset = startDate.getTimezoneOffset() };
                utcDate = new Date(startDate.getTime() + zoneOffset*60*1000);
            };

            // console.log(this.startvalue);
            // console.log(startDate);
            // console.log(startDate.getTime())
            // console.log(utcDate);
            if(this.startvalue){ firstDate = utcDate.toISOString() };

            if(this.hastime){
                this.flatpickerInstance = flatpickr(dateInputSelector, {
                    dateFormat: "Y/m/d H:i",
                    altFormat: 'd-M-Y H:i',
                    altInput: true,
                    enableTime: true,
                    defaultDate: firstDate,
                    defaultHour: 23,
                    defaultMinute: 59,
                    time_24hr: true
                });
            }else{
                this.flatpickerInstance = flatpickr(dateInputSelector, {
                    dateFormat: "Y/m/d",
                    altFormat: 'd-M-Y',
                    defaultDate: firstDate,
                    altInput: true
                }); //  disableMobile: true
            };
        },
        created: function(){
            // console.log(`initializing form-date field with title - ${this.$parent.title}`);
            // console.log(this.$props.startvalue);
        },
        updated: function(){ 
            // console.log(`updating form-date input with new value - ${this.value}`);
            this.$emit('event-bubble',{ data: this.value }) 
        },
        methods: {
            showDatepicker: function(event){
                // console.log('showDatepicker');
                let datepcikerContainer = event.target;
                if(datepcikerContainer.className.indexOf('flaticon') != -1){
                    datepcikerContainer = datepcikerContainer.parentElement;
                };
                $(datepcikerContainer).find(` ~ .form__input--date:not([type=hidden])`)[0].focus();
            }   
        }
    }
</script>