<template>
    <div class="profile__input--wrapper">
        <div 
            class="profile__container--dropdown dropdown-multiple"
            v-on:click.stop="toggleDropdown"
            v-on:keydown="handleKeyboard">
                <div 
                    v-on:focus="handleDropdownFocus"
                    v-on:blur="handleDropdownBlur"
                    class="dropdown__container--values dropdown__display--flex" 
                    style="display: inline-block;" 
                    tabindex="0">
                        <div 
                            class="dropdown-value dropdown-selected dropdown-placeholder"
                            v-bind:class="{ 'dropdown-placeholder--none':  selectValue.length }"
                        ></div>
                        <div 
                            class="dropdown-value dropdown-selected"
                            v-for="item in selectValue"
                            v-bind:key="item.vue_key"
                            v-bind:value="item.id">
                                <p 
                                    v-on:focus="handleDeselectFocus"
                                    v-on:blur="handleDeselectBlur"
                                    v-on:click.stop="deselectOption(item)" 
                                    tabindex="0" 
                                    class="dropdown-deselect">
                                        X
                                </p>
                                <p 
                                    class="dropdown-selected-title">
                                    {{ item.name }}
                                </p>
                        </div>
                </div>
                <input 
                    v-if="searchurl"
                    type="text" 
                    class="dropdown-search" 
                    v-bind:class="{ 'profile__container--show': toggleState}"
                    v-bind:value="searchQuery"
                    v-on:click.stop=""
                    v-on:input="sendQuery">
                <div class="profile__container--options options-grouped" v-bind:class="{ 'profile__container--show': toggleState, 'profile__container--showUP': toggleUp}">
                    <!--
                        20/06/2024 - for now remove highlight functionality,
                        because it not works properly
                        class="profile__option--parent"
                        v-for="(item, index) in dropdownOptions"
                    -->
                    <div
                        class="profile__option--parent"
                        v-for="(item) in dropdownOptions"
                        v-bind:key="item.vue_key">
                            <!--
                                20/06/2024 - for now remove highlight functionality,
                                because it not works properly
                                class="profile__option option-group"
                                v-bind:class="{ 'mark-keyboardFocus': highlightIndex == index}" 
                            -->
                            <div 
                                v-if="item.id == 'is-group'"
                                class="profile__option option-group"
                                v-bind:key="item.vue_key"
                                v-bind:value="item.id"
                                v-on:keypress.prevent=""
                                v-on:click.stop="">
                                    {{ item.name }}
                            </div>

                            <!--
                                20/06/2024 - for now remove highlight functionality,
                                because it not works properly
                                class="profile__option"
                                v-bind:class="{ 'mark-keyboardFocus': highlightIndex == index}" 
                            -->
                            <div 
                                v-if="item.id != 'is-group'"
                                class="profile__option"
                                v-bind:key="item.vue_key"
                                v-bind:value="item.id"
                                v-on:click.stop="selectOption(item)"
                                v-bind:title="`Group | Class - ${item.group}`">
                                    {{ item.name }}
                            </div>
                    </div>

                </div>
            
        </div>
        <select class="hidden_select form-data-target" v-bind:name="inputname" v-bind:id="inputid" multiple="multiple">
            <option
                selected
                v-for="item in selectValue"
                v-bind:key="item.id"
                v-bind:value="item.id">
                    {{ item.name }}
            </option>
            <option
                selected
                v-if="selectValue.length == 0"
                value=""
                style="display: none;">
                    {{ selectValue.length }}
            </option>
        </select>
		<div v-if="haserrors" class="login-form__warning--icon" v-bind:title="haserrors[0]"><p>!</p></div>
    </div>
</template>

<script>
    import Ajax from './js/ajax.js'
    import Align from './js/align.js'
    import CheckDuplicate from './js/checkDuplicate.js'
    import OrderArray from './js/order-array.js'
    import SearchHandler from './mixins/search_handler.js'

    export default {
        props: [
            'inputname',
            'inputid',
            'startvalue',
            'alloptions',
            'selectall',
            'searchurl',
            'preload',
            'preloadquery',
            'haserrors'
        ],
        mixins: [
            SearchHandler
        ],
        data: function(){
            return {
                storedOptions: '',
                selectValue: this.startvalue || [],
                dropdownOptions: this.alloptions || [],
                toggleState: false,
                toggleUp: false,
                eventCount: 0,
                // highlightIndex: 1,
                searchQuery: null,
                initialQuery: {'target': {'value': '   '}, 'initial': 1},
                lastVueKey: -1,
                optionsLoaded: false
            }
        },
        created: function(){
            //console.log(this.startvalue);
            if(Number(this.preload)){
                //console.log('preload options');
                Ajax.sendRequest('GET',{ 'q': (this.preloadquery || '') },this.searchurl,this.processResponse,this);
            }else{
                //console.log('dont option preload');
            };
        },
        mounted: function(){ this.initializeOptions(this.startvalue || [],this.alloptions || []) },
        watch: {
            dropdownOptions: function(newOptions,oldOptions){
                //console.log(`option watch hook`);
                this.optionsLoaded = Boolean(this.dropdownOptions.length);
                //console.log(`options loaded - ${this.optionsLoaded}`);
                //console.log(newOptions);
                //console.log(oldOptions);
                //console.log(this.dropdownOptions);
            }
        },
        methods: {
            //KEYBOARD HANDLERS:
            handleDeselectFocus: function(event){
                //console.log('deselect focus');
                let targetOptionValue = $(event.target.parentElement).attr('value');
                let targetOptionText = $(event.target.parentElement).find('.dropdown-selected-title')[0].innerText;
                let targetOptionFull = {
                    name: targetOptionText,
                    id: targetOptionValue
                };
                $(event.target).bind('keydown',(event) => {
                    if(event.key == "Enter"){
                        //console.log('enter pressed, call deselectOption');
                        this.deselectOption(targetOptionFull);
                        $(event.target).unbind('keydown');
                    };
                });
            },
            handleDeselectBlur: function(event){ $(event.target).unbind('keydown') },
            handleDropdownFocus: function(event){
                //console.log('dropdown focus',event);
                $(event.target).bind('keypress',(event) => {
                    //console.log(event);
                    if(event.key == "Enter"){
                        this.toggleDropdown(event);
                    };
                });
            },
            handleDropdownBlur: function(event){ $(event.target).unbind('keypress') },
            handleKeyboard: function(event){
                //console.log('handle keyboard',event);

                event.stopPropagation();
                // if(event.key != 'Tab' && event.key != 'Enter'){ event.preventDefault() };
                if(event.target.className.indexOf('dropdown-deselect') == -1){
                    if(event.key == "Escape"){
                        this.toggleState = true;
                        this.toggleDropdown(event);
                        return;
                    };
                    if(event.key == "ArrowUp"){
                        // this.highlightIndex += -1;
                    };
                    if(event.key == "ArrowDown"){
                        // this.highlightIndex += 1;
                    };
                    //console.log(`
                    //     new highlight index = ${this.highlightIndex}
                    //     options length = ${this.dropdownOptions.length}
                    // `);
                    if(this.dropdownOptions.length){
                        let optionLength = this.dropdownOptions.length;
                        // let targetIndex = (this.highlightIndex + optionLength) % optionLength;
                        // this.highlightIndex = targetIndex || 0;
                        this.$nextTick(() => {
                            let markedElement = $(event.target.parentElement).find('.mark-keyboardFocus')[0];
                            if(markedElement){
                                // if(markedElement.className.indexOf('option-group') != -1){
                                //     this.handleKeyboard(event);
                                //     return;
                                // };

                                let optionsContainer = $(event.target.parentElement).find('.profile__container--options')[0];
                                optionsContainer.scrollTop = markedElement.offsetTop;
                                if(event.key == "Enter" && this.toggleState){
                                    //console.log('Enter pressed, selectOption');
                                    let newOptionFull = {
                                        name: markedElement.innerText,
                                        id: $(markedElement).attr('value')
                                    };
                                    event.preventDefault();
                                    if(newOptionFull.id != 'is-group'){ this.selectOption(newOptionFull) };
                                };     
                            };
                        });
                    };
                };
            },
            //TOGGLE DROPDOWN METHODS:
            toggleDropdown: function(event){
                //console.log('toggle multigroup-dropdown',event);
                if(event.target.className.indexOf('profile__option--parent') != -1 && event.target.children[0].className.indexOf('option-group') != -1){ return };
                if(this.$el.parentElement.className.indexOf('field--disabled') != -1){ return };

                let openDropdowns = $(this.$root.$el).find(`.profile__container--show`);
                this.toggleState = !this.toggleState;
                this.toggleUp = this.toggleState && Align.vertical(this);
                if(this.toggleState){
                    if(this.dropdownOptions.length <= 0){
                        //console.log(`empty options, re-initialize`);
                        Ajax.sendRequest('GET',{ 'q': (this.preloadquery || '') },this.searchurl,this.processResponse,this);
                    };
                    $(openDropdowns).each(function(dropdown_index){ this.click() });
                    //console.log(`bind outside click listener`);
                    $(`body`).bind(`click`,this.toggleDropdown);
                    // if(this.optionsLoaded){ while(this.dropdownOptions[this.highlightIndex].id == 'is-group'){ this.highlightIndex++ } };
                    if(this.searchurl){ this.$nextTick(() => { $(this.$el).find(`.dropdown-search`)[0].focus() }) };
                }else{
                    //console.log(`unbind outside click listener`);
                    $(`body`).unbind(`click`,this.toggleDropdown);
                };
            },
            initializeOptions: function(rawSelected,rawOptions){
                //console.log(`initializing options`);
                //console.log(rawSelected);
                //console.log(rawOptions);
                this.lastVueKey = -1;
                rawSelected.forEach((Value) => {
                    this.lastVueKey++;
                    Value.vue_key = this.lastVueKey;
                });

                rawOptions.forEach((Option) => {
                    this.lastVueKey++;
                    Option.vue_key = this.lastVueKey;
                });

                this.selectValue = rawSelected;
                this.dropdownOptions = rawOptions;
            },
            updateOptions: function(groupedOptions){
                //console.log(`updating options`);
                //console.log(groupedOptions);
                groupedOptions = groupedOptions || [];
                let pureOptions = [];

                groupedOptions.forEach((Group) => {
                    pureOptions.push({ 'name': Group.group, 'id': 'is-group', 'vue_key': this.lastVueKey });
                    Group.items.forEach((Item) => { Item.group = Group.group; pureOptions.push(Item) });
                });

                if(pureOptions.length){
                    $(this.selectValue).each(function(value_index){
                        let thisDupes = CheckDuplicate.isDuped(pureOptions,this);
                        if(thisDupes.found){ pureOptions.splice(thisDupes.index,1) };
                    });
                };

                this.initializeOptions(this.selectValue,pureOptions);
                // this.initializeOptions(this.selectValue,groupedOptions);
            },
            selectOption: function(thisSelected){
                //console.log('selecting option');
                //console.log(thisSelected);
                let vueInstance = this;
                let dupes = CheckDuplicate.isDuped(this.dropdownOptions,thisSelected);
                this.dropdownOptions.splice(dupes.index,1);
                this.selectValue.push(thisSelected);
                this.$emit('event-bubble',{data: thisSelected, instance: this});
                this.$nextTick(() => {
                    $(this.$el)
                    .find(`.dropdown__container--values`)[0]
                    .scrollBy(0,9999);

                    // while(this.dropdownOptions[this.highlightIndex].id == 'is-group'){ this.highlightIndex++ };
                    //console.log(`order selected options by name`);
                    // this.selectValue = OrderArray.vueObjects(this.selectValue);
                });
            },
            deselectOption: function(thisDeselected){
                if(this.$el.parentElement.className.indexOf('field--disabled') != -1){ return };
                //console.log('deselect option');
                let selectDupes = CheckDuplicate.isDuped(this.selectValue,thisDeselected);
                if(selectDupes.found){
                    //console.log(`found deselected option in current selected`);
                    this.selectValue.splice(selectDupes.index,1);
                }else{
                    //console.log('deselected option not found in current selected');
                };
                let optionDupes = CheckDuplicate.isDuped(this.dropdownOptions,thisDeselected);
                if(optionDupes.found){
                    //console.log(`found deselected option in current options`);
                }else{
                    //console.log('deselected option not found in current options');
                    this.dropdownOptions.push(thisDeselected);
                };
                Ajax.sendRequest('GET',{ 'q': (this.preloadquery || '') },this.searchurl,this.processResponse,this);
                this.$emit('event-bubble',{data: thisDeselected, instance: this});
            }
        }
    }
</script>