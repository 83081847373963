<template>
    <div class="staff-lists__wrapper">
        <h3>Personal distribution lists</h3>
        <button v-if="!showListCreator" class="single-note__button--sourcing-task" v-on:click="showListCreator = true">New list</button>

        <div v-if="showListCreator" class="comment-form">
            <h3>New list</h3>

            <form-field
                title="Name"
                fieldtype="form-input"
                addclass="staff-list__info"
                v-bind:inputname="`list_name[new]`"
                startvalue=""
                v-on:event-bubble="setListName($event)"
            ></form-field>

            <form-field
                title="Members"
                fieldtype="form-multidrop"
                addclass="staff-list__info"
                v-bind:inputname="`list_members[new]`"
                selectall="true"
                preload="0"
                searchurl="/autocomplete/staff"
                v-bind:startvalue="[]"
                v-bind:alloptions="[]"
                v-on:event-bubble="setListMembers($event)"
            ></form-field>

            <div class="comment-form__errors">
                <div v-for="error in newListErrors" v-bind:key="error" class="error-message" style="color: red;">
                    {{ error }}
                </div>
            </div>

            <div class="comment-form__buttons">
                <button class="single-note__button--sourcing-task" v-on:click="createList">Create</button>
                <button class="single-note__button--sourcing-task" v-on:click="showListCreator = false">Cancel</button>
            </div>
        </div>


        <div class="staff-lists__container">
            <div v-for="list in lists" v-bind:key="list.id" class="staff-list__container">
                <form-field
                    title="Name"
                    fieldtype="form-input"
                    addclass="staff-list__info"
                    v-bind:inputname="`list_name[${list.id}]`"
                    v-bind:startvalue="list.name"
                    v-on:event-bubble="setListName($event,list)"
                ></form-field>

                <form-field
                    title="Members"
                    fieldtype="form-multidrop"
                    addclass="staff-list__info"
                    v-bind:inputname="`list_members[${list.id}]`"
                    selectall="true"
                    preload="0"
                    searchurl="/autocomplete/staff"
                    v-bind:startvalue="list.members"
                    v-bind:alloptions="[]"
                    v-on:event-bubble="setListMembers($event,list)"
                ></form-field>

                <div class="comment-form__buttons">
                    <button class="single-note__button--sourcing-task" v-on:click="updateList(list)">Update</button>
                    <button class="single-note__button--sourcing-task" v-on:click.prevent="deleteList(list)">Delete</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Ajax from '../form-field/sub-components/js/ajax.js'
import FormField from '../form-field/form-field.vue'

export default {
    props: [
        'stafflists'
    ],
    data: function(){
        console.log(`STAFF LISTS!`,this);
        return {
            lists: this.stafflists || [],
            showListCreator: false,
            newListMembers: [],
            newListName: '',
            newListErrors: []
        };
    },
    components: { Ajax, FormField },
    methods: {
        setListName(event,list){
            if(!list){
                this.newListName = event.data;
            }else{
                list.name = event.data;
            };
        },
        setListMembers(event,list){
            if(!list){
                this.newListMembers = event.instance.selectValue;
            }else{
                list.members = event.instance.selectValue;
            };
        },
        createList: function(){
            const Data = {};
            Data.name = this.newListName;
            Data.staff_ids = this.newListMembers.map((Member) => { return Member.id }); 

            Ajax.request('POST',Data,'/staff/staff-lists',(res) => {
                console.log(`SUCCESS`,JSON.parse(res));

                const response = JSON.parse(res);
                if(response.errors && response.errors.length){
                    this.newListErrors = response.errors;
                }else{
                    this.newListErrors = [];
                    window.location.reload();
                };
            },this,(response) => {
                console.log(`FAIL`,response);
            });
        },
        updateList(list){
            const Data = {};
            Data.name = list.name;
            Data.staff_ids = list.members.map((Member) => { return Member.id }); 

            Ajax.request('POST',Data,`/staff/staff-lists/${list.id}`,(response) => {
                window.location.reload();
            },this,(response) => {
                window.location.reload();
            });
        },
        deleteList(list){
            if(confirm(`Are you sure you want to delete ${list.name}?`)){
                Ajax.request('DELETE',{},`/staff/staff-lists/${list.id}`,(response) => {
                    window.location.reload();
                },this,(response) => {
                    window.location.reload();
                });
            };
        }
    }
}
</script>