<template>
    <div class="filters--wrapper">
        <div
            class="filters--copy"
            title="Copy filters' url"
            v-on:click="copyFilters()">
                <i class="flaticon2-copy"></i>
        </div>

        <form-field
            title="Show hidden"
            fieldtype="form-checkbox"
            inputname="q[show_hidden]"
            addclass="filter-target filter-hidden"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.show_hidden"
        ></form-field>

        <form-field
            title="Search term"
            fieldtype="form-input"
            inputname="q[term]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.term"
        ></form-field>

        <form-field
            title="Type"
            fieldtype="form-dropdown"
            inputname="q[candidate_type_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.candidate_type"
            v-bind:alloptions="formOptions.candidate_type_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Intake"
            fieldtype="form-dropdown"
            inputname="q[intake]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.intake"
            v-bind:alloptions="formOptions.intake_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Work status"
            fieldtype="form-dropdown"
            inputname="q[identification_work_status_approved]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.identification_work_status_approved"
            v-bind:alloptions="formOptions.work_status_options"
            addblank="true"
            blanktext="Any"
        ></form-field>


        <form-field
            title="Blacklisted"
            fieldtype="form-dropdown"
            inputname="q[blacklist]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.blacklist"
            v-bind:alloptions="formOptions.boolean_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

<!--         <form-field
            title="Blacklist"
            fieldtype="form-checkbox"
            inputname="q[blacklist]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.blacklist"
        ></form-field>
 -->

        <form-field
            title="Under offer"
            fieldtype="form-dropdown"
            inputname="q[under_offer]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.under_offer"
            v-bind:alloptions="formOptions.boolean_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Active"
            fieldtype="form-dropdown"
            inputname="q[active_nonactive]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.active_nonactive"
            v-bind:alloptions="formOptions.boolean_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Gdpr (no contact)"
            fieldtype="form-dropdown"
            inputname="q[gdpr]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.gdpr"
            v-bind:alloptions="formOptions.boolean_options"
            addblank="true"
            blanktext="Any"
        ></form-field>




<!--         <form-field
            title="From date"
            fieldtype="form-date"
            inputname="q[from_date]"
            v-bind:startvalue="appliedFilters.from_date"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
        ></form-field>

        <form-field
            title="To date"
            fieldtype="form-date"
            inputname="q[to_date]"
            v-bind:startvalue="appliedFilters.to_date"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
        ></form-field>
 -->
        <form-field
            title="Location BL"
            fieldtype="form-dropdown"
            inputname="q[bl_location_id]"
            addclass="filter-target filter-location-bl"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.bl_location_id"
            v-bind:alloptions="formOptions.bluelynx_location_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Contractor BLE"
            fieldtype="form-dropdown"
            inputname="q[works_for_ble]"
            addclass="filter-target"
            v-bind:startvalue="appliedFilters.works_for_ble"
            v-bind:alloptions="formOptions.works_for_ble_options"
            v-on:event-bubble="handleChange"
            addblank="true"
            blanktext="Any"
        ></form-field>

		<form-field
            title="Candidate flag"
			fieldtype="form-dropdown"
			inputname="q[candidate_flag_id]"
            addclass="filter-target"
			icon="dropdown-colors-mode"
			v-bind:startvalue="appliedFilters.candidate_flag"
            v-bind:alloptions="formOptions.candidate_flag_options"
            v-on:event-bubble="handleChange"
            addblank="true"
            blanktext="Any"
		></form-field>

        <form-field
            title="Heard of BL"
            fieldtype="form-dropdown"
            inputname="q[heard_of_blue_lynx_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.heard_of_blue_lynx"
            searchurl="/autocomplete/heard-of-blue-lynxes"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Registered on"
            fieldtype="form-dropdown"
            inputname="q[registered_on]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.registered_on"
            searchurl="/autocomplete/registered-on-options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <form-field
            title="Country"
            fieldtype="form-dropdown"
            inputname="q[preferred_country_id]"
            addclass="filter-target"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.preferred_country"
            searchurl="/autocomplete/countries"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <!-- 
            Adding 'filter-start-with' and 'js-filter-relocate' classes to below form-field element
            which are used to style and reposition the filter respectively 
        -->
        <form-field
            title="Start with"
            fieldtype="form-dropdown"
            inputname="q[start_with_letter]"
            addclass="filter-target filter-start-with js-filter-relocate"
            v-on:event-bubble="handleChange"
            v-bind:startvalue="appliedFilters.start_with_letter"
            v-bind:alloptions="formOptions.start_with_letter_options"
            addblank="true"
            blanktext="Any"
        ></form-field>

        <div class="js-filter-hidden">
            <i class="flaticon-eye eye-green"><p>/</p></i>
            <div class="filter-info">Toggle show/hide hidden candidates</div>
        </div>

        <input type="hidden" name="sort_order" v-bind:value="appliedFilters.sort_order"/>
        <input type="hidden" name="sort_by" v-bind:value="appliedFilters.sort_by"/>
    </div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'

    export default {
        props: [
            'formOptionsData',
            'appliedFiltersData'
        ],
        data: function(){
            var appliedFilters = JSON.parse(this.appliedFiltersData || '{}');
            var formOptions = JSON.parse(this.formOptionsData || '{}');

            // console.log(appliedFilters);
            // console.log(formOptions);

            return { 
                formOptions: formOptions,
                appliedFilters: appliedFilters,
                requestCounter: 0
            };
        },
        components: {
            FormField
        },
        methods: {
            handleChange: function(event){ 
                this.requestCounter++;
                let localCounter = this.requestCounter;
                let waitTime = 1000;
                console.log(`before next tick ${this.requestCounter}, ${localCounter}`)
                this.$nextTick(() => { 
                    console.log(`after next tick ${this.requestCounter}, ${localCounter}`)
                    setTimeout(() => { 
                        console.log(`after timeout ${this.requestCounter}, ${localCounter}`)
                        if(localCounter == this.requestCounter){ $(`#candidate-filter-form`).submit() };
                    },waitTime);
                }) 
            },
            copyFilters: async function(){
                try {
                    await navigator.clipboard.writeText(window.location.href);
                    document.activeElement.blur();
                } catch (error) {
                    alert(`Error when copying to clipboard`, error)
                }
            }
        }
    }
</script>