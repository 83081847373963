<template>
<div class="profile__container--wrapper profile__container--columns-3">

    <div class="profile__container--outer profile__container--details">
        <div class="profile__container--header">
            <h3>Staff details</h3>
        </div>
        <div class="profile__container--main">
            <form-field
                title="System ID"
                fieldtype="form-input"
                inputname="staff[code]"
                v-bind:startvalue="staff.code"
                v-bind:haserrors="staff.errors.code"
                v-bind:isdisabled="staff.is_system_user"
            ></form-field>

            <form-field
                title="Surname"
                fieldtype="form-input"
                inputname="staff[last_name]"
                v-bind:startvalue="staff.last_name"
                v-bind:haserrors="staff.errors.last_name"
            ></form-field>

            <form-field
                title="First name"
                fieldtype="form-input"
                inputname="staff[first_name]"
                v-bind:startvalue="staff.first_name"
                v-bind:haserrors="staff.errors.first_name"
            ></form-field>

            <form-field
                title="Initials"
                fieldtype="form-input"
                inputname="staff[initials]"
                v-bind:startvalue="staff.initials"
                v-bind:haserrors="staff.errors.initials"
            ></form-field>

            <form-field
                title="Nickname"
                fieldtype="form-input"
                inputname="staff[nickname]"
                v-bind:startvalue="staff.nickname"
                v-bind:haserrors="staff.errors.nickname"
            ></form-field>

            <form-field
                title="Gender"
                fieldtype="form-dropdown"
                inputname="staff[gender]"
                v-bind:startvalue="staff.gender"
                v-bind:alloptions="formOptions.gender_options"
                v-bind:haserrors="staff.errors.gender"
            ></form-field>

            <form-field
                title="Date of birth"
                fieldtype="form-date"
                inputname="staff[date_of_birth]"
                v-bind:startvalue="staff.date_of_birth"
                v-bind:error="staff.date_of_birth"
                v-bind:haserrors="staff.errors.date_of_birth"
            ></form-field>

            <form-field
                title="Blue Lynx location"
                fieldtype="form-dropdown"
                inputname="staff[bluelynx_location_id]"
                v-bind:startvalue="staff.bluelynx_location_id"
                v-bind:alloptions="formOptions.bluelynx_location_options"
                v-bind:haserrors="staff.errors.bluelynx_location_id"
            ></form-field>

            <form-field
                title="Partake in recruiter ranking"
                inputname="staff[participate_in_ranking]"
                fieldtype="form-radio"
                v-bind:startvalue="staff.participate_in_ranking"
                v-bind:alloptions="[{ name: 'Yes', id: true }, { name: 'No', id: false }]"
                v-bind:haserrors="staff.errors.participate_in_ranking"
            ></form-field>

            <form-field
                title="Employment start date"
                fieldtype="form-date"
                inputname="staff[employment_date]"
                v-bind:startvalue="staff.employment_date"
                v-bind:error="staff.employment_date"
                v-bind:haserrors="staff.errors.employment_date"
            ></form-field>

            <form-field
                title="Employment end date"
                fieldtype="form-date"
                inputname="staff[employment_end_date]"
                v-bind:startvalue="staff.employment_end_date"
                v-bind:error="staff.employment_end_date"
                v-bind:haserrors="staff.errors.employment_end_date"
            ></form-field>

            <form-field
                title="FTE Employment status"
                fieldtype="form-dropdown"
                inputname="staff[fte]"
                addblank="true"
                v-bind:startvalue="staff.fte"
                v-bind:alloptions="formOptions.staff_fte_options"
                v-bind:haserrors="staff.errors.fte"
            ></form-field>
        </div>
    </div>
    <div class="profile__container--outer profile__container--contacts">
        <div class="profile__container--header">
            <h3 style="visibility: hidden;">HIDDEN HEADER</h3> 
        </div>
        <div class="profile__container--main">

            <form-field
                title="Phone"
                fieldtype="form-input"
                inputname="staff[direct_phone]"
                v-bind:startvalue="staff.direct_phone"
                v-bind:haserrors="staff.errors.direct_phone"
                icon="phone"
            ></form-field>

            <form-field
                title="Mobile"
                fieldtype="form-input"
                inputname="staff[mobile]"
                v-bind:startvalue="staff.mobile"
                v-bind:haserrors="staff.errors.mobile"
                icon="phone"
            ></form-field>

            <form-field
                title="E-mail"
                fieldtype="form-input"
                inputname="staff[authentication_attributes][email]"
                v-bind:startvalue="staff.authentication.email"
                v-bind:haserrors="staff.authentication.errors.email"
                icon="mail"
            ></form-field>

            <div class="profile__container--field profile__container--image">
                <p>Image (portrait):</p>
                <div class="profile__input--wrapper profile__container--avatar">
                    <img
                     v-bind:src="staff.image.base64_image || staff.image.placeholder"
                     class="js-image-preview-target"
                    />

                   <!--  <input type="hidden" name="staff[image]" v-bind:value="staff.cached_image_data" id="staff_cached_image" /> -->
                    <i class="flaticon2-clip-symbol select-file">
                        <input type="file" name="staff[image]" class="js-image-preview-source" accept="image/*" />
                    </i>
                </div>
            </div>

        </div>
    </div>
    <div class="profile__container--outer profile__container--status profile__container--columnPos-3">
        <div class="profile__container--header">
            <h3>Status & Access Rights</h3>
        </div>
        <div class="profile__container--main">
            <form-field
                title="Staff role"
                fieldtype="form-dropdown"
                inputname="staff[staff_role_id]"
                v-bind:startvalue="staff.staff_role_id"
                v-bind:alloptions="formOptions.staff_role_options"
                v-bind:haserrors="staff.errors.staff_role_id"
            ></form-field>

            <form-field
                title="Department"
                fieldtype="form-dropdown"
                inputname="staff[staff_department_id]"
                v-bind:startvalue="staff.staff_department_id"
                v-bind:alloptions="formOptions.staff_department_options"
                v-bind:haserrors="staff.errors.staff_department_id"
            ></form-field>

            <form-field
                title="Is sourcer"
                inputname="staff[is_sourcer]"
                fieldtype="form-radio"
                v-bind:startvalue="staff.is_sourcer"
                v-bind:alloptions="[{ name: 'Yes', id: true }, { name: 'No', id: false }]"
                v-bind:haserrors="staff.errors.is_sourcer"
            ></form-field>

            <form-field
                title="Login disabled"
                inputname="staff[authentication_attributes][login_disabled]"
                fieldtype="form-radio"
                v-bind:startvalue="staff.authentication.login_disabled"
                v-bind:alloptions="[{ name: 'Yes', id: true }, { name: 'No', id: false }]"
                v-bind:haserrors="staff.authentication.errors.login_disabled"
            ></form-field>
        </div>
    </div>

</div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'

    export default {
        props: [
            'recordData',
            'formOptionsData'
        ],
        data: function(){
            var staff = JSON.parse(this.recordData || '{}');
            var formOptions = JSON.parse(this.formOptionsData || '{}');

            // console.log(staff); console.log(formOptions);

            return { 
                staff: staff, 
                formOptions: formOptions
            };
        },
        components: {
            FormField
        }
    }
</script>