<template>
    <form method="GET" :url="settings.submit_url" id='list-filter-form' class="filters--parent" autocomplete="off">
    

        <div class="filters__container--wrapper">
            <div class="filters__container--toggle filters__toggle--active">
                <p>Filters</p>
                <div class="filters__arrow filters__toggle--flip"><</div>
            </div>
            <div class="filters__container--dropdown table-filters">


                <div class="filters--wrapper">
                    <div
                        class="filters--copy"
                        title="Copy filters' url"
                        v-on:click="copyFilters()">
                            <i class="flaticon2-copy"></i>
                    </div>


                    <div 
                        v-for="(formFilter, index) in formFilters"
                        v-bind:key="index"
                        class="">
                        <form-field
                            v-if="formFilter.component_type == 'form-field'"
                            v-bind:title="formFilter.title"
                            v-bind:fieldtype="formFilter.type"
                            v-bind:inputname="formFilter.name"
                            v-bind:startvalue="formFilter.value"
                            v-bind:haserrors="formFilter.errors"
                            v-bind:searchurl="formFilter.searchurl"
                            v-bind:preload="formFilter.preload"
                            v-bind:alloptions="formFilter.alloptions"
                            v-bind:isdisabled="formFilter.isdisabled"
                            v-bind:addblank="formFilter.addblank"
                            addclass="filter-target"
                            v-on:event-bubble="handleChange"
                        ></form-field>

          
                    </div>

                    <input type="hidden" name="sort_order" v-bind:value="settings.sort_order"/>
                    <input type="hidden" name="sort_by" v-bind:value="settings.sort_by"/>
                </div>



            </div>
        </div>

    </form>



</template>

<script>
    import FormField from './../../form-field/form-field.vue'

    export default {
        props: [
            'filtersData',
            'settingsData'
        ],
        data: function(){
            var filtersData = JSON.parse(this.filtersData || '{}');
            var settingsData = JSON.parse(this.settingsData || '{}');

            // console.log(appliedFilters);
            // console.log(formOptions);

            return { 
                formFilters: filtersData,
                settings: settingsData,
                requestCounter: 0
            };
        },
        components: {
            FormField
        },
        methods: {
            handleChange: function(event){ 
                this.requestCounter++;
                let localCounter = this.requestCounter;
                let waitTime = 1000;
                this.$nextTick(() => { 
                    setTimeout(() => { if(localCounter == this.requestCounter){ $(`#list-filter-form`).submit() } },waitTime);
                });
            },
            copyFilters: async function(){
                try {
                    await navigator.clipboard.writeText(window.location.href);
                    document.activeElement.blur();
                } catch (error) {
                    alert(`Error when copying to clipboard`, error)
                }
            }
        }
    }
</script>