<template>
    <div class="search-table--wrapper" v-bind:class="{ 'hide-table': !showClass, 'depth-group': depth == 'job_group', 'depth-code': depth == 'job_code' }">

        <div v-if="depth != 'id'" class="search-table">
            <div v-for="c in classes" v-bind:key="c.job_class_description" class="ste class-level">
                <div v-if="depth == 'job_class'" class="table-quarter job-class" v-bind:class="{ 'match-class': c.matchClass }">
                    {{ c.job_class_description }}{{ debug ? `(${c.sortWeight})` : `` }}
                </div>
                <div>
                    <div v-for="group in c.job_groups" v-bind:key="group.job_group_description" class="ste group-level">
                        <div v-if="depth != 'job_code'" class="table-quarter job-group" v-bind:class="{
                            'match-group': group.matchGroup,
                            'match-class': group.matchClass
                        }">{{ group.job_group_description }}{{ debug ? `(${group.sortWeight})` : `` }}</div>
                        <div>
                            <div v-for="code in group.job_codes" v-bind:key="code.job_code_description" class="ste code-level">
                                <div class="table-quarter job-code" v-bind:class="{
                                    'match-code': code.matchCode, 
                                    'match-group': code.matchGroup,
                                    'match-class': code.matchClass
                                }">{{ code.job_code_description }}{{ debug ? `(${code.sortWeight})` : `` }}</div>
                                <div>
                                    <div v-for="title in code.job_titles" v-bind:key="title.name" class="table-quarter job-title" v-bind:class="{
                                        'match-title': title.matchTitle,
                                        'match-code': title.matchCode,
                                        'match-group': title.matchGroup,
                                        'match-class': title.matchClass
                                    }">
                                        {{ title.name }}{{ debug ? `(${title.sortWeight})` : `` }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    import Ajax from '../../form-field/sub-components/js/ajax.js'

    export default {
        props: [],
        data: function(){
            return {
                showClass: false,
                groupOnly: false,
                showCode: false,
                filterVue: null,
                codes: [],
                classes: [],
                groups: [],
                depth: '',
                jobTitles: [],
                debug: false
            }
        },
        mounted: function(){
            console.log(this);
            this.setFilterVue();
        },
        methods: {
            showCodeTable: function(){ this.hideClassTable(); this.showCode = true; return this },
            hideCodeTable: function(){ this.showCode = false; return this },
            showClassTable: function(){ this.hideCodeTable(); this.showClass = true; this.groupOnly = false; return this },
            hideClassTable: function(){ this.showClass = false; return this },
            showGroupTable: function(){ this.hideCodeTable(); this.showClass = true; this.groupOnly = true; return this },
            hideGroupTable: function(){ this.showClass = false; return this },
            populateCodes: function(){
                this.codes = this.filterVue.getCodeMatchInfo();
                return this;
            },
            populateClasses: function(){
                this.classes = this.filterVue.getSearchTableInfo();
                return this;
            },
            populateTitles: function(){
                this.titles = this.filterVue.getJobTitlesInfoArray();
                return this;
            },
            setFilterVue: function(){
                this.filterVue = document.getElementsByClassName('filters--wrapper-DEL')[0].__vue__;
            },
            update: function(){
                // this.populateClasses().populateCodes().populateTitles();

                const filterFields = document.querySelectorAll(`.table-filters .profile__container--field`);

                const jobTitleIds = filterFields[0].__vue__.$children[0].selectValue.map((val) => {
                    return `&job_title_ids[]=${val.id}`
                }).join(``);

                const depth = filterFields[1].__vue__.$children[0].checkedOption;
                this.depth = depth;
                // const haveSettingsVue = filterFields[2].__vue__.$children[0];

                const query = `/search/job-titles-tree?depth=${depth}${jobTitleIds}`;


                console.log(`Updating explain table`,jobTitleIds,depth,query);

                if(jobTitleIds.length > 17 && depth != 'id'){
                    Ajax.sendRequest('GET',{},query,(response) => {
                        console.log(`Update explain table response`,response);

                        this.classes = response.results;

                        let groups = [];
                        this.classes.forEach((C) => { C.job_groups.forEach((Group) => { groups.push(Group) }) });

                        let codes = [];
                        groups.forEach((Group) => { Group.job_codes.forEach((Code) => { codes.push(Code) }) });

                        let titles = [];
                        codes.forEach((Code) => { Code.job_titles.forEach((Title) => { titles.push(Title) }) });





                        const selectedTitles = document.querySelectorAll('.filters--wrapper.filters--wrapper-DEL .profile__container--field')[0].__vue__.$children[0].selectValue;
                        
                        selectedTitles.forEach((SelectedTitle) => {
                            let foundTitle = false;

                            console.log(`CHECKING TITLE`,SelectedTitle.name,SelectedTitle.id)

                            this.classes.forEach((C,classIndex) => {
                                C.job_groups.forEach((Group,groupIndex) => {
                                    Group.job_codes.forEach((Code,codeIndex) => {
                                        Code.job_titles.forEach((Title,titleIndex) => {
                                            if(!foundTitle && Title.id == SelectedTitle.id){
                                                console.log(`MATCHED SELECTED TITLE`,Title.id,SelectedTitle.id);

                                                const titleWeight = 1010101;
                                                
                                                this.classes[classIndex] = {
                                                    ...C,
                                                    matchClass: true,
                                                    sortWeight: C.sortWeight ? C.sortWeight + titleWeight : titleWeight
                                                };

                                                
                                                this.classes[classIndex].job_groups[groupIndex] = {
                                                    ...Group,
                                                    matchGroup: true,
                                                    sortWeight: Group.sortWeight ? Group.sortWeight + titleWeight : titleWeight 
                                                };

                                                
                                                this.classes[classIndex].job_groups[groupIndex].job_codes[codeIndex] = {
                                                    ...Code,
                                                    matchCode: true,
                                                    sortWeight: Group.sortWeight ? Group.sortWeight + titleWeight : titleWeight 
                                                };


                                                this.classes[classIndex].job_groups[groupIndex].job_codes[codeIndex].job_titles[titleIndex] = {
                                                    ...Title,
                                                    matchTitle: true,
                                                    sortWeight: titleWeight,
                                                    isCounted: true
                                                };
                                            };
                                        });
                                    });
                                });
                            });
                        });


                        const infoArray = document.querySelectorAll('.filters--wrapper.filters--wrapper-DEL')[0].__vue__.getJobTitlesInfoArray();
                        const selectedTitleIds = selectedTitles.map((SelectedTitle) => { return SelectedTitle.id });

                        console.log(`SEARCH TABLE INFO ARRAY`,infoArray,selectedTitleIds)
                        
                        infoArray.forEach((infoTitle) => {
                            let foundTitle = false;
                            // console.log(infoTitle);
                            this.classes.forEach((C,classIndex) => {
                                C.job_groups.forEach((Group,groupIndex) => {
                                    Group.job_codes.forEach((Code,codeIndex) => {
                                        Code.job_titles.forEach((Title,titleIndex) => {
                                                // console.log(Title,infoTitle);
                                            if(!foundTitle && Title.id == infoTitle.job_title_id && !Title.isCounted){
                                                foundTitle = true;

                                                console.log(`MATCH WITH INFO TITLE`,infoTitle,Title,Title.name);

                                                const sortWeight = 1000000*Number(Boolean(infoTitle.matchTitle)) 
                                                    + 10000*Number(Boolean(infoTitle.matchCode))
                                                    + 100*Number(Boolean(infoTitle.matchGroup))
                                                    + Number(Boolean(infoTitle.matchClass));

                                                // const titleWeightCount = Number(Boolean(infoTitle.matchTitle));
                                                // const codeWeightCount = titleWeightCount*10 + Number(Boolean(infoTitle.matchCode));
                                                // const groupWeightCount = codeWeightCount*10 + Number(Boolean(infoTitle.matchGroup));
                                                // const classWeightCount = groupWeightCount*10 + Number(Boolean(infoTitle.matchClass));

                                                //CLASS LEVEL
                                                this.classes[classIndex] = { 
                                                    ...C, 
                                                    matchClass: infoTitle.matchClass, 
                                                    sortWeight: C.sortWeight ? C.sortWeight + sortWeight : sortWeight
                                                };

                                                // console.log(`CLASS AFTER SETTING WEIGHT`,this.classes[classIndex]);

                                                //GROUP LEVEL
                                                this.classes[classIndex].job_groups[groupIndex] = { 
                                                    ...Group, 
                                                    matchGroup: infoTitle.matchGroup, 
                                                    matchClass: infoTitle.matchClass,
                                                    sortWeight: Group.sortWeight ? Group.sortWeight + sortWeight : sortWeight 
                                                };
                                                
                                                //CODE LEVEL
                                                this.classes[classIndex].job_groups[groupIndex].job_codes[codeIndex] = { 
                                                    ...Code, 
                                                    matchCode: infoTitle.matchCode, 
                                                    matchGroup: infoTitle.matchGroup, 
                                                    matchClass: infoTitle.matchClass,
                                                    sortWeight: Code.sortWeight ? Code.sortWeight + sortWeight : sortWeight 
                                                };
                                                
                                                //TITLE LEVEL
                                                this.classes[classIndex].job_groups[groupIndex].job_codes[codeIndex].job_titles[titleIndex] = { 
                                                    ...infoTitle,
                                                    ...Title, 
                                                    sortWeight: Title.sortWeight ? Title.sortWeight + sortWeight : sortWeight
                                                };

                                                // console.log(`FOUND TITLE?`,this.classes[classIndex].job_groups[groupIndex].job_codes[codeIndex].job_titles[titleIndex])
                                            };
                                        });
                                    });
                                });
                            });
                        });

                        //SORT CLASSES
                        let classOrderByIndex = [];
                        let classOrderWeights = [];
                        this.classes.forEach((C,classIndex) => {
                            C.sortWeight = C.sortWeight || 0;
                            
                            if(classOrderByIndex.length == 0){ 
                                classOrderByIndex.push(classIndex);
                                classOrderWeights.push(C.sortWeight || 0);
                            }else{
                                let sortNext = false;

                                classOrderWeights.forEach((Weight,weightIndex) => {
                                    if(!sortNext && (C.sortWeight || 0) >= Weight){
                                        sortNext = true;
                                        classOrderWeights.splice(weightIndex,0,C.sortWeight || 0);
                                        classOrderByIndex.splice(weightIndex,0,classIndex);
                                    }else if(!sortNext && weightIndex + 1 == classOrderWeights.length){
                                        classOrderWeights.push(C.sortWeight || 0);
                                        classOrderByIndex.push(classIndex);
                                    };
                                });
                            };
                        });

                        this.classes = classOrderByIndex.map((orderedIndex) => { return this.classes[orderedIndex] });

                        //ORDER GROUPS
                        this.classes.forEach((C) => {
                            let groupOrderByIndex = [];
                            let groupOrderWeights = [];

                            C.job_groups.forEach((Group,groupIndex) => {
                                Group.sortWeight = Group.sortWeight || 0;

                                if(groupOrderByIndex.length == 0){ 
                                    groupOrderByIndex.push(groupIndex);
                                    groupOrderWeights.push(Group.sortWeight || 0);
                                }else{
                                    let sortNext = false;

                                    groupOrderWeights.forEach((Weight,weightIndex) => {
                                        if(!sortNext && (Group.sortWeight || 0) >= Weight){
                                            sortNext = true;
                                            groupOrderWeights.splice(weightIndex,0,Group.sortWeight || 0);
                                            groupOrderByIndex.splice(weightIndex,0,groupIndex);
                                        }else if(!sortNext && weightIndex + 1 == groupOrderWeights.length){
                                            groupOrderWeights.push(Group.sortWeight || 0);
                                            groupOrderByIndex.push(groupIndex);
                                        };
                                    });
                                };
                            });

                            C.job_groups = groupOrderByIndex.map((orderedIndex) => { return C.job_groups[orderedIndex] });
                        });


                        //ORDER CODES
                        this.classes.forEach((C) => {
                            C.job_groups.forEach((Group) => {
                                let codeOrderByIndex = [];
                                let codeOrderWeights = [];

                                Group.job_codes.forEach((Code,codeIndex) => {
                                    Code.sortWeight = Code.sortWeight || 0;

                                    if(codeOrderByIndex.length == 0){ 
                                        codeOrderByIndex.push(codeIndex);
                                        codeOrderWeights.push(Code.sortWeight || 0);
                                    }else{
                                        let sortNext = false;

                                        codeOrderWeights.forEach((Weight,weightIndex) => {
                                            if(!sortNext && (Code.sortWeight || 0) >= Weight){
                                                sortNext = true;
                                                codeOrderWeights.splice(weightIndex,0,Code.sortWeight || 0);
                                                codeOrderByIndex.splice(weightIndex,0,codeIndex);
                                            }else if(!sortNext && weightIndex + 1 == codeOrderWeights.length){
                                                codeOrderWeights.push(Code.sortWeight || 0);
                                                codeOrderByIndex.push(codeIndex);
                                            };
                                        });
                                    };
                                });

                                Group.job_codes = codeOrderByIndex.map((orderedIndex) => { return Group.job_codes[orderedIndex] });
                            });
                        });


                        //ORDER TITLES
                        this.classes.forEach((C) => {
                            C.job_groups.forEach((Group) => {
                                Group.job_codes.forEach((Code) => {
                                    let titleOrderByIndex = [];
                                    let titleOrderWeight = [];

                                    Code.job_titles.forEach((Title,titleIndex) => {
                                        Title.sortWeight = Title.sortWeight || 0;

                                        if(titleOrderByIndex.length == 0){ 
                                            titleOrderByIndex.push(titleIndex);
                                            titleOrderWeight.push(Title.sortWeight || 0);
                                        }else{
                                            let sortNext = false;

                                            titleOrderWeight.forEach((Weight,weightIndex) => {
                                                if(!sortNext && (Title.sortWeight || 0) >= Weight){
                                                    sortNext = true;
                                                    titleOrderWeight.splice(weightIndex,0,Title.sortWeight || 0);
                                                    titleOrderByIndex.splice(weightIndex,0,titleIndex);
                                                }else if(!sortNext && weightIndex + 1 == titleOrderWeight.length){
                                                    titleOrderWeight.push(Title.sortWeight || 0);
                                                    titleOrderByIndex.push(titleIndex);
                                                };
                                            });
                                        };
                                    });

                                    // console.log(`TITLE SORT INDICES AND WEIGHTS`,titleOrderByIndex,titleOrderWeight);

                                    Code.job_titles = titleOrderByIndex.map((orderedIndex) => { return Code.job_titles[orderedIndex] });
                                });
                            });
                        });

                        // console.log(`RESULTING GROUPS`,groups,codes,titles,infoArray);
                    });
                }else{
                    this.showClass = false;
                };

                return this;
            }
        }
    }
</script>
