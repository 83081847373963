<template>
    <div class="profile__container--wrapper profile__container--columns-0">
        <div class="profile__container--outer candidate-details sectioned">
            <div class="profile__container--header">
                <h3>Switch from an External to a Blue Lynx Account</h3>
            </div>
            <div class="profile__container--main">
        <!--         <div>This will create a new login account with the e-mail:</div> -->
                <form-field
                    title="E-Mail"
                    fieldtype="form-input"
                    inputname="candidate[email]"
                    v-bind:startvalue="candidate.email"
                    v-bind:haserrors="candidate.errors.email_home"
                ></form-field>

<!--                 <form-field
                    title="City"
                    fieldtype="form-input"
                    inputname="candidate[town]"
                    v-bind:startvalue="candidate.town"
                    v-bind:haserrors="candidate.errors.town"
                ></form-field> -->
            </div>
        </div>
    </div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'

    export default {
        props: [
            'candidateData',
            'formOptionsData'
        ],
        data: function(){
            console.log(this.candidateData)
            var candidate = JSON.parse(this.candidateData || '{}');
            var formOptions = JSON.parse(this.formOptionsData || '{}');

            return { 
                candidate: candidate, 
                formOptions: formOptions
            };
        },
        components: {
            FormField
        }
    }
</script>