<template>
    <div class="filters--wrapper">
        <form-field
            title="From"
            fieldtype="form-date"
            inputname="rank[from_date]"
            v-bind:startvalue="startDate"
            v-on:event-bubble="handleChange"
        ></form-field>

        <form-field
            title="To"
            fieldtype="form-date"
            inputname="rank[to_date]"
            v-bind:startvalue="endDate"
            v-on:event-bubble="handleChange"
        ></form-field>
    </div>
</template>

<script>
    import FormField from '../form-field/form-field.vue'

    export default {
        props: [
            'datestart',
            'dateend'
        ],
        data: function(){
            return { 
                startDate: this.datestart,
                endDate: this.dateend
            };
        },
        components: {
            FormField
        },
        methods: {
            handleChange: function(event){
                let errorFlag = false;
                switch(event.title){
                    case 'From':
                        errorFlag = !(new Date(this.endDate).getTime() > new Date(event.data).getTime());
                        break;
                    case 'To':
                        errorFlag = !(new Date(event.data).getTime() > new Date(this.startDate).getTime());
                        break;
                    default:
                        alert('Something went wrong when selecting a date');
                        break;
                };
                if(errorFlag){
                    alert(`Please select a 'to date', which is after the 'from date'!`);
                }else{
                    this.$nextTick(() => { $(`#staff-ranking-filter-form`).submit() });
                };
            }
        }
    }
</script>