<template>
    <div class="profile__input--wrapper profile__container--radioGroup">
        <p 
            class="profile__radio--p"
            v-for="(option, index) in alloptions"
            v-bind:key="index"
            v-on:click.stop="selectOption(option)">
                <input
                    v-if="checkedCondition(option)"
                    v-bind:disabled="isdisabled"
                    checked
                    type="radio"
                    v-bind:id="fieldBaseId + index"
                    v-bind:value="option.id"
                    v-bind:name="inputname"
                    class="form-data-target">
                <input 
                    v-if="!checkedCondition(option)"
                    v-on:click.stop="selectOption(option)"
                    v-bind:disabled="isdisabled"
                    type="radio"
                    v-bind:id="fieldBaseId + index"
                    v-bind:value="option.id"
                    v-bind:name="inputname"
                    class="form-data-target">
                <label 
                    v-bind:for="fieldBaseId + index">
                        {{ option.name }}
                </label>
        </p>
		<div v-if="haserrors" class="login-form__warning--icon" v-bind:title="haserrors[0]"><p>!</p></div>
		<!-- <div 
			v-if="haserrors"
			class="error-explanation">
				{{ haserrors[0] }}
		</div> -->
    </div>
</template>

<script>
    export default {
        props: [
            'inputname',
            'inputid',
            'startvalue',
            'alloptions',
            'haserrors',
            'isdisabled'
        ],
        data: function(){
            return {
                checkedOption: this.startvalue,
                fieldBaseId: this.inputid
            };
        },
        methods: {
            selectOption: function(option){
                this.checkedOption = option.id;
                this.$emit('event-bubble',{ data: this.checkedOption });
                this.$nextTick(() => { $(this.$el).find(`input[checked]`)[0].focus() });
            },
            checkedCondition: function(option){
                return this.checkedOption == option.id;
            }
        }
    }
</script>