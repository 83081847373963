<template>
    <div class="profile__container--outer profile__container--languages"
        v-bind:class="addWrapperClasss">
        <div class="profile__container--header">
            <h3>Dossier</h3>
        </div>

        <div class="form-hint">
            After you click save, all dossier records of the external account will be transfered to the blue lynx account. The external account will be hidden.
        </div>

        <div v-if="records.length > 0" class="form-hint">
            There are conflicting records. You have to decide which one to leave. The other will be deleted irreversibly!
        </div>
        <div 
            v-bind:class="determineRecordClass(record, 'language-container profile__container--outer profile__container--main merge-conflict')"
            v-for="(record, index) in records"
            v-bind:key="record.vue_key">
            <!-- <div v-for="(record, index) in records" v-bind:key="record.vue_key">
                <div class="language-container"> -->
                    <input v-if="record.id" type="hidden" v-model="record.id" v-bind:name="'candidate[dossier_attributes][' + record.vue_key + '][id]'">

                    <input v-if="record.source_id" type="hidden" v-model="record.source_id" v-bind:name="'candidate[dossier_attributes][' + record.vue_key + '][source_id]'">

                    <input v-if="record._destroy" type="hidden" v-model="record._destroy" v-bind:name="'candidate[dossier_attributes][' + record.vue_key + '][_destroy]'">

                    <h3>{{record.job_title}}</h3>
                    <div>Stage: {{record.status}}</div>
                    <div>Owner: {{record.owner_data}}</div>
                    <div>Notes: {{record.notes_count}}</div>

                    <div v-if="index % 2 == 0">
                        <div v-if="record._destroy" class="button button-formAction danger" v-on:click="toggleRecordKeep(index)">
                            <i class="flaticon-reply"></i> Switch to Blue Lynx record
                        </div>
                        <div v-if="!record._destroy" class="button button-formAction" v-on:click="toggleRecordKeep(index)">
                            <i class="flaticon2-refresh"></i> Switch to External record
                        </div>
                    </div>

                <!-- </div>
            </div> -->
        </div>
    </div>
</template>

<script>
    import FormField from '../../../form-field/form-field.vue'

    export default {
        props: [
            'intialRecords',
            'addWrapperClasss'
        ],
        data: function(){
            var records = this.intialRecords || [];
            records.forEach(function(item, i) { item.vue_key = i; })
            // console.log(records);
            
            return { 
                nextVueKey: records.length, 
                records: records
            };
        }, 
        methods: {
            removeRecord: function(index) {
              var record = this.records[index];
              this.records[index]._destroy = "1";

              // if (record.id == null) {
              //   this.records.splice(index, 1)
              // } else {
              //   this.records[index]._destroy = "1"
              // }
            },

            undoRemove: function(index) {
              this.records[index]._destroy = null;
            },

            determineRecordClass: function(record, otherClasses){
                var recordClass = otherClasses || '';
                if(record._destroy){
                    recordClass = recordClass + ' removed-record';
                }else if(record.id == null || record._destroy == null){
                    recordClass = recordClass + ' new-record'
                }

                return recordClass;
            },
            toggleRecordKeep: function(index){
                if(this.records[index]._destroy){
                    this.undoRemove(index);
                    this.removeRecord(index + 1);
                }else{
                    this.undoRemove(index + 1);
                    this.removeRecord(index);
                };
            }
        },
        components: {
            FormField
        }
    }
</script>