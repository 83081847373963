<template>
    <div class="profile__container--outer profile__container--education">
        <div 
            v-for="(formKey, index) in formKeys"
            v-bind:key="formKey"
            class="profile__container--inner profile__container--education">
                <div class="profile__container--header">
                    <h3>Education details (TESTING)</h3>
                </div>
                <div class="profile__container--main">	
                    <form-field
                        title="Start date"
                        fieldtype="form-date"
                        inputname="candidates[education][start_date]"
                        v-bind:startvalue=formData[index].start_date
                        v-bind:haserrors=formData[index].errorFlag
                    ></form-field>

                    <form-field
                        title="End date"
                        fieldtype="form-date"
                        inputname="candidates[education][end_date]"
                        v-bind:startvalue=formData[index].end_date
                        v-bind:haserrors=formData[index].errorFlag
                    ></form-field>

                    <form-field
                        title="Education level"
                        fieldtype="form-dropdown"
                        inputname="candidates[education][education_level]"
                        v-bind:startvalue=formData[index].education_level_id
                        v-bind:alloptions=educationLevel
                        v-bind:haserrors=formData[index].errorFlag
                    ></form-field>

                    <form-field
                        title="Degree direction"
                        fieldtype="form-input"
                        inputname="candidates[education][degree_direction]"
                        v-bind:startvalue=formData[index].degree_direction
                        v-bind:haserrors=formData[index].errorFlag
                    ></form-field>

                    <form-field
                        title="Institute name"
                        fieldtype="form-input"
                        inputname="candidates[education][institute_name]"
                        v-bind:startvalue=formData[index].institute_name
                        v-bind:haserrors=formData[index].errorFlag
                    ></form-field>

                    <form-field
                        title="Institute location"
                        fieldtype="form-input"
                        inputname="candidates[education][institute_location]"
                        v-bind:startvalue=formData[index].institute_location
                        v-bind:haserrors=formData[index].errorFlag
                    ></form-field>

                    <form-field
                        title="GPA"
                        fieldtype="form-input"
                        inputname="candidates[education][gpa]"
                        v-bind:startvalue=formData[index].gpa
                        v-bind:haserrors=formData[index].errorFlag
                    ></form-field>

                    <form-field
                        title="Diploma"
                        fieldtype="form-dropdown"
                        inputname="candidates[education][diploma]"
                        v-bind:startvalue=formData[index].diploma_code_id
                        v-bind:alloptions=diplomaOptions
                        v-bind:haserrors=formData[index].errorFlag
                    ></form-field>
                </div>
        </div>
    </div>
</template>

<script>
    import FormField from './form-field/form-field.vue'

    export default {
        props: [
            'initialdata',
            'diplomaoptions',
            'educationlevel',
            'haserrors'
        ],
        data: function(){
            let jsonData = JSON.parse(this.initialdata);
            let keys = [];
            for(let i = 0; i < jsonData.length; i++){
                jsonData.errorFlag = this.haserrors[i];
                keys.push(i);
            };
            return {
                formData: jsonData,
                formKeys: keys,
                diplomaOptions: this.diplomaoptions,
                educationLevel: this.educationlevel
            }
        },
        components: {
            FormField
        }
    }
</script>