<template>
    <div class="profile__container--section">
        <form-field
            v-if="showJobClassSelect"
            title="Job class"
            fieldtype="form-dropdown"
            inputname="job_class_id"
            v-bind:startvalue="record.job_class"
            v-on:event-bubble="jobClassSelected"
            v-bind:alloptions="jobClassOptions"
        ></form-field>

        <form-field
            v-if="showJobGroupSelect"
            title="Job group"
            fieldtype="form-dropdown"
            inputname="job_group_id"
            v-bind:startvalue="record.job_group"
            v-bind:alloptions="jobGroupOptions"
            v-on:event-bubble="jobGroupSelected"
        ></form-field>

        <form-field
            v-if="showJobCodeSelect"
            title="Job code"
            fieldtype="form-dropdown"
            v-bind:inputname="record.input_name"
            v-bind:startvalue="record.job_code"
            v-bind:alloptions="jobCodeOptions"
            v-bind:haserrors="record.errors.job_code"
        ></form-field>

    </div>
</template>


<script>
    import FormField from '../../../form-field/form-field.vue'

    export default {
        props: [
            'recordData',
            'errorsData'
        ],
        components: {
            FormField
        },
        data: function(){
           // var record = JSON.parse(this.recordData || '{ "job_class": {}, "job_group": {}, "job_code": {} }');
            var record = this.recordData || { job_class: {}, job_group: {}, job_code: {}, errors: {}, input_name: 'job_code_id' };

            return {
                record: record,
                showJobGroupSelect: true,
                jobGroupOptions: [],
                jobCodeOptions: [],
                showJobCodeSelect: true,
                showJobClassSelect: true,
                jobClassOptions: []
            }
        },
        mounted: function(){
            let instance = this;
            this.loadJobClasses();

            if(this.record.job_class.id){
                // load job_groups
                this.loadJobGroups(this.record.job_class.id);
            };

            if(this.record.job_group.id){
                instance.loadJobCodes(instance.record.job_group.id, false);
            };

        },
        methods: {
            jobClassSelected: function(event){
                let selected = { id: event.data.id, name: event.data.name };
                if(selected.id != this.record.job_class.id){
                    this.record.job_class = selected;
                    this.record.job_group = {};
                    this.resetJobCodes();
                    this.loadJobGroups(selected.id);
                }
            },
            loadJobClasses: function(){
                $.ajax({
                    url: '/autocomplete/job_classes'
                }).done(this.handleJobClassesResponse);
            },
            loadJobGroups: function(job_class_id){
                $.ajax({
                    url: '/autocomplete/job_groups',
                    data: {
                        job_class_id: job_class_id
                    }
                }).done(this.handleJobGroupsResponse);
            },
            loadJobCodes: function(job_group_id, autoSelectJobCode = false){
                let instance = this;
                $.ajax({
                    url: '/autocomplete/job_codes',
                    data: {
                        job_group_id: job_group_id
                    }
                }).done(function(data){
                    instance.handleJobCodesResponse(data, autoSelectJobCode);
                });
            },

            jobGroupSelected: function(event){
                //console.log('country section received country change event');
                let selected = { id: event.data.id, name: event.data.name };
                if(selected.id != this.record.job_group.id){
                    this.record.job_group = selected;
                    this.record.job_code = {};
                    this.loadJobCodes(selected.id, true);
                }
            },
            handleJobClassesResponse: function(data){
                this.showJobClassSelect = false;
                this.jobClassOptions = data.results;

                this.$nextTick(() => {
                    this.showJobClassSelect = true;
                });
            },
            handleJobGroupsResponse: function(data){
                this.showJobGroupSelect = false;
               
                this.jobGroupOptions = data.results;

                this.$nextTick(() => {
                    this.showJobGroupSelect = true;
                });

            },
            handleJobCodesResponse: function(data, autoSelectJobCode){
                this.showJobCodeSelect = false;
                this.jobCodeOptions = data.results;
                if(autoSelectJobCode && this.jobCodeOptions.length == 1){
                    this.record.job_code = this.jobCodeOptions[0];
                }
                this.$nextTick(() => {
                    this.showJobCodeSelect = true;
                });
            },
            resetJobCodes: function(){
                this.record.job_code = {};
                this.showJobCodeSelect = false;
                this.jobCodeOptions = [];
                this.$nextTick(() => {
                    this.showJobCodeSelect = true;
                });
            }
        }
    }
</script>