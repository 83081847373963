module.exports = {
    isDuped: function(targetArray,targetValue){
        let foundFlag = 0;
        let foundIndex = -1;
        //console.log(`checking for dupes`)
        $(targetArray).each(function(array_index){
            if(this.id == targetValue.id){
                //console.log(`found match`);
                foundFlag = 1;
                foundIndex = array_index;
                return false;
            };
        });
        return {
            found: foundFlag,
            index: foundIndex
        }
    }
}