<template>
    <div class="notes-menu__wrapper" v-bind:class="{ 
        'notes-menu--show': showMenu,
        'notes-menu--dossier': isdossier
    }">
        <button 
            class="notes-menu__button single-note__button--save" 
            v-bind:class="{ 'notes-menu__button--show': showMenu }"
            v-on:click="toggleMenu">
                <div class="notes-menu__arrow"><i class="flaticon2-arrow"></i></div>
                <p>Notes <span class="notes-count">({{ notesCount }})</span></p>
        </button>

        <notes-table-full
            v-bind:isstatus="isstatus"
            v-bind:statusdata="statusdata"
            v-bind:viewer="viewer"
            v-bind:typefilter="typefilter"
            v-bind:urlnotes="urlnotes"
            v-bind:filternotes="filternotes"
            v-bind:canaddnote="canaddnote"
            v-bind:addnotedata="addnotedata"
            v-bind:addnoterecordid="addnoterecordid"
            v-bind:noeditor="false"
            v-bind:candidatetypeoptions="candidatetypeoptions"
        ></notes-table-full>
    </div>
</template>

<script>
    import NotesTableFull from './talbe.vue'

    export default {
        props: [
            "isstatus",
            "statusdata",
            "viewer",
            "isdossier",
            "typefilter",
            "urlnotes",
            "filternotes",
            'canaddnote',
            'addnotedata',
            'addnoterecordid',
            'candidatetypeoptions'
        ],
        data: function(){ 
            return { 
                showMenu: false,
                notesCount: 0
            };
        },
        components: { NotesTableFull },
        watch: {
            notesCount: function(newVal,oldVal){ 
                $(`.notes-count`).each(function(index){ 
                    let count;
                    if(newVal > 99){ count = `99+` }else{ count = newVal };
                    this.innerHTML = `(${count})`;
                });
            }
        },
        methods: { 
            toggleMenu: function(){ 
                // console.log($.merge($(`.note-button-copy-small`),$(`.note-button-copy-mid`)));
                $.merge($(`.note-button-copy-small`),$(`.note-button-copy-mid`)).each(function(index){ this.setButtonState() });
                this.showMenu = !this.showMenu; 
                document.body.style.overflow = this.showMenu ? 'hidden' : 'auto';
            } 
        }
    }
</script>