<template>
	<div v-bind:class="setFieldClass()">
        <i v-if="labelWarning" v-bind:title="labelWarning" class="flaticon-warning" style="color: red;"></i>
        <label
            v-if="Boolean(title)"
            v-bind:for="inputid"
            v-on:click="handle_labelClick">
                {{ title }}:
        </label>
        <component 
            v-bind:is="fieldtype"
            v-bind:inputname="inputname"
            v-bind:inputid="inputid"
            v-bind:startvalue="startvalue"
            v-bind:alloptions="alloptions"
            v-bind:addblank="addblank"
            v-bind:selectall="selectall"
            v-bind:blanktext="blanktext"
            v-bind:icon="icon"
            v-bind:searchurl="searchurl"
            v-bind:preload="preload"
            v-bind:preloadquery="preloadquery"
            v-bind:haserrors="haserrors"
            v-bind:isdisabled="isdisabled"
            v-bind:isrequired="isrequired"
            v-bind:hastime="hastime"
            v-bind:copy="copy"
            v-on:event-bubble="bubbleEvent"
            v-on:event-ajax="handleAjaxEvent"
        ></component>
        <i 
            v-if="isnew && dataNotOverwritten"
            class="flaticon-reply shift-data" 
            v-bind:title="`Overwrite ${title} with old data`" 
            v-on:click="copyOldData"
        ></i>
        <i
            v-if="isnew && !dataNotOverwritten"
            class="flaticon2-refresh shift-data"
            v-bind:title="`Restore original ${title} data`"
            v-on:click="restoreNewData"
        ></i>
	</div>
</template>

<script>
    import Format from '../notes/js/format.js'

    import FormInput from './sub-components/form-input.vue'
    import FormDate from './sub-components/form-date.vue'           
    import FormNumber from './sub-components/form-number.vue'       
    import FormCheckbox from './sub-components/form-checkbox.vue'  
    import FormDropdown from './sub-components/form-dropdown.vue'   
    import FormMultidrop from './sub-components/form-multidrop.vue' 
    import FormMultigroup from './sub-components/form-multigroup.vue'
    import FormTags from './sub-components/form-tags.vue'      
    import FormRadio from './sub-components/form-radio.vue'         
    import FormTextarea from './sub-components/form-textarea.vue'
    import FormColorpicker from './sub-components/form-colorpicker.vue'
    import FormFileUpload from './sub-components/form-file-upload.vue'

    //NOTE: set copy="true" to the 'form-tags' component to remove the copy to clipboard button
    export default {
        props: [
            'title',
            'fieldtype',
            'inputname',
            'setinputid',
            'startvalue',
            'alloptions',
            'addblank',
            'selectall',
            'blanktext',
            'icon',
            'searchurl',
            'preload',
            'preloadquery',
            'haserrors',
            'isdisabled',
            'addclass',
            'hastime',
            'isrequired',
            'isold',
            'isnew',
            'copy',
            'labelWarning'
        ],
        components: {
            FormInput,
            FormDate,
            FormNumber,
            FormCheckbox,
            FormDropdown,
            FormMultidrop,
            FormMultigroup,
            FormTags,
            FormRadio,
            FormTextarea,
            FormColorpicker,
            FormFileUpload,
        },
        data: function(){
            return {
                bonusClass: this.addclass || '',
                inputid: this.setinputid || this.inputname.replace(/\[/g,'_').replace(/]/g,''),
                dataNotOverwritten: true
            };
        },
        created: function(){
            // console.log(`generating field -> type = ${this.fieldtype}, title = ${this.title}`);
            // console.log('start value:');
            // console.log(this.startvalue);
            // console.log(this);
            // console.log('all options:');
            // console.log(this.alloptions);
        },
        updated: function(){
            // console.log(`updated field -> type = ${this.fieldtype}, title = ${this.title}`);
            // console.log(this.startvalue);
        },
        methods: {
            handle_labelClick: function(event){
                if(this.$el.className.indexOf('field--disabled') != -1){ return };
                switch(this.fieldtype){
                    case 'form-input':
                    case 'form-number':
                        event.preventDefault();
                        $(this.$el)
                        .find(`input`)[0]
                        .focus();
                        break;
                    case 'form-date':
                        $(this.$el)
                        .find(`.profile__container--datepicker`)[0]
                        .click();
                        break;
                    case 'form-tags':
                        $(this.$el)
                        .find(`.profile__container--values`)[0]
                        .click();
                        break;
                    case 'form-dropdown':
                        event.stopPropagation();
                        event.preventDefault();
                        $(this.$el).find(`.profile__container--dropdown`)[0].click();
                        this.$nextTick(() => {
                            $(this.$el).find(`.profile__container--selected`)[0].focus();
                        });
                        break;
                    case 'form-multidrop':
                    case 'form-multigroup':
                        event.stopPropagation();
                        event.preventDefault();
                        this.$nextTick(() => {
                            $(this.$el).find(`.dropdown__container--values`)[0].click();
                        });
                        break;
                    case 'form-radio':
                        let radioInputs = $(this.$el).find(`input[type="radio"]`);
                        let checkedRadio = $(this.$el).find(`input[checked]`);
                        let checkedIndex = radioInputs.index(checkedRadio);
                        let newIndex = (checkedIndex + 1) % radioInputs.length;
                        radioInputs[newIndex].click();
                        radioInputs[newIndex].focus();
                        break;
                    case 'form-textarea':
                    case 'form-checkbox':
                        break;
                    default:
                        console.log('invalid fieldtype');
                        break;
                }
            },
            bubbleEvent: function(event){
                event.title = this.title;
                this.$emit('event-bubble',event);
            },
            setFieldClass: function(){
                let classesArray = [
                    'profile__container--field',
                    this.fieldtype,
                    this.bonusClass,
                    { 'field--disabled': this.isdisabled },
                    { 'disabled--clicks': (this.isdisabled == true) && (this.fieldtype != 'form-tags' && this.fieldtype != 'form-multidrop') },
                    { 'profile__container--date': this.fieldtype == 'form-date' },
                    { 'error': this.haserrors }
                ];
                return classesArray;
            },
            copyOldData: function(){
                let formInput;
                let oldFormInput;
                switch(this.fieldtype){
                    case 'form-input':
                    case 'form-textarea':
                    case 'form-number':
                        formInput = $(this.$el).find(`.form-data-target`)[0];
                        oldFormInput = $(`#${formInput.id}_old`)[0];
                        formInput.value = oldFormInput.value;
                        break;
                    case 'form-date':
                        formInput = this.$children[0];
                        oldFormInput = $(`#${this.inputid}_old`).parents(`.profile__input--wrapper`)[0].__vue__.startvalue;
                        let flatpickerInstance = formInput.flatpickerInstance;
                        flatpickerInstance.setDate(oldFormInput,true,'Y/m/d H:i');
                        break;
                    case 'form-radio':
                        formInput = this.$children[0];
                        oldFormInput = $(`#${this.inputid}_old0`).parents(`.profile__input--wrapper`)[0].__vue__;
                        formInput.checkedOption = oldFormInput.checkedOption;
                        break;
                    case 'form-checkbox':
                        formInput = this.$children[0];
                        oldFormInput = $(`#${this.inputid}_old`).parents(`.profile__input--wrapper`)[0].__vue__;
                        formInput.value = oldFormInput.value;
                        break;
                    case 'form-dropdown':
                        formInput = this.$children[0];
                        oldFormInput = $(`#${this.inputid}_old`).parents(`.profile__input--wrapper`)[0].__vue__.selectedOption;
                        formInput.selectOption(oldFormInput);
                        break;
                    case 'form-multidrop':
                        formInput = this.$children[0];
                        oldFormInput = $(`#${this.inputid}_old`).parents(`.profile__input--wrapper`)[0].__vue__.selectValue;
                        formInput.selectValue = [];
                        $(oldFormInput).each(function(oldIndex){ formInput.selectOption(this) });
                        break;
                    case 'form-tags':
                        formInput = this.$children[0];
                        oldFormInput = $(`#${this.inputid}_old`).parents(`.profile__input--wrapper`)[0].__vue__.selectValue;
                        formInput.selectValue = [];
                        formInput.updateTags(oldFormInput.join(','));
                        break;
                    default:
                        alert(`ERROR: Unexpected field type - ${this.fieldtype}`);
                        break;
                };
                this.dataNotOverwritten = false;
                return this;
            },
            restoreNewData: function(){
                let formInput;
                switch(this.fieldtype){
                    case 'form-input':
                    case 'form-textarea':
                    case 'form-number':
                        formInput = $(this.$el).find(`.form-data-target`)[0];
                        formInput.value = this.startvalue;
                        break;
                    case 'form-date':
                        formInput = this.$children[0];
                        let flatpickerInstance = formInput.flatpickerInstance;
                        flatpickerInstance.setDate(this.startvalue,true,'Y/m/d H:i');
                        break;
                    case 'form-radio':
                        formInput = this.$children[0];
                        formInput.checkedOption = this.startvalue;
                        break;
                    case 'form-checkbox':
                        let value = '0';
                        if(this.startvalue == true || this.startvalue == '1' || this.startvalue == 'true' || this.startvalue == 'on'){
                            value = '1';
                        }
                        formInput = this.$children[0];
                        formInput.value = value;
                        break;
                    case 'form-dropdown':
                        formInput = this.$children[0];
                        formInput.selectedOption = this.startvalue || {};
                        $(formInput.dropdownOptions).each(function(option_index){
                            if(this.id == formInput.selectedOption){
                                formInput.selectOption(this);
                                return false;
                            };
                        });
                        break;
                    case 'form-multidrop':
                        formInput = this.$children[0];
                        formInput.selectValue = [];
                        $(this.startvalue).each(function(index){ formInput.selectOption(this) });
                        break;
                    case 'form-tags':
                        formInput = this.$children[0];
                        formInput.selectValue = [];
                        formInput.updateTags(this.startvalue.join(','));
                        break;
                    default:
                        alert(`ERROR: Unexpected field type - ${this.fieldtype}`);
                        break;
                };
                this.dataNotOverwritten = true;
                return this;
            },
            handleAjaxEvent: function(event){
            //    console.log(`${this.fieldtype} ${this.title} handleAjaxEvent`,event);

                let VueSender = event.VueComponent;

                let optionsContainer = $(VueSender.$el).find(`.profile__container--options`);

                if(event.step == 'start'){
                    let optionsCover = document.createElement('div');
                    optionsCover.style = `
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.5);
                        z-index: 999;
                        position: absolute;
                        top: 0rem;
                    `;
                    optionsCover.className = 'options-cover';
                    optionsCover.innerHTML = `<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`;


                    $(optionsContainer).append(optionsCover);
                    // console.log(optionsCover,optionsContainer);
                }else{
                    let optionsCover = $(optionsContainer).find(`.options-cover`);
                    $(optionsCover).remove();
                };
            }
        }
    }
</script>