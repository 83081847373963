<template>
    <note
        v-bind:notedata="notedata"
        v-bind:viewer="viewer"
        v-bind:noteeditable="noteeditable"
        v-bind:notenew="true"
        v-bind:recordid="recordid"
        v-bind:isstatus="isstatus"
        v-bind:statusdata="statusdata"
        v-bind:candidatetypeoptions="candidatetypeoptions"
        v-bind:reloadafteraction="reloadafteraction"
    ></note>
</template>

<script>
    import Note from './sub-components/note.vue'

    export default {
        props: [
            'viewer',
            'notedata',
            'noteeditable',
            'recordid',
            'isstatus',
            'statusdata',
            'candidatetypeoptions',
            'reloadafteraction'
        ],
        data: function(){ return {} },
        components: { Note },
        created: function () {
            // console.log(this.reloadafteraction);
        }
    }
</script>