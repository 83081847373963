<template>
    <div class="profile__input--wrapper">
        <textarea
            class="form-data-target"
            v-bind:disabled="isdisabled"
            v-model="displayText"
            v-bind:name="inputname"
            v-bind:id="inputid"
            v-bind:readonly="readState"
            v-bind:class="{ 'element-disabled': readState }"
            rows="4"
            cols="50">
        </textarea>		
		<div v-if="haserrors" class="login-form__warning--icon" v-bind:title="haserrors[0]"><p>!</p></div>
		<!-- <div 
			v-if="haserrors"
			class="error-explanation">
				{{ haserrors[0] }}
		</div> -->
    </div>
</template>

<script>
    export default {
		props: [
            'inputname',
            'inputid',
            'startvalue',
            'alloptions',
            'haserrors',
            'isdisabled'
        ],
        data: function(){
            return {
                readState: false,
                //fieldId: this.inputname.replace(/\[/g,'_').replace(/\]/g,''),
                displayText: this.startvalue || ''
            }
        },
        created: function(){
            if(this.alloptions){
                this.readState = this.alloptions.readonly;
            };
        }
    }
</script>