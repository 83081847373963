<template>
    <form v-bind:action="submitUrl" accept-charset="UTF-8" method="post">

        <input type="hidden" name="_method" value="delete">
        <input type="hidden" name="authenticity_token" v-bind:value="formToken">

        <div class="profile__container--wrapper profile__container--columns-2">
            <div class="profile__container--outer candidate-details sectioned">
                <div class="profile__container--header">
                    <h3>Delete</h3>
                    <!-- <i class="flaticon-notes notes-toggle"></i>
                    <div class="notes-container">
                        Note: The Text Kernel CV processing service searches for a match of "Job code" and "Web name"
                    </div> -->
                </div>
                <div class="profile__container--main">
                    <div class="form-hint">{{ hintText }}</div>

                    <button 
                    type="submit" 
                    class="submit-button danger" 
                    v-bind:data-confirm="submitConfirmText"
                    data-disable-with="submitting"
                    > 
                        Delete 
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'

    export default {
        props: [
            'options'
        ],
        data: function(){
            let defaultHintText = "This will set the the record id to null in all related records, delete all related records where the id is required and delete the record";

            let defaultSubmitConfirmText = "Are you sure?";

            return {  
                submitUrl: this.options.submit_url, 
                formToken: this.options.form_token,
                hintText: this.options.hint_text || defaultHintText,
                submitConfirmText: this.options.submit_confirm_text || defaultSubmitConfirmText
            };
        },
        components: {
            FormField
        }
    }
</script>

