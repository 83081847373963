<template>
    <div class="profile__container--wrapper profile__container--columns-2">

        <div class="profile__container--outer candidate-details sectioned profile__container--columnPos-1">
            <div class="profile__container--header">
                <h3></h3>
            </div>
            <div class="profile__container--main">
                <form-field
                    title="Request No."
                    fieldtype="form-input"
                    inputname="job_request[request_nr]"
                    v-bind:startvalue="jobRequest.request_nr"
                    v-bind:haserrors="jobRequest.errors.request_nr"
                    isdisabled="true"
                ></form-field>

                <form-field
                    title="Contract type"
                    fieldtype="form-input"
                    inputname="job_request[job_contract_type_info]"
                    v-bind:startvalue="jobRequest.job_contract_type_info"
                    isdisabled="true"
                ></form-field>

                <form-field
                    title="Job title"
                    fieldtype="form-input"
                    inputname="job_request[job_title]"
                    v-bind:startvalue="jobRequest.job_title"
                    v-bind:haserrors="jobRequest.errors.job_title"
                ></form-field>

                <form-field
                    title="Request date"
                    fieldtype="form-date"
                    inputname="job_request[request_date]"
                    v-bind:startvalue="jobRequest.request_date"
                    v-bind:haserrors="jobRequest.errors.request_date"
                ></form-field>


                <!-- Full width input? -->
                <form-field
                    title="Description"
                    fieldtype="form-input"
                    inputname="job_request[description]"
                    v-bind:startvalue="jobRequest.description"
                    v-bind:haserrors="jobRequest.errors.description"
                    isdisabled="true"
                ></form-field>

                <job-request-languages
                    addWrapperClasss="profile__container--columnPos-1"
                    v-bind:intial-records="jobRequest.languages"
                    v-bind:language-proficiency-options="formOptions.language_proficiency_options"
                ></job-request-languages>

                <!-- closing Details main -->
            </div>
            <!-- closing Details outer -->
        </div>

        <div class="profile__container--outer candidate-work sectioned profile__container--columnPos-2">
            <div class="profile__container--header">
                <h3></h3>
            </div>
            <div class="profile__container--main">
              
                <div class="profile__container--row">
                    <form-field
                        title="Region"
                        fieldtype="form-input"
                        inputname="job_request[location]"
                        v-bind:startvalue="jobRequest.location"
                        v-bind:haserrors="jobRequest.errors.location"
                    ></form-field>

                    <form-field
                        title="Region overrule"
                        fieldtype="form-input"
                        inputname="job_request[location_overrule]"
                        v-bind:startvalue="jobRequest.location_overrule"
                        v-bind:haserrors="jobRequest.errors.location_overrule"
                    ></form-field>
                </div>

                <div class="profile__container--row salary-row--flip">
                    <form-field
                        title="Salary min"
                        fieldtype="form-number"
                        inputname="job_request[salary_min]"
                        v-bind:startvalue="jobRequest.salary_min"
                        v-bind:alloptions="{
                            min: 0,
                            max: 1000000,
                            step: 1
                        }"
                        v-bind:haserrors="jobRequest.errors.salary_min"
                    ></form-field>

                    <form-field
                        title="Salary max"
                        fieldtype="form-number"
                        inputname="job_request[salary_max]"
                        v-bind:startvalue="jobRequest.salary_max"
                        v-bind:alloptions="{
                            min: 0,
                            max: 1000000,
                            step: 1
                        }"
                        v-bind:haserrors="jobRequest.errors.salary_max"
                    ></form-field>
                </div>

                <div class="profile__container--row salary-row--flip">
                    <form-field
                        title="Salary currency"
                        fieldtype="form-dropdown"
                        inputname="job_request[salary_currency]"
                        v-bind:startvalue="jobRequest.salary_currency"
                        v-bind:alloptions="formOptions.salary_currency_options"
                        v-bind:haserrors="jobRequest.errors.salary_currency"
                    ></form-field>

                     <form-field
                        title="Salary range"
                        fieldtype="form-radio"
                        inputname="job_request[salary_range_type]"
                        v-bind:startvalue="jobRequest.salary_range_type"
                        v-bind:alloptions="formOptions.salary_range_type_options"
                        v-bind:haserrors="jobRequest.errors.salary_range_type"
                    ></form-field>
                </div>

                <form-field
                    title="Salary overrule"
                    fieldtype="form-dropdown"
                    inputname="job_request[salary_overrule_id]"
                    v-bind:startvalue="jobRequest.salary_overrule_id"
                    v-bind:alloptions="formOptions.salary_overrule_options"
                    v-bind:haserrors="jobRequest.errors.salary_overrule"
                    addblank="true"
                ></form-field>

                <div class="profile__container--row date-row">
                    <form-field
                        title="Start date"
                        fieldtype="form-date"
                        inputname="job_request[start_date]"
                        v-bind:startvalue="jobRequest.start_date"
                        v-bind:haserrors="jobRequest.errors.start_date"
                    ></form-field>

  
                    <form-field
                        title="ASAP"
                        fieldtype="form-checkbox"
                        inputname="job_request[is_asap]"
                        v-bind:startvalue="jobRequest.is_asap"
                        v-bind:haserrors="jobRequest.errors.is_asap"
                    ></form-field>
                </div>

                <!-- 
                <form-field
                    title="Request status"
                    fieldtype="form-dropdown"
                    inputname="job_request[job_request_status_id]"
                    v-bind:startvalue="jobRequest.job_request_status_id"
                    v-bind:alloptions="formOptions.job_request_status_options"
                    v-bind:haserrors="jobRequest.errors.job_request_status"
                ></form-field> 
                -->

                <form-field
                    title="Duration"
                    fieldtype="form-dropdown"
                    inputname="job_request[job_duration_id]"
                    v-bind:startvalue="jobRequest.job_duration_id"
                    v-bind:alloptions="formOptions.job_duration_options"
                    v-bind:haserrors="jobRequest.errors.job_duration"
                    addblank="true"
                ></form-field>
                <!-- closing work main div -->
            </div>
            <!-- closing work outer div -->
        </div>
        <div class="profile__container--outer sectioned ck-column profile__container--columns-1-3 profile__container--rowPos-2 alternative-jobs-wrapper">
            <div class="profile__container--header"><h3></h3></div>

            <div class="profile__container--main">
                
                <div class="profile__container--header"><h3>Alternative job titles for feeds and sites</h3></div>
                <div class="profile__container--inner">
                    <form-field
                        title="Original request name (default name unless overruled below)"
                        fieldtype="form-input"
                        inputname="job_request[job_title]"
                        v-bind:startvalue="jobRequest.job_title"
                        isdisabled="true"
                    ></form-field>

                    <div 
                        v-for="(record, index) in titleOverrules"
                        v-bind:key="record.vue_key">

                        <input v-if="record.id" type="hidden" v-model="record.id" v-bind:name="'job_request[job_request_title_overrules_attributes][' + record.vue_key + '][id]'">
                        <input type="hidden" v-model="record.feed" v-bind:name="'job_request[job_request_title_overrules_attributes][' + record.vue_key + '][feed]'">
                        
                        <form-field
                            v-bind:title="record.feed_name"
                            fieldtype="form-input"
                            v-bind:inputname="'job_request[job_request_title_overrules_attributes][' + record.vue_key + '][job_title]'"
                            v-bind:startvalue="record.job_title"
                            v-bind:haserrors="record.errors.job_title"
                        ></form-field>
                    </div>

                    <form-field
                        title="Advert is written in"
                        fieldtype="form-dropdown"
                        inputname="job_request[language_id]"
                        v-bind:startvalue="jobRequest.language"
                        searchurl="/autocomplete/languages?is_job_request_written_in_option=1"
                        v-bind:haserrors="jobRequest.errors.language_id"
                        addblank="true"
                    ></form-field>
                </div>
            
                <textarea id="ck-editor" name="job_request[job_description]" v-model="jobRequest.job_description"></textarea>
                <div id="ck-menu"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'
    import JobRequestLanguages from './shared/job-request-languages.vue'

    export default {
        props: [
            'jobRequestData',
            'formOptionsData'
        ],
        data: function(){
            let jobRequest = JSON.parse(this.jobRequestData || '{}');
            let formOptions = JSON.parse(this.formOptionsData || '{}');

            let titleOverrules = jobRequest.title_overrules || [];
            titleOverrules.forEach(function(item, i) { item.vue_key = i; });
            console.log(titleOverrules)
            // console.log(jobRequest);

            return { 
                jobRequest: jobRequest, 
                titleOverrules: titleOverrules,
                formOptions: formOptions
            };
        },
        components: {
            FormField,
            JobRequestLanguages
        }
    }
</script>