<template>
<div class="profile__container--wrapper profile__container--columns-3">

    <div class="profile__container--outer profile__container--details">
        <div class="profile__container--header">
            <h3>Company details</h3>
        </div>
        <div class="profile__container--main">
            <form-field
                title="Salesforce Id"
                fieldtype="form-input"
                inputname="company[salesforce_id]"
                v-bind:startvalue="company.salesforce_id"
                v-bind:haserrors="company.errors.salesforce_id"
            ></form-field>

            <form-field
                title="Salesforce sync"
                inputname="company[salesforce_sync]"
                fieldtype="form-radio"
                v-bind:startvalue="company.salesforce_sync"
                v-bind:alloptions="[{ name: 'Yes', id: true }, { name: 'No', id: false }]"
                v-bind:haserrors="company.errors.salesforce_sync"
            ></form-field>

            <form-field
                title="SF Account owner"
                fieldtype="form-input"
                inputname="company[salesforce_owner]"
                v-bind:startvalue="company.salesforce_owner"
                isdisabled='1'
            ></form-field>

            <form-field
                title="Account manager"
                fieldtype="form-dropdown"
                inputname="company[account_manager_id]"
                v-bind:startvalue="company.account_manager"
                searchurl="/autocomplete/staff"
                v-bind:haserrors="company.errors.account_manager_id || unexpectedAccountManagerError"
                preload='1'
            ></form-field>
            <!--<div>{{ company.salesforce_account_manager }}</div>-->

            <form-field
                title="Company No"
                fieldtype="form-input"
                inputname="company[client_no]"
                v-bind:startvalue="company.client_no"
                v-bind:haserrors="company.errors.client_no"
                v-bind:isdisabled="1"
            ></form-field>

            <form-field
                title="Name"
                fieldtype="form-input"
                inputname="company[name]"
                v-bind:startvalue="company.name"
                v-bind:haserrors="company.errors.name"
            ></form-field>

            <form-field
                title="Status (blacklist/no contact)"
                fieldtype="form-dropdown"
                inputname="company[status]"
                v-bind:startvalue="{ name: company.status, id: company.status }"
                v-bind:haserrors="company.errors.status"
                v-bind:alloptions="formOptions.status_options"
                addblank="true"
            ></form-field>

            <form-field
                title="Industry"
                fieldtype="form-dropdown"
                inputname="company[industry_id]"
                v-bind:startvalue="company.industry"
                v-bind:haserrors="company.errors.industry_id"
                searchurl="/autocomplete/industries"
            ></form-field>


            <form-field
                title="Description"
                fieldtype="form-textarea"
                inputname="company[description]"
                v-bind:startvalue="company.description"
                v-bind:haserrors="company.errors.description"
            ></form-field>

        <div class="profile__container--col">
            <form-field
                title="Country"
                fieldtype="form-dropdown"
                inputname="company[country_id]"
                v-bind:startvalue="company.country"
                searchurl="/autocomplete/countries"
                v-bind:haserrors="company.errors.country_id || unexpectedCountryError"
                preload='1'
            ></form-field>
            <!--<div>{{ company.salesforce_country }}</div>-->

            <form-field
                title="Region"
                fieldtype="form-dropdown"
                inputname="company[region]"
                v-bind:startvalue="{ name: company.region, id: company.region }"
                v-bind:haserrors="company.errors.region"
                v-bind:alloptions="formOptions.region_options"
                addblank="true"
            ></form-field>
        </div>



            <!-- Visiting address - own container? -->
            <form-field
                title="Visiting address"
                fieldtype="form-input"
                inputname="company[va_address]"
                v-bind:startvalue="company.va_address"
                v-bind:haserrors="company.errors.va_address"
            ></form-field>
            <form-field
                title="Number"
                fieldtype="form-input"
                inputname="company[va_housenumber]"
                v-bind:startvalue="company.va_housenumber"
                v-bind:haserrors="company.errors.va_housenumber"
            ></form-field>
            
            <form-field
                title="Postcode"
                fieldtype="form-input"
                inputname="company[va_postcode]"
                v-bind:startvalue="company.va_postcode"
                v-bind:haserrors="company.errors.va_postcode"
            ></form-field>
            <form-field
                title="City"
                fieldtype="form-input"
                inputname="company[va_town]"
                v-bind:startvalue="company.va_town"
                v-bind:haserrors="company.errors.va_town"
            ></form-field>

            <!-- End of visiting address -->

        </div>
    </div>
    <div class="profile__container--outer profile__container--contacts">
        <div class="profile__container--header">
            <h3 style="visibility: hidden;">HIDDEN HEADER</h3> 
        </div>
        <div class="profile__container--main">

            <form-field
                title="Phone"
                fieldtype="form-input"
                inputname="company[phone]"
                v-bind:startvalue="company.phone"
                v-bind:haserrors="company.errors.phone"
            ></form-field>


            <form-field
                title="Company E-mail"
                fieldtype="form-input"
                inputname="company[email]"
                v-bind:startvalue="company.email"
                v-bind:haserrors="company.errors.email"
            ></form-field>

            <form-field
                title="Website"
                fieldtype="form-input"
                inputname="company[website]"
                v-bind:startvalue="company.website"
                v-bind:haserrors="company.errors.website"
            ></form-field>

            <form-field
                title="Type"
                fieldtype="form-dropdown"
                inputname="company[company_type]"
                v-bind:startvalue="{ name: company.company_type, id: company.company_type }"
                v-bind:haserrors="company.errors.company_type"
                v-bind:alloptions="formOptions.company_type_options"
                addblank="true"
            ></form-field>


            <form-field
                title="Client"
                inputname="company[is_client]"
                fieldtype="form-radio"
                v-bind:startvalue="company.is_client"
                v-bind:alloptions="[{ name: 'Yes', id: true }, { name: 'No', id: false }]"
                v-bind:haserrors="company.errors.is_client"
            ></form-field>


            <form-field
                title="Supplier"
                inputname="company[is_supplier]"
                fieldtype="form-radio"
                v-bind:startvalue="company.is_supplier"
                v-bind:alloptions="[{ name: 'Yes', id: true }, { name: 'No', id: false }]"
                v-bind:haserrors="company.errors.is_supplier"
            ></form-field>


            <form-field
                title="T&C Sent"
                fieldtype="form-dropdown"
                inputname="company[terms_conditions_sent]"
                v-bind:startvalue="{ name: company.terms_conditions_sent, id: company.terms_conditions_sent }"
                v-bind:haserrors="company.errors.terms_conditions_sent"
                v-bind:alloptions="formOptions.terms_conditions_sent_options"
                addblank="true"
            ></form-field>

            <form-field
                title="T&C Signed"
                fieldtype="form-dropdown"
                inputname="company[terms_conditions_signed]"
                v-bind:startvalue="{ name: company.terms_conditions_signed, id: company.terms_conditions_signed }"
                v-bind:haserrors="company.errors.terms_conditions_signed"
                v-bind:alloptions="formOptions.terms_conditions_signed_options"
                addblank="true"
            ></form-field>

            <!-- Billing address - own container? -->
        <div class="profile__container--section">
            <form-field
                title="Billing Street"
                fieldtype="form-input"
                inputname="company[billing_address_street]"
                v-bind:startvalue="company.billing_address_street"
                v-bind:haserrors="company.errors.billing_address_street"
            ></form-field>

            <form-field
                title="Billing City"
                fieldtype="form-input"
                inputname="company[billing_address_city]"
                v-bind:startvalue="company.billing_address_city"
                v-bind:haserrors="company.errors.billing_address_city"
            ></form-field>
            <form-field
                title="Billing Province"
                fieldtype="form-input"
                inputname="company[billing_address_province]"
                v-bind:startvalue="company.billing_address_province"
                v-bind:haserrors="company.errors.billing_address_province"
            ></form-field>

            <form-field
                title="Billing Postcode"
                fieldtype="form-input"
                inputname="company[billing_address_postcode]"
                v-bind:startvalue="company.billing_address_postcode"
                v-bind:haserrors="company.errors.billing_address_postcode"
            ></form-field>

            <form-field
                title="Billing Country"
                fieldtype="form-input"
                inputname="company[billing_address_country]"
                v-bind:startvalue="company.billing_address_country"
                v-bind:haserrors="company.errors.billing_address_country"
            ></form-field>

            <form-field
                v-if="formOptions.can_view_feed_name"
                title="Feed name"
                fieldtype="form-input"
                inputname="company[feed_name]"
                v-bind:startvalue="company.feed_name"
                v-bind:haserrors="company.errors.feed_name"
                v-bind:isdisabled="!formOptions.can_edit_feed_name"
            ></form-field>
        </div>

            <!-- End of Billing address -->

            <!-- Correspondence address - own container? -->
        <div class="profile__container--section">
            <form-field
                title="Cor. address (legacy)"
                fieldtype="form-input"
                inputname="company[pa_postboxnumber]"
                v-bind:startvalue="company.pa_postboxnumber"
                v-bind:haserrors="company.errors.pa_postboxnumber"
                v-bind:isdisabled="1"
            ></form-field>

            <form-field
                title="City (legacy)"
                fieldtype="form-input"
                inputname="company[pa_town]"
                v-bind:startvalue="company.pa_town"
                v-bind:haserrors="company.errors.pa_town"
                v-bind:isdisabled="1"
            ></form-field>

            <form-field
                title="Postcode (legacy)"
                fieldtype="form-input"
                inputname="company[pa_postcode]"
                v-bind:startvalue="company.pa_postcode"
                v-bind:haserrors="company.errors.pa_postcode"
                v-bind:isdisabled="1"
            ></form-field>
        </div>
            <!-- End of Correspondence address -->


        </div>
    </div>
    <div class="profile__container--outer profile__container--status profile__container--columnPos-3">
        <div class="profile__container--header">
            <h3></h3>
        </div>
        <div class="profile__container--main">
          
        </div>
    </div>

</div>
</template>

<script>
    import FormField from './../../form-field/form-field.vue'

    export default {
        props: [
            'recordData',
            'formOptionsData'
        ],
        data: function(){
            var company = JSON.parse(this.recordData || '{}');
            var formOptions = JSON.parse(this.formOptionsData || '{}');
            // console.log(staff); console.log(formOptions);

            // company.salesforce_account_manager = 'Joe';
            // company.salesforce_country = 'Bau-bau';

            let unexpectedAccountManager;
            if(company.salesforce_account_manager != undefined && company.salesforce_account_manager != null){
                unexpectedAccountManager = [`Unexpected SF value 'account manager': '${company.salesforce_account_manager}'`];
            };

            let unexpectedCountry;
            if(company.salesforce_country != undefined && company.salesforce_country != null){
                unexpectedCountry = [`Unexpected SF value 'country': '${company.salesforce_country}'`];
            };

            return { 
                company: company, 
                formOptions: formOptions,
                unexpectedAccountManagerError: unexpectedAccountManager,
                unexpectedCountryError: unexpectedCountry
            };
        },
        components: {
            FormField
        }
    }
</script>