document.addEventListener('turbolinks:click', function (event) { toggle_loadingBackground('block') });

document.addEventListener('turbolinks:load', function (event) {

    /**
     * @created by Zheko Hristov on 16/06/2023
     * @last-updated by Zheko Hristov on 16/06/2023
     *
     * Fixing bug after client report in trello - "Header search bar not responsive"
     **/

    $('body').on('click', function(e) {
        if ($(e.target).parents('.header__search--container').length === 0 && $(e.target).parents('.mobile__search--container').length === 0) {
            $('.header__search--results, .mobile__search--results').empty();
            $('.search-filter-button').css({'display': 'none'});
        }
    });

    //POPULATE THE DASHBOARD MENU BUTTONS WITH THEIR RESPECTIVE MENUS
    load_dashboardMenus();

    //TARGETS DASHBOARD MENUS, MOBILE HEADER MENU AND BACKGROUND AND BINDS THE RESPECTIVE LISTENERS
    load_listeners();
    
    //TARGET ALL TABLES AND MARK EACH CONTENT FIELD WITH THE CORRESPONDING 'data-label' AT THE TOP OF IT'S COLUMN
    load_tableLabels();

    //TARGET ALL TABLE ROWS AND ADD HTML MARKUP FOR AN EXPANSIVE DROPDOWN ON MOBILE SCREENS
    load_tableEntryExpand();

    //TARGET ALL TABLE EXPAND BUTTONS AND BIND CLICK LISTENERS
    load_tableExpandListeners();
    
    //TARGET ALL DETAILED FORM CLIPBOARD COPY ICONS AND 
    //MAKE THEM TABBABLE, BIND KEYBOARD & CLICK HANDLERS, WHERE BOTH
    //COPY FIELD TEXT TO CLIPBOARD, RADIO FIELDS COPIED AS 'FIELD_NAME: FIELD_TEXT' FORMAT
    load_clipboardCopy();
    
    //TARGET DASHBOARD MENUS AND HIGHLIGHT(BACKGROUND-COLOR) THE ONE CONTAINING A LINK TO THE CURRENT PAGE
    //ALSO GENERATES THE SUB-HEADER CONTENT, PASSING THE HIGHLIGHTED MENU'S NAME AS INPUT
    highlight_header();
    
    //TARGET ALL FILE-SELECT ICONS AND BIND CLICK HANDLER
    //REROUTING THE CLICK EVENT TO THE RESPECTIVE FILE INPUT
    load_fileSelectors();

    //FIND ALL TABLE-RELATED SEARCH BARS AND BIND SUBMIT HANDLERS FOR CLICK AND KEYBOARD EVENT
    load_tableSearch();

    //TARGET THE TABLE'S ASSOCIATED FILTER CONTAINER TOGGLER AND BIND THE CLICK HANDLER TO IT
    load_filtersToggle();

    //TARGETS ALL TABLES' PAGINATION DROPDOWN AND BINDS A CLICK HANDLER TO IT'S OPTIONS
    //load_paginationLimiter();

    //LOAD LEVEL "3" SUB-HEADER
    load_navigationLevel3();

    //MOVES CONTENT TO THE SUB-HEAD
    cloneToSub();

    //LOADS THE FILE SELECTOR STYLE AND EVENT BINDING
    load_documentFilePickers();

    //BINDS HANDLERS TO THE CKEDITOR BUTTONS
    load_CKEditorExternalBindings();

    //BIND RESIZE LISTENERS FOR STRETCHING TEXTAREA HEIGHTS TO FIT THEIR CONTENT
    load_tallTextareas();

    //BIND DOSSIER HANDLERS FOR EXPANDING/COLLAPSING TABLES
    load_dossierExpandButtons();
    
    //CREATES A COPY OF THE NOTES MENU BUTTON TO THE MAIN HEADER FOR LESS THAN THE WIDEST SCREENS
    copyNoteButton();

    //BINDING RESIZE LISTENERS TO POSITION TABLE FILTERS ON MEDIA RESPONSE
    relocateFilters();

    //QUERIES THE SERVER(OR SIMPLY COUNTS THE PRESENT NOTES) TO COUNT THE OVERDUE TASKS
    load_bellNotification();

    //BINDS CKEDITOR PREVIEW HANDLER
    load_ckPreview();

    //BINDS THE 'ADD CANDIDATE TO DOSSIER' BUTTON HANDLER
    load_addCandidateToDossier();

    //FIXES THE UPLOAD DOCUMENT BUTTON ON CANDIDATE DOCUMENT PAGES BY ALLOWING CLICK-THROUGH THE ICON
    load_documentIconClick();

    //TARGETS TABLES WITH CLASS (.table--mid-target) AND BUILDS A MID-SCREEN WIDTH MARKUP
    build_midTables();

    //Pages with dossier notes create multiple elements with duplicate id's (#ck-note--wrapper), 
    //which does not seem to trigger a warning in the console nor interfere with expected behavior, 
    //this function removes them
    removeDuplicateCKWrappers();

    //Repositions the titles of dashboard lists inside their respective table's 
    //"table__container--outer" div
    loadDashboardListTitles();

    //test_findExternalDossier();

    //orderDossiers();

    padPermissionsTable();
    // test_findCandidateDossierMismatch();

    //SHOWS/HIDES THE LOADING BACKGROUND
    toggle_loadingBackground('none');
});

//SHOWS/HIDES THE LOADING BACKGROUND
function toggle_loadingBackground(state) {
    let loadingBackground = $(`#loading-background`);
    if(state){
        $(loadingBackground).css(`display`,state);
        window.lockBackground = !(state == 'none');
        return;
    };
    let displayState = $(loadingBackground).css(`display`);
    if (displayState == 'none') {
        $(loadingBackground).css(`display`, `block`);
    } else {
        $(loadingBackground).css(`display`, `none`);
    };
};

//TARGETS DASHBOARD MENUS, MOBILE HEADER MENU AND BACKGROUND AND BINDS THE RESPECTIVE LISTENERS
function load_listeners() {
    //BINDING LISTENERS FOR THE DASHBOARD MENUS
    $(`.toggle_dashboardMenu`).click(function (event) {
        handle_dashboardMenu(event);
    });
    ///--------------------------------------------------
    ///--------------------------------------------------
    //---BINDING0 "ONHOVER(IN/OUT)" TO DASHBOARD MENUS---
    //      note: in function handle_dashboardMenu(event,classN){...}
    //            the code setting ".background-blur" to "display: block" should 
    //            be uncommented, to switch from "ONHOVER" to "ONCLICK"
    //            and vice versa. This block should be commented(inverse to other).
    //
    $(`.toggle_dashboardMenu`).mouseenter(function (event) {
        handle_dashboardMenu(event);
    });
    $(`.toggle_dashboardMenu`).mouseleave(function (event) {
        clear_dashboardMenu();
    });
    ///--------------------------------------------------
    ///--------------------------------------------------
    ///--------------------------------------------------
    //BINDING LISTENER FOR THE MOBILE SIDE-MENU
    $(`.toggle_mobileMenu`).click(function (event) {
        handle_mobileMenu(event);
    });
    //BINDING LISTENERS TO THE HIDDEN BACKGROUND FOR CLOSING MENUS ON 'CLICK-OUTSIDE' EVENTS
    if ($('.background-blur')) {
        $('.background-blur').click(function (event) {
            clear_dashboardMenu();
            clear_filters();
            $(`.mobile-menu__shown`).removeClass('mobile-menu__shown');
            $(`.header-menu__active`).removeClass('header-menu__active');
            $('.background-blur').css('display', 'none');
        });
    };
    if ($(`.header__container--menu`)) {
        $(`.header__container--menu`).click(function (event) {
            if (event.target.className.indexOf('header__container--menu') != -1) {
                clear_dashboardMenu();
                clear_filters();
            };
        });
    };
    if ($(`.header__container--wrapper`)) {
        $(`.header__container--wrapper`).click(function (event) {
            if (event.target.className.indexOf('header__container--wrapper') != -1) {
                clear_dashboardMenu();
                clear_filters();
            };
        });
    };
    if ($(`.header__container--mobile`)) {
        $(`.header__container--mobile`).click(function (event) {
            if (event.target.className.indexOf('header__container--mobile') != -1) {
                clear_dashboardMenu();
                clear_filters();
            };
        });
    };
};

//POPULATE THE DASHBOARD MENU BUTTONS WITH THEIR RESPECTIVE MENUS
function load_dashboardMenus() {
    $(`.dashboard-menu__container--button`).each(function (menu_index) {
        let targetClass = `.dashboard-menu__container--${this.classList[2].replace('toggle_', '')}`;
        if (!$(this).find(targetClass)[0]) { this.innerHTML += $(targetClass)[0].outerHTML };
    });
    clear_dashboardMenu();
};

////-----------------////-----------------////-----------------////
//SHOW THE DASHBOARD MENU RELATED TO RESPECTIVE EVENT
function handle_dashboardMenu(event) {
    if (event.target.className.indexOf('dashboard-menu__container--button') == -1 && event.target.parentElement.className.indexOf('dashboard-menu__container--button') == -1) {
        return;
    };
    //---------------------------------------------
    //---------------------------------------------
    //---BINDING0 "ONCLICK/ONHOVER" SWITCH BLOCK---
    //      note: uncomment this code, along with the respective "BINDING0" code above,
    //            to switch from "ONHOVER" to "ONCLICK" and vice versa
    //            
    // let mobileMenu = $(`.header__container--menu`);
    // let mobileMenuRight = mobileMenu.css(`right`).replace(`px`,``);
    // if(mobileMenuRight != 0){
    //     if(event.type != 'mouseenter'){
    //         $(`.background-blur`).css(`display`,`block`);
    //     };
    // };
    //---------------------------------------------
    //---------------------------------------------
    //---------------------------------------------
    let toggler_button;
    if (event.target.className.indexOf(`dashboard-menu__container--button`) != -1) {
        toggler_button = event.target;
    } else {
        toggler_button = $(event.target).parent()[0];
    };
    let toggled_menu = $(toggler_button).find(`.dashboard-menu__container--floating`)[0];
    let returnFlag = 0;
    if (toggled_menu && toggled_menu.className.indexOf(`dashboard-menu__hide`) == -1) {
        returnFlag = 1;
    };
    clear_dashboardMenu();
    clear_filters();
    if (returnFlag) {
        return;
    };
    let targetClass = `.dashboard-menu__container--${toggler_button.classList[2].replace('toggle_', '')}`;
    let dashboardMenu = $(toggler_button).find(targetClass)[0];
    let newView;
    if ($(toggler_button).parent()[0].className.indexOf('mobile') != -1) {
        newView = 'dashboard-menu__mobile';
    } else {
        newView = 'dashboard-menu__desktop';
    };
    $(dashboardMenu).removeClass(`dashboard-menu__desktop`)
        .removeClass(`dashboard-menu__mobile`)
        .removeClass(`dashboard-menu__hide`)
        .addClass(newView);
    let dashboardArrow = $(toggler_button).find('.dashboard-menu__arrow-down')[0];
    $(dashboardArrow).addClass(`filters__arrow--flip`);
};

//TARGET ALL DASHBOARD MENUS AND HIDE THEM
function clear_dashboardMenu() {
    $(`.dashboard-menu__container--floating`).each(function (index) {
        $(this).removeClass(`dashboard-menu__desktop`)
            .removeClass(`dashboard-menu__mobile`)
            .removeClass(`dashboard-menu__hide`)
            .addClass(`dashboard-menu__hide`);
    });
    $(`.dashboard-menu__arrow-down`).each(function (index) {
        $(this).removeClass(`filters__arrow--flip`);
    });
};

//SHOW OR HIDE THE HEADER SIDE-MENU
function handle_mobileMenu(event) {
    clear_dashboardMenu();
    clear_filters();
    let toggleButton = $('.header-menu__button--mobile')[0];
    let headerMenu = $('.header__container--menu')[0];
    let headerMenuClass = headerMenu.className;
    if (headerMenuClass.indexOf('mobile-menu__shown') == -1) {
        $(headerMenu).addClass('mobile-menu__shown');
        $(toggleButton).addClass('header-menu__active');
        $('.background-blur').css('display', 'block');
    } else {
        $(headerMenu).removeClass('mobile-menu__shown');
        $(toggleButton).removeClass('header-menu__active');
        $('.background-blur').css('display', 'none');
    };
};

//TARGET ALL ACTIVE DROPDOWNS AND CLOSE THEM
function clear_filters() {
    $(`.filters__container--show`).removeClass(`filters__container--show`);
    $(`.filters__container--showUP`).removeClass(`filters__container--showUP`);
    $(`.filters__arrow--flip`).removeClass(`filters__arrow--flip`);
    $(`.profile__container--show`).removeClass(`profile__container--show`);
    $(`.profile__container--showUP`).removeClass(`profile__container--showUP`);
    $(`.profile__arrow--flip`).removeClass(`profile__arrow--flip`);
    document.activeElement.blur();
};

//SEND THE COPIED TEXT TO THE CLIPBOARD 
async function sendToClipboard(textToSend) {
    try {
        await navigator.clipboard.writeText(textToSend);
        document.activeElement.blur();
    } catch (error) {
        alert(`Error when copying to clipboard`, error)
    };
};

//TARGET ALL DETAILED FORM CLIPBOARD COPY ICONS AND 
//MAKE THEM TABBABLE, BIND KEYBOARD & CLICK HANDLERS, WHERE BOTH
//COPY FIELD TEXT TO CLIPBOARD, RADIO FIELDS COPIED AS 'FIELD_NAME: FIELD_TEXT' FORMAT
function load_clipboardCopy() {
    $(`.input-copy:not(.linkedin-copy)`).attr(`tabindex`, 0);
    $(`.input-copy:not(.linkedin-copy)`).bind(`keydown`, function (event) {
        if (event.key == "Enter") {
            event.target.click();
        };
        return;
    });
    $(`.input-copy:not(.linkedin-copy)`).each(function (index) {
        $(this).click(function (event) {
            let inputParent = getFieldParent(event.target, 'profile__input--wrapper');
            let fieldParent = getFieldParent(event.target, `profile__container--field`);
            let fieldNameContainer = $(fieldParent).find(` > p`)[0] || $(fieldParent).find(` > label`);
            let fieldName = fieldNameContainer.innerText;
            let inputTarget = $(inputParent).find(`input`);
            let clipboardOutput = ``;
            let targetClass = $(inputTarget).attr(`class`) || `EMPTY`;
            let targetType = $(inputTarget).attr(`type`) || `EMPTY`;
            if (targetType.indexOf(`radio`) != -1) {
                let checkInput = $(inputParent).find(`input[checked]`);
                clipboardOutput = `${fieldName} ${checkInput[0].labels[0].innerText}`;
            } else if (targetClass.indexOf(`input-tag`) != -1) {
                let titles = $(inputParent).find(`.dropdown-selected-title`);
                $(titles).each(function (title_index) {
                    if (title_index > 0) {
                        clipboardOutput += `, `;
                    };
                    clipboardOutput += this.innerText;
                });
            } else if (targetClass.indexOf(`datepicker`) != -1) {
                clipboardOutput = inputTarget[0].defaultValue;
            } else if (inputTarget.length && inputTarget[0].className.indexOf(`dropdown-search`) == -1) {
                clipboardOutput = inputTarget[0].value;
            } else {
                inputTarget = $(inputParent).find(`.profile__container--dropdown`);
                if ($(inputParent).find(`.dropdown-multiple`).length) {
                    clipboardOutput = ``;
                    $(inputTarget).find(`.dropdown-value`).each(function (value_index) {
                        if (value_index > 0) {
                            clipboardOutput += `, `;
                        };
                        clipboardOutput += $(this).find(`p`)[1].innerText;
                    });
                } else {
                    if (!getFieldParent(event.target, `custom-tag-editor`)) {
                        clipboardOutput = $(inputTarget).find(`.profile__container--selected`).find(`p`)[0].innerText;
                    };
                };
            };
            let sendToClip;
            if (clipboardOutput) {
                sendToClip = clipboardOutput;
            } else {
                sendToClip = '';
            };
            sendToClipboard(sendToClip);
            //---------------------------------
            //-----NEW WRITE TO CLIPBOARD------
            // navigator.clipboard.writeText(sendToClip);
            // document.activeElement.blur();
            //---------------------------------
            //---------------------------------
            //-----OLD WRITE TO CLIPBOARD-----
            //      note: document.execCommand() is said by MDN to be OBSOLETE
            //            whereas its replacement: navigator.clipboard.writeText()
            //            is more modern, but may need permission from the user
            //            more testing should be conducted before settling
            //
            // let temp_element = document.createElement('input');
            // $(temp_element).css('display','block');
            // $(temp_element).attr('class','temp-class');
            // $(temp_element).attr(`value`,sendToClip);
            // $(`body`).append(temp_element);
            // $(`.temp-class`)[0].select();
            // document.execCommand('copy');
            // $(`.temp-class`)[0].remove();
            //---------------------------------
        });
    });
};

//TARGET DASHBOARD MENUS AND HIGHLIGHT(BACKGROUND-COLOR) THE ONE CONTAINING A LINK TO THE CURRENT PAGE
//ALSO GENERATES THE SUB-HEADER CONTENT, PASSING THE HIGHLIGHTED MENU'S NAME AS INPUT
function highlight_header() {
    let pageTitle = location.href.split(`/`)[3];

    console.log(`highlight_header pageTitle=${pageTitle}`);

    if(location.href.indexOf('dashboard-settings') != -1){ return };

    if (pageTitle.length) {
        let headerButtons = $(`.dashboard-menu__container--button`);
        let subheadFlag = 0;
        let searchFlag = Boolean(pageTitle.indexOf('fiters_submitted') != -1);
        headerButtons.each(function (index) {
            if(!searchFlag){
                let buttonTitle = this.innerText.toLowerCase().replace(/ /g, `_`).replace(/</g, ``).slice(0, pageTitle.length).replace(/\s/g, '');            
                if (pageTitle.indexOf(buttonTitle) != -1) {
                    $(this).addClass(`dashboard-menu__highlight`);
                    if (!subheadFlag) {
                        let wordsArray = buttonTitle.split('_');
                        let finalString = '';
                        $(wordsArray).each(function (words_index) {
                            let stringTail = ' ';
                            if (words_index + 1 == wordsArray.length) {
                                stringTail = '';
                            };
                            finalString += this[0].toUpperCase() + this.slice(1) + stringTail;
                        });
                        let targetName = this.classList[2].replace(`toggle_`, ``);
                        subheadFlag = 1;
                        load_subheader(targetName);
                    };
                };
            }else{
                if(index == 4 || index == 9){
                    $(this).addClass(`dashboard-menu__highlight`);
                };
            };
        });
    };
};

//GENERATE SUB-HEADER CONTENT BASED THE DASHBOARD MENU THAT MATCHES THE INPUT CLASS NAME
function load_subheader(elementClass) {
    //console.log('loading sub-header');
    let subHeader = $(`.sub-header__container--wrapper`)[0];
    let targetMenu = $(`.dashboard-menu__container--${elementClass}`)[0];
    $(subHeader).find(`.sub-header__content--button`).each(function (index) {
        this.remove();
    });
    let foundMatch = 0;
    let link = location.href;
    const pathName = location.pathname;
    // console.log('showing page url');
    // console.log(link);
    // console.log('starting first loop');
    $(targetMenu).find(`.dashboard-menu__container--content`).each(function (index) {
        let currentAnchor = $(this).find(`a`);
        let currentName = $(currentAnchor).attr(`href`);
        // console.log('showing item url');
        // console.log(currentName);
        let highlightClass = ``;
        // if (link.indexOf(`?`) != -1) {
        //     if (link.replace(/#/g, '').indexOf(currentName) != -1) {
        //         highlightClass = `sub-header__content--selected`;
        //         foundMatch = 1;
        //     };
        // } else {
        //     if (link.replace(/#/g, '').slice(-currentName.length) == currentName) {
        //         highlightClass = `sub-header__content--selected`;
        //         foundMatch = 1;
        //     };
        // };

        if (pathName === currentName) {
            highlightClass = `sub-header__content--selected`;
            foundMatch = 1;
        }

        let newHTML = `
            <div class="sub-header__content--button ${highlightClass}">
                ${this.innerHTML}
            </div>
        `;
        $(subHeader).append(newHTML);
    });
    if (!foundMatch) {
        // console.log('starting second loop');
        $(subHeader).find(`.sub-header__content--button`).each(function (index) {
            let currentName = $(this).find(`a`).attr(`href`);
            // console.log(`showing item url`);
            // console.log(link);
            // console.log(currentName);
            if (link.split('/')[3] == currentName.replace('/', '')) {
                $(this).addClass(`sub-header__content--selected`);
            };
        });
    };
};

//TARGET ALL FILE-SELECT ICONS AND BIND CLICK HANDLER
//REROUTING THE CLICK EVENT TO THE RESPECTIVE FILE INPUT
function load_fileSelectors() {
    $(`.select-file`).each(function (index) {
        $(this).click(function (event) {
            let fileInput = $(event.target).find(`input`)[0];
            if (fileInput) {
                fileInput.click();
            };
        });
    });
};

//HIDING ALL DROPDOWN MENUS AT THE PAGE'S INITIAL LOADING
window.addEventListener('beforeunload', function (event) {
    clear_dashboardMenu();
    clear_filters();
});

//HIDING ALL DROPDOWN MENUS AT THE PAGE'S INITIAL LOADING
window.addEventListener('turbolinks:before-render', function (event) {
    clear_dashboardMenu();
    clear_filters();
});

//GET THE INPUT FIELD(focusField)'S PARENT ELEMENT THAT HAS THE INPUT CLASS(parentClass) 
//AND RETURN IT
function getFieldParent(focusField, parentClass) {
    let parentFlag = 0;
    let parentFocus;
    let currentFocus = focusField;
    while (!parentFlag) {
        let checkClass;
        if (typeof currentFocus.className == 'object') {
            checkClass = currentFocus.className.baseVal;
        } else {
            checkClass = currentFocus.className;
        };
        if (checkClass.indexOf(parentClass) != -1) {
            parentFlag = 1;
            parentFocus = currentFocus;
        } else {
            currentFocus = currentFocus.parentElement;
            if (currentFocus) { } else {
                return false;
            };
        };
    };
    return parentFocus;
};

//CHECK IF FOCUS IS BEING PASSED TO/FROM A DETAIL FORM FIELD, AND MARK/UNMARK IT
window.addEventListener(`focusin`, function (event) {
    let parentFocus = getFieldParent(event.target, `profile__container--field`);
    if (parentFocus) {
        $($(parentFocus).find(` > p`)[0]).addClass(`mark-tabFocus`);
        $($(parentFocus).find(` > label`)[0]).addClass(`mark-tabFocus`);
    };
});
window.addEventListener(`focusout`, function (event) {
    let parentFocus = getFieldParent(event.target, `profile__container--field`);
    if (parentFocus) {
        $($(parentFocus).find(` > p`)[0]).removeClass(`mark-tabFocus`);
        $($(parentFocus).find(` > label`)[0]).removeClass(`mark-tabFocus`);
    };
});

//FIND ALL TABLE-RELATED SEARCH BARS AND BIND SUBMIT HANDLERS FOR CLICK AND KEYBOARD EVENT
function load_tableSearch() {
    let searchContainers = $.merge(
        $(`.header__search--container`),
        $(`.mobile__search--container`)
    );
    $(searchContainers).each(function (table_index) {
        //$(this).find(`.header__search--button`).bind(`click`, handle_tableSearch);
        $(this).find(`input`).bind(`keydown`, handle_tableSearchKey);
        $(this).find(`input`).bind(`focus`,(event) => { handle_tableSearch(event) });

        //16/06/2023 - comment bottom code due fixing bug, reported from client in trello - Header search bar not responsive"
        
        // $(this).find(`input`).bind(`blur`,(event) => {
        //     // console.log(`table search input`);
        //     let searchParent = getFieldParent(event.target.parentElement,`__search--`);
        //     let classTarget = searchParent.className.replace('__search--container','');
        //     let searchResults = $(searchParent).find(`.${classTarget}__search--results`)[0];
        //     let searchButton = $(searchParent).find(`.search-filter-button`)[0];
        //     setTimeout(() => { 
        //         searchResults.innerHTML = ``;
        //         searchButton.style.display = 'none';
        //     },200);
        // });
        $(this).find(`.search-filter-button`).bind(`click`,(event) => {
            const parent = getFieldParent(event.target,'header__container--wrapper')

            console.log(parent)

            const dropdownOption = $(parent).find('.form-dropdown')[0].__vue__.$children[0].selectedOption.name.replace(/ /g,'_').toLowerCase();
            const inputValue = $(parent).find(`input`)[0].value;
            const filteredPage = `/${dropdownOption}?q%5Bterm%5D=${inputValue}`;

            console.log(dropdownOption,inputValue,filteredPage);

            location.href = filteredPage;
        });
    });
};

//GET THE CURRENT TEXT IN THE TABLE SEARCH BAR AND FORWARD IT TO SERVER AS STRING
function handle_tableSearch(event) {
    let searchParent = getFieldParent(event.target.parentElement, `__search--`);
    let searchInput = $(searchParent).find(`input`);
    let searchQuery = searchInput[0].value || ``;
    let searchScope = $(searchParent).find(`select`);
    if (searchScope.length) {
        searchScope = $(searchScope).find(`option`)[0].value;
    } else {
        searchScope = location.href.split(`/`)[3];
    };
    searchScope = searchScope.replace(/_search/g,'');

    let searchList = $(searchParent).find(`.header__search--results`)[0];

    if(searchQuery != ''){
        let optionsCover = document.createElement('div');
        optionsCover.style = `
            position: absolute;
            top: 6rem;
            display: grid;
            grid-template-columns: 100%;
            left: 1rem;
            max-height: calc(50vh - 7rem);
            height: calc(50vh - 7rem);
            overflow-y: auto;
            width: calc(100% - 1rem);
            z-index: 77;
            background-color: rgba(0,0,0,0.5);
        `;
        optionsCover.className = 'options-cover';
        optionsCover.innerHTML = `<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`;
    
        $(searchParent).append(optionsCover);
    };

    let queryString = `/search/${searchScope.replace(/_/g,'-')}?q=${searchQuery}` || ``;
    ajax('GET',queryString,(Response) => {
        // console.log('handler');
        let ResponseObject = JSON.parse(Response.response);
        let classTarget = searchParent.className.replace('__search--container','');
        let resultsContainer = $(searchParent).find(`.${classTarget}__search--results`)[0];
        let searchButton = $(searchParent).find(`.search-filter-button`)[0];
        let resultsHTML = ``;
        ResponseObject.results.forEach((result) => { resultsHTML += `<a href="${result.details_url}">${result.title}</a>` });
        resultsContainer.innerHTML = resultsHTML;
        searchButton.style.display = 'block';

        let optionsCover = $(searchParent).find(`.options-cover`);
        $(optionsCover).remove();
    });
};

//REROUTE "ENTER" KEYSTROKE TO THE TABLE SEARCH BAR SUBMIT HANDLER
//AKA SUBMIT THE FORM WHEN ENTER IS PRESSED
let tableSearchCounter = 0;
let tableSearchWait = 500;
function handle_tableSearchKey(event) {
    tableSearchCounter++
    let localSearchCounter = tableSearchCounter;
    setTimeout(() => {
        if(tableSearchCounter == localSearchCounter){
            let searchParent = getFieldParent(event.target.parentElement, `__search--`);
            let classTarget = searchParent.className.replace('__search--container','');
            let searchInput = $(searchParent).find(`input`);
            let searchQuery = searchInput[0].value;
            if(searchQuery.length > 2){
                handle_tableSearch(event);
            }else{
                $(searchParent).find(`.${classTarget}__search--results`)[0].innerHTML = ``;
                $(searchParent).find(`.search-filter-button`)[0].style.display = 'none';
            };
        };
    },tableSearchWait);
};

function ajax(method,url,handler,data){
    let xml = new XMLHttpRequest();
    xml.onreadystatechange = function(){ if(this.readyState == 4 && this.status == 200){ handler(this) } };
    xml.open(method,url,true);
    xml.send(JSON.stringify(data));
};

//TARGET ALL TABLES AND MARK EACH CONTENT FIELD WITH THE CORRESPONDING 'data-label' AT THE TOP OF IT'S COLUMN
function load_tableLabels() {
    $(`.table__container--wrapper`).each(function (table_index) {
        if($(this).find(`.table__container--label`).length){
            let labels = $(this).find(`.table__container--label`).find(`p`);
            $(labels).each(function(label_index){
                let currentLabel;
                if($(this).find(`a`).length){
                    currentLabel = $(this).find(`span`)[1];
                }else{
                    currentLabel = this;
                };
                labels[label_index] = currentLabel.innerText;
            });
            $(this).find(`.table__container--single`).each(function (row_index) {
                $(this).find(`.table__container--content`).each(function (entry_index) {
                    $(this).attr(`data-label`, labels[entry_index]);
                });
            });
        };
    });
    $(`.search-list--wrapper`).each(function(list_index){
        let labelContainer = $(this).find(`.search-list--labels`);
        if(labelContainer.length){
            let labels = $(labelContainer).find(` > div`);
            let listRows = $(this).find(`.search-list--item`);
            $(listRows).each(function(row_index){
                let rowItems = $(this).find(` > div`);
                $(rowItems).each(function(item_index){
                    $(this).attr(`data-label`,labels[item_index].innerText);
                });
            });
        };
    });
};

//TARGET ALL TABLE ROWS AND ADD HTML MARKUP FOR AN EXPANSIVE DROPDOWN ON MOBILE SCREENS
function load_tableEntryExpand() {
    $(`.table-dropdown`).each(function (button_index) {
        let rowParent = getFieldParent(this, `table__container--single`);
        let firstContentContainer = $(rowParent).find(`.table__container--content`)[0];
        let linkContent = $(firstContentContainer).find(`a`);
        
        let firstContent;
        if (linkContent.length) {
            firstContent = linkContent[0].innerText;
        } else {
            firstContent = firstContentContainer.innerText;
        };

        let newWrapper = `
            <div class="table__container--toggle">
                <a href="#" class="flaticon2-arrow table-dropdown"></a>
                <p>${$(firstContentContainer).attr(`data-label`)}</p>
                <p>${firstContent}</p>
            </div>
        `;
        $(this).remove();
        $(rowParent).prepend(newWrapper);
    });

    let parentTargetClass;
    let dossierTables = $(`.dossier-tables__wrapper`);
    if(dossierTables.length){
        parentTargetClass = 'dossier-tables__wrapper';
    }else{
        parentTargetClass = 'table__container--wrapper';
    };

    let expandAllButton = $(`.js-table-expand-all`);
    $(expandAllButton).bind(`click`,function(event){
        let globalState = this.className.indexOf('table-dropdown--active') != -1;
        if(globalState){
            $(this).removeClass(`table-dropdown--active`);
        }else{
            $(this).addClass(`table-dropdown--active`);
        };
        let tableParent = getFieldParent(this,parentTargetClass);
        $(tableParent).find(`.table-dropdown`).each(function(index){
            let content = $(getFieldParent(this,'table__container--single')).find(`.table__container--content`);
            set_tableEntryExpanded(!globalState,content,this);
        });
    });

    $(expandAllButton).click();
};

//TARGET ALL TABLE DROPDOWN BUTTONS AND BIND CLICK LISTENERS
function load_tableExpandListeners() { $(`.table-dropdown`).bind(`click`, handle_tableEntryExpand) };

//TOGGLES THE MOBILE TABLE EXPAND/CONTRACT
function handle_tableEntryExpand(event) {
    event.preventDefault();
    let eventParent = getFieldParent(event.target, 'table__container--single');
    let tableContent = $(eventParent).find(`.table__container--content`);
    let state = event.target.className.indexOf(`table-dropdown--active`) == -1;
    set_tableEntryExpanded(state,tableContent,event.target);
    event.target.blur();
};

function set_tableEntryExpanded(state,content,button){
    if(!state){
        $(content).removeClass(`table__content--reveal`);
        $(button).removeClass(`table-dropdown--active`);
    }else{
        $(content).addClass(`table__content--reveal`);
        $(button).addClass(`table-dropdown--active`);
    };
};

//TARGET THE TABLE'S ASSOCIATED FILTER CONTAINER TOGGLER AND BIND THE CLICK HANDLER TO IT
function load_filtersToggle() { 
    let filtersToggle = $(`.filters__container--toggle`);

    if($(filtersToggle).length){
        let local_ToggleEnabledSetting = true;
        if(!local_ToggleEnabledSetting){ 
            $(filtersToggle).remove();
        }else{ 
            $(filtersToggle).bind(`click`, handle_filtersToggle);
        };
    };
};

//TOGGLES THE TABLE'S ASSOCIATED FILTER CONTAINER EXPAND/CONTRACT FOR MOBILE SCREENS
function handle_filtersToggle(event) {
    event.stopPropagation();
    event.preventDefault();
    let eventParent = getFieldParent(event.target, `filters__container--wrapper`);
    let eventToggler = getFieldParent(event.target, `filters__container--toggle`);
    let filterArrow = $(eventToggler).find(`.filters__arrow`);
    let isActive = $(eventToggler).find(`.filters__toggle--flip`).length;
    let displayTargets = $(eventParent).find(` > *:not(.filters__container--toggle)`);
    if (isActive) {
        $(displayTargets).addClass(`filters__display--none`);
        $(eventToggler).removeClass(`filters__toggle--active`);
        $(filterArrow).removeClass(`filters__toggle--flip`);
    } else {
        $(displayTargets).removeClass(`filters__display--none`);
        $(eventToggler).addClass(`filters__toggle--active`);
        $(filterArrow).addClass(`filters__toggle--flip`);
    };
};

//----ApplyTKD map, Variant 1---- ALSO PART OF VARIANT 3(mix)
function load_TKStepMap(link,hook){
    if(link.indexOf(hook) != -1){
        let labels = ['Personalia', 'Work Experience', 'Education', 'Professional Skills'];
        let sliceStart = link.indexOf(hook) + hook.length + 1;
        let sliceEnd = sliceStart + 1;
        let step = link.slice(sliceStart,sliceEnd);
        let mapContainer = document.createElement('div');
        mapContainer.className = 'tk-step--container'
        let mapItems = ``;
        for(let i = 0; i < 4; i++){
            let itemClass = '';
            if(i + 1 <= step){
                itemClass = 'tk-step--previous';
            };
            if(i > 0){
                let itemBridge = `
                    <div class="tk-step--bridge ${itemClass}">
                    </div>
                `;
                mapItems += itemBridge;
            };
            let mapItem = `
                <div class="${itemClass} tk-step">
                    ${i + 1}
                    <p class="tk-step--label">${labels[i]}</p>
                </div>
            `;
            mapItems += mapItem;
        };
        mapContainer.innerHTML = mapItems;
        $(`.profile__container--wrapper`).prepend(mapContainer);
        load_TKStepNav();
    };
};

function load_TKStepNav(){
    let navContainer = $(`.navigation__container--actions`);
    if(navContainer.length){ $(`.tk-step--container`).prepend(navContainer) };
};

function load_navigationLevel3(){
    // console.log($(`.navigation__container--detailed`));
    // console.log(`loading nav lvl 3`);
    let navContainer = $(`.navigation__container--detailed`);
    if(navContainer.length){
        let link = location.href;
        let subHeader = $(`.sub-header__container--wrapper`);
        $(`.sub-header__content--button`).remove();
        let navItems = $(navContainer).find(`a`);
        // console.log(`showing page url`);
        // console.log(link);
        let highlightFlag = 0;
        $(navItems).each(function(item_index){
            // console.log(`showing nav item url`);
            let highlightDisabled = ``;
            if(this.className.indexOf('disabled') != -1){
                highlightDisabled = `sub-header__content--disabled`;
                $(this).addClass('disabled');
            };
            // console.log(this.href);
            let itemHighlight = ``;

            if(link.indexOf(this.href) != -1 && !highlightFlag){
                // console.log(`nav item url matches page url`);
                itemHighlight = 'sub-header__content--selected'
                highlightFlag = 1;
            };

            // if(link == this.href && !highlightFlag){
            //     // console.log(`nav item url matches page url`);
            //     itemHighlight = 'sub-header__content--selected'
            //     highlightFlag = 1;
            // };

            this.className = `sub-header__nav-item`
            let itemWrapper = document.createElement('div');
            itemWrapper.className = `sub-header__content--button ${itemHighlight} ${highlightDisabled}`;
            itemWrapper.innerHTML = this.outerHTML;
            $(subHeader).append(itemWrapper);
            this.remove();
        });
        if(!highlightFlag){
            // console.log(`did NOT find matching url`);
        };
    };
};

function cloneToSub(){
    let profileActions = $(`.profile__options--actions`);
    let submitDates = $(profileActions).find(`.header-dates`);
    let submitButton = $.merge($(profileActions).find(`button`),$(profileActions).find(`a`));

    let hiddenFilterSelector = `js-filter-hidden`;
    let hiddenFilter = $(`.${hiddenFilterSelector}`);

    let subHead = $(`.sub-header__container--right`);
    if(submitDates.length){
        $(submitDates).clone(true).appendTo(subHead);
    };
    if(hiddenFilter.length){
        let currentHiddenValue = 0;
        
        if($(`.notes__dashboard`).length){
            currentHiddenValue = $(`.filter-hidden`)[0].__vue__.$children[0].value;
            if(currentHiddenValue != 0){ 
                $(`.notes__dashboard`).find(`.flaticon-eye`).removeClass(`eye-crossed`) 
            };

            $(`.notes__dashboard`).find(`.${hiddenFilterSelector}`).bind('click',function(event){ 
               handleEyeClick(`.notes__dashboard`);
            });
        }else{
            $(hiddenFilter).clone(true).appendTo(subHead);
            currentHiddenValue = $(`.filter-hidden`)[0].__vue__.startvalue;
            if(!currentHiddenValue){ $(subHead).find(`.flaticon-eye`).addClass(`eye-crossed`) };

            $(subHead).find(`.${hiddenFilterSelector}`).bind('click',function(event){ 
               handleEyeClick(subHead);
            });
        };

        function handleEyeClick(wrapper){
            ($(`#q_show_hidden`)[0] || $(`#show_hidden`)[0]).click();
            $(wrapper).find(`.flaticon-eye`).first().toggleClass(`eye-crossed`);
        };
    };
    if(submitButton.length){
        $(submitButton).clone(true).appendTo(subHead);
    };
};

function load_documentFilePickers(){
    let filePickers = $(`.file-picker__input`);
    if(filePickers.length){
        //console.log('file selectors exist');
        $(filePickers).each(function(index){
            let pickerInput = this;
            let pickerLabel = $(this).find(` ~ .file-picker__label p`)[0];
            pickerInput.addEventListener('change',function(event){
                //console.log(`change event detected`,event);
                let fileName = pickerInput.files[0].name;
                let limitLabelLength = 20;
                if(fileName.length > limitLabelLength){
                    let extensionIndex = fileName.lastIndexOf('.');
                    let fileExtension = fileName.slice(extensionIndex);
                    let limitLabelStart = fileName.slice(0,limitLabelLength - 2 - fileExtension.length);
                    fileName = `${limitLabelStart}${fileExtension}`;
                };
                pickerLabel.innerText = fileName || 'Choose File';
            });
        });
    }else{
        //console.log('NO file selectors exist');
    };
};

function load_CKEditorExternalBindings(){
    if($(`#ck-editor`).length){
        //BINDING THE 'PREVIEW' BUTTON
        // console.log(`ck editor bindings`);
        let previewButton = $(`.js-ck-editor__button--preview`);
        $(previewButton).bind('click',() => { $(`.ck-button--preview`)[0].click() });

        //BINDING THE 'SAVE AS DRAFT' BUTTON
        let saveDraftButton = $(`.js-ck-editor__button--draft`);
        $(saveDraftButton).bind('click',() => {
            let articleHTML = $(`.ck-editor__main .ck-content`)[0].innerHTML.replace('<p><br data-cke-filler="true"></p>','');
            // console.log(articleHTML);
        });

        //BINDING CKEDITOR'S WINDOW RESIZE HANDLER
        let oldWidth = window.innerWidth;
        let mediaResponseWidth = 650;
        if(oldWidth <= mediaResponseWidth){
            setTimeout(() => { handle_CKEditorResize({'switchNarrow': true}) },1000);
        };
        window.addEventListener('resize',function(event){  handle_CKEditorResize() } );

        function handle_CKEditorResize(options){
            if($(`#ck-editor`).length){
                options = options || {};

                let currentWidth = window.innerWidth;
            
                let currentTest = currentWidth > mediaResponseWidth;
                let oldTest = oldWidth <= mediaResponseWidth;
            
                let switchWide = options.switchWide || (currentTest && oldTest);
                let switchNarrow = options.switchNarrow || (!currentTest && !oldTest);
            
                if(switchWide){
                    console.log('enable editing');
                    $(`.ck-editor__editable`)[0].contentEditable = true;
                }else if(switchNarrow){
                    console.log('disable editing');
                    $(`.ck-editor__editable`)[0].contentEditable = false;
                };
                
                oldWidth = currentWidth;
            };
        };

        //MOVING THE EXTERNAL CK EDITOR BUTTONS TO WITHIN THE JOB REQUEST FORM
        $(`#ck-menu`).replaceWith($(`#js-ck-menu`));
    };
};

function load_tallTextareas(event){
    // console.log('loading textareas');
    let textareas = $(`.textarea-tall`);
    textareas.each(function(){
        let textarea = $(this).find(`textarea`);
        let areaParent = textarea[0].parentElement;

        if($(areaParent).find(`.textarea-fitter`).length == 0){ 
            let fitter = document.createElement('div');
            fitter.setAttribute('class','textarea-fitter');
            fitter.style.setProperty('height','fit-content');
            fitter.innerText = textarea[0].value;
            $(areaParent).append(fitter);
        };

        let waitFlag = true;
        let interval =  setInterval(() => {
            if(waitFlag){ waitFlag = false }else{
                let newHeight = `${19 + Number($(areaParent).find(`.textarea-fitter`).css('height').replace('px',''))}px`;
                textarea.css('max-height',newHeight);
                textarea.css('height',newHeight);
                clearInterval(interval);
            };
        },400);
    });
};

window.addEventListener('resize',(event) => { load_tallTextareas() });

function load_dossierExpandButtons(){
    let expandButtons = $(`.js-dossier-notes__button--expand`);
    if(expandButtons.length){ $(expandButtons).bind('click',handle_dossierExpandButtons) };
    
    let showButtons = $(`.js-dossier-notes__button--show`);
    if(showButtons.length){ $(showButtons).bind('click',handle_dossierShowButtons) };

    let dossierButtons = $(`.js-dossier-expand`);
    if(dossierButtons.length){ 
        $(dossierButtons).bind('click',handle_dossierButtons); 
    };

    let dossierHistory = $(`.js-dossier-history`);
    if(dossierHistory.length){
        $(dossierHistory).bind(`click`,handle_dossierHistory);
    };
};

function handle_dossierHistory(event){
    // console.log(`called handle_dossierHistory()`);
    let dossierOuter = getFieldParent(event.target,'dossier-outer');

    let expandTargetSelector;
    let expandedClass = `js-dossier-history--expanded`;
    let historyButton = $(dossierOuter).find(`.js-dossier-history`);
    if(historyButton[0].className.indexOf(expandedClass) == -1){
        historyButton.addClass(expandedClass);
        historyButton.find(`p`)[0].innerHTML = `Contract all`;
        expandTargetSelector = `.js-dossier-expand:not(.js-dossier-expanded)`;
    }else{
        historyButton.removeClass(expandedClass);
        historyButton.find(`p`)[0].innerHTML = `Expand all`;
        expandTargetSelector = `.js-dossier-expanded`; 
    };
    let expandTargets = $(dossierOuter).find(expandTargetSelector);
    if(expandTargets.length){ 
        // window.lockBackground = true;
        toggle_loadingBackground(`block`);
        loadDossier(expandTargets,0) 
    };
};

function loadDossier(expandButtons,index){
    // console.log(`calling loadDossier index - ${index}`);
    if(index < expandButtons.length){
        // console.log(`make the call`);
        let currentButton = expandButtons[index];
        let recordParent = $(getFieldParent(currentButton,'dossier-table')).find(`.dossier__statuses--wrapper`);
        let VueInstance = recordParent[0].__vue__;
        if(!VueInstance.dataLoaded){
            VueInstance.loadStatuses((Data) => {
                // console.log(`loadDossier callback index - ${index}`);
                VueInstance.dataLoaded = true;
                VueInstance.statusArray = Data.results;
                VueInstance.initializeArrayKeys();
                currentButton.click();
                loadDossier(expandButtons,index + 1);
            });
        }else{
            currentButton.click();
            loadDossier(expandButtons,index + 1);
        };
    }else{
        // console.log(`block the call`);
        // window.lockBackground = false;
        toggle_loadingBackground(`none`);
    };
};

function handle_dossierButtons(event){
    // console.log(`calling handle_dossierButtons()`);
    let dossierTable = getFieldParent(event.target,'dossier-table');

    let statusWrapper = $(dossierTable).find(`.dossier__statuses--wrapper:not(.status-loaded)`);
    if(statusWrapper.length){
        statusWrapper.addClass('status-loaded');
        statusWrapper.click();
    };

    let targetButton = getFieldParent(event.target,'js-dossier-expand');
    if(targetButton.className.indexOf('expanded') != -1){
        $(targetButton).removeClass('js-dossier-expanded');
        $(dossierTable).removeClass('dossier-table--expanded');
    }else{
        $(targetButton).addClass('js-dossier-expanded');
        $(dossierTable).addClass('dossier-table--expanded');
    };
};

function handle_dossierExpandButtons(event){
    console.log(`calling handle_dossierExpandButtons()`);
    let showClass = `dossier-notes__wrapper--show`;
    let showText = ``;

    let notesWrapper = getFieldParent(event.target,'dossier-notes__wrapper');
    if(notesWrapper.className.indexOf(showClass) != -1){
        $(notesWrapper).removeClass(showClass);
        showText = `Show changes`;
    }else{
        $(notesWrapper).addClass(showClass);
        showText = `Hide changes`;
    };
    $(notesWrapper).find(`p`)[0].innerText = showText;
};

function handle_dossierShowButtons(event){
    console.log(`calling handle_dossierShowButtons()`);
    let showClass = `dossier-notes__container--show`;
    let showText = ``;

    let notesWrapper = getFieldParent(event.target,'dossier-notes__container');
    if(notesWrapper.className.indexOf(showClass) != -1){
        $(notesWrapper).removeClass(showClass);
        showText = `View notes`;
    }else{
        $(notesWrapper).addClass(showClass);
        showText = `Hide notes`;
    };
    $(notesWrapper).find(`button p`)[0].innerText = showText;
};

function copyNoteButton(){
    // START
    //USED FOR LIMITING STATUS TRANSITIONS
    $(`.note-button-copy-small`).remove();
    $(`.note-button-copy-mid`).remove();
    $(`.main__container--wrapper`).removeClass(`notes-marker`);
    // STOP

    let notesMenu = $(`.notes-menu__wrapper`);
    if(notesMenu.length){
        $(`.main__container--wrapper`).addClass(`notes-marker`);

        let notesButton = $(notesMenu).find(`.notes-menu__button`);

        let mobileSearch = $(`.mobile__search--container`)
        $(notesButton).clone(true).appendTo(mobileSearch);

        let mobileHeaderMenu = $(`.header__container--mobile .header-menu__container--wrapper`);
        $(notesButton).clone(true).appendTo(mobileHeaderMenu);


        let mobileButton = $(mobileSearch).find(`.notes-menu__button`);
        $(mobileButton).addClass(`note-button-copy-small`);

        let newButton = $(mobileHeaderMenu).find(`.notes-menu__button`);
        $(newButton).addClass('note-button-copy-mid');

        let showClass = 'notes-menu__button--show';
        
        let buttons = $.merge(newButton, mobileButton);
        $(buttons).unbind();
        $(buttons).bind('click',(event) => { notesButton[0].click() });
        $(buttons).each(function(index){ 
            this.setButtonState = function(){ 
                $(this).removeClass(showClass);
                if(notesButton[0].className.indexOf(showClass) == -1){ $(this).addClass(showClass) };
            }; 
        });
    };

    if($(`.dossier-tables__wrapper`).length){
        // START
        //USED FOR LIMITING STATUS TRANSITIONS
        $(`.job-req-candidate-status`).unbind();
        $(`.js-add-status-note`).unbind();
        // STOP

        $(`.job-req-candidate-status:not(.active):not(.inactive):not(.status-change--disabled)`).bind(`click`,handle_changeStatus);
        $(`.js-add-status-note`).bind('click',handle_addLastStatusNote);
    };
};

function handle_addLastStatusNote(event){
    // console.log('---calling jr status handle---');
    let status = event.target;

    let dossierTable = getFieldParent(status,'dossier-table');
    let dossierTableState = Number(dossierTable.className.indexOf('dossier-table--expanded') != -1);
    
    // console.log(`dossier table state - '${dossierTableState}'`);
    let expandButton = $(dossierTable).find(`.js-dossier-expand`);
    let dossierVueInstance = $(dossierTable).find(`.dossier__statuses--wrapper`)[0].__vue__;
    if(!dossierVueInstance.dataLoaded){
        // console.log(`data not loaded`);
        dossierVueInstance.loadStatuses((Data) => {
            dossierVueInstance.dataLoaded = true;
            dossierVueInstance.statusArray = Data.results;
            dossierVueInstance.initializeArrayKeys();
            toggleNewNote();
        });
    }else{
        // console.log(`data is loaded`);
        toggleNewNote();
    };

    function toggleNewNote(){
        // console.log(`toggleNewNote`);
        if(!dossierTableState){ expandButton.click() };
        dossierVueInstance.$nextTick(() => {
            if($(dossierTable).find(`.notes__container--outer`).first().find(`.single-note__button--expand`)[0] == undefined){
                toggleNewNote()
            }else{
                $(dossierTable).find(`.notes__container--outer`).first().find(`.single-note__button--expand`)[0].click();
            };
        });
    };
};

function handle_changeStatus(event){
    // if(confirm(`Change the candidate's status to ${event.target.title}?`)){
    toggle_loadingBackground(`block`);
    let newStatusName = event.target.innerText;
    let newStatusId = 0;
    let newStatusElement;

    $(getFieldParent(event.target,'table__cell--status'))
        .find(`.job-req-candidate-status`)
        .each(function(statusIndex){ 
            $(this).removeClass(`active`).removeClass(`inactive`);
            if(this.innerText == newStatusName){ 
                newStatusElement = this;
                newStatusId = $(this).attr(`data-code`);
            };
        });
        
    let dossierTable = getFieldParent(event.target,'dossier-table');
    let dossierTableState = Number(dossierTable.className.indexOf('dossier-table--expanded') != -1);

    if(newStatusId == 2){ toggle_loadingBackground('none') };
    let dossierVue = $(getFieldParent(event.target,'table__container--single')).find(`.dossier__statuses--wrapper`)[0].__vue__;
    dossierVue.updateStatus(newStatusId,(Data) => {
        $(newStatusElement).addClass(Data.status.selected_class_name);
        $(dossierTable).find(`.dossier__statuses--wrapper:not(.status-loaded)`).addClass(`status-loaded`);
        dossierVue.statusArray = [];
        dossierVue.loadStatuses((Data) => {
            dossierVue.dataLoaded = true;
            dossierVue.statusArray = Data.results;
            dossierVue.initializeArrayKeys();
            if(!dossierTableState){ $(dossierTable).find(`.js-dossier-expand`).click() };
            $(dossierTable).find(`.js-add-status-note`).click();
            copyNoteButton();
            toggle_loadingBackground(`none`);
        });
    });
    // };
};

function relocateFilters(){
    let forms = document.querySelectorAll(`form`);
    

    if(forms.length){ 
        let formID = forms[0].id;

        window.addEventListener(`resize`,(event) => { checkMedia() });
        
        let triggerWidth = 1081;
        let previousWidth = window.innerWidth;
        let flagInitial = true;
        let rulesInitial = previousWidth > triggerWidth;
    
        let mainContainer = $(`#vueApp`);
        let filtersWrapper = $(`.filters--wrapper`);
        let selectorStartWith = `filter-start-with`;
        let filterStartWith = $(`.${selectorStartWith}`);
    
        function checkMedia(){
            if(flagInitial){
                if(rulesInitial){ doMediaBig() }else{ doMediaSmall() };
                flagInitial = false;
                return;
            };
    
            let currentWidth = window.innerWidth;
            let rules = {
                'big': (currentWidth > triggerWidth && previousWidth <= triggerWidth),
                'small': (currentWidth <= triggerWidth && previousWidth > triggerWidth)
            };
            if(rules.big){ doMediaBig() }else if(rules.small){ doMediaSmall() };
            previousWidth = window.innerWidth;
        };
    
        function doMediaSmall(){
            $(filtersWrapper)
                .append(filterStartWith)
                .find(` > .${selectorStartWith}`)
                .find(`.form-data-target`)
                .attr('form',formID);
        };
    
        function doMediaBig(){
            $(mainContainer)
                .append(filterStartWith)
                .find(` > .${selectorStartWith}`)
                .find(`.form-data-target`)
                .attr('form',formID);
        };
    
        checkMedia();
    };
};

function handle_deleteApplication(event){
    let rowParent = getFieldParent(event.target,`table__container--single`);
    let dossierWrapper = $(rowParent).find(`.dossier__statuses--wrapper`);
    let candidateName = $(rowParent).find(`.table__container--content[data-label='Name:'] a`)[0].innerText;
    if(confirm(`Delete ${candidateName}'s application?`)){ let candidateId = dossierWrapper[0].__vue__.candidateid };
};

function test_findExternalDossier(){
    let testValue = $(`#test-id`)[0].innerText;
    let currentPageArray = location.href.split('/');

    let newPageString = ``;
    if(testValue == 0){
        currentPageArray[4]++;
        currentPageArray.forEach((string) => { newPageString = `${newPageString}/${string}` });
        newPageString = newPageString.replace(`/`,``);
        window.open(newPageString,`_top`);
    }else{
        alert(`FOUND JOB REQUEST WITH EXTERNAL CANDIDATE`);
    };
};

function load_bellNotification(){
    if ($('.header-icon__container--bell').length > 0) {
        ajax('GET','/overdue-count',(Response) => { notifyOverdues(JSON.parse(Response.response).overdue) });

        function notifyOverdues(total){
            if(total){
                let bellIcon = $(`.header-icon__container--bell.header-icon`);
                $(bellIcon).addClass(`notifications-active`);
                $(`
                    <a class="overdue-counter" href="/">${total}</a>
                    <div class="info info--overdue">You have ${total} overdue tasks!</div>
                `).appendTo(bellIcon);
                $(bellIcon).find(`.info`).bind(`click`,() => { $(bellIcon).find(`.overdue-counter`)[0].click() });
            };
        };
    }
};

function load_addCandidateToDossier(){
    let addCandidateForm = $(`.form__add-candidate`);
    if(addCandidateForm.length){
        addCandidateForm.each(function(formIndex){
            let selectField = $(this).find(`.form-dropdown`);
            let dropdownVueInstance = selectField[0].__vue__.$children[0];
            let addButton = $(this).find(`.action__button`);
            setAddButtonActiveState(addButton,false);
            let checkOptionsLoaded = setInterval(() => {
                if(dropdownVueInstance.dropdownOptions.length){
                    let fieldOptions = $(this).find(`.profile__container--options`);
                    $(fieldOptions).bind(`click`,function(event){
                        dropdownVueInstance.$nextTick(() => { setAddButtonActiveState(addButton,dropdownVueInstance.selectedOption.id) });
                    });
                    clearInterval(checkOptionsLoaded);
                };
            },10);
        });

        function setAddButtonActiveState(addButton,active){
            $(addButton).attr('disabled',!active);
            if(active){
                $(addButton).removeClass(`action__button--disabled`);
            }else{
                if($(addButton)[0].className.indexOf('action__button--disabled') == -1){ $(addButton).addClass('action__button--disabled') };
            };
        };
    };

};

function load_ckPreview(){ 
    $(`.js-ck-preview`).bind('click',function(){
        let ckEditor = $(this).parents(`.profile__container--main`);
        let previewButton = $(ckEditor).find(`.ck-button--preview`);
        $(previewButton).click();
    })
};

function build_midTables(){
    let tableParents = $(`.table--mid-target`);
    $(tableParents).each(function(tableIndex){
        let tableHead = `<div class="table--head table--row">`;

        let labels = $(this).find(`.table__container--label.table-mid`);
        $(labels).each(function(label_index){
            tableHead = `${tableHead}${getLabelHTML(this)}`;
        });

        tableHead = `${tableHead}</div>`;
        // console.log(tableHead);

        let tableBody = `<div class="table--body">`;

        let rows = $(this).find(`.table__container--single`);
        $(rows).each(function(rowIndex){
            let tableRow = `<div class="table--row">`;

            let rowContent = $(this).find(`.table__container--content.table-mid`);
            $(rowContent).each(function(columnIndex){
                tableRow = `${tableRow}${getContentHTML(this)}`;
            });

            tableRow = `${tableRow}</div>`;
            tableBody = `${tableBody}${tableRow}`;
        });

        tableBody = `${tableBody}</div>`;
        // console.log(tableBody);

        let tablePagination = $(this).find(`.table__pagination--wrapper`)[0].outerHTML;

        let pageClass = this.classList[this.classList.length - 1];

        let midTable = document.createElement('div');
        midTable.setAttribute('class',`table--mobile ${pageClass}`);
        midTable.innerHTML = `
            ${tableHead}
            ${tableBody}
            ${tablePagination}
        `;

        $(midTable).insertAfter(this);



        let doubleLabel = $(midTable).find(`.table--label.table-double--0`);
        if(doubleLabel.length){
            $(doubleLabel[0]).find(`p`).append($(doubleLabel[1]).find(`p`)[0].innerHTML);
            $(doubleLabel[1]).remove();
        };

        let midRows = $(midTable).find(`.table--row`);

        $(midRows).each(function(rowIndex){
            let doubleContent = $(this).find(`.table--content.table-double--0`);
            if(doubleContent.length){
                doubleContent[0].innerHTML = `
                    <div>${doubleContent[0].innerHTML}</div>
                    <div>${doubleContent[1].innerHTML}</div>
                `;
                doubleContent[1].remove();
            };
        });

        // console.log(midTable);
    });



    function getLabelHTML(originalLabel){
        let addedClass = ``;
        if(originalLabel.className.indexOf('table-double') != -1){
            let labelClass = originalLabel.className;
            let doubleIndex = labelClass.indexOf('table-double');
            addedClass = labelClass.slice(doubleIndex,doubleIndex + 15);
        };
        return `<div class="table--label ${addedClass}">${originalLabel.innerHTML}</div>`;
    };

    function getContentHTML(originalContent){
        let addedClass = ``;
        if(originalContent.className.indexOf('table-double') != -1){
            addedClass = 'content--rows-2 table-double--0';
        };
        return `<div class="table--content ${addedClass}">${originalContent.innerHTML}</div>`
    };
};

function load_documentIconClick(){
    $(`.document-table__container`).each(function(index){
        let submitButton = $(this).find(`.file-picker__label ~ input`);
        $(submitButton).bind(`mouseenter`,(event) => { $(submitButton).css('background-color','#f98f16') });
        $(submitButton).bind(`mouseout`,(event) => { $(submitButton).css('background-color','rgb(29, 201, 183)') });

        let uploadIcon = $(this).find(`.flaticon-upload-1`);
        $(uploadIcon).bind(`click`,(event) => { $(submitButton).click() });
        $(uploadIcon).bind(`mouseenter`,(event) => { $(submitButton).css('background-color','#f98f16') });
        $(uploadIcon).bind(`mouseout`,(event) => { $(submitButton).css('background-color','rgb(29, 201, 183)') });
    });
};

function removeDuplicateCKWrappers(){
    document.querySelectorAll(`[id=ck-note--wrapper]`).forEach((El, i) => { if(i > 0){ El.remove() } });
};

function loadDashboardListTitles(){
    let lists = $(`.dashboard-list`);
    if(lists.length){
        $(lists).each(function(index){
            let tableOuter = $(this).find(`.table__container--outer`);
            let listTitle = $(this).find(`.dashboard-list-title`);
            $(tableOuter).prepend(listTitle);
        });
    };
};

function orderDossiers(){
    let dossierWrapper = $(`.dossier-tables__wrapper`);
    if(dossierWrapper.length){
        $(dossierWrapper).each(function(index){
            let activeStatuses = $(this).find(`.active:not(.jr-status)`);
            let inactiveStatuses = $(this).find(`.inactive`);
            let setStatuses = $.merge(activeStatuses,inactiveStatuses);

            let currentWrapper = $(this).find(`.dossier-outer`);

            $(setStatuses).each(function(){
                $(currentWrapper).append($(this).parents(`.dossier-table--wrapper`));
            });
        });
    };
};

function padPermissionsTable(){
    let url = window.location.href;
    if(url.indexOf(`staff_roles/`) != -1){
        console.log(`pad table`)

        let templatePad = `<div class="checkbox-inline table__container--content"></div>`;

        let permissionRows = $(`.table__container--single`);
        console.log(permissionRows);

        let maxCols = 0;
        $(permissionRows).each(function(index){
            let currentCols = $(this).find(`.table__container--content`);
            if(currentCols.length > maxCols){ maxCols = currentCols };
        });

        $(permissionRows).each(function(index){
            let currentCols = $(this).find(`.table__container--content`);
            if(currentCols.length < maxCols){
                for(let i = currentCols.length; i < maxCols; i++){
                    $(this).append($(templatePad));
                };
            };
        });

    }else{ console.log(`no table to pad`) };
};


function test_findCandidateDossierMismatch(){
    let lists = $(`.candidate-list--status`);
    if(lists.length){
        let interval = setInterval(() => {
            let mismatchFlag = false;
        
            $(lists).each(function(index){
                let counter = $(this).find(`.candidate-dossier-counter`)[0].innerText;
                let titles = $(this).find(`.search-list__jr-list div`);
                console.log(counter,titles.length);
        
                if(!mismatchFlag){
                    mismatchFlag = (counter != titles.length);
                };
            });
        
            console.log(`mismatch found - ${mismatchFlag}`);
        
            if(!mismatchFlag){ console.clear(); $(`.next`).find(`a`)[0].click() };

            clearInterval(interval);
        },5000);
    };
};

function test_floodNoteMenu(){
    let notes = $(`.notes__container--single:not(.notes__container--single-new)`);
    console.log(`note length`,notes.length);
    if(notes.length == 0){ 
        let nonBlockingTimeout = setInterval(() => {
            clearInterval(nonBlockingTimeout);
            test_floodNoteMenu();
        },500);
    }else if(notes.length > 99){
        console.log(`test finished`);
    }else{
        let newNoteVue = $(`.notes__container--single-new`)[0].__vue__.$children[0];
        newNoteVue.toggleNoteExpand();
        newNoteVue.$nextTick(() => { 
            newNoteVue.createNote();
            let nonBlockingTimeout = setInterval(() => {
                clearInterval(nonBlockingTimeout);
                test_floodNoteMenu();
            },500);
        });
    };
};

function test_flushNoteMenu(){
    let notes = $(`.notes__container--single:not(.notes__container--single-new)`);
    console.log(`note length`,notes.length);
    if(notes.length == 0){ 
        let nonBlockingTimeout = setInterval(() => {
            clearInterval(nonBlockingTimeout);
            test_flushNoteMenu();
        },500);
    }else if(notes.length < 10){
        console.log(`test finished`);
    }else{
        let newNoteVue = $(`.notes__container--single:not(.notes__container--single-new)`)[0].__vue__;
        newNoteVue.toggleNoteExpand();
        newNoteVue.$nextTick(() => { 
            newNoteVue.deleteNote();
            let nonBlockingTimeout = setInterval(() => {
                clearInterval(nonBlockingTimeout);
                test_flushNoteMenu();
            },500);
        });
    };
};

window.floodNotes = test_floodNoteMenu;
window.flushNotes = test_flushNoteMenu;
//DEBUGGING FUNCTION THAT LOGS ALL FUNCTION CALLS
// (function(){
//     let call = Function.prototype.call;
//     Function.prototype.call = function(){
//         if(
//             this.toString().indexOf(`jQuery`) == -1
//             && this.toString().indexOf(`native`) == -1
//             && this.toString().indexOf(`Native`) == -1
//             && this.toString().indexOf(`WEBPACK`) == -1
//             && this.toString().indexOf(`webpack`) == -1
//             && this.toString().indexOf(`Rails`) == -1
//         ){
//             console.log(this.toString(), arguments[0]);
//         };
//         return call.apply(this, arguments);
//     };
// })();


