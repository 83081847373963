module.exports = {
    alphabetical: function(array){
        let ParentObject = this;
        let outputArray = [];
        $(array).each(function (arrayIndex) {
            if (!arrayIndex) {
                outputArray.push(this.toString());
            } else {
                let currentItem = this;
                $(outputArray).each(function (outputIndex) {
                    let compareResult = ParentObject.compareSmallerCharCode(currentItem, this);
                    if (compareResult) {
                        outputArray.splice(outputIndex, 0, currentItem.toString());
                        return false;
                    };
                    if (outputArray.length - 1 == outputIndex) {
                        outputArray.push(currentItem.toString());
                    };
                });
            };
        });
        return outputArray;
    },
    compareSmallerCharCode: function(stringOne,stringTwo){
        let oneLetters = stringOne.trim();
        let twoLetters = stringTwo.trim();
        let maxComparison = Math.min(oneLetters.length, twoLetters.length) + 1;
        for (let i = 0; i < maxComparison; i++) {
            let oneCurrent = oneLetters.slice(i, i + 1);
            let twoCurrent = twoLetters.slice(i, i + 1);
            let oneCode = String(oneCurrent).charCodeAt() || Infinity;
            let twoCode = String(twoCurrent).charCodeAt() || Infinity;
            if(oneCode > 96){
                oneCode = oneCode - 32;
            };
            if(twoCode > 96){
                twoCode = twoCode - 32;
            };
            let compared = oneCode - twoCode;
            if (compared == 0) {
                continue;
            } else if (compared < 0) {
                return true;
            } else if (compared > 0) {
                return false;
            };
        };
    },
    vueObjects(objectsArray){
        let ParentObject = this;
        let selectedNamesArray = [];
        $(objectsArray).each(function(){
            selectedNamesArray.push(this.name);
        });
        let orderedNamesArray = ParentObject.alphabetical(selectedNamesArray);
        let orderedObjectsArray = [];
        $(orderedNamesArray).each(function(){
            orderedObjectsArray.push({
                'name': `${this}`,
                'id': objectsArray[selectedNamesArray.indexOf(`${this}`)].id
            });
        });
        return orderedObjectsArray;
    }
};